<script>
  import SignIn from './SignInForm.svelte';
  import ResetPassword from './ResetPasswordForm.svelte';
  import AssignNewPassword from './AssignNewPassword.svelte';
  import Register from './RegisterForm.svelte';
  import Register2 from './RegisterForm2.svelte';
  import Invitation from './RequestInvitationForm.svelte';
  import {onMount} from "svelte";
  import {UrlService} from "../../comm/UrlService";
  import FullHeight from "../layout-helpers/FullHeight.svelte";
  import LicenseAgreement from "./LicenseAgreement.svelte";
  import UserOptions from "../user-options/UserOptions.svelte";
  import SideBySidePane from "../panes/SideBySidePane.svelte";
  import PaneManager from "../panes/PaneManager.svelte";
  import Navigator from "../navigator/Navigator.svelte";
  import logo from '../../assets/images/logo-dark.svg';

  let resetKey;
  let date = new Date();
  const RESET_KEY_PARAM = 'resetKey';
  const LOGIN_STATE = {
    SignIn: 'SignIn',
    Register: 'Register',
    ResetPass: 'ResetPass',
    EnterNewPass: 'EnterNewPass',
    Invite: 'Invite',
  };

  let loginState = LOGIN_STATE.SignIn;

  onMount(() => {
    const isPageEvent = UrlService.isParamInURL(RESET_KEY_PARAM);
    if (isPageEvent) {
      resetKey = UrlService.getPathValue(RESET_KEY_PARAM);
      loginState = LOGIN_STATE.EnterNewPass;
    }
  });

  function onChangeLoginState(data) {
    loginState = data.detail;
  }
</script>

<style lang="scss">
  :global(.login-layout-wrap) {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  :global(.login) {
    font-family: 'Open Sans', sans-serif, Arial;
    font-style: normal;
  }

  :global(button) {
    outline: none;
    height: 35px;
    width: 150px;
    background-color: #6f117f;
    border-radius: 5px;
    color: white;
    border: solid 1px #6f117f;
    cursor: pointer;
  }

  :global(button:hover) {
    background-color: #ffc359;
    border-color: #ffc359;
    color: black;
  }

  :global(button:disabled) {
    background-color: #d8d4fc;
    border-color: #d8d4fc;
  }

  :global(input, textarea) {
    width: 100%;
    height: 60px;
    outline: none;
    border-radius: 5px;
    border-color: #d8d4fc;
    border-width: 2px;
    margin-bottom: 10px;
  }

  :global(input:focus, textarea:focus) {
    border-color: #ffc359;
  }

  div :global(h1) {
    margin-bottom: 30px;
    font-weight: 400;
  }

  div :global(a) {
    color: #6f117f;
    cursor: pointer;
    text-decoration: underline;
  }

  div :global(.or-text) {
    align-self: center;
    color: #6f117f;
    padding: 20px;
  }

  div :global(.login-form) {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
  }

  div :global(.login-form-field) {
    margin-bottom: 25px;
  }

  div :global(.err-message) {
    color: #85096a;
    font-size: 12px;
  }

  div :global(.go-to-btn) {
    align-self: center;
    background-color: #6f117f;
    border-color: #6f117f;
  }

  div :global(.go-to-btn:hover) {
    background-color: #ffc359;
    border-color: #ffc359;
  }

  div :global(.login-form--hint) {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .left-pane-bg {
    background-image: url('./login-back.png');
    background-size: cover; /* Cover the entire pane */
    background-position: center; /* Center the background image */
  }
</style>

<div class="flex w-full h-screen">
  <div class="w-1/2 h-full bg-pane-bg hidden lg:block flex items-start justify-start p-8 left-pane-bg overflow-y-auto">
    <h1 class="text-white font-bold text-3xl lg:text-3xl xl:text-4xl 2xl:text-5xl mt-12 ml-20 mr-20">
      A corporate wiki and docs-as-code hosting platform, designed for software developers.
    </h1>
    <br>
    <ul class="list-disc ml-20 mr-20 text-2xl lg:text-2xl xl:text-3xl 2xl:text-4xl text-white space-y-6">
      <li>
        Combine multiple projects from around the organization
        to a unified portal        
      </li>
      <li>Host content from GitHub, GitLab, Bitbucket, Azure DevOps, and more</li>
      <li>Native Markdown, HTML, &amp; XML support</li>
      <li>
        Architecture documents, reference documents,
        and source code, all in one place
      </li>
      <li>
        Fast, integrated regular expression-based searching to find
        exactly what you need
      </li>
    </ul>  
  </div>
  <div class="w-full lg:w-1/2 h-full flex flex-col justify-between bg-white">
    <div class="flex justify-center items-center h-20 mt-4">
      <img src={logo} alt="OpenSquiggly" class="w-[270px] h-[45px]">
    </div>
    <div class="flex-1 overflow-y-auto">
      {#if loginState === LOGIN_STATE.SignIn }
        <SignIn 
          loginState="{LOGIN_STATE}"
          on:changeLoginState={onChangeLoginState}
        />
      {:else if loginState === LOGIN_STATE.ResetPass}
        <ResetPassword
          loginState="{LOGIN_STATE}"
          on:changeLoginState={onChangeLoginState}
        />
      {:else if loginState === LOGIN_STATE.Register}
        <Register2
          loginState="{LOGIN_STATE}"
          on:changeLoginState={onChangeLoginState}
        />
      {:else if loginState === LOGIN_STATE.EnterNewPass}
        <AssignNewPassword
          loginState="{LOGIN_STATE}"
          resetKey="{resetKey}"
          on:changeLoginState={onChangeLoginState}
        />
      {:else}-->
        <Invitation
          loginState="{LOGIN_STATE}"
          on:changeLoginState={onChangeLoginState}
        />      
      {/if}
    </div>
    <div class="flex justify-center items-center h-10 bg-gray-200">
      <p class="text-xs text-gray-600">&copy; 2024 OpenSquiggly, Inc. All rights reserved.</p>
    </div>
  </div>
</div>
