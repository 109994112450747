<script>
  import { createEventDispatcher } from 'svelte';

  export let iconName;
  export let title = '';
  export let cursorType = 'pointer';
  export let size = 'normal';

  const dispatch = createEventDispatcher();

  function onClick() {
    dispatch('click');
  }
</script>

<style lang="scss">
  .icon {
  }
  
  .normal-size {
    font-size: 16px;
  }
  
  .large-size {
    font-size: 24px;
    font-weight: bold;
    color: #339;
  }

  .move {
    cursor: move;
  }

  .pointer {
    cursor: pointer;
  }

  .icon-spacing {
    margin: 0 5px;
  }
</style>

<span class="material-icons-outlined icon icon-spacing {cursorType === 'move' ? 'move' : 'pointer'} {size}-size"
      on:click={onClick} {title}>{iconName}</span>
