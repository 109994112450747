<script>
  import { createEventDispatcher } from "svelte";

  export let layoutMethod = "tables";
  
  const dispatch = createEventDispatcher();
  
  function onSubmit(event) {
    dispatch("submit", event);
  }
</script>

<style>
  form.single-column-form {
    /* font-size: 12px; */
    /* color: black; */
    width: 100%;
    max-width: 900px;
  }
  
  form.single-column-form table.single-column-form-table {
    width: 100%;
  }
</style>  

{#if layoutMethod === "tables"}
  <form class="single-column-form stylable-form" on:submit|preventDefault={onSubmit}>
    <table class="single-column-form-table">
      <slot />
    </table>      
  </form>
{:else}
  <div>Unknown Form Layout Method</div>
{/if}  
