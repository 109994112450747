<script>
  import { RequestService } from "../../comm/RequestService";
  import { createEventDispatcher } from "svelte";

  export let selectedPageId;
  export let overrideablePageOptions;

  const dispatch = createEventDispatcher();
  let referencedPages = [];
  let bindingObject;
  
  $: updateBindingObject(overrideablePageOptions);
  
  $: if (selectedPageId) {
    getReferencedPagesList();
  }
  
  function updateBindingObject(options) {
    bindingObject = overrideablePageOptions.getPlainObject();
  }
  
  async function getReferencedPagesList() {
    const url = `apiV2/topicPages/${selectedPageId}/referenced`;
    const resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      referencedPages = resp.result || [];
    } else {
      console.error('Error: ', resp.resultMessage);
    }
  }

  function onSaveOptions() {
    dispatch('updatepageoptions', { primaryParentId: bindingObject.primaryParentId });
  }
</script>

<style lang="scss">
  .referenced-pages {
    select {
      margin-left: 10px;
      width: 400px;
    }

    .buttons {
      margin-top: 10px;

      button {
        width: 80px;
      }
    }

    ul {
      margin-left: 0;
      padding-left: 0;
      margin-top: 20px;

      li {
        list-style-type: none;
      }
    }
  }
</style>

<section class="referenced-pages">
  {#if referencedPages.length}
    <form on:submit|preventDefault={() => onSaveOptions()}>
      <label>
        Primary Parent
        <select bind:value={bindingObject.primaryParentId} disabled="{referencedPages.length === 1}">
          {#each referencedPages as rPage}
            <option value="{rPage.suid}">
              {rPage.title}
            </option>
          {/each}
        </select>
      </label>
      <div class="buttons">
        <button type="submit">Save</button>
      </div>
    </form>
  {/if}
  <ul>
    {#if referencedPages.length}
      {#each referencedPages as rPage}
        <li>✻ {rPage.title}
          {#if rPage.suid === bindingObject.primaryParentId}
            (Primary Parent)
          {/if}
        </li>
      {/each}
    {:else}
      <li><h4>There are no referenced pages.</h4></li>
    {/if}
  </ul>
</section>
