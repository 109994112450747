<script>
  import { RequestService } from "../../comm/RequestService";
  import { createEventDispatcher } from "svelte";
  import Loading from "../layout-helpers/Loading.svelte";

  const GROUP_TYPE = {
    Custom: 1,
    AllMembers: 2,
    Administrators: 3,
  };

  export let organizationUsers = [];
  //export let organization;
  export let group;
  const dispatch = createEventDispatcher();
  let groupMembers = [];
  let isAddMemberButtonEnabled = true;
  let isUserFormDisplayed = false;
  let errMessage = '';
  let isValidData = true;
  let loading = false;
  let availableUsers = [];
  let userForAdding;

  $: {
    if (group) {
      getGroupMembers();
    }
  }

  function filterAvailableUsers() {
    availableUsers = organizationUsers.filter((ou) => {
      return !groupMembers.some((gm) => gm.userId.value === ou.userId.value)
    })
  }

  async function getGroupMembers() {
    loading = true;
    const url = `/apiV2/groups/${group.id.value}/members`;
    let resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      groupMembers = resp.result || [];
      // console.log('groupMembers ', groupMembers);
      filterAvailableUsers();
    } else {
      console.error(resp.resultMessage);
    }
    loading = false;
  }

  async function onRemoveGroupMember(memberId) {
    const url = `/apiV2/groups/${group.id.value}/members`;
    let resp = await RequestService.delete(url, { groupMembershipId: memberId });

    if (resp.resultCode === 0) {
      await getGroupMembers();
    } else {
      console.error(resp.resultMessage);
    }
  }

  function onHideMemberSection() {
    dispatch('hidemembersection');
  }

  function onShowFormToAddMember() {
    isAddMemberButtonEnabled = false;
    isUserFormDisplayed = true;
  }

  function onCloseFormToAddUser() {
    isAddMemberButtonEnabled = true;
    isUserFormDisplayed = false;
    errMessage = '';
    isValidData = true;
  }

  async function saveUser() {
    let url;
    let resp;

    if (group.type === GROUP_TYPE.Administrators) {
      url = `/apiV2/groups/${group.id.value}/administrators`;
    } else {
      url = `/apiV2/groups/${group.id.value}/member`;
    }

    resp = await RequestService.post(url, { userId: userForAdding.userId.value });

    if (resp.resultCode === 0) {
      isAddMemberButtonEnabled = true;
      isUserFormDisplayed = false;
      await getGroupMembers();
    } else {
      console.error(resp.resultMessage);
      errMessage = resp.resultMessage;
      isValidData = false;
    }
  }
</script>
<style lang="scss">
  .organization-group-users {
    position: relative;

    .title {
      display: flex;
      justify-content: space-between;

      span {
        cursor: pointer;
      }
    }

    form {
      position: relative;

      .err-message {
        top: 27px;
        left: 0;
      }

      .form-field {
        width: 308px;
        margin-bottom: 50px;
      }
    }
  }
</style>
<div class="organization-group-users">
  <Loading {loading}>
    <h3 class="title">
      Members for {group.title.toUpperCase()} group
      <span class="material-icons-outlined" on:click={onHideMemberSection}
            title="Close"
      >close</span>
    </h3>
    {#if groupMembers.length}
      <table>
        <tbody>
        <tr>
          <th>User Name</th>
          <th>Actions</th>
        </tr>
        {#each groupMembers as member (member.id.value)}
          <tr>
            <td>{member.targetName}</td>
            <td>
              <button type="button" class="action-btn" on:click={() => onRemoveGroupMember(member.id.value)}>
                Delete
              </button>
            </td>
          </tr>
        {/each}
        </tbody>
      </table>
    {:else}
      <div>There are no current user members.</div>
    {/if}

    {#if isAddMemberButtonEnabled}
      {#if !availableUsers.length}
        <p>There are no available user members to add into group.</p>
      {/if}
      <button class="show-group-form" type="button" on:click={onShowFormToAddMember}
              disabled="{!availableUsers.length}">
        Add New User Member
      </button>
    {/if}
    {#if isUserFormDisplayed}
      <form on:submit|preventDefault={(event) => saveUser(event)}>
        <label class="form-field">Select User:
          <select bind:value={userForAdding}>
            {#each availableUsers as user}
              <option value="{user}">{user.targetName}</option>
            {/each}
          </select>
          {#if !isValidData}
            <span class="err-message">{errMessage}</span>
          {/if}
        </label>

        <div class="action-buttons-section">
          <button type="submit" name="saveBtn">Save</button>
          <button type="button" on:click={onCloseFormToAddUser}>Close</button>
        </div>
      </form>
    {/if}
  </Loading>
</div>
