<script>
  import UserOptionsService from "../user-options/UserOptionsService";
  import { createEventDispatcher } from "svelte";

  export let userOptions;
  
  const dispatch = createEventDispatcher();
  let globalBindingObject;
  let timeBindingObject;
  
  $: updateGlobalBindingObject(userOptions);
  $: updateTimeBindingObject(userOptions);
  
  function updateGlobalBindingObject(userOptions) {
    globalBindingObject = userOptions.globalTopicPageOptions.getPlainObject();
  }
  
  function updateTimeBindingObject(userOptions) {
    timeBindingObject = userOptions.topicPageTimeStampOptions.getPlainObject();
  }

  async function onSaveDefaultPageOptions() {
    userOptions.globalTopicPageOptions.merge(globalBindingObject);
    userOptions.topicPageTimeStampOptions.merge(timeBindingObject);
    const resp = await UserOptionsService.updateUserOptions(userOptions.getPlainObject());

    if (resp.resultCode === 0) {
      // console.log('save userOptions ', resp);
      dispatch('useroptionsupddated');
    } else {
      console.error('save userOptions ', resp.resultMessage)
    }
  }
</script>

<style lang="scss">
  .option-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    /*
    .form-subsection-title {
      margin-bottom: 5px;
    }
    */

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
</style>

<p>The following options control various aspects of how a page is rendered. These default settings can be overridden on
  a per-page basis.</p>
<div class="option-section">
  <div class="form-section-title">Topic Pages Time Stamp Display</div>
  <label>
    <input type='checkbox' bind:checked={timeBindingObject.displayDate}>Display Date (when left margin is visible)
  </label>

  <label>
    <input type='checkbox' bind:checked={timeBindingObject.displayTime}>Display Time (when left margin is visible)
  </label>
</div>

<!-- The following sections are not shown because the journal pages feature is -->
<!-- not currently explosed to the user                                        -->

<!--<div class="option-section">-->
<!--  <div class="form-section-title">Journal Pages Time Stamp Display</div>-->
<!--  <div class="form-subsection-title">When Jot Date Matches Journal Page Date:</div>-->
<!--  <label>-->
<!--    <input type='checkbox' bind:checked={[...binding-object-here...].displayDate}>-->
<!--    Display Date-->
<!--  </label>-->

<!--  <label>-->
<!--    <input type='checkbox' bind:checked={[...binding-object-here...].displayTime}>-->
<!--    Display Time-->
<!--  </label>-->

<!--  <div class="form-subsection-title">When Jot Date Differs From Journal Page Date:</div>-->
<!--  <label>-->
<!--    <input type='checkbox' bind:checked={[...binding-object-here...].displayDate}>-->
<!--    Display Date-->
<!--  </label>-->

<!--  <label>-->
<!--    <input type='checkbox' bind:checked={[...binding-object-here...].displayTime}>-->
<!--    Display Time-->
<!--  </label>-->
<!--</div>-->

<div class="option-section">
  <div class="form-section-title">Topic Page Appearance</div>
  <label>
    <input type='checkbox' bind:checked={globalBindingObject.displayGutter}>
    Show Left Margin Gutter
  </label>

  <label>
    <input type='checkbox' bind:checked={globalBindingObject.displaySeparators}>
    Show Borders Between Top-Level Jots
  </label>
</div>

<!--<div class="option-section">-->
<!--  <div class="form-section-title">Journal Page Appearance</div>-->
<!--  <label>-->
<!--    <input type='checkbox' bind:checked={userOptions.globalJournalPageOptions.displayGutter}>-->
<!--    Show Left Margin Gutter-->
<!--  </label>-->

<!--  <label>-->
<!--    <input type='checkbox' bind:checked={userOptions.globalJournalPageOptions.displaySeparators}>-->
<!--    Show Borders Between Top-Level Jots-->
<!--  </label>-->
<!--</div>-->

<button type="button" on:click={onSaveDefaultPageOptions}>Save</button>
