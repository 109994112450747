<script>
  import { tick } from 'svelte';
  import { writable } from 'svelte/store';
  import FullHeight from '../layout-helpers/FullHeight.svelte';
  import SearchHeaderArea from './SearchHeaderArea.svelte';
  import PaneWithTopHeader from '../panes/PaneWithTopHeader.svelte';
  import SearchResultsArea from "./SearchResultsArea.svelte";
  import { RequestService } from "../../comm/RequestService";
  import HeaderPaneWithInfo from "../panes/HeaderPaneWithInfo.svelte";
  import Modal from "../layout-helpers/Modal.svelte";
  import ButtonWithIcon from "../layout-helpers/ButtonWithIcon.svelte";
  import { topicPagesList$ } from '../../stores';

  export let pinnable;
  export let pinned;
  export let searchTerm = '';
  export let useRegexp = true;

  const switchSearchFile$ = writable({ searchFileOrder: '' });
  const switchSearchResults$ = writable({ searchResultOrder: '' });

  let searchData;
  let modalRef;
  let ref;
  let loading = true;
  let searchResults = [];
  let metadataResult = {};
  let selectedItem;
  let isWarningDisplayed = false;
  let isError = false;
  let errorMessage = '';
  let searchDifference = 0;

  async function getSearchResults(searchTerm, useRegexp) {
    try {
      if (searchTerm === '') {
        searchResults = [];
        selectedItem = null;
        return;
      }

      loading = true;
      const parsedTerm = encodeURIComponent(searchTerm);
      const url = `/api/Search?query=${parsedTerm}&useRegexp=${useRegexp}`;
      searchResults = [];
      selectedItem = null;
      await tick();

      const res = await RequestService.get(url);
      searchResults = res.result.data || [];
      metadataResult = res.result.metadata;
      searchData = { searchTerm: metadataResult.sanitizedQuery, useRegexp, caseInsensitive: !metadataResult.isCaseSensitive };
      checkErrors();
      checkWarning();
      getSearchDifference();
      loading = false;
    } catch (err) {
      console.error('Error: ', e)
    }
  }

  function onItemSelected(event) {
    selectedItem = event.detail.item;
  }

  $: getSearchResults(searchTerm, useRegexp);
  $: selectedItem = (searchResults && searchResults.length) ? searchResults[0] : null;

  function onChangeActiveFile(val) {
    if (val.detail.emitValue === 'next') {
      switchSearchFile$.set({ searchFileOrder: 'next' });
    } else {
      switchSearchFile$.set({ searchFileOrder: 'prev' });
    }
  }

  function onChangeSearchResult(val) {
    if (val.detail.emitValue === 'next') {
      switchSearchResults$.set({ searchResultOrder: 'next' });
    } else {
      switchSearchResults$.set({ searchResultOrder: 'prev' });
    }
  }

  async function onDeleteSelectedPage() {

    if (confirm(`Are you sure you want to remove page ${selectedItem.title}?`)) {
      const url = `apiV2/topicPages/unreferenced/${selectedItem.id.value}`;

      const result = await RequestService.delete(url);

      if (result.resultCode === 0) {
        //console.log('searchResults ', searchResults);
        //console.log('selectedItem ', selectedItem);
        const pageIndex = searchResults.findIndex((page) => page.suid === selectedItem.suid);

        searchResults.splice(pageIndex, 1);
        searchResults = [...searchResults];
        topicPagesList$.set('update');
      }
    }
  }

  function checkErrors() {
    if (!!metadataResult.errorMessage) {
      isError = true;
      errorMessage = metadataResult.errorMessage;
    } else if (metadataResult.parseCode !== 0) {
      isError = true;
      errorMessage = `Error parsing search expression: ${metadataResult.parseCode}`;
    } else if (!metadataResult['isValid']) {
      isError = true;
      errorMessage = `Search expression is not valid`;
    } else if (metadataResult.searchEngineErrorCode !== 0) {
      isError = true;
      errorMessage = `Search Engine Error: ${metadataResult.searchEngineErrorCode}`;
    } else if (metadataResult.searchEngineCode !== 0) {
      isError = true;
      errorMessage = `Search Engine Code: ${metadataResult.searchEngineCode}`;
    } else {
      isError = false;
    }
  }

  function checkWarning() {
    if (metadataResult.reachedMaxCount) {
      isWarningDisplayed = false;
    } else {
      isWarningDisplayed = metadataResult.reachedMaxScale || metadataResult.reachedTimeout ||
        metadataResult.operationIsCanceled || metadataResult.largeSizeDocsSkippedCount > 0;
    }
  }

  function getSearchDifference() {
    searchDifference = metadataResult.candidateDocsTotalCount - metadataResult.candidateDocsSearchedCount;
  }
</script>


<style lang="scss">
  .search-pane {
    height: 100%;

    .open-modal {
      margin-left: 10px;
    }

    .header-sections-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-section {
        display: flex;
        align-items: center;

        .warning, .error {
          font-size: 20px;
          margin-right: 5px;
          cursor: default;
        }

        .warning {
          color: orange;
        }

        .error {
          color: red;
        }
      }

      .nav-section {
        display: flex;

        :global(button) {
          margin-left: 5px;
        }
      }
    }
  }

  .modal-data-list {
    margin: 0;
    padding: 0;

    .modal-data-list-item {
      list-style-type: none;

      .title-column {
        width: 200px;
        display: inline-block
      }
    }
  }
</style>

<Modal bind:this={modalRef} title="Search Metadata">
  <ul class="modal-data-list">
    {#each Object.entries(metadataResult) as [title, value]}
      <li class="modal-data-list-item">
        <span class="title-column">{title}:</span>
        <span>{value}</span>
      </li>
    {/each}
  </ul>
</Modal>

<div class="search-pane">
  <PaneWithTopHeader bind:this={ref}>
    <svelte:fragment slot="header">
      <SearchHeaderArea
        pinnable={pinnable}
        pinned={pinned}
        searchTerm={searchTerm}
        useRegexp={useRegexp}
        on:togglepin
        on:showtopicpage
        on:clearsearch
      />
    </svelte:fragment>
    <svelte:fragment slot="body">
      <PaneWithTopHeader size="small">
        <svelte:fragment slot="header">
          <HeaderPaneWithInfo>
            <div class="header-sections-wrapper">
              <div class="title-section">
                {#if loading}
                  <span>Loading ...</span>
                {:else if !searchResults}
                  <span></span>
                {:else if searchResults.length === 1}
                  <span>Found <strong>{searchResults.length}</strong> result</span>
                {:else}

                  {#if isError}
                    <span class="material-icons-outlined error" title="Search Error">
                      report_problem
                    </span>
                    <spa>{errorMessage}</spa>
                  {:else}

                    {#if isWarningDisplayed}
                      <span class="material-icons-outlined warning"
                            title="There might be more results that match the condition that are not displayed.">
                      report_problem
                    </span>
                    {/if}

                    <div>Found <strong>{searchResults.length}</strong> results
                      {#if searchDifference > 0}
                        <span>(Another <strong>{searchDifference}</strong> possible results not shown)</span>
                      {/if}
                    </div>

                  {/if}

                {/if}
                <button class="open-modal" on:click={() => modalRef.open()}>View metadata</button>
              </div>
              <div class="nav-section">
                <ButtonWithIcon iconName="arrow_upward" emitValue="prev"
                                on:click={onChangeActiveFile}
                                title="Previous Page"
                />
                <ButtonWithIcon iconName="arrow_downward" emitValue="next"
                                on:click={onChangeActiveFile}
                                title="Next Page"
                />
                <ButtonWithIcon iconName="navigate_before" emitValue="prev"
                                on:click={onChangeSearchResult}
                                title="Previous Search Result"
                />
                <ButtonWithIcon iconName="navigate_next" emitValue="next"
                                on:click={onChangeSearchResult}
                                title="Next Search Result"
                />
              </div>
            </div>
          </HeaderPaneWithInfo>
        </svelte:fragment>
        <svelte:fragment slot="body">
          <FullHeight>
            <SearchResultsArea
              loading={loading}
              {searchData}
              {switchSearchFile$}
              {switchSearchResults$}
              {searchResults}
              {selectedItem}
              on:itemselected={onItemSelected}
              on:deletepage={onDeleteSelectedPage}
            />
          </FullHeight>
        </svelte:fragment>
      </PaneWithTopHeader>
    </svelte:fragment>
  </PaneWithTopHeader>
</div>
