<script>
  import { SNIPPET_TYPE } from "../../constants";
  import PlainTextEditor from "./PlainTextEditor.svelte";
  import FileSnippetForm from "./FileSnippetForm.svelte";
  import { onMount } from "svelte";
  import Tabs from "../layout-helpers/Tabs.svelte";
  import Prerender from "../layout-helpers/Prerender.svelte";
  import FileReferenceSnippetForm from "./FileReferenceSnippetForm.svelte";

  export let snippetForEdit = {};
  export let pageSuid = '';
  export let appendSection;
  export let editorSectionState$;

  let activeTab;
  let options;
  let newTextSnippet = {};
  let newFileSnippet = {};
  let newFileReferenceSnippet = {};

  const SNIPPET_TAB = {
    Edit: 'edit',
    File: 'file',
    Text: 'text',
    FileRef: 'fileref'
  };
  let tabsList;
  let snippetTabActive = SNIPPET_TAB.Text;

  onMount(() => {
    initNewSnippetsModel()
  });

  $: {
    if (!!snippetForEdit.suid) {
      snippetTabActive = SNIPPET_TAB.Edit;
    }
  }

  $: checkSnippetMode(appendSection);
  $: updateTabsListProps(appendSection.isEditSnippetSelected);

  function updateTabsListProps(editSnippetSelected = false) {
    if (editSnippetSelected) {
      tabsList = [
        { name: 'Edit', isActive: true, isDisplayed: true, type: SNIPPET_TAB.Edit },
        { name: 'Append Text', isActive: false, isDisplayed: true, type: SNIPPET_TAB.Text },
        { name: 'Upload File', isActive: false, isDisplayed: true, type: SNIPPET_TAB.File },
        { name: 'File Reference', isActive: false, isDisplayed: true, type: SNIPPET_TAB.FileRef },
      ];
    } else {
      tabsList = [
        { name: 'Edit', isActive: false, isDisplayed: false, type: SNIPPET_TAB.Edit },
        { name: 'Append Text', isActive: true, isDisplayed: true, type: SNIPPET_TAB.Text },
        { name: 'Upload File', isActive: false, isDisplayed: true, type: SNIPPET_TAB.File },
        { name: 'File Reference', isActive: false, isDisplayed: true, type: SNIPPET_TAB.FileRef },
      ];
    }
  }

  function checkSnippetMode(appendSectionData) {
    if (appendSectionData.enable && !appendSectionData.isEditSnippetSelected) {
      snippetTabActive = SNIPPET_TAB.Text;
      initNewSnippetsModel()
    }
  }

  function initNewSnippetsModel() {
    newTextSnippet = {
      content: '',
      formatType: 4,
      font: 1,
      fontSize: 1,
      bold: false,
      italics: false,
      underLine: false,
      displayLineNumbers: false,
      snippetType: SNIPPET_TYPE.Text
    };
    
    newFileSnippet = {
      title: '',
      description: '',
      embedInDocument: false,
      linkToView: false,
      linkToDownload: false,
      width: '',
      fileName: '',
      snippetType: SNIPPET_TYPE.File
    };
    
    newFileReferenceSnippet = {
      mountPointId: null,
      relativePath: '',
      startLineNumber: -1,
      endLineNumber: -1,
    };
  }

  function onTabStatusChanged(tabType) {
    snippetTabActive = tabType.detail;
  }

  function onCancelSnippetUpdate() {
    editorSectionState$.set({ enable: false, mode: '' })
  }
</script>

<style lang="scss">
  .editor-container {
    border: 1px solid black;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .main-content {
      height: calc(100% - 30px);

      :global(button) {
        min-width: 100px;
      }
    }
  }
</style>

<div class="editor-container">
  <Tabs {tabsList} on:tabstatus={onTabStatusChanged} />
  <div class="main-content">
    <Prerender visible={(snippetTabActive === SNIPPET_TAB.Edit) && (snippetForEdit.snippetType === SNIPPET_TYPE.Text)}>
      <PlainTextEditor snippet={snippetForEdit}
                       editSnippetSelected={true}
                       {pageSuid}
                       on:cancelsnippetupdate={onCancelSnippetUpdate}
                       on:snippetadded
                       on:clearcustomstyle
      />
    </Prerender>
    
    <Prerender visible={(snippetTabActive === SNIPPET_TAB.Edit) && (snippetForEdit.snippetType === SNIPPET_TYPE.File)}>
      <FileSnippetForm snippet={snippetForEdit}
                       editSnippetSelected={true}
                       {pageSuid}
                       on:cancelsnippetupdate={onCancelSnippetUpdate}
                       on:snippetadded
                       on:clearcustomstyle
      />
    </Prerender>

    <Prerender visible={(snippetTabActive === SNIPPET_TAB.Edit) && (snippetForEdit.snippetType === SNIPPET_TYPE.FileRef)}>
      <FileReferenceSnippetForm
        snippet={snippetForEdit}
        editSnippetSelected={true}
        {pageSuid}
        on:cancelsnippetupdate={onCancelSnippetUpdate}
        on:snippetadded
        on:clearcustomstyle
      />
    </Prerender>

    <Prerender visible={snippetTabActive === SNIPPET_TAB.Text}>
      <PlainTextEditor snippet={newTextSnippet}
                       {pageSuid}
                       on:cancelsnippetupdate={onCancelSnippetUpdate}
                       on:snippetadded
                       on:clearcustomstyle
      />
    </Prerender>

    <Prerender visible={snippetTabActive === SNIPPET_TAB.File}>
      <FileSnippetForm snippet={newFileSnippet}
                       {snippetTabActive}
                       {pageSuid}
                       on:cancelsnippetupdate={onCancelSnippetUpdate}
                       on:snippetadded
                       on:clearcustomstyle
      />
    </Prerender>

    <Prerender visible={snippetTabActive === SNIPPET_TAB.FileRef}>
      <FileReferenceSnippetForm 
        snippet={newFileReferenceSnippet}
        {pageSuid}
        on:snippetadded
        on:cancelsnippetupdate={onCancelSnippetUpdate}
      />
    </Prerender>    
  </div>
</div>
