<script>
  import { RequestService } from "../../comm/RequestService";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { UrlService } from "../../comm/UrlService";
  import { URL_PARAM } from "../../constants";
  import Loading from "../layout-helpers/Loading.svelte";

  const dispatch = createEventDispatcher();
  let organizations = [];
  let isCreateOrgButtonsEnabled = true;
  let isOrgFormDisplayed = false;
  let orgData = {};
  let isValidData = true;
  let errMessage = 'test error';
  let loaded = false;

  onMount(async () => {
    await getOrganizationsList();
    resetOrgData();
    checkUrlParams();
  });

  onDestroy(() => {
    resetOrgData();
    isValidData = true;
  });

  function resetOrgData() {
    orgData = {
      name: '',
      fullName: '',
    }
  }

  function checkUrlParams() {
    const isOrgIdParam = UrlService.isParamInURL(URL_PARAM.ORGANIZATION_ID);
    if (isOrgIdParam) {
      let orgId = UrlService.getPathValue(URL_PARAM.ORGANIZATION_ID);
      const organization = organizations.find((o) => o.id.value === orgId);
      onChangeOrgState(organization, 'ext-connection');
    }
  }

  async function onEditOrganization(org) {
    orgData = {
      name: org.name,
      fullName: org.fullName,
      organizationId: org.id.value,
    };
    isCreateOrgButtonsEnabled = false;
    isOrgFormDisplayed = true;
  }

  async function saveGroup() {
    const url = '/apiV2/organization';
    let resp;
    if (orgData.organizationId) {
      resp = await RequestService.put(url, orgData);
    } else {
      resp = await RequestService.post(url, orgData);
    }

    if (resp.resultCode === 0) {
      resetOrgData();
      isOrgFormDisplayed = false;
      isCreateOrgButtonsEnabled = true;
      await getOrganizationsList();
    } else {
      console.error(resp.resultMessage);
      errMessage = resp.resultMessage;
      isValidData = false;
    }
  }

  async function getOrganizationsList() {
    const url = '/apiV2/organization';
    let resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      organizations = resp.result || [];
    } else {
      console.error(resp.resultMessage);
    }
    
    loaded = true;
  }

  function onShowFormToCreateGroup() {
    isCreateOrgButtonsEnabled = false;
    isOrgFormDisplayed = true;
  }

  function onCloseGroupForm() {
    resetOrgData();
    isCreateOrgButtonsEnabled = true;
    isOrgFormDisplayed = false;
  }

  function formatDate(val) {
    const date = new Date(val);
    const options = {
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      timeZoneName: 'short'
    };
    return date.toLocaleString(options);
  }

  function onChangeOrgState(organization, state) {
    dispatch('changeorgstate', { state, organization });
  }
</script>
<style lang="scss">
  .organizations-table {
    position: relative;

    .err-message {
      bottom: 40px;
      left: 200px;
    }

    .show-group-form {
      margin-top: 10px;
    }

    .action-buttons-section {
      margin-top: 30px;

      button {
        min-width: 80px;
      }
    }
  }
</style>

<Loading loading={!loaded} >
  <div class="organizations-table">
    <h3>Current Organizations</h3>
    {#if organizations.length}
      <table>
        <tbody>
        <tr>
          <th>Organization Name</th>
          <th>Organization Id</th>
          <th>Actions</th>
        </tr>
        {#each organizations as org (org.id.value)}
          <tr>
            <td>{org.fullName}</td>
            <td>{org.name}</td>
            <td>
              <button type="button" class="action-btn" on:click={() => onChangeOrgState(org, 'user')}>
                Manage Members
              </button>
              <button type="button" class="action-btn" on:click={() => onChangeOrgState(org, 'ext-connection')}>
                Manage External Connections
              </button>
              <button type="button" class="action-btn" on:click={() => onChangeOrgState(org, 'mount-point')}>
                Manage Mount Points
              </button>
              <button type="button" class="action-btn" on:click={() => onChangeOrgState(org, 'group')}>
                Manage Groups
              </button>
              <button type="button" class="action-btn" on:click={() => onEditOrganization(org)}>Edit</button>
              <button type="button" class="action-btn" disabled>Delete</button>
              <!--on:click={() => onRemoveOrganization(group.id.value)}-->
            </td>
          </tr>
        {/each}
        </tbody>
      </table>
    {:else}
      <div>There are no current organizations.</div>
    {/if}
  
    {#if isCreateOrgButtonsEnabled}
      <button class="show-group-form" type="button" on:click={onShowFormToCreateGroup}>Add New Organization</button>
    {/if}
    {#if isOrgFormDisplayed}
      <form id="mount-points-form" on:submit|preventDefault={(event) => saveGroup(event)}>
        <label class="form-field">Organization Id *:
          <input class="input-field"
                 type="text"
                 bind:value={orgData.name}
                 required
                 minlength="3"
                 maxlength="24"
                 pattern="^[a-z0-9 -]+$"
                 title="Name should only contain lowercase letters, numbers, dashes and spaces">
        </label>
        <label class="form-field">Organization Name:
          <input type="text" bind:value={orgData.fullName}>
        </label>
        {#if !isValidData}
          <span class="err-message">{errMessage}</span>
        {/if}
  
        <div class="action-buttons-section">
          <button type="submit" name="saveBtn">Save</button>
          <button type="button" on:click={onCloseGroupForm}>Close</button>
          <button type="button" on:click={resetOrgData}>Clear</button>
        </div>
      </form>
    {/if}
  </div>
</Loading>
