import {RequestService} from "../../comm/RequestService";

export class TreeNavigatorService {

  static getRootTopicPage() {
    const url = '/apiv2/topicpages/treeroot';

    return RequestService.get(url);
  }

  static getTopicPages() {
    const url = '/api/ReadTopicPages';

    return RequestService.get(url);
  }

  static getPageData(suid) {
    // const url = `/api/ReadTopicPage3?suid=${suid}`;
    const url = `/apiv2/topicpages/${suid}`;

    return RequestService.get(url);
  }

  static getPageById(suid) {
    const url = `/apiV2/topicPages/${suid}`;

    return RequestService.get(url);
  }
  
  static updatePage(suid, data) {
    const url = `/apiV2/topicPages/${suid}`;

    return RequestService.put(url, data);
  }
  
  static renameTocEntry(data) {
    const url = `/apiV2/tocentry/rename`;

    return RequestService.put(url, data);    
  }

  static getTocEntryPages(suid) {
    const url = `/apiV2/tocEntry/read?suid=${suid}`;

    return RequestService.get(url);
  }
  
  static getTocEntryCount(suid) {
    const url = `/apiV2/tocEntry/count?suid=${suid}`;

    return RequestService.get(url);    
  }

  static reorderTocEntryPages(parentSuid, tocEntries) {
    const url = `/apiV2/tocEntry/reorder`;
    const data = {
      parentId: parentSuid,
      tocEntries
    };
    //an example: tocEntries = [{tocEntryId: "3fa85f64-5717-4562-b3fc-2c963f66afa6", pageSuid: "string"}]

    return RequestService.post(url, data);
  }

  static moveTocEntryPageAbove(data) {
    const url = `/apiV2/tocEntry/move/above`;
    /*
      data example:
      "topicPageIdFrom": "suid",
      "topicPageIdTo": "suid",
      "tocEntryId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "relativeTocEntryId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    */
    return RequestService.post(url, data);
  }

  static copyTocEntryPageAbove(data) {
    const url = `/apiV2/tocEntry/copy/above`;
    /*
      data example:
      "topicPageIdFrom": "suid",
      "topicPageIdTo": "suid",
      "tocEntryId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "relativeTocEntryId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    */
    return RequestService.post(url, data);
  }

  static moveTocEntryPageBelow(data) {
    const url = `/apiV2/tocEntry/move/below`;
    return RequestService.post(url, data);
  }

  static copyTocEntryPageBelow(data) {
    const url = `/apiV2/tocEntry/copy/below`;
    return RequestService.post(url, data);
  }

  static removeTocEntryPage(parentId, tocEntryId) {
    const url = `/apiV2/tocEntry/remove`;
    const data = {
      parentId,
      tocEntryId
    };
    
    return RequestService.post(url, data);
  }

  static insertTocEntryPageAbove(data) {
    const url = `/apiV2/tocEntry/insert/above`;

    return RequestService.post(url, data);
  }

  static insertTocEntryPageBelow(data) {
    const url = `/apiV2/tocEntry/insert/below`;

    return RequestService.post(url, data);
  }

  static createTopicPage(data) {
    const url = `/apiV2/topicPages/add`;

    return RequestService.post(url, data);
  }
  
  static createMappedPage(data) {
    const url = `/apiV2/topicPages/addMapped`;

    return RequestService.post(url, data);
  }

  static createTopicPageAbove(data) {
    const url = `/apiV2/topicPages/add/above`;

    return RequestService.post(url, data);
  }

  static createTopicPageBelow(data) {
    const url = `/apiV2/topicPages/add/below`;

    return RequestService.post(url, data);
  }
}
