<script>
  import { writable } from 'svelte/store';
  import { onDestroy, onMount } from "svelte";
  import ScrollablePane from '../panes/ScrollablePane.svelte';
  import PaneWithTopHeader from '../panes/PaneWithTopHeader.svelte';
  import TopicPageHeaderArea from './TopicPageHeaderArea.svelte';
  import TopicPageRenderer from './TopicPageRenderer.svelte';
  import TopicPageEditor from './TopicPageEditor.svelte';
  import EditPageOptions from '../page-options/EditPageOptions.svelte';
  import { userOptions$ } from '../../stores.js';
  import { PANE_PAGE_MODE } from "../../constants";
  import { SNIPPET_TYPE } from "../../constants";
  import TopicPage from "../../pages/TopicPage";
  import PagePropertiesViewer from "../page-options/PagePropertiesViewer.svelte";

  export let pinnable;
  export let pinned;
  export let searchResultsAvailable = false;
  export let topicPage;
  export let navNode = null;
  export let searchData = '';
  export let switchSearchFile$ = null;
  export let switchSearchResults$ = null;
  export let allowRefresh = false;
  export let allowOpenInGitHost = false;
  export let allowDelete = false;

  const editorSectionState$ = writable({ enable: false, isEditSnippetSelected: false });

  let snippetSuid;
  let unsubscribeUserOptions;
  let userOptions;
  let pageMode = PANE_PAGE_MODE.View;
  let snippetExpanded = true;
  let currentTopicPage;

  onMount(() => {
    unsubscribeUserOptions = userOptions$.subscribe((val) => {
      if (val) {
        userOptions = val;
      }
    });
  });
  
  $: if (topicPage !== currentTopicPage) {
    currentTopicPage = topicPage;
    if (pageMode === PANE_PAGE_MODE.EditPageOptions || pageMode === PANE_PAGE_MODE.ViewPageOptions) {
      pageMode = currentTopicPage.hasEditableProperties ?
        PANE_PAGE_MODE.EditPageOptions :
        PANE_PAGE_MODE.ViewPageOptions
      ;
    }
  }

  onDestroy(() => {
    unsubscribeUserOptions();
  });

  function onViewPage() {
    pageMode = PANE_PAGE_MODE.View;
  }

  function onEditPage() {
    pageMode = PANE_PAGE_MODE.EditContent;
  }

  function onEditPageOptions() {
    pageMode = PANE_PAGE_MODE.EditPageOptions;
  }

  function onViewPageOptions() {
    pageMode = PANE_PAGE_MODE.ViewPageOptions;
  }  

  function onToggleSnippetView(data) {
    snippetExpanded = data.detail.isExpanded;
  }

  function onRefreshSnippet() {
    topicPage = new TopicPage(topicPage.jsonData);
  }

  function onSnippetSuidChanged(data) {
    snippetSuid = data.detail.suid
  }
  
  function onPageOptionsChanged(event) {
    topicPage.options.merge(event.detail);
    topicPage.timeStampOptions.merge(event.detail);
    
    // Trigger an update of subcomponents
    topicPage = topicPage;
  }
</script>

<style>
  .topic-page-pane {
    height: 100%;
  }
</style>

<div class="topic-page-pane">
  <PaneWithTopHeader>
    <svelte:fragment slot="header">
      <TopicPageHeaderArea
        allowDelete={allowDelete}
        allowOpenInGitHost={allowOpenInGitHost}
        allowRefresh={allowRefresh}
        pinnable={pinnable}
        pinned={pinned}
        searchResultsAvailable={searchResultsAvailable}
        currentTopicPage={topicPage}
        {pageMode}
        {snippetSuid}
        {editorSectionState$}
        on:viewpage={onViewPage}
        on:editpage={onEditPage}
        on:editpageoptions={onEditPageOptions}
        on:viewpageoptions={onViewPageOptions}
        on:togglepin
        on:showsearchresults
        on:refreshsnippet={onRefreshSnippet}
        on:snippetexpanded={onToggleSnippetView}
        on:deletepage
      />
    </svelte:fragment>
    <svelte:fragment slot="body">
      {#if pageMode === PANE_PAGE_MODE.EditContent && topicPage.hasEditableContent}
        <TopicPageEditor 
          currentTopicPage={topicPage}
          {editorSectionState$}
          {snippetExpanded}
        />
      {:else}
        <ScrollablePane>
          {#if pageMode === PANE_PAGE_MODE.View || (pageMode === PANE_PAGE_MODE.EditContent && !topicPage.hasEditableContent)}
            {#if userOptions}
              <TopicPageRenderer
                {searchData}
                {switchSearchFile$}
                {switchSearchResults$}
                {userOptions}
                {topicPage}
                {navNode}
                on:snippetsuid={onSnippetSuidChanged}
              />
            {/if}
          {:else if pageMode === PANE_PAGE_MODE.EditPageOptions}
            <EditPageOptions 
              selectedPage={topicPage}
              overrideablePageOptions={topicPage.options}
              overrideableTimeStampOptions={topicPage.timeStampOptions}
              on:pageoptionschanged={onPageOptionsChanged}
            />
          {:else if pageMode === PANE_PAGE_MODE.ViewPageOptions}
            <PagePropertiesViewer page={topicPage} />
          {:else}
            <div>Error: Unknown Page Mode</div>
          {/if}
        </ScrollablePane>
      {/if}
    </svelte:fragment>
  </PaneWithTopHeader>
</div>
