<script>
  import { CONNECTION_TYPE_LIST, GIT_ACCESS_TYPE, REFRESH_TYPE, TREE_FORMAT_TYPE } from "../../../constants";
  import FormItemWithLabel from "../../form-helpers/FormItemWithLabel.svelte";
  import FormDropdownInput from "../../form-helpers/FormDropdownInput.svelte";
  import FormTextInput from "../../form-helpers/FormTextInput.svelte";
  import FormTextAreaInput from "../../form-helpers/FormTextAreaInput.svelte";
  import FormTextValue from "../../form-helpers/FormTextValue.svelte";
  import FormNumberInput from "../../form-helpers/FormNumberInput.svelte";

  // These properties are used to populate the dropdown lists
  export let mountPoints;
  export let externalConnections;
  export let isCreateMode;

  // These properties are used to bind to the individual field controls
  export let id;
  export let name;
  export let description;
  export let startPath;
  export let gitAccessType;
  export let connectionType;
  export let innerMountPointId;
  export let externalConnectionId;
  export let treeFormatType;
  export let refreshType;
  export let pollingInterval;
</script>

<FormItemWithLabel label="Git Access Type" required={true}>
  <FormDropdownInput bind:value={gitAccessType} disabled={!isCreateMode}>
    <option value="{GIT_ACCESS_TYPE.PUBLIC}">Public</option>
    <option value="{GIT_ACCESS_TYPE.PRIVATE}">Private</option>
    <option value="{GIT_ACCESS_TYPE.REUSE_EXISTING}">Reuse Existing Repository</option>
  </FormDropdownInput>
</FormItemWithLabel>

{#if gitAccessType === GIT_ACCESS_TYPE.PUBLIC}
  <FormItemWithLabel label="Connection Type" required={true}>
    <FormDropdownInput bind:value={connectionType}>
      {#each CONNECTION_TYPE_LIST as cType}
        <option value="{cType.value}">{cType.label}</option>
      {/each}      
    </FormDropdownInput>
  </FormItemWithLabel>
{:else if gitAccessType === GIT_ACCESS_TYPE.REUSE_EXISTING}
  <FormItemWithLabel label="Repository Mount Point" required={true}>
    <FormDropdownInput bind:value={innerMountPointId} required={true} disabled={!isCreateMode}>
      {#each mountPoints as mPoint}
        <option value="{mPoint.id}">{mPoint.name}</option>
      {/each}
    </FormDropdownInput>
  </FormItemWithLabel>
{:else if gitAccessType === GIT_ACCESS_TYPE.PRIVATE}
  <FormItemWithLabel label="External Connection" required={true}>
    <FormDropdownInput bind:value={externalConnectionId} required={true} disabled={!isCreateMode}>
      {#each externalConnections as connection}
        <option value="{connection.id}">{connection.name} (Provider = {CONNECTION_TYPE_LIST[connection.connectionType - 1].label})</option>
      {/each}
    </FormDropdownInput>
  </FormItemWithLabel>
{/if}

<FormItemWithLabel label="Name" required={true} validator={() => name.trim() !== ''}>
  <FormTextInput bind:value={name} required={true} />
</FormItemWithLabel>

<FormItemWithLabel label="Description" required={false}>
  <FormTextAreaInput bind:value={description} rows=4 cols=25 />
</FormItemWithLabel>

<FormItemWithLabel label="Start Path" required={false}>
  <FormTextInput bind:value={startPath} required={false} disabled={!isCreateMode} />
</FormItemWithLabel>

<FormItemWithLabel label="Tree Format Type" required={true}>
  <FormDropdownInput bind:value={treeFormatType} required={true} disabled={!isCreateMode}>
    <option value="{TREE_FORMAT_TYPE.RAW}">Standard File Navigator (Raw File/Folder Names)</option>
    <option value="{TREE_FORMAT_TYPE.TOC_LESS}">OpenSquiggly Simplified Document Tree Formatter</option>
  </FormDropdownInput>
</FormItemWithLabel>  

{#if gitAccessType !== GIT_ACCESS_TYPE.REUSE_EXISTING}
  <FormItemWithLabel label="Refresh Type" required={false}>
    <FormDropdownInput bind:value={refreshType} required={true}>
      <option value="{REFRESH_TYPE.POLLING}">Polling</option>
      <option value="{REFRESH_TYPE.TRIGGERED}">Triggered</option>
    </FormDropdownInput>
  </FormItemWithLabel>
  {#if refreshType === REFRESH_TYPE.TRIGGERED}
    <FormItemWithLabel label="Webhook URL" required={false}>
    {#if isCreateMode}
      <FormTextValue value="available after save" />
    {:else}
      <FormTextValue value="{window.location.origin}/apiv2/mountpoint/refresh/{id}" />
    {/if}
    </FormItemWithLabel>
  {/if}
  {#if refreshType === REFRESH_TYPE.POLLING}
    <FormItemWithLabel label="Polling Interval" required={false}>
      <FormNumberInput suffix="minutes" bind:value={pollingInterval} />
    </FormItemWithLabel>
  {/if}
{/if}
