<script>
  import { createEventDispatcher } from 'svelte';
  import TopicPage from '../../pages/TopicPage.js';
  import { TreeNavigatorService } from "../navigator/TreeNavigatorService";

  const dispatch = createEventDispatcher();
  let title = '';

  async function onSaveTopicPage() {
    // This component adds a new page to the database, but it does not add
    // a table of contents entry anywhere. It is the reponsibility of the
    // invoking component to catch the 'pageadded' event and add in any
    // appropriate toc entries.
    
    const postData = {
      title: title
    };
    
    // console.log("Inside onSaveTopicPage()");
    const response = await TreeNavigatorService.createTopicPage(postData);
    // console.log("response =", response);
    if (response.resultCode === 0) {
      const topicPage = new TopicPage(response.result);
      dispatch('pageadded', {
        title: title,
        pageSuid: topicPage.suid
      });
      title = '';
    }
  }

</script>

<form on:submit|preventDefault={onSaveTopicPage} id="add-topic-page-form">
  <div class="form-section">Add Topic Page</div>
  <div class="navigator-control-block">
    <label><input type="text" placeholder="Enter page name" bind:value={title} required></label>
    <button type="submit">Add New</button>
  </div>
</form>
