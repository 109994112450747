import "regenerator-runtime/runtime";

export class RequestService {
  //static cacheService = new CacheService();
  static headers = {
    'Content-Type': 'application/json;charset=utf-8',
  };

  static getHeaders(cacheRegion = '') {
    const headers = {
      'Content-Type': 'application/json;charset=utf-8',
      'Cache-Control': 'public, max-age=3600'
    };

    if (cacheRegion) {
      headers['cache-key'] = cacheRegion;
    }

    return headers;
  }

  static async get(url, cacheRegion = '') {
    // if (this.cacheService.contains(url)) {
    //   return Promise.resolve(this.cacheService.find(url));
    // }
    const response = await fetch(url);

    if (response.ok) {
      return await response.json();
    } else {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
  }

  static async post(url, data, isFormData = false, cacheRegion = '') {
    //this.cacheService.reset(cacheRegion);
    const formDataHeaders = {};

    const response = await fetch(url, {
      method: 'POST',
      headers: isFormData ? formDataHeaders : RequestService.headers,
      body: isFormData ? data : JSON.stringify(data)
    });

    if (response.ok) {
      return response.json();
    } else {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
  }

  static async put(url, data, isFormData = false, cacheRegion = '') {
    //this.cacheService.reset(cacheRegion);
    const formDataHeaders = {};

    const response = await fetch(url, {
      method: 'PUT',
      headers: isFormData ? formDataHeaders : RequestService.headers,
      body: isFormData ? data : JSON.stringify(data)
    });

    if (response.ok) {
      return response.json();
    } else {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
  }

  static async delete(url, data, cacheRegion = '') {
    //this.cacheService.reset(cacheRegion);
    const response = await fetch(url, {
      method: 'DELETE',
      headers: RequestService.headers,
      body: JSON.stringify(data)
    });

    if (response.ok) {
      return response.json();
    } else {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
  }

  static clearCache(cacheRegion) {
    this.cacheService.reset(cacheRegion);
  }
}
