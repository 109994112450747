<script>
  export let value;
  export let disabled = false;
  export let placeholder = "";
  export let required = false;
  export let title = "";
</script>

<style>
  input.field-input {
    width: 100%;
  }
</style>
  
<input 
  class="field-input input-field" 
  type="text"
  placeholder={placeholder}
  title={title}
  required={required}
  bind:value={value} 
  disabled={disabled}
>
