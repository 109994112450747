<script>
  import { createEventDispatcher, onMount } from "svelte";
  import OverrideablePageOptions from "../../misc/OverrideablePageOptions";

  export let overrideablePageOptions;

  const dispatch = createEventDispatcher();
  let bindingObject;
  let isCancelDisabled;

  onMount(() => {
    isCancelDisabled = true;
  });
  
  function updateBindingObject(options) {
    bindingObject = options.getPlainObject();
  }
  
  $: updateBindingObject(overrideablePageOptions);
  
  $: if (bindingObject.urlSlug !== overrideablePageOptions.urlSlug) {
    isCancelDisabled = false;
  } else {
    isCancelDisabled = true;
  }

  function onSaveOptions() {
    dispatch('updatepageoptions', new OverrideablePageOptions(bindingObject));
    isCancelDisabled = true;
  }

  function onCancel() {
    bindingObject.urlSlug = overrideablePageOptions.urlSlug;
    isCancelDisabled = true;
  }
</script>

<style lang="scss">
  .general-section {
    height: 150px;

    input {
      margin-left: 10px;
      width: 400px;
    }

    .buttons {
      margin-top: 90px;

      button {
        min-width: 80px;
      }
    }
  }
</style>

<div class="general-section">
  <form on:submit|preventDefault={(event) => onSaveOptions(event)}>
    <label class="override-element">
      URL Slug
      <input type="text" bind:value={bindingObject.urlSlug} required pattern="^[a-zA-Z0-9-]*$"
      title="Only letters, numbers and '-' are allowed">
    </label>
    <div class="buttons">
      <button type="submit">Save</button>
      <button type="button" on:click={onCancel} disabled="{isCancelDisabled}">Cancel</button>
    </div>
  </form>
</div>
