<script>
  import CodeMirrorWrapper from "./CodeMirrorWrapper.svelte";
  import 'codemirror/mode/javascript/javascript';
  import 'codemirror/mode/clike/clike';
  import 'codemirror/mode/php/php';
  import 'codemirror/mode/python/python';
  import 'codemirror/mode/perl/perl';
  import 'codemirror/mode/commonlisp/commonlisp';
  import 'codemirror/mode/swift/swift';
  import 'codemirror/mode/ruby/ruby';
  import 'codemirror/mode/ruby/ruby';
  import 'codemirror/theme/material.css';

  export let content;
  export let extensionType;
  export let displayLineNumbers;
  export let switchSearchFile$ = {};
  export let switchSourceCodeSearchResults$ = null;
  export let searchData = '';
  export let startLineNumber = -1;

  let options;

  $: updateOptions(content, extensionType, displayLineNumbers);

  function updateOptions(content, extensionType, displayLineNumbers) {
    options = {
      mode: getLangOption(extensionType),
      lineNumbers: displayLineNumbers,
      lineNumberFormatter: lineNo => (startLineNumber < 1) ? lineNo : lineNo + startLineNumber - 1,
      theme: 'default',
      scrollbarStyle: 'null',
      readOnly: true,
      value: content,
      //styleSelectedText: true
    }
  }

  function getLangOption(extensionType) {
    switch (extensionType) {
      case 'cs':
        return 'text/x-csharp';
      case 'html':
        return 'text/html';
      case 'php':
        return 'text/x-php';
      case 'java':
        return 'text/x-java';
      case 'py':
        return 'text/x-python';
      case 'perl':
        return 'text/x-perl';
      case 'lisp':
        return 'text/x-common-lisp';
      case 'swift':
        return 'text/x-swift';
      case 'cpp':
        return 'text/x-c++src';
      case 'objective-c':
        return 'text/x-objectivec';
      case 'rb':
        return 'text/x-ruby';
      case 'css':
        return 'text/css';
      case 'scss':
        return 'text/x-scss';
      case 'less':
        return 'text/x-less';
      case 'js':
        return 'text/javascript';
      default:
        return '';
    }
  }
</script>

<style lang="scss">
  :global(.code-mirror-wrap .CodeMirror .CodeMirror-scroll) {
    overflow-y: hidden !important;
    overflow-x: auto !important;
    margin-bottom: 0 !important;
  }
</style>

<div class="code-mirror-wrap">
  <CodeMirrorWrapper
    {options}
    {switchSearchFile$}
    {switchSourceCodeSearchResults$}
    {searchData}
  />
</div>
