import { RequestService } from "../../comm/RequestService";

export default class UserOptionsService {

  static getUserOptions() {
    const url = `/api/ReadUserOptions`;

    return RequestService.get(url);
  }

  static getOwnedGroups() {
    const url = `/apiV2/groups`;

    return RequestService.get(url);
  }

  static addGroup(title) {
    const url = `/apiV2/groups`;

    return RequestService.post(url, { title });
  }

  static removeEmptyGroup(groupId) {
    const url = `/apiV2/groups/${groupId}`;

    return RequestService.delete(url);
  }

  static updateGroup(title, groupId) {
    const url = `/apiV2/groups/${groupId}`;

    return RequestService.put(url, { title });
  }

  static getMembershipForGroup(groupId) {
    const url = `/apiV2/groups/${groupId}/members`;

    return RequestService.get(url);
  }

  static addGroupMember(data) {
    const url = `/apiV2/groups/${data.groupId}/members`;

    return RequestService.post(url, { login: data.login });
  }

  static getAccessControlListForGroup(groupId) {
    const url = `/apiV2/groups/${groupId}/access-control`;

    return RequestService.get(url);
  }

  static addAccessControlForGroup(data) {

    const url = `/apiV2/groups/${data.groupId}/access-control`;

    return RequestService.post(url, data);
  }

  static getOwnedPageCollections() {
    const url = `/apiV2/owned/page/collections`;

    return RequestService.get(url);
  }

  static addPageCollection(title) {
    const url = `/apiV2/owned/page/collections`;

    return RequestService.post(url, title);
  }

  static updateUserOptions(data) {
    const url = '/apiV2/users/options';

    return RequestService.put(url, data);
  }

  static sendTestEmail() {
    const url = '/apiV2/test/send/email';

    return RequestService.post(url, {});
  }
}
