<script>
  import UnusedProperty from '../layout-helpers/UnusedProperty.svelte';

  export let snippet;
</script>

<UnusedProperty propertyName="snippet" val={snippet} comment="Component not implemented" />

<div>
  ContainerSnippetViewer - NOT IMPLEMENTED
</div>  
