<script>
  export let label = "";
</script>

<style>
  td.field-section-heading {
    font-weight: bold;
    text-decoration: underline;
    font-size: 16px;
    padding-top: 1em;
  }
</style>  

<tr><td class="field-section-heading" colspan="3">{label}</td></tr>
  
