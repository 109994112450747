<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import VerticalCenter from '../layout-helpers/VerticalCenter.svelte';
  import VerticalCenterItem from '../layout-helpers/VerticalCenterItem.svelte';
  import MaterialIcon from '../layout-helpers/MaterialIcon.svelte';
  import ButtonWithIcon from '../layout-helpers/ButtonWithIcon.svelte';
  import TwoPartHeaderPane from '../panes/TwoPartHeaderPane.svelte';
  import { CONNECTION_TYPE, HOST_NAME, PANE_PAGE_MODE } from "../../constants";
  import { RequestService } from "../../comm/RequestService";
  import { signalRConnection$, loadingProgress$ } from '../../stores';

  export let subscribeToEvents = true;
  export let allowRefresh = false;
  export let allowOpenInGitHost = false;
  export let allowDelete = false;
  export let pinnable;
  export let pinned;
  export let searchResultsAvailable = false;
  export let currentTopicPage;
  export let pageMode = PANE_PAGE_MODE.View;
  export let editorSectionState$;
  export let snippetSuid;
  
  let appendSection;
  let unsubscribe1;
  let unsubscribeSignalRConnection;
  let originalFilePath;
  let originalHostName = 'Native Host';

  onMount(() => {
    unsubscribe1 = editorSectionState$.subscribe((val) => {
      appendSection = val;
    });

    if (subscribeToEvents) {
      unsubscribeSignalRConnection = signalRConnection$.subscribe((eventData) => {
        if (eventData) {
          listenNotificationEvents(eventData)
        }
      })
    }
  });

  onDestroy(() => {
    unsubscribe1();
    unsubscribeSignalRConnection && unsubscribeSignalRConnection();
  });

  $: if (snippetSuid) {
    snippetSuidChanged(snippetSuid);
  }

  const dispatch = createEventDispatcher();
  let isExpanded = true;

  function onShowSearchResults() {
    dispatch('showsearchresults');
  }

  function onViewPage() {
    dispatch('viewpage');
  }

  function onEditPage() {
    dispatch('editpage');
  }

  function onEditPageOptions() {
    dispatch('editpageoptions');
  }

  function onViewPageOptions() {
    dispatch('viewpageoptions');
  }

  function isPageTypeEqual(page, type) {
    if (page) {
      return page.jsonData.suid.substring(0, 2) === type;
    } else return false
  }

  function onToggleSnippetsView() {
    isExpanded = !isExpanded;
    dispatch('snippetexpanded', { isExpanded });
  }

  function onToggleAppendSnippetSection() {
    editorSectionState$.set({ enable: !appendSection.enable, isEditSnippetSelected: false });
  }

  async function onRefreshFromServer() {
    try {
      loadingProgress$.set(true);
      const url = `/apiV2/mountPoint/pull/${currentTopicPage.pageLocator.mountPointId}`;
      const res = await RequestService.put(url);

      if (res.resultCode !== 0) {
        loadingProgress$.set(false);
      }
    } catch (e) {
      loadingProgress$.set(false);
    }
  }

  function listenNotificationEvents(args) {
    if (args.status === 'Done' || args.status === 'Error') {
      dispatch('refreshsnippet');
      loadingProgress$.set(false);
    }
  }

  function onOpenGitHost() {
    window.open(originalFilePath, '_blank');
  }

  async function getOriginalPath(suid) {
    const parsedSuid = encodeURIComponent(suid);
    const url = `/apiV2/mountPoint/path/${parsedSuid}`;

    const req = await RequestService.get(url);

    if (req.resultCode === 0) {
      originalFilePath = req.result.originalFilePath || '';
      originalHostName = req.result.gitHostDisplayName || '';

      return req.result.gitRepoType;
    } else {
      console.error('Error: ', req.resultMessage);
    }
  }

  async function snippetSuidChanged(snippetSuid) {
    const gitRepoType = await getOriginalPath(snippetSuid) || null;

    if (originalHostName) {
      return;
    }

    switch (gitRepoType) {
      case CONNECTION_TYPE.GENERIC_GIT_HOST:
        originalHostName = HOST_NAME.GenericGitHost;
        break;
      case CONNECTION_TYPE.AZURE_DEV_OPS:
        originalHostName = HOST_NAME.AzureDevOps;
        break;
      case CONNECTION_TYPE.BITBUCKET_CLOUD:
        originalHostName = HOST_NAME.Bitbucket;
        break;
      case CONNECTION_TYPE.GITHUB:
        originalHostName = HOST_NAME.GitHub;
        break;
      case CONNECTION_TYPE.GITLAB:
        originalHostName = HOST_NAME.GitLab;
        break;
      default:
        originalHostName = 'Native Host';
    }
  }

  function onDeletePage() {
    dispatch('deletepage', currentTopicPage);
  }
  
  function showPageModeButtons() {
    return currentTopicPage &&
    (currentTopicPage.hasEditableContent ||
      currentTopicPage.hasEditableProperties ||
      currentTopicPage.hasViewableProperties);
  }
</script>

<style lang="scss">
  .topic-page-header-area {
    width: 100%;

    .page-mode-buttons {
      border: 1px dashed lightgrey;
    }

    .show-search-btn {
      margin-left: 10px;
    }

    .item {
      font-size: 16px;
      padding: 0 5px;
    }

    .page-mode {
      font-weight: bold;
    }

    .toggle-snippet-view-btn {
      margin-right: 10px;
      min-width: 100px;
    }

    //.append-snippet-action {
    //  display: inline-flex;
    //  // margin-right: 20px;
    //
    //  :global(.icon) {
    //    font-size: 30px;
    //  }
    //}
    
    .snippet-action-group {
      :global(.icon) {
        font-size: 30px;
        font-weight: bold;
      }     
      
      margin-left: 10px;
    }

    .git-action-btn, .button-group {
      margin-left: 10px;
    }
  }
</style>

<div class="topic-page-header-area">
  <TwoPartHeaderPane pinnable={pinnable} pinned={pinned} on:togglepin>
    <svelte:fragment slot="left">
      <VerticalCenter>
        <VerticalCenterItem>
          {#if currentTopicPage}
            <slot><h2>{currentTopicPage.title}</h2></slot>
          {:else}
            <slot>No Page Loaded</slot>
          {/if}
        </VerticalCenterItem>
      </VerticalCenter>
    </svelte:fragment>
    <svelte:fragment slot="right">
      <VerticalCenterItem>
        {#if showPageModeButtons()}
          <div class="page-mode-buttons">
            <VerticalCenter>
              {#if pageMode === PANE_PAGE_MODE.View || !currentTopicPage.hasEditableContent}
                <VerticalCenterItem>
                  <span class="item page-mode">VIEW</span>
                </VerticalCenterItem>
              {:else if pageMode === PANE_PAGE_MODE.EditContent}
                <VerticalCenterItem>
                  <span class="item page-mode">EDIT</span>
                </VerticalCenterItem>
              {:else if pageMode === PANE_PAGE_MODE.EditPageOptions}
                <VerticalCenterItem>
                  <span class="item page-mode">EDIT PAGE PROPERTIES</span>
                </VerticalCenterItem>
              {:else if pageMode === PANE_PAGE_MODE.ViewPageOptions}
                <VerticalCenterItem>
                  <span class="item page-mode">PAGE PROPERTIES</span>
                </VerticalCenterItem>                
              {/if}
              <VerticalCenterItem>
                <MaterialIcon iconName="visibility" on:click={onViewPage} title="View Page"/>
              </VerticalCenterItem>
              {#if currentTopicPage && currentTopicPage.hasEditableContent}
                <VerticalCenterItem>
                  <MaterialIcon iconName="mode_edit" on:click={onEditPage}
                                title="Edit Page Content"/>
                </VerticalCenterItem>
              {/if}
              {#if currentTopicPage && currentTopicPage.hasEditableProperties}
                <VerticalCenterItem>
                  <MaterialIcon iconName="note_alt" on:click={onEditPageOptions} title="Edit Page Properties" />
                </VerticalCenterItem>
              {:else if currentTopicPage && currentTopicPage.hasViewableProperties}
                <VerticalCenterItem>
                  <MaterialIcon iconName="note_alt" on:click={onViewPageOptions} title="View Page Properties" />
                </VerticalCenterItem>                
              {/if}
            </VerticalCenter>
          </div>
        {/if}
        {#if pageMode === PANE_PAGE_MODE.EditContent && currentTopicPage.hasEditableContent}
          <div class="snippet-action-group">
            <VerticalCenter>
              <VerticalCenterItem>
                <button class="toggle-snippet-view-btn" type="button" on:click={onToggleSnippetsView}>
                  {isExpanded ? 'Collapse All' : 'Expand All'}
                </button>
              </VerticalCenterItem>
              <VerticalCenterItem>
                {#if appendSection.enable}
                  <MaterialIcon iconName="remove" on:click={onToggleAppendSnippetSection}
                                title={'Hide Edit Snippet Section'}/>
                {:else}
                  <MaterialIcon iconName="add" on:click={onToggleAppendSnippetSection} title={'Show Edit Snippet Section'}/>
                {/if}
              </VerticalCenterItem>
            </VerticalCenter>
          </div>
        {/if}
        {#if (allowRefresh && currentTopicPage && currentTopicPage.isRefreshable) ||
             (allowOpenInGitHost && currentTopicPage && currentTopicPage.isOpenableInGitHost) ||
             (allowDelete && currentTopicPage && currentTopicPage.isDeletable)}
          <div class="button-group">
            {#if allowRefresh && currentTopicPage && currentTopicPage.isRefreshable}
              <button on:click="{()=> {onRefreshFromServer()}}">
                Refresh Now
              </button>
            {/if}
            {#if allowOpenInGitHost && currentTopicPage && currentTopicPage.isOpenableInGitHost}
              <button class="git-action-btn" type="button" disabled={!originalFilePath} on:click={onOpenGitHost}>
                View in {originalHostName}
              </button>
            {/if}
            {#if allowDelete && currentTopicPage && currentTopicPage.isDeletable}
              <button on:click="{()=> {onDeletePage()}}" disabled="{!currentTopicPage.jsonData.isUnreferenced}">
                Delete Page
              </button>
            {/if}
          </div>
        {/if}
      </VerticalCenterItem>
      {#if searchResultsAvailable}
        <VerticalCenterItem>
          <div class="show-search-btn">
            <ButtonWithIcon
              iconName="pageview"
              text="Show Search Results"
              on:click={onShowSearchResults}
            />
          </div>
        </VerticalCenterItem>
      {/if}
    </svelte:fragment>
  </TwoPartHeaderPane>
</div>
