<script>
  import VerticalCenter from "../layout-helpers/VerticalCenter.svelte";
  import VerticalCenterItem from "../layout-helpers/VerticalCenterItem.svelte";

  export let message = "";
</script>

<style>
  .header-pane-with-info {
    padding: 5px 10px;
    width: 100%;
  }
</style>

<div class="header-pane-with-info">
  {#if message}
    <div class="header-info">
      <VerticalCenter>
        <VerticalCenterItem>
          {message}
        </VerticalCenterItem>
      </VerticalCenter>
    </div>
  {:else}
    <div class="header-info">
      <slot/>
    </div>
  {/if}
</div>
