<script>
  import {createEventDispatcher, onMount} from "svelte";
  import AutoComplete from "simple-svelte-autocomplete";
  import {RequestService} from "../../comm/RequestService";

  export let snippet;
  export let pageSuid;
  export let editSnippetSelected = false;
  export let snippetTabActive = '';

  let selectedMountPoint;
  let mountPoints = [];
  let organizations = [];
  let personalAccountOrg = {
    isPersonal: true,
    // How can I get access to the current user id?
    id: { value: '', rawValue: '' },
    name: "Personal",
    fullName: "Personal"
  };

  let selectedOrg;
  let currentOrg;
  let startLineNumber;
  let endLineNumber;
  let currentSnippet;

  const dispatch = createEventDispatcher();

  onMount(async () => {
    await getOrganizationsData();
    
    if (snippet.mountPointId) {
      await getMountPoint();
    }
  });

  $: if (currentOrg !== selectedOrg) {
    currentOrg = selectedOrg;
    getMountPointsData().then(() => {
      selectedMountPoint = mountPoints.find(item => item.id === snippet.mountPointId);
    });
  }
  
  $: if (currentSnippet !== snippet) {
    startLineNumber = String(snippet.startLineNumber);
    endLineNumber = String(snippet.endLineNumber);
    selectedMountPoint = mountPoints.find(item => item.id === snippet.mountPointId);

    currentSnippet = snippet;
  }

  async function getOrganizationsData() {
    const requestUrl = `/apiv2/organization/memberof`;
    const resp = await RequestService.get(requestUrl);

    if (resp.resultCode === 0) {
      organizations = [personalAccountOrg, ...resp.result] || [personalAccountOrg];
    }
  }
  
  async function getMountPoint() {
    const requestUrl = `/apiv2/mountpoint/${snippet.mountPointId}`;
    const resp = await RequestService.get(requestUrl);   
    if (resp.resultCode === 0) {
      selectedOrg = organizations.find(item => item.id.value === resp.result.ownerId);
      if (selectedOrg === null || selectedOrg === undefined) {
        selectedOrg = organizations[0];
      }
    }
  }

  async function getMountPointsData() {
    const requestUrl =
      currentOrg && !currentOrg.isPersonal
        ? `/apiv2/mountpoint/all?orgId=${currentOrg.id.value}`
        : `/apiv2/mountpoint`;

    const resp = await RequestService.get(requestUrl);

    if (resp.resultCode === 0) {
      mountPoints = resp.result || [];
      selectedMountPoint = mountPoints.find(item => item.id === snippet.mountPointId);
    }
  }

  async function onSaveFileSnippet(event) {
    let req;
    let url;
    const data = {
      mountPointId: selectedMountPoint.id,
      relativePath: snippet.relativePath,
      startLineNumber: Number(startLineNumber),
      endLineNumber: Number(endLineNumber)
    };
    
    if (snippet.suid) {
      url = `/apiv2/topicpages/${pageSuid}/snippets/filereference/${snippet.suid}`;
      req = RequestService.put(url, data)
    } else {
      url = `/apiv2/topicpages/${pageSuid}/snippets/filereference`;
      req = RequestService.post(url, data)
    }

    const resp = await req;

    if (resp.resultCode === 0) {
      if (event.submitter.value === 'close') {
        onCancel();
      }
      
      // What fields should we clear?
      // if (!editSnippetSelected) {
      //   content = '';
      //   editor.setValue('');
      // }

      dispatch('snippetadded');
    }
  }

  function onClear() {
  }

  function onCancel() {
    dispatch('cancelsnippetupdate', {snippet});
    dispatch('clearcustomstyle');
  }
</script>

<style lang="scss">
  .file-snippet-form {
    border: solid 1px #bed6eb;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    .body {
      display: flex;
      flex-direction: column;
      padding: 10px;

      .form-field-label {
        max-width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 5px;

        input[type='text'], textarea {
          width: 80%;
        }

        input[type='checkbox'] {
          margin-right: 10px;
          line-height: 0;
        }

        .file-name-field {
          padding-right: 20px;
        }
      }

      .field-with-note > span {
        display: flex;
        flex-direction: column;
        width: 80%;

        input[type='text'] {
          width: 100%;
        }

        span {
          text-transform: none;
          font-size: 14px;
        }
      }

      .hide {
        display: none;
      }

      .checkbox-field {
        justify-content: flex-start;
        cursor: pointer;
      }

      .button-wrap {
        margin-top: 10px;
      }
    }
  }

  .organization-field,
  .mount-point-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;

    :global(.autocomplete) {
      min-width: 0;
      width: 100%;
      font-size: 9pt;
      padding: 0;
    }

    :global(.autocomplete-input) {
      padding-left: 2px;
    }

    :global(.autocomplete-list-item) {
      padding-left: 2px;
    }
  }
</style>

<form class="file-snippet-form" on:submit|preventDefault={onSaveFileSnippet}>
  <div class="body">
    <div style="width: 100%;">
      <label class="organization-field" for="organization-field" style="display: block; width: 100%;">
        <b>Personal/Organization:</b>
        <AutoComplete
          items={organizations}
          bind:selectedItem={selectedOrg}
          valueFieldName="id"
          labelFieldName="name"
          delay="{200}"
        />
      </label>
    </div>
    <label class="mount-point-field" for="mount-point-field" style="display: block; width: 100%;">
      <b>Mount Point:</b>
      <AutoComplete
        items={mountPoints}
        bind:selectedItem={selectedMountPoint}
        valueFieldName="id"
        labelFieldName="name"
        delay="{200}"
      />
    </label>
    <label class="form-field-label">Relative Path
      <input type="text" bind:value={snippet.relativePath}>
    </label>
    <label class="form-field-label">Starting Line Number
      <input type="text" pattern="^-?[0-9]+$" maxlength="10" bind:value={startLineNumber}>
    </label>
    <label class="form-field-label">Ending Line Number
      <input type="text" pattern="^-?[0-9]+$" maxlength="10" bind:value={endLineNumber}>
    </label>
    <div class="button-wrap">
      {#if editSnippetSelected}
        <button value="update" type="submit">Update</button>
        <button value="close" type="submit">Update & Close</button>
      {:else}
        <button value="update" type="submit">Add</button>
        <button value="close" type="submit">Add & Close</button>
      {/if}
      <button on:click={onClear} type="button">Clear</button>
      <button on:click={onCancel} type="button">Close</button>
    </div>    
  </div>
</form>    
