<script>
  import { createEventDispatcher } from 'svelte';
  import PreRender from '../layout-helpers/Prerender.svelte';
  import FullHeight from '../layout-helpers/FullHeight.svelte';
  import TopicPagePane from '../topic-pages/TopicPagePane.svelte';
  import SearchPane from '../search/SearchPane.svelte';
  import { CONTENT_PAGE_MODE } from "../../constants";

  export let pinnable;
  export let pinned;
  export let searchResultsAvailable;
  export let topicPage;
  export let navNode;
  export let mode = CONTENT_PAGE_MODE.Content;
  export let searchTerm = '';
  export let useRegexp = false;

  const dispatch = createEventDispatcher();

  function onShowSearchResults() {
    dispatch('modechanged', {
      mode: CONTENT_PAGE_MODE.Search
    });
  }

  function onShowTopicPage() {
    dispatch('modechanged', {
      mode: CONTENT_PAGE_MODE.Content
    });
  }
</script>

<PreRender visible={mode === CONTENT_PAGE_MODE.Content}>
  <FullHeight>
    <TopicPagePane
      allowDelete={false}
      allowRefresh={true}
      allowOpenInGitHost={true}
      pinnable={pinnable}
      pinned={pinned}
      searchResultsAvailable={searchResultsAvailable}
      on:togglepin
      on:showsearchresults={onShowSearchResults}
      {topicPage}
      {navNode}
    />
  </FullHeight>
</PreRender>

<PreRender visible={mode === CONTENT_PAGE_MODE.Search}>
  <FullHeight>
    {#if mode === CONTENT_PAGE_MODE.Search}
      <SearchPane
        pinnable={pinnable}
        pinned={pinned}
        searchTerm={searchTerm}
        useRegexp={useRegexp}
        on:togglepin
        on:showtopicpage={onShowTopicPage}
        on:clearsearch
      />
    {/if}
  </FullHeight>
</PreRender>
