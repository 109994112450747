<script>
  import { createEventDispatcher } from "svelte";
  import { customTooltip } from '../layout-helpers/Tooltip';

  import ActionMenu from './ActionMenu.svelte';
  import EditPageOptions from "./EditPageOptions.svelte";

  export let tree;
  export let enableDragAndDrop;
  export let depth = 0;
  export let onClick;
  export let onRemovePage;
  export let onPageOptUpdated;
  export let onDrillInto;
  export let nodesByLocalId;

  const dispatch = createEventDispatcher();
  const px = 'px';

  let dropdownPlacementTrigger;
  let depthSpacer = depth * 20;
  let draggableElementId;
  let endElementId;
  let title = '';

  function forceRefresh(variable) {
    if (variable === tree) {
      tree = tree;
    }
  }

  function clickHandler(event, child) {
    dispatch('nodeselected', {
      node: child
    });
  }

  function expandHandler(event, child) {
    if (!child.childrenLoaded) {
      dispatch('nodeexpanded', {
        node: child
      });
    } else {
      dispatch('nodeexpanded', {
        node: child
      });
      child.expanded = true;
      forceRefresh(tree);
    }
  }

  function collapseHandler(event, child) {
    child.expanded = false;
    forceRefresh(tree);
  }

  function drillIntoHandler(event, child) {
    if (onDrillInto) {
      onDrillInto(child);
    }
  }

  function removePageHandler(event) {
    event.detail.removing = true;
    forceRefresh(tree);
  }

  function removeConfirmHandler(event, child) {
    child.removing = false;
    if (onRemovePage) {
      onRemovePage(event, child);
      forceRefresh(tree);
    }
  }

  function removeCancelHandler(event, child) {
    child.removing = false;
    forceRefresh(tree);
  }

  function getUniqId(child) {
    return child.localId
  }

  function onAddChild(evt) {
    dispatch('beginaddchild', evt.detail);
  }

  function onAddSibling(evt) {
    dispatch('beginaddsibling', evt.detail);
  }

  function onAddTextHeading(evt) {
    dispatch('beginaddtextheading', evt.detail);
  }  

  function editPageOptions(data) {
    data.detail.isEditMode = true;
    forceRefresh(tree);
  }

  function cancelPageOptionsUpdate(data) {
    data.detail.isEditMode = false;
    forceRefresh(tree);
  }

  function onCreateNewPage(evt, node) {
    if (node.relationshipType === 'child') {
      // Complete add child operation
      dispatch('completeaddchild', {
        tempNode: node,
        newPageTitle: title
      });
    } else if (node.relationshipType === 'sibling') {
      // Complete add sibling operation
      dispatch('completeaddsibling', {
        tempNode: node,
        newPageTitle: title
      });
    }

    title = '';
  }

  function onCreateNewTextHeading(evt, node) {
    if (node.relationshipType === 'child') {
      // Complete add child operation
      dispatch('completeaddtextheading', {
        tempNode: node,
        newPageTitle: title
      });
    } else if (node.relationshipType === 'sibling') {
      // Complete add sibling operation
      dispatch('completeaddtextheading', {
        tempNode: node,
        newPageTitle: title
      });
    }

    title = '';
  }

  function onCancelCreation(evt, node) {
    if (node.relationshipType === 'child') {
      // Cancel add child operation
      dispatch('canceladdchild', {
        tempNode: node
      });
    } else if (node.relationshipType === 'sibling') {
      // Cancel add sibling operation
      dispatch('canceladdsibling', {
        tempNode: node
      });
    }

    title = '';
  }

  function onCancelCreateTextHeading(evt, node) {
    if (node.relationshipType === 'child') {
      // Cancel add child operation
      dispatch('canceladdtextheading', {
        tempNode: node
      });
    } else if (node.relationshipType === 'sibling') {
      // Cancel add sibling operation
      dispatch('canceladdtextheading', {
        tempNode: node
      });
    }

    title = '';
  }

  function onFocusEl(node) {
    node.focus();
  }

  function onPageOptionsUpdated(node, changeDetails) {
    onPageOptUpdated(node, changeDetails)
  }

  function setDragEffects(evt, nodeToDropOn) {
    if (nodeToDropOn.isTocEntriesModifiable) {
      // The target node is a topic page - it can accept incoming drag requests
      const dti = JSON.parse(evt.dataTransfer.getData("text") || "\"\"");
      evt.dataTransfer.effectAllowed = dti.dragAction;
      evt.dataTransfer.dropEffect = dti.dragAction;
    } else {
      // Mapped or virtual pages cannot accept incoming drag requests
      evt.dataTransfer.effectAllowed = "none";
      evt.dataTransfer.dropEffect = "none";
    }
  }

  function onDragStart(evt, node) {
    const dti = {
      parentLocalId: tree.localId,
      localId: node.localId
    };

    if (evt.ctrlKey || evt.metaKey || evt.altKey) {
      dti.dragAction = "copy";
      evt.dataTransfer.dropEffect = 'copy';
    } else {
      if (tree.isTocEntriesModifiable) {
        // Default action for topic pages is "move"
        dti.dragAction = "move"
        evt.dataTransfer.effectAllowed = "move";
        evt.dataTransfer.dropEffect = "move";
      } else {
        // Default action for mapped or virtual pages is not allowed
        // User should hold down the ctrl key to initiate a copy
        dti.dragAction = "none";
        evt.dataTransfer.effectAllowed = "none";
        evt.dataTransfer.dropEffect = "none";
      }
    }

    evt.dataTransfer.setData("text", JSON.stringify(dti));
  }

  function onDragEnd(evt, node) {
  }

  function onDragOver(evt, node) {
    evt.preventDefault();
    setDragEffects(evt, node);
  }

  function onDragEnter(evt, node) {
    node.dragCounter++;
    tree = tree;

    if (node.dragCounter === 1) {
      setDragEffects(evt, node);
    }
  }

  function onDragLeave(evt, node) {
    evt.preventDefault();
    node.dragCounter--;
    tree = tree;

    if (node.dragCounter === 0) {
      setDragEffects(evt, node);
    }
  }

  function onDrop(evt, node) {
    // console.log("Inside onDrop");
    const dti = JSON.parse(evt.dataTransfer.getData("text"));

    if (dti.dragFromSearch) {
      dispatch("newnode", {
        suid: dti.suid,
        title: dti.title,
        destParentNode: tree,
        destNode: node
      });
    } else {
      // console.log("nodesByLocalId =", nodesByLocalId);
      const sourceParentNode = nodesByLocalId[dti.parentLocalId];
      const sourceNode = nodesByLocalId[dti.localId];

      if (node !== sourceNode && dti.dragAction !== "none") {
        const eventName = (dti.dragAction === "copy") ? "copynode" : "movenode";
        dispatch(eventName, {
          sourceParentNode: sourceParentNode,
          sourceNode: sourceNode,
          destParentNode: tree,
          destNode: node
        });
      }
    }

    node.dragCounter = 0;
    tree = tree;
    evt.preventDefault();
  }

  function onDragAboveEnter(evt, node) {
    node.dragAboveCounter++;
    tree = tree;

    if (node.dragAboveCounter === 1) {
      setDragEffects(evt, tree);
    }
  }

  function onDragAboveLeave(evt, node) {
    evt.preventDefault();
    node.dragAboveCounter--;
    tree = tree;

    if (node.dragAboveCounter === 0) {
      setDragEffects(evt, tree);
    }
  }

  function onDragAboveOver(evt, node) {
    evt.preventDefault();
    setDragEffects(evt, tree);
  }

  function onDropAbove(evt, node, siblingNodeAbove) {
    const dti = JSON.parse(evt.dataTransfer.getData("text"));

    if (dti.dragFromSearch) {
      dispatch("newnodebetween", {
        suid: dti.suid,
        title: dti.title,
        destParentNode: tree,
        destNode1: siblingNodeAbove,
        destNode2: node
      });
    } else {
      const sourceParentNode = nodesByLocalId[dti.parentLocalId];
      const sourceNode = nodesByLocalId[dti.localId];

      if (node !== sourceNode && dti.dragAction !== "none") {
        const eventName = (dti.dragAction === "copy") ? "copynodebetween" : "movenodebetween";
        dispatch(eventName, {
          sourceParentNode: sourceParentNode,
          sourceNode: sourceNode,
          destParentNode: tree,
          destNode1: siblingNodeAbove,
          destNode2: node
        });
      }
    }

    node.dragAboveCounter = 0;
    tree = tree;
    evt.preventDefault();
  }

  function onDragBelowEnter(evt, node) {
    node.dragBelowCounter++;
    tree = tree;

    if (node.dragBelowCounter === 1) {
      setDragEffects(evt, tree);
    }
  }

  function onDragBelowLeave(evt, node) {
    evt.preventDefault();
    node.dragBelowCounter--;
    tree = tree;

    if (node.dragBelowCounter === 0) {
      setDragEffects(evt, tree);
    }
  }

  function onDragBelowOver(evt, node) {
    evt.preventDefault();
    setDragEffects(evt, tree);
  }

  function onDropBelow(evt, node) {
    const dti = JSON.parse(evt.dataTransfer.getData("text"));

    if (dti.dragFromSearch) {
      dispatch("newnodebetween", {
        suid: dti.suid,
        title: dti.title,
        destParentNode: tree,
        destNode1: node,
        destNode2: null
      });
    } else {
      const sourceParentNode = nodesByLocalId[dti.parentLocalId];
      const sourceNode = nodesByLocalId[dti.localId];

      if (node !== sourceNode && dti.dragAction !== "none") {
        const eventName = (dti.dragAction === "copy") ? "copynodebetween" : "movenodebetween";
        dispatch(eventName, {
          sourceParentNode: sourceParentNode,
          sourceNode: sourceNode,
          destParentNode: tree,
          destNode1: node,
          destNode2: null
        });
      }
    }

    node.dragBelowCounter = 0;
    tree = tree;
    evt.preventDefault();
  }
</script>

<style lang="scss">
  @import '../../../static/styles/theme-standard';
  @import '../../../static/styles/core-fonts';

  .nav-row {
    height: 30px;

    .actions-cell {
      width: 40px;
      padding: 5px 0;

      &--content-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 3px;

        .action-menu, .drill-into {
          display: none;
        }
      }
    }

    &.droppable {
      background-color: rgba(68, 64, 60, 0.3);
    }

    .title-cell {
      padding: 0 5px;
      max-width: 80px;

      &--content-wrap {
        display: flex;
        align-items: center;

        .text-ellipsis {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .collapsed, .expanded, .empty {
          margin-right: 5px;
          display: flex;
        }

        .page-link {
          &:hover {
            color: $blue-800;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    .count-cell {
      width: 30px;
      text-align: center;
      padding: 0;

      span {
        cursor: inherit;
      }
    }

    span {
      cursor: pointer;
    }
  }
  
  .remove-page-title {
    text-align: center;
    word-break: break-word;
  }

  .title-field {
    width: 100%;
  }

  .remove-actions, .add-page-actions {
    display: flex;
    justify-content: center;

    button {
      min-width: 75px;
      margin: 5px 5px 0;
    }
  }

  .nav-row:hover {
    .action-menu, .drill-into {
      display: flex;
    }
  }

  .selectable-nav-row:hover {
    background-color: rgba(0, 0, 0, .05);
  }

  .reorder-row, .last-row {
    height: 8px;

    td {
      padding: 0;
    }

    &.droppable {
      background-color: rgba(68, 64, 60, 0.3);
    }
  }

  :global(.tippy-content) {
    word-break: break-word;
  }
</style>

{#if tree.expanded}
  {#each tree.children as child, index}
    {#if enableDragAndDrop && (index === 0 || (index > 0 && !tree.children[index-1].expanded))}
      <tr class="main-reorder-item reorder-row" id="{'reorder_' + getUniqId(child)}"
          class:droppable={child.dragAboveCounter >= 1}
          on:dragenter={evt => onDragAboveEnter(evt, child)}
          on:dragleave={evt => onDragAboveLeave(evt, child)}
          on:dragover={evt => onDragAboveOver(evt, child)}
          on:drop={evt => onDropAbove(evt, child, (index === 0) ? null : tree.children[index-1])}
      >
        <td colspan=3></td>
      </tr>
    {/if}

    {#if child.removing}
      <tr>
        <td colspan="3">
          <div class="remove-page-title">Remove
            <b>
              {child.label}
            </b>?
          </div>
          <div class="remove-actions">
            <button type="button" on:click={event => removeConfirmHandler(event, child)}>
              Remove
            </button>
            <button type="button" on:click={event => removeCancelHandler(event, child)}>
              Cancel
            </button>
          </div>
        </td>
      </tr>
    {:else if child.pageCreating}
      <tr>
        <td colspan="3">
          <form on:submit|preventDefault={(event) => onCreateNewPage(event, child)}>
            <label>
              <input type="text" class="title-field" use:onFocusEl
                     placeholder="{child.relationshipType === 'child' ? 'Enter child page name' : 'Enter sibling page name'}"
                     bind:value={title} required>
            </label>
            <div class="add-page-actions">
              <button type="submit">Add</button>
              <button type="button" on:click={event => onCancelCreation(event, child)}>
                Cancel
              </button>
            </div>
          </form>
        </td>
      </tr>
    {:else if child.textHeadingCreating}
      <tr>
        <td colspan="3">
          <form on:submit|preventDefault={(event) => onCreateNewTextHeading(event, child)}>
            <label>
              <input type="text" class="title-field" use:onFocusEl
                     placeholder="Enter heading label"
                     bind:value={title} required>
            </label>
            <div class="add-page-actions">
              <button type="submit">Add</button>
              <button type="button" on:click={event => onCancelCreateTextHeading(event, child)}>
                Cancel
              </button>
            </div>
          </form>
        </td>
      </tr>      
    <!--{:else if child.isEditMode}-->
    <!--  {#if child.isPage}-->
    <!--    <tr>-->
    <!--      <td colspan="3">-->
    <!--        <PageOptionsUpdate-->
    <!--          onCompleted={onPageOptionsUpdated}-->
    <!--          pageId={child.pageSuid}-->
    <!--          pageData={child}-->
    <!--          on:cancelUpdate={cancelPageOptionsUpdate}-->
    <!--        />-->
    <!--      </td>-->
    <!--    </tr>-->
    <!--  {/if}-->
    {:else}
      <tr class="main-reorder-item nav-row" id="{getUniqId(child)}"
          class:droppable={enableDragAndDrop && child.dragCounter >= 1}
          class:selectable-nav-row={child.isSelectable}
          draggable={enableDragAndDrop}
          on:dragstart={evt => onDragStart(evt, child)}
          on:dragend={evt => onDragEnd(evt, child)}
          on:dragover={evt => onDragOver(evt, child)}
          on:dragenter={evt => onDragEnter(evt, child)}
          on:dragleave={evt => onDragLeave(evt, child)}
          on:drop={evt => onDrop(evt, child)}
      >
        <td class="title-cell">
          <div class="title-cell--content-wrap">
            <div style="width: {depthSpacer + px}; flex-shrink: 0;"></div>
            {#if child.isExpandable}
              {#if child.innerPageCount === 0}
                <span class="empty">
                <span class="material-icons-outlined">insert_drive_file</span>
              </span>
              {:else if !child.expanded}
                <span class="collapsed" on:click={event => expandHandler(event, child)}>
                  <span class="material-icons-outlined">add_box</span>
                </span>
              {:else}
                <span class="expanded" on:click={event => collapseHandler(event, child)}>
                  <span class="material-icons-outlined">indeterminate_check_box</span>
                </span>
              {/if}
            {/if}
            {#if child.isEditMode}
              <EditPageOptions
                onCompleted={onPageOptionsUpdated}
                pageId={child.pageSuid}
                pageData={child}
                parentPageId={child.parent ? child.parent.pageSuid : null}
                on:cancelUpdate={cancelPageOptionsUpdate}
              />
            {:else if !!child.label}
              {#if !child.isPage}
                <span class="text-ellipsis" id="{child.localId}">
                  <b><u>{child.label}</u></b>
                </span>
              {:else}
                <span class="page-link text-ellipsis" id="{child.suid}"
                      on:click={event => clickHandler(event, child)}
                      use:customTooltip={{ content: child.label }}>
                  {child.label}
                </span>
              {/if}
            {/if}
          </div>
        </td>
        <td class="count-cell">
          {#if child.innerPageCount !== 0}
            <span>({child.innerPageCount})</span>
          {/if}
        </td>
        <td class="actions-cell">
          <div class="actions-cell--content-wrap">
            <div class="action-menu">
              <ActionMenu
                allowRename={child.isRenameable}
                allowEditPageOptions={false}
                allowRemove={child.parent && child.parent.isTocEntriesModifiable}
                allowAddChildPages={child.isTocEntriesModifiable}
                allowAddSiblingPages={child.parent && child.parent.isTocEntriesModifiable}
                node={child}
                on:removePage={removePageHandler}
                on:editPageOptions={editPageOptions}
                on:addchild={onAddChild}
                on:addsibling={onAddSibling}
                on:addtextheading={onAddTextHeading}
              />
            </div>
            {#if child.isPage}
              <span class="drill-into" on:click={event => drillIntoHandler(event, child)}>
                <span class="material-icons-outlined">double_arrow</span>
              </span>
            {/if}
          </div>
        </td>
      </tr>
    {/if}

    <!-- TODO: Need a good way to allow mapped page options to be edited -->
    <!--                allowEditPageOptions={child.hasEditableProperties}-->

    {#if enableDragAndDrop && (!child.expanded && (index + 1 === tree.children.length))}
      <tr class="main-reorder-item last-row" id="{'last_' + getUniqId(child)}"
          class:droppable={child.dragBelowCounter >= 1}
          on:dragenter={evt => onDragBelowEnter(evt, child)}
          on:dragleave={evt => onDragBelowLeave(evt, child)}
          on:dragover={evt => onDragBelowOver(evt, child)}
          on:drop={evt => onDropBelow(evt, child)}
      >
        <td colspan=3></td>
      </tr>
    {/if}

    <svelte:self
      tree={child}
      depth={++depth}
      {enableDragAndDrop}
      {nodesByLocalId}
      {onClick}
      {onRemovePage}
      {onDrillInto}
      {onPageOptUpdated}
      on:nodeexpanded
      on:nodeselected
      on:movenodebetween
      on:movenode
      on:copynodebetween
      on:copynode
      on:newnodebetween
      on:newnode
      on:beginaddchild
      on:beginaddsibling
      on:beginaddtextheading
      on:completeaddchild
      on:completeaddsibling
      on:completeaddtextheading
      on:canceladdchild
      on:canceladdsibling
      on:canceladdtextheading
    />
  {/each}
{/if}
