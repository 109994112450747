class DateUtil {
  constructor() {
  }

  static now() {
    return new Date();
  }

  static getDateFromJson(jsonValue) {
    // return new Date(parseInt(jsonValue.replace("/Date(", "").replace(")/", ""), 10));
    return new Date(jsonValue);
  }

  static formatDate(date) {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    return month + '/' + day + '/' + year;
  }

  static formatTime(date) {
    let a_p;
    let curr_hour = date.getHours();
    if (curr_hour < 12) {
      a_p = 'am';
    } else {
      a_p = 'pm';
    }
    if (curr_hour === 0) {
      curr_hour = 12;
    }
    if (curr_hour > 12) {
      curr_hour = curr_hour - 12;
    }

    let curr_min = date.getMinutes();

    curr_min = curr_min + '';

    if (curr_min.length === 1) {
      curr_min = `0${curr_min}`;
    }

    return curr_hour + ':' + curr_min + '' + a_p;
  }

  static getCurrentFormattedDate() {
    const now = new Date();
    return DateUtil.formatDate(now);
  }

  static parseDateIndex(dateIndex) {
    const parts = dateIndex.match(/^(\d{4})(\d{2})(\d{2})$/);
    const dateText = parts[2] + '/' + parts[3] + '/' + parts[1] + ' 12:00 AM';
    const milli = Date.parse(dateText);
    const date = new Date(milli);

    return date;
  }
}

export default DateUtil;
