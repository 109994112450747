<style lang="scss">
  @import '../../../static/styles/core-palette';

  .app-default-styles {
    font-family: "Trebuchet MS", Verdana, Helvetica, sans-serifverdana, serif;
    font-size: 16px;
    height: 100%;
    width: 100%;

    :global(p) {
      margin-bottom: 1em;
    }
    
    :global(li p) {
      margin-top: 0;
      margin-bottom: 0;
    }
    
    :global(li pre) {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }

    :global(.header-title) {
      font-weight: bold;
      color: #339;
    }

    :global(.header-info) {
    }
  }
</style>

<div class="app-default-styles">
  <slot />
</div>
