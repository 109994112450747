<script>
  import "../../../static/css/tailwind.css";
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import {LoginService} from "./LoginService";
  import FullHeight from "../layout-helpers/FullHeight.svelte";
  import ScrollablePane from "../panes/ScrollablePane.svelte";
  import LicenseAgreement from "./LicenseAgreement.svelte";

  export let loginState;

  let isValidData = true;
  let isSuccessRequest = false;
  let registerForm;
  let errMessage = '';
  let acceptLicense = false;
  let acceptEval = false;
  let showLicense = false;
  let generalOptions = { evalMode: false };
  const dispatch = createEventDispatcher();

  resetForm();
  
  onMount(async () => {
    let result = await LoginService.getGeneralOptions();
    if (result.resultCode === 0) {
      generalOptions = result.result;
    } else {
      generalOptions = { evalMode: false };
    }
  });

  onDestroy(() => {
    resetForm();
    isValidData = true;
    isSuccessRequest = false;
  });

  function resetForm() {
    registerForm = {
      // invitationCode: '',
      userName: '',
      email: '',
      fullName: '',
      password: '',
      confirmPassword: '',
    }
  }

  async function onRegister() {
    if (registerForm.password !== registerForm.confirmPassword) {
      isValidData = false;
      errMessage = 'The passwords you entered do not match.';
      return;
    }

    const resp = await LoginService.register(registerForm);

    if (resp.resultCode === 0) {
      isSuccessRequest = true;
    } else {
      isSuccessRequest = false;
      isValidData = false;
      errMessage = resp.resultMessage;
    }
  }

  function onSignIn() {
    dispatch('changeLoginState', loginState.SignIn);
  }

  function onRequestInvite() {
    dispatch('changeLoginState', loginState.Invite);
  }
  
  function onShowLicenseAgreement() {
    showLicense = true;
  }
  
  function onAcceptAndCloseLicenseAgreement() {
    acceptLicense = true;
    showLicense = false;  
  }

  function onCloseLicenseAgreement() {
    showLicense = false;
  }
</script>

{#if !isSuccessRequest}
  {#if showLicense}
    <LicenseAgreement 
      on:acceptandclose={onAcceptAndCloseLicenseAgreement}
      on:close={onCloseLicenseAgreement} 
    />
  {:else}
    <div class="px-[20%]">
      <div class="font-sans font-400 text-slate-400 text-4xl pb-8">Create an account</div>
      <form flex flex-col on:submit|preventDefault={onRegister}>
        <label>
          <input class="font-mono text-lg px-1 py-2 mb-2 h-[50px]"
                 type="text"
                 placeholder="Login Id"
                 bind:value={registerForm.userName}
                 required
                 minlength="4"
                 maxlength="32"
                 pattern="^[A-Za-z\w]+$"
                 title="Login Id should only contain uppercase & lowercase letters, numbers, and the underscore character">
        </label>
        <label>
          <input class="font-mono text-lg px-1 py-2 mb-2 h-[50px]"
                 type="email"
                 placeholder="E-mail Address"
                 bind:value={registerForm.email}
                 required
                 pattern="\S+@\S+\.\S+"
                 minlength="7"
                 title="The E-Mail Address should be in the format anystring@anystring.anystring">
        </label>
        <label>
          <input class="font-mono text-lg px-1 py-2 mb-2 h-[50px]"
                 type="text"
                 placeholder="Full Name"
                 minlength="5"
                 bind:value={registerForm.fullName}
                 required>
        </label>
        <label>
          <input class="font-mono text-lg px-1 py-2 mb-2 h-[50px]"
                 type="password"
                 placeholder="Password"
                 minlength="5"
                 bind:value={registerForm.password}
                 required>
        </label>
        <div class="">
          <label>
            <input class="font-mono text-lg px-1 py-2 mb-2 h-[50px]"
                   type="password"
                   minlength="5"
                   placeholder="Confirm Password"
                   bind:value={registerForm.confirmPassword}
                   required>
          </label>
          {#if !isValidData}
            <div class="err-message">{errMessage}</div>
          {/if}
        </div>      
        <div class="mt-4 text-base border border-indigo-900 flex flex-row justify-start items-start m-0 p-0">
          <label>
            <input id="licenseAgreement" class="h-4 w-4" type="checkbox" bind:checked={acceptLicense}>
            <span>I have read and agree to the&nbsp;</span>
          </label>
          {#if generalOptions.subscriptionMode}
            <span class="hover:underline text-base text-blue-600 hover:text-blue-800 hover:cursor-pointer" on:click={onShowLicenseAgreement}>subscription agreement.</span>
          {:else}
            <span class="hover:underline text-base text-blue-600 hover:text-blue-800 hover:cursor-pointer" on:click={onShowLicenseAgreement}>license agreement.</span>
          {/if}
        </div>
        {#if generalOptions.evalMode}
          <div class="text-base border border-indigo-900 flex flex-row justify-start items-start m-0 p-0">
            <label>
              <input id="evalAgreement" class="h-4 w-4" type="checkbox" bind:checked={acceptEval}>
              <span>I acknowledge and accept the evaluation terms</span>
            </label>
          </div>
          <div class="font-sans text-base mt-2">
            <b><u>Evaluation Terms:</u></b>&nbsp;I acknowledge that this OpenSquiggly server instance is for 
            evaluation purposes only and that accounts may be
            deactivated, bandwidth-limited, disk space-limited, or otherwise usage-limited, and documents
            stored on the system may be deleted with or without notice to the user. Documents on this server are not
            guaranteed to be backed up and are not subject to any disaster recovery plans. Do not store permanent
            or important documents on this server for which the only copy of the document is stored on this server.
          </div>
          {#if !acceptEval}
            <div class="mt-4 font-sans text-[18px] font-semibold italic">
              Please accept the evaluation terms to proceed with creating the account.
            </div>
          {/if}
          <button type="submit" class="font-sans text-[14px] mt-4 submit-btn" disabled={!acceptEval}>Create an account</button>
        {:else}
          <button type="submit" class="font-sans text-[14px] mt-4 submit-btn" disabled={false}>Create an account</button>
        {/if}
      </form>
    </div>
  {/if}
{:else}
  <p class="font-sans text-[18px]">
    Welcome to OpenSquiggly. A new user account has been created and a confirmation e-mail has been sent to your
    e-mail address. Please login with your login id and password.
  </p>
{/if}
{#if !showLicense}
  {#if !isSuccessRequest}
    <div class="flex flex-col items-center mt-6 font-sans text-[14px]">or</div>
  {/if}
  <div class="flex flex-col items-center mt-2">
    <button type="button" class="go-to-btn font-sans text-[14px]" on:click="{onSignIn}">Sign in</button>
  </div>
{/if}
