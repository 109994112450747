<script>
  import { createEventDispatcher } from 'svelte';
  import { LoginService } from "./LoginService";

  export let loginState;

  const dispatch = createEventDispatcher();
  let login = '';
  let password = '';
  let isRemember = false;
  let isValidData = true;

  async function onSignIn() {
    try {
      isValidData = true;
      const resp = await LoginService.login({ userName: login, password, rememberMe: isRemember });

      if (resp.resultCode === 0) {
        localStorage.isUserLoggedIn = true;
        document.location.replace('/');
      } else {
        isValidData = false;
      }
    } catch (err) {
      console.error('login err ', err)
    }
  }

  function onResetPass() {
    dispatch('changeLoginState', loginState.ResetPass);
  }

  function onRegister() {
    dispatch('changeLoginState', loginState.Register);
  }
</script>

<style lang="scss">
  .login-form {

    .login-form-field {
      margin-bottom: 0;
    }

    @media screen and (max-width: 1050px) {
      &--actions {
        flex-direction: column;

        button {
          margin-bottom: 10px;
        }
      }
    }

    &--actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0 50px;

      label {
        display: flex;
        cursor: pointer;

        input[type="checkbox"] {
          margin-right: 10px;
          background-color: #6f117f;
          width: 16px;
          height: 16px;
        }
      }
    }

    .err-message {
      line-height: 1.2;
    }

    .forgot-password {
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
    }
  }
</style>

<div class="login-form px-[20%]">
  <h1>Sign in</h1>
  <form class="single-column-form" on:submit|preventDefault={onSignIn}>
    <label><input
      type="text"
      placeholder="Login Id"
      bind:value={login}
      required></label>
    <div class="login-form-field">
      <label><input
        type="password"
        placeholder="Password"
        bind:value={password}
        required></label>
      {#if !isValidData}
        <div class="err-message">Login was unsuccessful. Please correct the errors and try again.<br>
          The user name or password provided is incorrect.
        </div>
      {/if}
    </div>

    <div class="login-form--actions">
      <button type="submit">Sign in</button>
      <label>
        <input type="checkbox" bind:checked={isRemember}>Remember me
      </label>
    </div>
  </form>

  <div class="forgot-password">
    <span>Can't sign in to your account?</span>
    <a id="reset-pass-link" on:click={onResetPass}>Forgot your password</a>
  </div>

  <span class="or-text">or</span>
  <button type="button" class="go-to-btn" on:click={onRegister}>Create an account</button>
</div>
