import {TreeNodeItem} from "./TreeNodeItem.js";

export class StaticTextTreeNodeItem extends TreeNodeItem {
  constructor(parentTreeNodeItem, windowBottomCoord, tocEntry) {
    super(parentTreeNodeItem, windowBottomCoord, tocEntry);
  }
  
  dup() {
    return new StaticTextTreeNodeItem(
      this.parentTreeNodeItem,
      this.windowBottomCoord,
      this.tocEntry.dup()
    );
  }

  static createFromTreePageDto(dto, parentTreeNodeItem, windowBottomCoord, tocEntry) {
    return new StaticTextTreeNodeItem(parentTreeNodeItem, windowBottomCoord, tocEntry);
  }
  
  get pageSuid() {
    return null;
  }

  get title() {
    return this.tocEntry ? this.tocEntry.title : "";
  }
  
  set title(val) {
    if (this.tocEntry) {
      this.tocEntry.title = val;
    }
  }

  get tocEntryId() {
    return this._tocEntry ? this._tocEntry.entryId : null;
  }
  
  get isExpandable() {
    return false;
  }
  
  get backReferenceId() {
    return "text_" + this.tocEntryId + "_" + this.parent.pageSuid;
  }
  
  get isRenameable() {
    return true;
  }
}
