import { TreeNodeItem } from "./TreeNodeItem.js";
import TopicPage from "../../pages/TopicPage.js";

export class PageTreeNodeItem extends TreeNodeItem {
  constructor(parentTreeNodeItem, windowBottomCoord, tocEntry, page) {
    super(parentTreeNodeItem, windowBottomCoord, tocEntry);

    this._page = page;
  }
  
  dup() {
    return new PageTreeNodeItem(
      this.parentTreeNodeItem,
      this.windowBottomCoord,
      this.tocEntry.dup(),
      this.page.dup()
    );
  }

  static createFromTreePageDto(dto, parentTreeNodeItem, windowBottomCoord, tocEntry) {
    let page = new TopicPage(dto);
    return new PageTreeNodeItem(parentTreeNodeItem, windowBottomCoord, tocEntry, page);
  }
  
  get page() {
    return this._page;
  }
  
  get pageSuid() {
    return this.page ? this.page.suid : null;
  }

  get tocEntryPageSuid() {
    return this.tocEntry.pageSuid;
  }

  // get tocEntryId() {
  //   return this.tocEntry.entryId;
  // }

  set tocEntryId(val) {
    // Not recommended. Will be deprecated in the future.
    this.tocEntry.entryId = val;
  }

  set tocEntryType(val) {
    // Not recommended. Will be deprecated in the future.
    this.tocEntry.type = val;
  }

  get tocEntryTitle() {
    return this.tocEntry.title;
  }

  set tocEntryTitle(val) {
    // Not recommended. Will be deprecated in the future.
    this.tocEntry.title = val;
  }

  get tocEntryPageSuid() {
    return this.tocEntry.pageSuid;
  }

  set tocEntryPageSuid(val) {
    this.tocEntry.pageSuid = val;
  }

  get innerPageCount() {
    return this.page ? this.page.innerPageCount : 0;
  }
  
  set innerPageCount(val) {
    if (this.page) {
      this.page.innerPageCount = val;
    }
  }
  
  get title() {
    return this.page ? this.page.title : "";
  }
  
  set title(val) {
    if (this.page) {
      this.page.title = val;
    }
  }
  
  get mountPointId() {
    return this.page ? this.page.mountPointId : null;
  }
  
  get relativePath() {
    return this.page ? this.page.relativePath : null;
  }
  
  get isExpandable() {
    return true;
  }

  get isPage() {
    return !!this.page;
  }

  get isTopicPage() {
    return this.page && this.page.isTopicPage;
  }

  get isMappedPage() {
    return this.page && this.page.isMappedPage;
  }

  get isVirtualPage() {
    return this.page && this.page.isVirtualPage;
  }

  get isTocEntriesModifiable() {
    // The user can control the tocEntries of a topic page. Mapped pages
    // and virtual pages have their tocEntries controlled by the external
    // mount point that the page referernces.
    return this.isTopicPage;
  }

  get hasEditableProperties() {
    // Physical pages (i.e., topic pages and mapped pages) can have the title
    // of the page modified by the user. Virtual pages have their titles controlled
    // by the name of the file it references. However, we're going to change
    // this in the future so that the user can override the title of a virtual
    // page.
    return this.isPhysicalPage;
  }
  
  get backReferenceId() {
    return "page_" + this.pageSuid;
  }
  
  get isSelectable() {
    return true;
  }
  
  get isRenameable() {
    return this.isPhysicalPage;
  }
  
  get isDeletable() {
    return this.page && this.page.isDeletable;
  }
}
