import PageLocator from '../misc/PageLocator.js';
import DateUtil from '../utils/DateUtil.js';
import Page from './Page.js';
import { PAGE_LOCATOR_TYPE } from "../misc/PageLocator";

class TopicPage extends Page {
  constructor(jsonData) {
    super(jsonData);

    if (jsonData.itemType === undefined) {
      this.itemType = 1;
    }
    
    if (jsonData.pageLocator) {
      this.pageLocator = new PageLocator(jsonData.pageLocator);

      if (!jsonData.suid) {
        if (jsonData.pageLocator.pageLocatorType === PAGE_LOCATOR_TYPE.TopicPage &&
          jsonData.itemType === 1) {
          jsonData.suid = "tp:" + jsonData.id.value;
        } else if (jsonData.pageLocator.pageLocatorType === PAGE_LOCATOR_TYPE.TopicPage &&
          jsonData.itemType === 2) {
          jsonData.suid = "mp:" + jsonData.id.value;
        } else {
          jsonData.suid = "vp:" + jsonData.pageLocator.mountPointId.value + ":" + jsonData.pageLocator.relativePath;
        }
      }
    } else if (jsonData.suid && jsonData.suid.substring(0, 2) === "tp") {
      this.pageLocator = new PageLocator({
        pageLocatorType: PAGE_LOCATOR_TYPE.TopicPage,
        pageId: {
          value: jsonData.suid.substring(3)
        }
      });
    } else if (jsonData.suid && jsonData.suid.substring(0, 2) === "mp") {
      this.pageLocator = new PageLocator({
        pageLocatorType: PAGE_LOCATOR_TYPE.TopicPage,
        pageId: {
          value: jsonData.suid.substring(3)
        }
      });
    } else if (jsonData.suid && jsonData.suid.substring(0, 2) === "vp") {
      const parts = jsonData.suid.split(":");
      this.pageLocator = new PageLocator({
        pageLocatorType: PAGE_LOCATOR_TYPE.VirtualPage,
        mountPointId: {
          value: parts[1]
        },
        relativePath: parts[2]
      });
    } else {
      this.pageLocator = new PageLocator({
        pageLocatorType: PAGE_LOCATOR_TYPE.TopicPage,
        pageId: {
          value: jsonData.id.value
        }
      });
      jsonData.suid = "tp:" + jsonData.id.value;
    }
  }

  dup() {
    return new TopicPage({ ...this._jsonData });
  }

  getId() {
    if (this.pageLocator.pageLocatorType === PAGE_LOCATOR_TYPE.TopicPage) {
      return this.pageLocator.pageId;
    } else {
      throw new Error("getId() is not valid for virtual pages");
    }
  }

  get suid() {
    return this.jsonData.suid;
  }

  get title() {
    return this.jsonData.title;
  }

  set title(val) {
    this.jsonData.title = val;
  }

  get innerPageCount() {
    return this.jsonData.innerPageCount;
  }
  
  set innerPageCount(val) {
    this.jsonData.innerPageCount = val;
  }
  
  get relativePath() {
    return this.jsonData.relativePath;
  }
  
  get mountPointId() {
    return this.jsonData.mountPointId;
  }

  get urlSlug() {
    return this.jsonData.options.urlSlug;
  }

  get isTopicPage() {
    return this.suid.substring(0, 3) === 'tp:';
  }

  getRenderLeftMargin() {
    if (!this.jsonData || !this.jsonData.options) {
      return true;
    }

    if (this.jsonData.options.overrideDisplayGutter) {
      return this.jsonData.options.displayGutter;
    } else {
      //return window.clientManager.userOptions.jsonData.globalTopicPageOptions.displayGutter;
      //TODO need to check this functionality when displayGutter will be work
    }
  }

  getRenderWithSeparators() {
    if (!this.jsonData || !this.jsonData.options) {
      return true;
    }

    if (this.jsonData.options.overrideDisplaySeparators) {
      return this.jsonData.options.displaySeparators;
    } else {
      //return window.clientManager.userOptions.jsonData.globalTopicPageOptions.displaySeparators;
      //TODO need to check this functionality when displaySeparators will be work
    }
  }

  getTimeStampOptions(/* snippet */) {
    if (this.timeStampOptions.override) {
      return this.timeStampOptions;
    } else {
      //return window.clientManager.userOptions.topicPageTimeStampOptions;
      //TODO need to check this functionality when topicPageTimeStampOptions will be work
    }
  }

  getAddPostData() {
    return {
      topicGuid: this.getId()
    };
  }

  getReorderPostData(snippets) {
    return {
      clientVersion: window.clientManager.clientVersion,
      currentDate: DateUtil.getCurrentFormattedDate(),
      topicGuid: this.getId(),
      snippets: snippets
    };
  }

  getRemovePostData(snippet) {
    return {
      clientVersion: window.clientManager.clientVersion,
      currentDate: DateUtil.getCurrentFormattedDate(),
      topicGuid: this.getId(),
      snippetId: snippet.getId()
    };
  }

  getEffectiveTimeStampOptions(userOptions) {
    if (this.timeStampOptions.override) {
      return this.timeStampOptions;
    } else {
      return userOptions.topicPageTimeStampOptions;
    }
  }
}

export default TopicPage;
