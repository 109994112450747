<script>
  import { RequestService } from "../../comm/RequestService";

  export let isAdmin = false;

  async function onRebuildIndexes() {
    const url = '/apiV2/search/rebuild';
    const req = await RequestService.post(url, null);

    if (req.resultCode === 0) {
      // console.log('Rebuild Indexes in process')
    }
  }

  async function onRebuildAllIndexes() {
    const url = '/apiV2/search/rebuild/all';
    const req = await RequestService.post(url, null);

    if (req.resultCode === 0) {
      // console.log('Rebuild All Indexes in process')
    }
  }
</script>

<p>
  Use this functionality if new features have been added to the product that require rebuilding indexes, if the database
  has been copied from one location to another, if the index has become corrupt somehow, etc.
</p>
<button type="button" on:click={onRebuildIndexes}>Rebuild Search Index</button>

{#if isAdmin}
  <p>Use this functionality for rebuild indexes for all users.</p>
  <button type="button" on:click={onRebuildAllIndexes}>Rebuild All Search Index</button>
{/if}
