<script>
  import { createEventDispatcher } from "svelte";

  export let tabsList = [];
  const dispatch = createEventDispatcher();

  function onChangeTab(tabType) {
    tabsList.forEach((tab) => {
      tab.isActive = tab.type === tabType;
    });
    tabsList = [...tabsList];

    dispatch('tabstatus', tabType);
  }
</script>


<style lang="scss">
  .switchers {
    display: flex;

    &--tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      min-height: 30px;
      background-color: #f3f7fb;
      cursor: pointer;
      text-align: center;
      padding: 5px;
      color: #41559c;
      border: solid 1px #bed6eb;
      border-bottom: none;
    }

    &--tab:last-child {
      border-left: none;
    }

    &--tab:first-child {
      border-right: none;
    }

    .active {
      background-color: #cae2f0;
      font-weight: bold;
    }
  }
</style>

<div class="switchers">
  {#each tabsList as tab}
    {#if tab.isDisplayed}
      <div class="{tab.isActive ? 'switchers--tab active' : 'switchers--tab'}"
           on:click={() => onChangeTab(tab.type)}
      >
        {tab.name}
      </div>
    {/if}
  {/each}
</div>
