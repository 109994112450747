<style lang="scss">
  .collapsible {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    margin: 0 0 16px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  }
</style>

<section class="accordions collapsible">
  <slot>Please add at least one Accordion</slot>
</section>
