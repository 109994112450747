<script>
  import SourceCodeViewer from "./SourceCodeViewer.svelte";

  export let searchData = '';
  export let switchSearchFile$ = {};
  export let switchSourceCodeSearchResults$ = null;
  export let snippet;
  
  let selectedLines = '';

  function getLinesInRange(str, startLine, endLine) {
    let currentLine = 1;
    let startIndex = 0;
    let endIndex = str.length;
    let foundStart = false;

    for (let i = 0; i < str.length; i++) {
      if (str[i] === '\n') {
        currentLine++;
      }

      if (!foundStart && (startLine === -1 || currentLine === startLine)) {
        startIndex = startLine === -1 ? 0 : (str[i] === '\n' ? i + 1 : i);
        foundStart = true;
      }

      if (endLine !== -1 && currentLine === endLine + 1) {
        endIndex = i - 1;
        break;
      }
    }

    return str.substring(startIndex, endIndex);
  }
  
  $: selectedLines = getLinesInRange(snippet.content, snippet.startLineNumber, snippet.endLineNumber);
</script>

<SourceCodeViewer
  {searchData}
  {switchSourceCodeSearchResults$}
  {switchSearchFile$}
  content={selectedLines}
  extensionType="cs"
  displayLineNumbers={true}
  startLineNumber={snippet.startLineNumber}
/>
