<script>
  // export let label = "";
  // export let required = false;
</script>

<style lang="scss">
  td.field-label {
    width: 250px;
  }

  td.field-input {
  }
  
  td.field-control {
    width: 125px;
  }
</style>

<tr>
  <td class="field-label"><slot name="label-column" /></td>
  <td class="field-input"><slot name="input-column" /></td>
  <td class="field-control"><slot name="control-column" /></td>
</tr>  
