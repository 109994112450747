<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  
  function onAddMountPoint() {
    dispatch("addmountpoint");
  }

  function onQuickAddMountPoint() {
    dispatch("quickaddmountpoint");
  }

  function onImportManifest() {
    dispatch("importmanifest");
  }

  function onRefreshAll() {
    dispatch("refreshall");
  }
</script>

<style lang="scss">
</style>

<div>
  <button type="button" on:click={onAddMountPoint}>Add New</button>
  <button type="button" on:click={onQuickAddMountPoint}>Quick Add</button>
  <button type="button" on:click={onImportManifest}>Import Manifest</button>
  <button type="button" on:click={onRefreshAll}>Refresh All</button>
</div>  
