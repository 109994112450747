import PageOptions from './PageOptions.js';

class OverrideablePageOptions extends PageOptions {
  constructor(sourceObjectOrJsonData) {
    if (sourceObjectOrJsonData === undefined) {
      sourceObjectOrJsonData = {
        overrideDisplayGutter: false,
        overrideDisplaySeparators: false
      };
    }
    
    super(sourceObjectOrJsonData);
  }

  get overrideDisplayGutter() {
    return this._jsonData.overrideDisplayGutter;
  }
  
  set overrideDisplayGutter(val) {
    this._jsonData.overrideDisplayGutter = val;
  }

  get overrideDisplaySeparators() {
    return this._jsonData.overrideDisplaySeparators;
  }
  
  set overrideDisplaySeparators(val) {
    this._jsonData.overrideDisplaySeparators = val;
  }
  
  getEffectiveDisplaySeparators(basePageOptions) {
    if (basePageOptions instanceof PageOptions) {
      return this.overrideDisplaySeparators ?
        this.displaySeparators :
        basePageOptions ? basePageOptions.getEffectiveDisplaySeparators() : false;
    } else if (basePageOptions) {
      return this.overrideDisplaySeparators ?
        this.displaySeparators :
        basePageOptions.displaySeparators !== undefined ? 
          basePageOptions.displaySeparators : false;     
    } else {
      return this.overrideDisplaySeparators ?
        this.displaySeparators : false;      
    }
  }
  
  getEffectiveDisplayGutter(basePageOptions) {
    if (basePageOptions instanceof PageOptions) {
      return this.overrideDisplayGutter ?
        this.displayGutter :
        basePageOptions ? basePageOptions.getEffectiveDisplayGutter() : false;
    } else if (basePageOptions) {
      return this.overrideDisplayGutter ?
        this.displayGutter :
        basePageOptions.displayGutter !== undefined ?
          basePageOptions.displayGutter : false;
    } else {
      return this.overrideDisplayGutter ?
        this.displayGutter : false;
    }
  }

  copy(overrideablePageOptions) {
    super.copy(overrideablePageOptions);
    this.overrideDisplayGutter = overrideablePageOptions.overrideDisplayGutter;
    this.overrideDisplaySeparators = overrideablePageOptions.overrideDisplaySeparators;
  }

  merge(overrideablePageOptions) {
    super.merge(overrideablePageOptions);

    // Note: I don't use the spread operator here because I want to ensure
    // that I only bring in known properties associated with this object
    if (overrideablePageOptions.overrideDisplayGutter !== undefined) {
      this.overrideDisplayGutter = overrideablePageOptions.overrideDisplayGutter;
    }
    
    if (overrideablePageOptions.overrideDisplaySeparators !== undefined) {
      this.overrideDisplaySeparators = overrideablePageOptions.overrideDisplaySeparators;
    }
  }

  getPlainObject() {
    return { ...this._jsonData };
  }
}

export default OverrideablePageOptions;
