<script>
  import { createEventDispatcher, onDestroy } from 'svelte';
  
  export let title = '';
  export let height = 200;
  export let width = null;

  const dispatch = createEventDispatcher();
  let modalElRef;
  let visible = false;
  let closeCallback;

  function keyPress(ev) {
    if (ev.key === "Escape") {
      close()
    }
  }

  export function open(callback) {
    closeCallback = callback;
    if (visible) return;
    window.addEventListener("keydown", keyPress);

    // This prevents scrolling of the main window on larger screens
    document.body.style.overflow = "hidden";

    visible = true;
    
    // Move the modal in the DOM to be the last child of <BODY> so that it can be on top of everything
    document.body.appendChild(modalElRef)
  }

  function close(retVal) {
    if (!visible) return;

    window.removeEventListener("keydown", keyPress);
    document.body.style.overflow = "";
    visible = false;
    if (closeCallback) closeCallback(retVal);
    dispatch('modalclosed');
  }

  onDestroy(() => {
    window.removeEventListener("keydown", keyPress);
  });
</script>

<style lang="scss">
  .topModal {
    visibility: hidden;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4448;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal {
      position: relative;
      background: white;
      filter: drop-shadow(5px 5px 5px #555);
      width: 30vw;
      min-height: 20vh;

      hr {
        margin: 0;
      }

      .modal-header, .modal-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 10px;
      }

      .modal-content {
        overflow: auto;
        padding: 10px;
        height: calc(100% - 100px);
        min-height: 100px;
      }
    }
  }

  .visible {
    visibility: visible !important;
  }
</style>

<div class="topModal" class:visible bind:this={modalElRef} on:click={()=>close()}>
  <div class="modal" on:click|stopPropagation>
    <div class="modal-header">
      <h1>{title}</h1>
      <span></span>
    </div>
    <hr>
    {#if width}
      <div class="modal-content" style="height: {height+'px'}; width: {width+'px'}; overflow-y: auto;">
        <slot/>
      </div>
    {:else}
      <div class="modal-content" style="height: {height+'px'}; overflow-y: auto;">
        <slot/>
      </div>
    {/if}
    <hr>
    <div class="modal-footer">
      <span></span>
      <button on:click={close}>Close</button>
    </div>
  </div>
</div>
