<script>
  import { onMount } from 'svelte';
  import { scale } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';

  export let node;
  export let allowRename = false;
  export let allowEditPageOptions = false;
  export let allowRemove = false;
  export let allowAddChildPages = false;
  export let allowAddSiblingPages = false;

  const dispatch = createEventDispatcher();

  let actionButtonRef;
  let windowBottomCoord;
  let isAllowedSize = true;
  let show = false; // menu state
  let menu = null; // menu wrapper DOM reference

  $: if (show) {
    const elemBottom = window.pageYOffset + actionButtonRef.getBoundingClientRect().bottom;
    if (windowBottomCoord - elemBottom <= 200) {
      isAllowedSize = false;
    }
  }

  onMount(() => {
    const handleOutsideClick = (event) => {
      if (show && !menu.contains(event.target)) {
        show = false;
      }
    };
    windowBottomCoord = node.windowBottomCoord;
    const handleEscape = (event) => {
      if (show && event.key === 'Escape') {
        show = false;
      }
    };

    document.addEventListener('click', handleOutsideClick, false);
    document.addEventListener('keyup', handleEscape, false);

    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
      document.removeEventListener('keyup', handleEscape, false);
    };
  });

  function onRemoveAction(event, node) {
    dispatch('removePage', node);
    show = false;
  }

  function onAddChildToTop(event, node) {
    dispatch('addchild', {
      newPageLocation: 'top',
      parentNode: node
    });
    show = false;
  }

  function onAddChildToBottom(event, node) {
    dispatch('addchild', {
      newPageLocation: 'bottom',
      parentNode: node
    });
    show = false;
  }

  function onAddSiblingAbove(event, node) {
    node.newPageLocation = 'top';
    dispatch('addsibling', {
      newPageLocation: 'above',
      relativeNode: node
    });
    show = false;
  }

  function onAddSiblingBelow(event, node) {
    dispatch('addsibling', {
      newPageLocation: 'below',
      relativeNode: node
    });
    show = false;
  }

  function onAddTextHeadingAbove(event, node) {
    node.newPageLocation = 'top';
    dispatch('addtextheading', {
      newPageLocation: 'above',
      relativeNode: node
    });
    show = false;
  }

  function onAddTextHeadingBelow(event, node) {
    node.newPageLocation = 'top';
    dispatch('addtextheading', {
      newPageLocation: 'below',
      relativeNode: node
    });
    show = false;
  }
  
  function onEditPageOptions(event, node) {
    dispatch('editPageOptions', node);
    show = false;
  }

  function onDisplayDebugInfo(event, node) {
    // console.log("node =", node);
  }

  function isPageTypeMatch(suid, type) {
    return suid.substring(0, 2) === type;
  }
</script>

<style lang="scss">
  @import '../../../static/styles/theme-standard';

  .relative {
    position: relative;
    display: flex;

    span {
      cursor: pointer;
    }
  }

  .active {
    background-color: rgba(0, 0, 0, .05);
  }

  .absolute-top {
    top: 20px;
  }

  .absolute-bottom {
    bottom: 20px;
  }

  .absolute {
    position: absolute;
    background: #fff;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    right: 0;
    z-index: 1;
    border-radius: 5px;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 30px;

      span {
        padding-right: 3px;
      }
    }

    div:hover {
      background-color: rgba(0, 0, 0, .05);
      font-weight: 600;
    }

    .separator {
      height: 2px;
      border-top: 1px solid $warmgray-900;
    }
  }
</style>

<div class="relative" bind:this={menu}>
  <span bind:this={actionButtonRef}
        class="material-icons-outlined {show ? 'active' : ''}"
        on:click={() => (show = !show)}
  >more_horiz</span>
  {#if show}
    <div
      in:scale={{ duration: 100, start: 0.95 }}
      out:scale={{ duration: 75, start: 0.95 }}
      class="absolute {isAllowedSize ? 'absolute-top' : 'absolute-bottom'}"
    >
      {#if allowRename}
        <div on:click={(event) => onEditPageOptions(event, node)}>
          <span class="material-icons-outlined">edit</span>
          Rename
        </div>
      {/if}
      {#if allowEditPageOptions}
        <div on:click={(event) => onEditPageOptions(event, node)}>
          <span class="material-icons-outlined">edit</span>
          Edit Page Options
        </div>
      {/if}
      {#if allowRemove}
        <div on:click={(event) => onRemoveAction(event, node)}>
          <span class="material-icons-outlined">delete</span>
          Remove
        </div>
      {/if}
      {#if (allowEditPageOptions || allowRemove) && (allowAddChildPages || allowAddSiblingPages)}
        <div class="separator"></div>
      {/if}
      {#if allowAddChildPages}
        <div on:click={(event) => onAddChildToTop(event, node)}>
          <span class="material-icons-outlined">add</span>
          Add child to top
        </div>
        <div on:click={(event) => onAddChildToBottom(event, node)}>
          <span class="material-icons-outlined">add</span>
          Add child to bottom
        </div>
      {/if}
      {#if allowAddSiblingPages}
        <div on:click={(event) => onAddSiblingAbove(event, node)}>
          <span class="material-icons-outlined">add</span>
          Add sibling above
        </div>
        <div on:click={(event) => onAddSiblingBelow(event, node)}>
          <span class="material-icons-outlined">add</span>
          Add sibling below
        </div>
        <div on:click={(event) => onAddTextHeadingAbove(event, node)}>
          <span class="material-icons-outlined">add</span>
          Add text heading above
        </div>
        <div on:click={(event) => onAddTextHeadingBelow(event, node)}>
          <span class="material-icons-outlined">add</span>
          Add text heading below
        </div>        
      {/if}
      <div on:click={(event) => onDisplayDebugInfo(event, node)}>
        <span class="material-icons-outlined">add</span>
        Display debug info
      </div>
    </div>
  {/if}
</div>
