import { route } from "../components/navigator/TreeNavigator3.svelte";

const getSplitSimbol = (e) => {
  if (e.includes('=')) {
    return '=';
  } else {
    return '%3D';
  }
};

export class UrlService {

  static getPathValue(param) {
    if (document.location.search.includes(param)) {
      let params = document.location.search
        .replace('?', '')
        .split('%26')
        .reduce(
          function (p, e) {
            let a = e.split(getSplitSimbol(e));
            p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
            return p;
          },
          {}
        );

      return params[param];
    } else {
      return '';
    }
  }

  static updateURL(urlString) {
    const query = urlString ? '?' : '';
    if (history.pushState) {
      const baseUrl = document.location.protocol + "//" + document.location.host + "/";
      const newUrl = baseUrl + query + encodeURIComponent(urlString);
      history.pushState({urlString: encodeURIComponent(urlString)}, '', newUrl);
    } else {
      console.warn('History API does not supported.');
    }
  }

  static updateURLWithSuid(suid) {
    if (history.pushState) {
      const baseUrl = document.location.protocol + "//" + document.location.host + "/";
      const newUrl = baseUrl + /* "page/" */ suid;
      history.pushState({ urlString: newUrl, suid: suid }, '', "?page=" + suid);
    } else {
      console.warn('History API does not supported.');
    }
  }
  
  static updateURLToHome() {
    history.pushState({}, '', '/');
  }
  
  static routeContainsPageSuid(route) {
    return route !== null && route.startsWith("page=");
  }
  
  static getPageSuidFromRoute(route) {
    return route.slice(5);
  }
    
  static isParamInURL(param) {
    return document.location.search.includes(param)
  }
  
  static getRouteFromLocation() {
    const text = document.location.search.slice(1);
    return (text !== null && text !== '') ? text : null;
  }
}
