import { Guid } from "js-guid";

export class TreeNodeItem {
  constructor(parentTreeNodeItem, windowBottomCoord, tocEntry) {
    this._localId = Guid.newGuid().toString();
    this._parentTreeNodeItem = parentTreeNodeItem;
    this._childrenLoaded = false;
    this._children = [];
    this._expanded = false;
    this._removing = false;
    this._pageCreating = false;
    this._textHeadingCreating = false;
    this._previousTreeState = null;
    this._dragCount = 0;
    this._dragAboveCounter = 0;
    this._dragBelowCounter = 0;    
    this._windowBottomCoord = windowBottomCoord;
    this._tocEntry = tocEntry;
  }
  
  regenerateLocalId() {
    this._localId = Guid.newGuid().toString();
  }
  
  dup() {
    return null;
  }
  
  get localId() {
    return this._localId;
  }
  
  get parent() {
    // Deprecated. Replace references with parentTreeNodeItem.
    return this._parentTreeNodeItem;
  }
  
  set parent(val) {
    // Deprecated. Replace references with parentTreeNodeItem.
    this._parentTreeNodeItem = val;
  }

  get parentTreeNodeItem() {
    return this._parentTreeNodeItem;
  }

  set parentTreeNodeItem(val) {
    // Deprecated. Replace references with parentTreeNodeItem.
    this._parentTreeNodeItem = val;
  }

  get parentLocalId() {
    return this.parentTreeNodeItem ? this.parentTreeNodeItem.localId : "root";
  }

  get childrenLoaded() {
    return this._childrenLoaded;
  }

  set childrenLoaded(val) {
    this._childrenLoaded = val;
  }

  get innerPageCount() {
    return 0;
  }
  
  set innerPageCount(val) {
  }

  get children() {
    return this._children;
  }

  set children(val){
    this._children = val;
  }

  get expanded() {
    return this._expanded;
  }

  set expanded(val) {
    this._expanded = val;
  }

  get removing() {
    return this._removing;
  }

  set removing(val) {
    this._removing = val;
  }

  get pageCreating() {
    return this._pageCreating;
  }

  set pageCreating(val) {
    this._pageCreating = val;
  }

  get textHeadingCreating() {
    return this._textHeadingCreating;
  }

  set textHeadingCreating(val) {
    this._textHeadingCreating = val;
  }  

  get title() {
    return "";
  }
  
  set title(val) {
  }
  
  get label() {
    return this.title;
  }
  
  set label(val) {
    this.title = val;
  }
  
  get tocEntry() {
    return this._tocEntry;
  }
  
  set tocEntry(val) {
    // Not recommended, but if you really must change the tocEntry
    // of an existing node item, this setter is available. It will
    // be deprecated in the future, but useful for some current
    // legacy code;
    this._tocEntry = val;
  }

  get tocEntryId() {
    return this._tocEntryId ? this._tocEntryId.entryId : null;
  }

  get tocEntryId2() {
    return "Haha 2!!";
    // return this.tocEntry.entryId;
  }
  
  get blah() {
    return "blah";
  }
  
  set tocEntryId(val) {
    // Not recommended. Will be deprecated in the future.
    this.tocEntry.entryId = val;
  }

  get tocEntryType() {
    return this.tocEntry.type;
  }

  get tocEntryTitle() {
    return this.tocEntry.title;
  }
  
  set tocEntryType(val) {
    // Not recommended. Will be deprecated in the future.
    this.tocEntry.type = val;
  }
  
  set tocEntryTitle(val) {
    // Not recommended. Will be deprecated in the future.
    this.tocEntry.title = val;
  }
  
  get tocEntryPageSuid() {
    return this.tocEntry.pageSuid;
  }
  
  set tocEntryPageSuid(val) {
    this.tocEntry.pageSuid = val;
  }

  get mountPointId() {
    return null;
  }

  get relativePath() {
    return null;
  }

  get previousTreeState() {
    return this._previousTreeState;
  }

  set previousTreeState(val) {
    this._previousTreeState = val;
  }

  get dragCount() {
    return this._dragCount;
  }

  set dragCount(val) {
    this._dragCount = val;
  }

  get dragAboveCounter() {
    return this._dragAboveCounter;
  }

  set dragAboveCounter(val) {
    this._dragAboveCounter = val;
  }

  get dragBelowCounter() {
    return this._dragBelowCounter;
  }

  set dragBelowCounter(val) {
    this._dragBelowCounter = val;
  }
  
  get windowBottomCoord() {
    return this._windowBottomCoord;
  }
  
  get isExpandable() {
    return true;
  }
  
  get pageSuid() {
    return null;
  }
  
  get isPage() {
    return false;
  }
  
  get isPhysicalPage() {
    return this.isTopicPage || this.isMappedPage;
  }
  
  get isTopicPage() {
    return false;
  }
  
  get isMappedPage() {
    return false;
  }
  
  get isVirtualPage() {
    return false;
  }
  
  get isTocEntriesModifiable() {
    return false;
  }
  
  get hasEditableProperties() {
    return false;
  }
  
  get backReferenceId() {
    // This id is used to track different nodes in the tree
    // that are equivalent copies of each other.
    return "node_" + this.localId;
  }
  
  get isSelectable() {
    return false;
  }
  
  get isRenameable() {
    return false;
  }
  
  get isDeletable() {
    return false;
  }
}
