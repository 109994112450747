<script>
  import TopicPage from "../../pages/TopicPage.js";
  import SnippetEditor from "../snippet-editors/SnippetEditor.svelte";
  import { PAGE_TYPE } from "../../constants";
  import { RequestService } from "../../comm/RequestService";
  import StackedPane from "../panes/StackedPane.svelte";
  import ScrollablePane from "../panes/ScrollablePane.svelte";
  import SnippetsEditorContainer from "../snippet-editors/SnippetsEditorContainer.svelte";
  import { onDestroy, onMount } from "svelte";
  import PreRender from "../layout-helpers/Prerender.svelte";

  export let currentTopicPage = null;
  export let snippetExpanded;
  export let editorSectionState$;

  let appendSection = {};
  let unsubscribe1;
  let topLevelSnippets = [];
  let isTopicPage = false;
  let draggableEl;
  let snippetForEdit;
  let stackedPaneRef;

  onMount(() => {
    unsubscribe1 = editorSectionState$.subscribe((val) => {
      appendSection = val;

      if (!appendSection.enabled) {
        onClearCustomStyle();
      }
    })
  });

  onDestroy(() => {
    unsubscribe1();
  });

  async function refreshSnippets(page) {
    if (!page) {
      return;
    }
    isTopicPage = PAGE_TYPE.Topic === page.itemType;
    let topicPage = new TopicPage(page.jsonData);

    const result = await topicPage.readSnippets();
    topLevelSnippets = result.topLevelSnippets;
  }

  $: refreshSnippets(currentTopicPage);

  async function onRemoveSnippet(data) {
    if (confirm('Are you sure you want to remove this snippet?')) {
      const url = `/apiv2/topicpages/${currentTopicPage.suid}/snippets/${data.detail.snippet.suid}`;

      const result = await RequestService.delete(url);

      if (result.resultCode === 0) {
        const snippetIndex = topLevelSnippets.findIndex((snippet) => snippet.suid === data.detail.snippet.suid);
        topLevelSnippets.splice(snippetIndex, 1);
        topLevelSnippets = [...topLevelSnippets];
      }
    }
  }

  function onDragstart(evt) {
    draggableEl = evt.target;
    evt.dataTransfer.effectAllowed = "move";
  }

  function onDragover(evt) {
    evt.preventDefault();
  }

  function onDragend() {
    draggableEl.draggable = false;
  }

  function onDrop(evt) {
    const swapArray = function (arr, oldPlace, newPlace) {
      if ((Math.min(oldPlace, newPlace) < 0) || (Math.max(oldPlace, newPlace) >= arr.length)) {
        console.error('Out of range');
        return null;
      }
      const item = arr.splice(oldPlace, 1);
      arr.splice((newPlace > 0) ? newPlace : 0, 0, item[0]);
      return arr;
    };

    const endEl = evt.target.closest('.edit-snippet-table-row');
    if (draggableEl.id !== endEl.id) {
      const dragStartIndex = topLevelSnippets.findIndex((el) => el.suid === draggableEl.id);
      const dragEndIndex = topLevelSnippets.findIndex((el) => el.suid === endEl.id);
      const reorderedArr = swapArray(topLevelSnippets, dragStartIndex, dragEndIndex);

      reorderSnippets(reorderedArr);
    }
  }

  async function reorderSnippets(newArr) {
    const url = `/apiV2/topicPages/${currentTopicPage.suid}/snippets/reorder`;
    const snippetIds = topLevelSnippets.map((snippet) => {
      return snippet.suid
    });

    const resp = await RequestService.put(url, { snippetIds });

    if (resp.resultCode === 0) {
      topLevelSnippets = newArr;
    }
  }

  function onEditSnippet(data) {
    appendSection = { enable: true, isEditSnippetSelected: true };
    editorSectionState$.set(appendSection);
    snippetForEdit = data.detail.snippet;
  }

  async function onSnippetUpdated() {
    await refreshSnippets(currentTopicPage);
  }

  function onClearCustomStyle() {
    stackedPaneRef.returnDefaultHeight();
  }
</script>

<style lang="scss">
  .empty-page {
    color: #833;
  }

  table {
    width: 100%;

    tr {
      height: 60px;
    }
  }
</style>

<StackedPane splitterOpen={appendSection.enable} resizable={true} bind:this={stackedPaneRef}>
  <svelte:fragment slot="top">
    <ScrollablePane>
      {#if topLevelSnippets.length}
        <table>
          {#each topLevelSnippets as snippet (snippet.suid)}
            <tr class="edit-snippet-table-row" id={snippet.suid}
                on:dragstart={onDragstart} on:dragover={onDragover} on:dragend={onDragend} on:drop={onDrop}>
              <SnippetEditor {snippet}
                             {isTopicPage}
                             {snippetExpanded}
                             on:removesnippet={onRemoveSnippet}
                             on:editsnippet={onEditSnippet}
              />
            </tr>
          {/each}
        </table>
      {:else}
        <div class="empty-page">This page contains no content.</div>
      {/if}
    </ScrollablePane>
  </svelte:fragment>
  <svelte:fragment slot="bottom">
    {#if appendSection.enable}
      <SnippetsEditorContainer
        pageSuid={currentTopicPage.suid}
        {editorSectionState$}
        {snippetForEdit}
        {appendSection}
        on:snippetadded={onSnippetUpdated}
        on:clearcustomstyle={onClearCustomStyle}
      />
    {/if}
  </svelte:fragment>
</StackedPane>
