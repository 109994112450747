<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { RequestService } from "../../comm/RequestService";
  import Loading from "../layout-helpers/Loading.svelte";

  const dispatch = createEventDispatcher();
  export let organization;
  export let group;
  let mountPointPermissions = [];
  let mountPoints = [];
  let isAddPermissionsButtonEnabled = true;
  let isPermissionsFormDisplayed = false;
  let currentMountPointForGrant;
  let availableMountPoints = [];
  let loading = false;

  onMount(async () => {
    await getPermissionsList();
    await getMountPointsData();
    filterAvailableMountPoints();
    clearFormData();
  });

  function filterAvailableMountPoints() {
    if (mountPointPermissions.length) {
      availableMountPoints = mountPoints.filter((mpp) => {
        return !mountPointPermissions.some((mp) => {
          return mp.mountPointId.value === mpp.id
        })
      });
    } else {
      availableMountPoints = mountPoints;
    }
  }

  function clearFormData() {
    currentMountPointForGrant = {
      viewMountPoints: false,
      pullMountPoints: false,
      includeSearchResult: false,
      mountPointId: '',
      id: '',
    };
  }

  async function getPermissionsList() {
    loading = true;
    const url = `/apiV2/groups/${group.id.value}/access-control`;
    let resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      mountPointPermissions = resp.result || [];
    } else {
      console.error(resp.resultMessage);
    }
    loading = false;
  }

  async function getMountPointsData() {
    let requestUrl = '';
    if (!!organization) {
      requestUrl = `/apiV2/mountPoint/all?orgId=${organization.id.value}`;
    } else {
      requestUrl = `/apiV2/mountPoint`;
    }

    const resp = await RequestService.get(requestUrl);

    if (resp.resultCode === 0) {
      mountPoints = resp.result;
    } else {
      console.error('getMountPointsData ', resp.resultMessage);
      return [];
    }
  }

  async function onEditPermission(permission) {
    currentMountPointForGrant = {
      viewMountPoints: permission.viewMountPoints,
      pullMountPoints: permission.pullMountPoints,
      mountPointId: permission.mountPointId.value,
      includeSearchResult: permission.includeSearchResult,
      id: permission.id.value
    };
    const currentMountPoint = mountPoints.find((mp) => mp.id === permission.mountPointId.value);
    availableMountPoints = [...availableMountPoints, currentMountPoint];
    onShowFormToAddPermissions();
  }

  async function onRemovePermission(permission) {
    const url = `/apiV2/groups/${group.id.value}/access-control/${permission.id.value}`;
    let resp = await RequestService.delete(url);

    if (resp.resultCode === 0) {
      await getPermissionsList();
      filterAvailableMountPoints();
      onCloseGrantPermissionForm();
    } else {
      console.error(resp.resultMessage);
    }
  }

  function onBackToGroupsList() {
    if (!!organization) {
      dispatch('changeorgstate', { state: 'group', organization, group });
    } else {
      dispatch('setgroupsstate');
    }
  }

  function onShowFormToAddPermissions() {
    isAddPermissionsButtonEnabled = false;
    isPermissionsFormDisplayed = true;
  }

  async function savePermission() {
    let url = '';
    if (currentMountPointForGrant.id) {
      url = `/apiV2/groups/${group.id.value}/access-control/${currentMountPointForGrant.id}`;
    } else {
      url = `/apiV2/groups/${group.id.value}/access-control`;
    }
    const data = {
      viewMountPoints: currentMountPointForGrant.viewMountPoints,
      pullMountPoints: currentMountPointForGrant.pullMountPoints,
      mountPointId: currentMountPointForGrant.mountPointId || mountPoints[0].id,
      includeSearchResult: currentMountPointForGrant.includeSearchResult
    };

    let resp;
    if (currentMountPointForGrant.id) {
      resp = await RequestService.put(url, data);
    } else {
      resp = await RequestService.post(url, data);
    }

    if (resp.resultCode === 0) {
      await getPermissionsList();
      filterAvailableMountPoints();
      onCloseGrantPermissionForm();
    } else {
      console.error('savePermission ', resp.resultMessage);
      return [];
    }
  }

  function onCloseGrantPermissionForm() {
    isAddPermissionsButtonEnabled = true;
    isPermissionsFormDisplayed = false;
    filterAvailableMountPoints();
    clearFormData();
  }

  function getPermissionStatus(permission) {
    let status = '';
    if (permission.viewMountPoints) {
      status = 'Read ';
    }
    if (permission.pullMountPoints) {
      status = status ? status + '+ Refresh ' : 'Refresh ';
    }
    if (permission.includeSearchResult) {
      status = status ? status + '+ Search' : 'Search';
    }

    return status ? status : 'No permissions granted';
  }
</script>
<style lang="scss">
  .group-permissions {
    position: relative;

    .back-btn {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }

    .no-permissions-text {
      margin-bottom: 20px;
    }

    form label {
      display: flex;
    }
  }
</style>
<div class="group-permissions">
  <button on:click={onBackToGroupsList} class="back-btn">
    <span class="material-icons-outlined">arrow_back_ios</span>
    Back To Groups List
  </button>
  <Loading {loading}>
    <h3>Group: {group.title.toUpperCase()}</h3>

    {#if mountPointPermissions.length}
      <table>
        <tbody>
        <tr>
          <th>Mount Point Name</th>
          <th>Permissions Granted</th>
          <th>Actions</th>
        </tr>
        {#each mountPointPermissions as permission (permission.id.value)}
          <tr>
            <td>{permission.mountPointName}</td>
            <td>{getPermissionStatus(permission)}</td>
            <td>
              <button type="button" class="action-btn" on:click={() => onEditPermission(permission)}>Edit</button>
              <button type="button" class="action-btn" on:click={() => onRemovePermission(permission)}>Remove</button>
            </td>
          </tr>
        {/each}
        </tbody>
      </table>
    {:else}
      <div class="no-permissions-text">There are no Mount Point Permissions.</div>
    {/if}

    {#if isAddPermissionsButtonEnabled}
      <button class="show-group-form" type="button"
              disabled={!availableMountPoints.length}
              title="{availableMountPoints.length ? '' : 'There are no available Mount Points for Grant Permissions'}"
              on:click={onShowFormToAddPermissions}>Grant Permissions to Mount Point
      </button>
    {/if}
    {#if isPermissionsFormDisplayed}

      <form on:submit|preventDefault={(event) => savePermission(event)}>
        {#if mountPoints.length}
          <label class="form-field">Mount Point To Grant Permission To:
            <select required
                    bind:value={currentMountPointForGrant.mountPointId}
                    disabled={currentMountPointForGrant.id}
            >
              {#each availableMountPoints as mtPoint}
                <option value="{mtPoint.id}">{mtPoint.name}</option>
              {/each}
            </select>
          </label>

          <h3>Permissions To Grant</h3>
          <table>
            <tbody>
            <tr>
              <th>Permission Name</th>
              <th>Granted</th>
            </tr>
            <tr>
              <td>Read</td>
              <td>
                <label>
                  <input type="checkbox" bind:checked={currentMountPointForGrant.viewMountPoints}>
                </label>
              </td>
            </tr>
            <tr>
              <td>Refresh</td>
              <td>
                <label>
                  <input type="checkbox" bind:checked={currentMountPointForGrant.pullMountPoints}>
                </label>
              </td>
            </tr>
            <tr>
              <td>Search</td>
              <td>
                <label>
                  <input type="checkbox" bind:checked={currentMountPointForGrant.includeSearchResult}>
                </label>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="action-buttons-section">
            <button type="submit" name="saveBtn">Save</button>
            <button type="button" on:click={onCloseGrantPermissionForm}>Cancel</button>
          </div>
        {:else}
          <div class="no-permissions-text">
            {#if !!organization}
              There are no mount points for organizations.<br>
              Please add at least one Mount Point for Organization
              <b>{organization.fullName ? organization.fullName.toUpperCase(): organization.name.toUpperCase()}</b>.
            {:else}
              There are no mount points.<br>
              Please add at least one Mount Point
            {/if}
          </div>
        {/if}
      </form>
    {/if}
  </Loading>
</div>
