<script>
  import PlainTextSnippetViewer from "./PlainTextSnippetViewer.svelte";
  import FileSnippetViewer from "./FileSnippetViewer.svelte";
  import DrawingSnippetViewer from "./DrawingSnippetViewer.svelte";
  import ContainerSnippetViewer from "./ContainerSnippetViewer.svelte";
  import FileReferenceSnippetViewer from "./FileReferenceSnippetViewer.svelte";

  export let snippet;
  export let searchData = '';
  export let switchSearchFile$ = {};
  export let switchSourceCodeSearchResults$ = null;
</script>

<div class="snippet-viewer">
  {#if snippet && snippet.snippetType === 1}
    <PlainTextSnippetViewer
      {searchData}
      {switchSourceCodeSearchResults$}
      {switchSearchFile$}
      {snippet}
    />
  {:else if snippet && snippet.snippetType === 2}
    <FileSnippetViewer snippet={snippet}/>
  {:else if snippet && snippet.snippetType === 5}
    <DrawingSnippetViewer snippet={snippet}/>
  {:else if snippet && snippet.snippetType === 6}
    <ContainerSnippetViewer snippet={snippet}/>
  {:else if snippet && snippet.snippetType === 7}
    <FileReferenceSnippetViewer
      {searchData}
      {switchSourceCodeSearchResults$}
      {switchSearchFile$}
      {snippet}
    />
  {/if}
</div>
