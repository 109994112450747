import PageOptions from './PageOptions.js';
import TimeStampDisplayOptions from './TimeStampDisplayOptions.js';

class UserOptions {
  constructor(jsonData) {
    this._jsonData = jsonData;
    this._globalTopicPageOptions = new PageOptions(jsonData.globalTopicPageOptions);
    this._globalJournalPageOptions = new PageOptions(jsonData.globalJournalPageOptions);
    this._topicPageTimeStampOptions = new TimeStampDisplayOptions(jsonData.topicPageTimeStampOptions);
    this._journalPageTimeStampOptions = new TimeStampDisplayOptions(jsonData.journalPageTimeStampOptions);
    this._journalPageAltTimeStampOptions = new TimeStampDisplayOptions(jsonData.journalPageAltTimeStampOptions);
  }
  
  get globalTopicPageOptions() {
    return this._globalTopicPageOptions;
  }
  
  get globalJournalPageOptions() {
    return this._globalJournalPageOptions;
  }
  
  get topicPageTimeStampOptions() {
    return this._topicPageTimeStampOptions;
  }
  
  get journalPageTimeStampOptions() {
    return this._journalPageTimeStampOptions;
  }
  
  get journalPageAltTimeStampOptions() {
    return this._journalPageAltTimeStampOptions;
  }
  
  get isAdministrator() {
    return this._jsonData.isAdministrator;
  }
  
  get eMail() {
    return this._jsonData.eMail;
  }
  
  getPlainObject() {
    return { ...this._jsonData };
  }
}

export default UserOptions;
