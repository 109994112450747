<script>
  import { RequestService } from "../../comm/RequestService";
  import { createEventDispatcher } from "svelte";
  import Loading from "../layout-helpers/Loading.svelte";

  export let organizationUsers = [];
  export let organization;
  const dispatch = createEventDispatcher();
  let userLogin = '';
  let isCreateUserButtonEnabled = true;
  let isUserFormDisplayed = false;
  let errMessage = '';
  let isValidData = true;
  let loading = false;
  $: {
    userLogin = '';
    getOrganizationUsers();
  }

  async function getOrganizationUsers() {
    loading = true;
    const url = `/apiV2/organization/${organization.id.value}/members`;
    let resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      organizationUsers = resp.result || [];
    } else {
      console.error(resp.resultMessage);
    }
    loading = false;
  }

  async function onRemoveUser(user) {
    const canDelete = confirm(`\nAre you sure you want to delete user ${user.targetName}?\nWarning!!! User will be removed from all groups.`);

    if (canDelete) {
      const url = `/apiV2/organization/${organization.id.value}/members`;
      let resp = await RequestService.delete(url, { userId: user.userId.value });

      if (resp.resultCode === 0) {
        await getOrganizationUsers();
      } else {
        console.error(resp.resultMessage);
      }
    }
  }

  function onHideUserSection() {
    dispatch('hideusersection');
  }

  function onShowFormToAddUser() {
    isCreateUserButtonEnabled = false;
    isUserFormDisplayed = true;
  }

  function onCloseFormToAddUser() {
    userLogin = '';
    isCreateUserButtonEnabled = true;
    isUserFormDisplayed = false;
    errMessage = '';
    isValidData = true;
  }

  async function saveUser() {
    let url = `/apiV2/organization/${organization.id.value}/members`;
    let resp = await RequestService.post(url, { login: userLogin });

    if (resp.resultCode === 0) {
      userLogin = '';
      isCreateUserButtonEnabled = true;
      isUserFormDisplayed = false;
      await getOrganizationUsers();
    } else {
      console.error(resp.resultMessage);
      errMessage = resp.resultMessage;
      isValidData = false;
    }
  }

  function onBackToList() {
    dispatch('changeorgstate', { state: 'organization' });
  }
</script>
<style lang="scss">
  .organization-group-users {
    position: relative;

    td, th {
      border: 1px solid grey;
      min-width: 120px;
    }

    td:first-child, th:first-child {
      min-width: 180px;
    }

    .show-group-form {
      margin-top: 20px;
    }

    form {
      position: relative;

      .err-message {
        top: 27px;
        left: 0;
      }

      .form-field {
        width: 308px;
        margin-bottom: 50px;

        input {
          width: 210px;
        }
      }
    }
  }
</style>
<div class="organization-group-users">
  <button on:click={onBackToList} class="back-btn">
    <span class="material-icons-outlined">arrow_back_ios</span>
    Back To Organizations List
  </button>

  <Loading {loading}>
    <h3>
      Current Users for {organization.fullName ? organization.fullName.toUpperCase(): organization.name.toUpperCase()}
    </h3>
    {#if organizationUsers.length}
      <table>
        <tbody>
        <tr>
          <th>User Name</th>
          <th>Actions</th>
        </tr>
        {#each organizationUsers as user (user.id.value)}
          <tr>
            <td>{user.targetName}</td>
            <td>
              <button type="button" class="action-btn"
                      disabled={user.isAdministrator}
                      on:click={() => onRemoveUser(user)}>
                Delete
              </button>
            </td>
          </tr>
        {/each}
        </tbody>
      </table>
    {:else}
      <div>There are no current users.</div>
    {/if}

    {#if isCreateUserButtonEnabled}
      <button class="show-group-form" type="button" on:click={onShowFormToAddUser}>Add New User</button>
    {/if}
    {#if isUserFormDisplayed}
      <form on:submit|preventDefault={(event) => saveUser(event)}>

        <label class="form-field">Login Id *:
          <input class="input-field"
                 type="text"
                 placeholder="Login Id or Email"
                 bind:value={userLogin}
                 required
          >
          {#if !isValidData}
            <span class="err-message">{errMessage}</span>
          {/if}
        </label>

        <div class="action-buttons-section">
          <button type="submit" name="saveBtn">Save</button>
          <button type="button" on:click={onCloseFormToAddUser}>Close</button>
          <button type="button" on:click={userLogin = ''}>Clear</button>
        </div>
      </form>
    {/if}
  </Loading>
</div>
