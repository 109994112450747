<script>
  export let loading = false;
</script>

{#if loading}
  <h3 class="loading">
    Loading...
  </h3>
{:else}
  <slot/>
{/if}
