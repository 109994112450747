<script>
  import MountPointsTable from "./MountPointsTable.svelte";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import MountPointsActions from "./MountPointsActions.svelte";
  import EditMountPoint from "./EditMountPoint.svelte";
  import { ExtendedMountPoint } from "../../../domain-objects/ExtendedMountPoint";
  import { CONNECTION_TYPE } from "../../../constants";
  import { externalConnectionTable$, mountPointsList$, signalRConnection$ } from "../../../stores";
  import { RequestService } from "../../../comm/RequestService";
  import QuickAddMountPointForm from "../QuickAddMountPointForm.svelte";
  import ImportManifestForm from "./ImportManifestForm.svelte";

  export let mountPoints;
  export let externalConnections;
  export let loading;
  export let beforeSaveNewFunc = null;

  const dispatch = createEventDispatcher();
  let adding = false;
  let quickAdding = false;
  let importManifest = false;
  let newMountPoint = new ExtendedMountPoint();
  // let unsubscribeSignalRConnection;

  newMountPoint.connectionType = CONNECTION_TYPE.GITHUB;
  newMountPoint.externalConnections = externalConnections;

  onMount(() => {
    newMountPoint.innerMountPointId = (mountPoints.length > 0) ? mountPoints[0].id : null; // TODO: ???
    // unsubscribeSignalRConnection = signalRConnection$.subscribe((eventData) => {
    //   if (eventData) {
    //     listenNotificationEvents(eventData)
    //   }
    // });
  });

  onDestroy(() => {
    // unsubscribeSignalRConnection();
  });

  function updateExternalConnectionTable() {
    // Not sure we need this any more???
    externalConnectionTable$.set('update');
  }

  function clearForm() {
    // How will we set the organization id for organization mount points??????
    // Option 1 : Pass in a function as a property that creates a new mount point.
    //            Fill in the organization id when the object is created. I don't
    //            use this technique very frequently. It can be a little confusing
    //            to a future engineer as to what is happening. The benefit is that
    //            it saves some duplicating code.
    // Option 2 : Dispatch an event prior to saving that the calling component can
    //            use to fixup the object. This seems even more confusing that Option 1.
    // Option 3 : Don't save the mount point from this component. Let the parent
    //            component save it. Saving function will be mostly duplicated in that case.
    //
    // Option 3 is probably the least convoluted thing to do, though it causes some 
    // duplicated code.
    //
    // But does that mean the calling component should also handling deleting of mount
    // points? Currently, deleting of mount points is handled down in MountPointsTable.
    // It is confusing if saving, updating, and deleting are not all handled in one place.
    //
    // Hmmmm. This is a bit of a hard decision.
    
    newMountPoint = new ExtendedMountPoint();
    newMountPoint.connectionType = CONNECTION_TYPE.GITHUB;
    newMountPoint.externalConnections = externalConnections;
    newMountPoint.innerMountPointId = (mountPoints.length > 0) ? mountPoints[0].id : null;
  }

  // async function listenNotificationEvents(args) {
  //   console.log("Inside listenNotificationEvents");
  //   console.log("args =", args);
  //   if (args.status === 'Done' || args.status === 'Error') {
  //     dispatch("refreshdatatables")
  //
  //     // if (createPageData.isAutoCreatePage) {
  //     //   await addMappedPageIntoNavigator();
  //     // }
  //   }
  // }

  function onAddMountPoint() {
    adding = true;
  }

  function onQuickAddMountPoint() {
    quickAdding = true;
  }
  
  function onCloseQuickAdd() {
    quickAdding = false;
  }

  async function onRefreshAll() {
    dispatch("refreshdatatables");
  }

  function onClose() {
    adding = false;
  }

  function onClear() {
    clearForm();
  }

  async function onMountPointRefreshed(event) {
    // console.log("Inside onMountPointRefreshed");
    dispatch("mountpointrefreshed");
  }

  async function onMountPointDeleted(data) {
    dispatch("mountpointdeleted");
  }

  async function onSaveNew() {
    let mountPointToSave = newMountPoint.dup();
    if (beforeSaveNewFunc) {
      // Give the controlling component a chance to fix up the mount point
      // with any extra properties before we save it. The OrganizationMountPointsForm
      // uses this function to fill in the organization id before we save it.
      beforeSaveNewFunc(mountPointToSave);
    }
    
    const requestUrl = `/apiV2/mountPoint`;
    const response = await RequestService.post(requestUrl, mountPointToSave.toPlainObject());

    if (response.resultCode === 0) {
      // console.log("Successfully saved");
      clearForm();
      adding = false;
      dispatch("mountpointadded", mountPointToSave);
    } else {
      console.error('onSaveNew ', response.resultMessage);
    }
  }
  
  async function onMountPointUpdated(event) {
    // event.detail should contain:
    //   original: A reference to the original mount point
    //   updated: A copy of the original mount point with updates

    // console.log("Inside onMountPointUpdated");
    // console.log("detail =", event.detail);

    const requestUrl = `/apiV2/mountPoint`;
    const response = await RequestService.put(requestUrl, event.detail.updated.toPlainObject());

    if (response.resultCode === 0) {
      // console.log("Successfully saved");
    } else {
      alert(response.resultMessage);
      console.error('onMountPointUpdated ', response.resultMessage);
    }

    dispatch("mountpointupdated", event.detail);
  }
  
  async function onImportManifest() {
    importManifest = true;
  }
  
  function onImportManifestCancel() {
    importManifest = false;
  }
  
  $: newMountPoint.externalConnections = externalConnections;
</script>

{#if adding}
  <EditMountPoint
    isCreateMode={true}
    mountPoint={newMountPoint}
    mountPoints={mountPoints}
    externalConnections={externalConnections}
    on:close={onClose}
    on:clear={onClear}
    on:save={onSaveNew}
  />
{:else if quickAdding}
  <QuickAddMountPointForm
    on:quicksavecancel={onCloseQuickAdd}
    on:quicksavesuccess={onRefreshAll}
  />
{:else if importManifest}
  <ImportManifestForm
    mountPoints={mountPoints}
    on:cancel={onImportManifestCancel}
  />
{:else}
  <MountPointsActions
    on:addmountpoint={onAddMountPoint}
    on:quickaddmountpoint={onQuickAddMountPoint}
    on:importmanifest={onImportManifest}
    on:refreshall={onRefreshAll}
  />
{/if}

<MountPointsTable
  loading={loading}
  mountPoints={mountPoints}
  externalConnections={externalConnections}
  on:mountpointrefreshed={onMountPointRefreshed}
  on:mountpointupdated={onMountPointUpdated}
  on:mountpointdeleted={onMountPointDeleted}
/>
