<script>
  import ScrollablePane from '../panes/ScrollablePane.svelte';
  import StackedPane from "../panes/StackedPane.svelte";
  import FullHeight from "../layout-helpers/FullHeight.svelte";
  import TopicPage from "../../pages/TopicPage.js";
  import SearchResultsTable from "./SearchResultsTable.svelte";
  import TopicPagePane from "../topic-pages/TopicPagePane.svelte";
  import UnusedProperty from '../layout-helpers/UnusedProperty.svelte';

  export let loading;
  export let searchResults;
  export let selectedItem = null;
  export let searchData;
  export let switchSearchFile$;
  export let switchSearchResults$;

  let selectedPage = null;

  $: {
    selectedPage = selectedItem ? new TopicPage(selectedItem) : null;
  }
</script>

<UnusedProperty propertyName="loading" val={loading} comment="TODO: Display a spinner"/>
<style lang="scss">
  .search-topic-page-pane-wrap, .search-results-area {
    height: 100%;
  }
</style>
<div class="search-results-area">
  <StackedPane splitterOpen={true} resizable={true}>
    <svelte:fragment slot="top">
      <FullHeight>
        <ScrollablePane>
          <SearchResultsTable
            {switchSearchFile$}
            searchResults={searchResults}
            selectedItem={selectedPage}
            on:itemselected
          />
        </ScrollablePane>
      </FullHeight>
    </svelte:fragment>
    <svelte:fragment slot="bottom">
      <div class="search-topic-page-pane-wrap">
        <FullHeight>
          <TopicPagePane
            allowDelete={true}
            allowOpenInGitHost={true}
            allowRefresh={true}
            pinnable={false}
            pinned={false}
            {searchData}
            {switchSearchFile$}
            {switchSearchResults$}
            topicPage={selectedPage}
            on:deletepage
          />
        </FullHeight>
      </div>
    </svelte:fragment>
  </StackedPane>
</div>
