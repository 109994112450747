<script>
  import { onMount } from 'svelte';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  export let resizable = false;
  export let splitterOpen = false;
  let paneSeparatorRef;
  let topPaneRef;
  let bottomPaneRef;
  let customTopSectionHeight;

  onMount(() => {
    if (resizable) {
      paneSeparatorRef.addEventListener('mousedown', initResize, false);
      paneSeparatorRef.addEventListener('dblclick', returnDefaultHeight, false);
    }
  });

  export function returnDefaultHeight() {
    topPaneRef.style.height = null;
    bottomPaneRef.style.height = null;
    customTopSectionHeight = null;

    emitPanesHeight();
  }

  function initResize() {
    window.addEventListener('mousemove', resize, false);
    window.addEventListener('mouseup', stopResize, false);
  }

  function emitPanesHeight() {
    let topSectionHeightPx = getComputedStyle(topPaneRef);
    let bottomSectionHeightPx = getComputedStyle(bottomPaneRef);

    dispatch('paneResized', {
      topSize: topSectionHeightPx.height,
      bottomSize: bottomSectionHeightPx.height,
    });
  }

  function resize(e) {
    e.preventDefault();
    //topPaneRef.style.transition = 'none';
    topPaneRef.style.height = (e.clientY - topPaneRef.offsetTop) + 'px';
    bottomPaneRef.style.height = `calc(100% - ${topPaneRef.style.height} - 20px)`;
    customTopSectionHeight = topPaneRef.style.height;

    emitPanesHeight();
  }

  function stopResize() {
    window.removeEventListener('mousemove', resize, false);
    window.removeEventListener('mouseup', stopResize, false);

    //topPaneRef.style.transition = null;
  }

</script>

<style lang="scss">
  @import '../../../static/styles/core-palette';

  .stacked-pane-outer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .top-item {
      height: 100%;
      box-sizing: border-box;
      min-height: 0;
      //transition: height 0.1s linear;
    }

    .half-height {
      height: 50%;
    }

    .full-height {
      height: 100%;
    }

    .bottom-item {
      flex-direction: column;
      height: calc(50% - 20px);
      min-height: 0;
      //transition: height 0.1s linear;

      .pane-separator {
        border-top: 5px double lightgray;
        cursor: row-resize;
      }

      .resize-deprecated {
        cursor: initial;
      }
    }

    .open {
      display: flex;
    }

    .closed {
      display: none;
    }
  }
</style>

<div class="stacked-pane-outer">
  <div class="top-item {splitterOpen ? 'half-height' : 'full-height'}" bind:this={topPaneRef}>
    <div class="full-height">
      <slot name="top" />
    </div>
  </div>
  <div 
    class="bottom-item" 
    class:open="{splitterOpen}" 
    class:closed="{!splitterOpen}" 
    bind:this={bottomPaneRef}
  >
    <div 
      class="pane-separator" 
      class:resize-deprecated="{!resizable}"
      bind:this={paneSeparatorRef}></div>
    <div class="full-height">
      <slot name="bottom" />
    </div>
  </div>
</div>  
