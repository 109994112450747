class PageOptions {
  constructor(sourceObjectOrJsonData) {
    if (sourceObjectOrJsonData === undefined) {
      this._jsonData = {
        primaryParentId: "",
        urlSlug: "",
        displayGutter: false,
        displaySeparators: false
      };      
    } else if (sourceObjectOrJsonData instanceof PageOptions) {
      this._jsonData = sourceObjectOrJsonData.getPlainObject();
    } else {
      this._jsonData = sourceObjectOrJsonData;
    }
  }
  
  get urlSlug() {
    return this._jsonData.urlSlug;
  }
  
  set urlSlug(val) {
    this._jsonData.urlSlug = val;
  }
  
  get primaryParentId() {
    return this._jsonData.primaryParentId;
  }
  
  set primaryParentId(val) {
    this._jsonData.primaryParentId = val;
  }

  get displayGutter() {
    return this._jsonData.displayGutter;
  }
  
  set displayGutter(val) {
    this._jsonData.displayGutter = val;
  }
  
  get displaySeparators() {
    return this._jsonData.displaySeparators;
  }
  
  set displaySeparators(val) {
    this._jsonData.displaySeparators = val;
  }

  getEffectiveDisplaySeparators() {
    return this.displaySeparators;
  }

  getEffectiveDisplayGutter() {
    return this.displayGutter;
  }
  
  copy(pageOptions) {
    this.urlSlug = pageOptions.urlSlug;
    this.displayGutter = pageOptions.displayGutter;
    this.displaySeparators = pageOptions.displaySeparators;
  }

  merge(pageOptions) {
    if (pageOptions.urlSlug !== undefined) {
      this.urlSlug = pageOptions.urlSlug;
    }
    
    if (pageOptions.primaryParentId !== undefined) {
      this.primaryParentId = pageOptions.primaryParentId;
    }

    // Note: I don't use the spread operator here because I want to ensure
    // that I only bring in known properties associated with this object
    if (pageOptions.displayGutter !== undefined) {
      this.displayGutter = pageOptions.displayGutter;
    }
    
    if (pageOptions.displaySeparators !== undefined) {
      this.displaySeparators = pageOptions.displaySeparators;
    }
  }
  
  getPlainObject() {
    return { ...this._jsonData };
  }
}

export default PageOptions;
