import TimeStampDisplayOptions from './TimeStampDisplayOptions.js';

class OverrideableTimeStampOptions extends TimeStampDisplayOptions {
  constructor(sourceObjectOrJsonData) {
    if (!sourceObjectOrJsonData) {
      sourceObjectOrJsonData = {
        override: false,
        displayDate: false,
        displayTime: false
      };
    }
    
    super(sourceObjectOrJsonData);
  }
  
  get override() {
    return this._jsonData.override;
  }
  
  set override(val) {
    this._jsonData.override = val;
  }
  
  getEffectiveDisplayDate(baseTimeStampOptions) {
    if (baseTimeStampOptions instanceof TimeStampDisplayOptions) {
      return this.override ?
        this.displayDate :
        baseTimeStampOptions.getEffectiveDisplayDate();
    } else if (baseTimeStampOptions) {
      return this.override ?
        this.displayDate :
        (baseTimeStampOptions.displayDate !== undefined) ?
          baseTimeStampOptions.displayDate : false;  
    } else {
      return this.override ?
        this.displayDate : false;
    }
  }
  
  getEffectiveDisplayTime(baseTimeStampOptions) {
    if (baseTimeStampOptions instanceof TimeStampDisplayOptions) {
      return this.override ?
        this.displayTime :
        baseTimeStampOptions.getEffectiveDisplayTime();
    } else if (baseTimeStampOptions) {
      return this.override ?
        this.displayTime :
        (baseTimeStampOptions.displayTime !== undefined) ?
          baseTimeStampOptions.displayTime : false;
    } else {
      return this.override ?
        this.displayTime : false;   
    }
  }

  getPlainObject() {
    return { ...this._jsonData };
  }

  copy(overrideableTimeStampOptions) {
    super.copy(overrideableTimeStampOptions);
    this.override = overrideableTimeStampOptions.override;
  }

  merge(overrideableTimeStampOptions) {
    super.merge(overrideableTimeStampOptions);
    
    // Note: I don't use the spread operator here because I want to ensure
    // that I only bring in known properties associated with this object
    if (overrideableTimeStampOptions.override !== undefined) {
      this.override = overrideableTimeStampOptions.override;
    }
  }
}

export default OverrideableTimeStampOptions;
