<script>
  export let size = "medium";
  export let headerBackgrounColor = null;
  export let bodyBackgroundColor = null;
  export let border = "bottom";

  function getSizeClassName() {
    switch (size) {
      case "small":  return "size-small";
      case "medium": return "size-medium";
      case "large":  return "size-large";
      default:       return "size-medium";
    }
  }
  
  function getBorderClassName() {
    return "border-" + border;
  }
  
  function getHeaderStyle() {
    return headerBackgrounColor ? "background-color: " + headerBackgrounColor : "";
  }
  
  function getBodyStyle() {
    return bodyBackgroundColor ? "background-color: " + bodyBackgroundColor : "";
  }
</script>

<style lang="scss">
  .flex-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    min-height: 0;
    box-sizing: border-box;
    font-family: "Trebuchet MS", Verdana, Helvetica, sans-serifverdana, serif;
  }

  .header-item {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    font-size: 16px;
    
    &.border-bottom {
      border-bottom: 1px solid gray;
    }
    
    &.border-none {
      border: 0;
    }
  }

  .size-large {
    min-height: 400px;
    //padding: 5px 0;
  }

  .size-medium {
    min-height: 50px;
    //padding: 5px 0;
  }

  .size-small {
    min-height: 40px;
  }

  .body-item {
    flex-grow: 1;
    width: 100%;
    min-height: 0;
  }
</style>

<div class="flex-wrapper pane-with-top-header">
<!--  <div class="header-item" class:size-medium="{size === 'medium'}" class:size-small="{size === 'small'}">-->
  <div class={`header-item ${getSizeClassName()} ${getBorderClassName()}`} style={getHeaderStyle()}>
    <slot name="header" />
  </div>
  <div class="body-item" style={getBodyStyle()}>
    <slot name="body" />
  </div>
</div>                         
