<script>
  import MaterialIcon from "../layout-helpers/MaterialIcon.svelte";
  import PlainTextSnippetViewer from '../snippet-viewers/PlainTextSnippetViewer.svelte';
  import PlainTextEditor from '../snippet-editors/PlainTextEditor.svelte';
  import { createEventDispatcher } from 'svelte';

  export let snippet;
  export let isTopicPage;
  export let snippetExpanded;

  const dispatch = createEventDispatcher();
  let isEditorEnabled = false;
  let dragElRef;

  function onMousedown(evt) {
    evt.target.closest('.edit-snippet-table-row').draggable = true;
  }

  function snippetUpdated() {
    isEditorEnabled = false;
  }

  function cancelSnippetUpdate() {
    isEditorEnabled = false;
  }

  function onRemove() {
    dispatch('removesnippet', {snippet});
  }

  function onEdit() {
    dispatch('editsnippet', {snippet});
  }
</script>

<style lang="scss">
  .snippet-row-control {
    border: #bed6eb 1px solid;
    width: 70px;

    .action-button-wrap {
      display: flex;
      align-items: center;

      .action-button {
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 0;
      }
    }
  }

  td:last-child {
    background-color: #f3f7fb;
    border: #bed6eb 1px solid;

    .snippet-content {
      max-width: 800px;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>


{#if isTopicPage}
  <td class="snippet-row-control">
    <div class="action-button-wrap">
      <div class="action-button">
        <MaterialIcon iconName="mode_edit" on:click={onEdit} title={'Edit Snippet'}/>
      </div>
      <div class="action-button">
        <MaterialIcon iconName="clear" on:click={onRemove} title={'Remove Snippet'}/>
      </div>
      <div class="action-button" on:mousedown={onMousedown}>
        <MaterialIcon iconName="drag_handle" cursorType="move" title={'Drag Snippet'}/>
      </div>
    </div>
  </td>
{/if}
<td>
  {#if snippetExpanded}
    {#if !isEditorEnabled}
      <PlainTextSnippetViewer {snippet} />
    {:else}
      <PlainTextEditor 
        {snippet}
        on:cancelsnippetupdate={cancelSnippetUpdate}
        on:snippetupdated={snippetUpdated}
      />
    {/if}
  {:else}
    <div class="snippet-content">
      <div>{snippet.content}</div>
    </div>
  {/if}
</td>
