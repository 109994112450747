import { RequestService } from "../../comm/RequestService";

export class LoginService {

  static login(data) {
    const url = '/accountV2/login';

    return RequestService.post(url, data);
  }

  static register(data) {
    const url = '/accountV2/register';

    return RequestService.post(url, data);
  }

  static getInviteCode(data) {
    const url = '/accountV2/invite/sendEmail';

    return RequestService.post(url, data);
  }

  static requestResetPassword(login) {
    const url = '/accountV2/password/reset/sendEmail';

    return RequestService.post(url, { login });
  }

  static resetPassword(resetData) {
    const url = '/accountV2/password/reset';

    return RequestService.post(url, resetData);
  }
  
  static getGeneralOptions() {
    return RequestService.get('/apiv2/options/general');
  }

  static getLicenseAgreement() {
    return RequestService.get('/apiv2/license/eula');
  }

  static getTestResetKey(login) {
    const url = `/accountV2/password/reset/resetKeyTest?login=${login}`;

    return RequestService.get(url);
  }
}
