<script>
  export let visible = false;
</script>

<style lang="scss">
  .wrapper {
    height: 100%;
    width: 100%;
  }

  .not-showing {
    display: none;
  }
</style>

<div class="wrapper" class:not-showing="{!visible}">
  <slot />
</div>
