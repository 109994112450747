<script>
  import { createEventDispatcher } from 'svelte';
  import { LoginService } from "./LoginService";
  import { onDestroy } from 'svelte';

  export let loginState;

  const dispatch = createEventDispatcher();
  let isValidData = true;
  let isSuccessRequest = false;
  let errMessage = '';
  let invitationForm;

  resetForm();

  function resetForm() {
    invitationForm = {
      userName: '',
      email: '',
      country: '',
      hear: '',
      comments: '',
    }
  }

  onDestroy(() => {
    resetForm();
    isValidData = true;
    isSuccessRequest = false;
  });

  function onRegister() {
    dispatch('changeLoginState', loginState.Register);
  }

  async function onRequestInvitation() {
    const resp = await LoginService.getInviteCode(invitationForm);

    if (resp.resultCode === 0) {
      isSuccessRequest = true;
    } else {
      isSuccessRequest = false;
      isValidData = false;
      errMessage = resp.resultMessage;
    }
  }
</script>

<style lang="scss">
  .login-form {
    .go-to-btn-success {
      align-self: center;
    }

    p {
      margin-bottom: 50px;
    }
  }
</style>

<div class="login-form px-[20%]">
  <h1>Request invitation code</h1>
  {#if !isSuccessRequest}
    <span
      class="login-form--hint">To receive an invitation code, please fill out the brief form below.</span>
    <form on:submit|preventDefault={onRequestInvitation}>
      <label><input
        type="text"
        placeholder="Full Name"
        bind:value={invitationForm.userName}
        required>
      </label>
      <label>
        <input
          type="email"
          placeholder="E-Mail Address"
          bind:value={invitationForm.email}
          pattern="\S+@\S+\.\S+"
          required
          minlength="7"
          title="The E-Mail Address should be in the format anystring@anystring.anystring">
      </label>
      <label>
        <input
          type="text"
          placeholder="Country You Live In"
          bind:value={invitationForm.country}
          required>
      </label>
      <label>
        <textarea placeholder="How did you hear about us?">{invitationForm.hear}</textarea>
      </label>
      <div class="login-form-field">
        <label>
          <textarea placeholder="Questions, Comments or Feedback">{invitationForm.comments}</textarea>
        </label>
        {#if !isValidData}
          <div class="err-message">{errMessage}</div>
        {/if}
      </div>
      <button type="submit" class="submit-btn">Send Request</button>
    </form>
  {:else}
    <p>
      An invitation code has been sent to your e-mail address. Please create an account using the invitation code
      you received.
    </p>
  {/if}
  {#if !isSuccessRequest}
    <span class="or-text">or</span>
  {/if}
  <button type="button"
          class="{isSuccessRequest ? 'go-to-btn-success' : 'go-to-btn'}"
          on:click="{onRegister}">Create an account
  </button>
</div>
