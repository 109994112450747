<script>
  export let value;
</script>

<style>
  div.field-value {
    display: inline-block;
    width: 100%;
  }
</style>  

<div class="field-value">{value}</div>
