<script>
  import FormItem from "./FormItem.svelte";

  export let label = "";
  export let overridden;
  export let defaultValue = "";
  export let value;
  export let required = false;
  export let disabled = false;
  
  function onRestoreDefault(event) {
    event.preventDefault();
    value = defaultValue;
  }
</script>

<style>
  input.field-input {
    width: 100%;
  }
</style>

<FormItem>
  <svelte:fragment slot="label-column">
    <input type="checkbox" bind:checked={overridden} disabled={disabled}>{label}
  </svelte:fragment>
  <svelte:fragment slot="input-column">
    {#if overridden}
      <input class="field-input" type="text" bind:value={value} disabled={disabled} required={required}>
    {:else}
      <input class="field-input" type="text" value={defaultValue} placeholder={defaultValue} disabled={true}>
    {/if}
  </svelte:fragment>
  <svelte:fragment slot="control-column">
    <button on:click={onRestoreDefault} disabled={!overridden}>Restore Default</button>
  </svelte:fragment>
</FormItem>
