<script>
  import { onDestroy, onMount } from "svelte";
  import { RequestService } from "../../../comm/RequestService";
  import { ExtendedMountPoint } from "../../../domain-objects/ExtendedMountPoint";
  import { CONNECTION_TYPE } from "../../../constants";
  import { externalConnectionTable$, mountPointsList$, signalRConnection$ } from "../../../stores";
  import CommonMountPointsForm from "./CommonMountPointsForm.svelte";

  let loading = true;
  let mountPoints = [];
  let externalConnections = [];
  let unsubscribeSignalRConnection;
  
  
  onMount(() => {
    async function getData() {
      await getDataForTable();
    }

    unsubscribeSignalRConnection = signalRConnection$.subscribe(async (eventData) => {
      if (eventData) {
        await listenNotificationEvents(eventData)
      }
    });
    
    getData();
  });

  onDestroy(() => {
    unsubscribeSignalRConnection();
  });

  async function listenNotificationEvents(args) {
    if (args.status === 'Done' || args.status === 'Error' || args.status === 'InProgress') {
      await getDataForTable();
    }
  }
  
  function updateExternalConnectionTable() {
    // Not sure we need this any more???
    externalConnectionTable$.set('update');
  }
  
  async function getExternalConnections() {
    const resp = await RequestService.get('apiv2/externalconnection');

    if (resp.resultCode === 0) {
      return resp.result;
    } else {
      console.error('getExternalConnections ', resp.resultMessage);
      return [];
    }
  }

  async function getMountPoints() {
    const response = await RequestService.get(`/apiv2/mountpoint`);

    if (response.resultCode === 0) {
      let result = [];
      response.result.forEach(dto => {
        const mp = ExtendedMountPoint.createFromPlainObject(dto);
        mp.externalConnections = externalConnections;
        result.push(mp);
      });
      return result;
    } else {
      console.error('getMountPoints ', response.resultMessage);
      return [];
    }
  }

  async function getDataForTable() {
    loading = true;
    externalConnections = await getExternalConnections();
    mountPoints = await getMountPoints();
    loading = false;
  }
  
  async function onMountPointAdded(event) {
    // console.log("Inside onMountPointAdded");
    await getDataForTable();    
  }
  
  async function onMountPointRefreshed(event) {
    // console.log("Inside onMountPointRefreshed");
    await getDataForTable();
  }

  async function onMountPointDeleted(data) {
    updateExternalConnectionTable();
    mountPointsList$.set('update');
    await getDataForTable();
  }
  
  async function onMountPointUpdated(event) {
    // event.detail should contain:
    //   original: A reference to the original mount point
    //   updated: A copy of the original mount point with updates
    
    // console.log("Inside onMountPointUpdated");
    // console.log("detail =", event.detail);
    
    const requestUrl = `/apiV2/mountPoint`;
    const response = await RequestService.put(requestUrl, event.detail.updated.toPlainObject());

    if (response.resultCode === 0) {
      // console.log("Successfully saved");
    } else {
      alert(response.resultMessage);
      console.error('onMountPointUpdated ', response.resultMessage);
    }

    await getDataForTable();
    updateExternalConnectionTable();
    mountPointsList$.set('update');
  }
</script>

<CommonMountPointsForm
   mountPoints={mountPoints}
   externalConnections={externalConnections}
   loading={loading}
   on:mountpointadded={onMountPointAdded}
   on:mountpointrefreshed={onMountPointRefreshed}
   on:mountpointupdated={onMountPointUpdated}
   on:mountpointdeleted={onMountPointDeleted}
/>
