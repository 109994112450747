<script>
  import { createEventDispatcher } from 'svelte';
  import VerticalCenter from '../layout-helpers/VerticalCenter.svelte';
  import VerticalCenterItem from '../layout-helpers/VerticalCenterItem.svelte';
  import UnusedProperty from '../layout-helpers/UnusedProperty.svelte';
  import ButtonWithIcon from '../layout-helpers/ButtonWithIcon.svelte';
  import TwoPartHeaderPane from '../panes/TwoPartHeaderPane.svelte';

  export let pinnable;
  export let pinned;
  export let searchTerm;
  export let useRegexp;

  const dispatch = createEventDispatcher();
  let cutString = '';
  let stringLengthLimit = 100;

  $: if (searchTerm) {
    cutString = searchTerm.length > stringLengthLimit ? `${searchTerm.substring(0, stringLengthLimit)}...` : searchTerm;
  }

  function onClear() {
    dispatch('clearsearch');
  }

  function onShowTopicPage() {
    dispatch('showtopicpage');
  }
</script>

<style lang="scss">
  .search-header-area {
    width: 100%;

    .show-topic-btn {
      margin-left: 10px;
    }

    .clear-btn, .show-topic-btn {
      height: 100%;

      :global(button) {
        height: 100%;
      }
    }
  }
</style>

<div class="search-header-area">
  <UnusedProperty propertyName="useRegexp" val={useRegexp} comment="Reserved for future use"/>

  <TwoPartHeaderPane pinnable={pinnable} pinned={pinned} on:togglepin>
    <svelte:fragment slot="left">
      <VerticalCenter>
        <VerticalCenterItem>
          <slot><h2>Search: {cutString}</h2></slot>
        </VerticalCenterItem>
      </VerticalCenter>
    </svelte:fragment>
    <svelte:fragment slot="right">
      <VerticalCenterItem>
        <div class="clear-btn">
          <ButtonWithIcon on:click={onClear} iconName="clear_all" text="Clear Results"/>
        </div>
      </VerticalCenterItem>
      <VerticalCenterItem>
        <div class="show-topic-btn">
          <ButtonWithIcon on:click={onShowTopicPage} iconName="feed" text="Show Topic Page"/>
        </div>
      </VerticalCenterItem>
    </svelte:fragment>
  </TwoPartHeaderPane>
</div>
