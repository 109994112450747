import { TocEntry } from "../../pages/TocEntry";
import { TOC_ENTRY_TYPE } from "../../constants";
import { StaticTextTreeNodeItem } from "./StaticTextTreeNodeItem";
import { PageTreeNodeItem } from "./PageTreeNodeItem";

export class TreeNodeItemFactory {
  static createFromTreePageDto(dto, parentTreeNodeItem, windowBottomCoord) {
    let tocEntry = TocEntry.createFromTreePageDto(dto);

    switch (tocEntry.type) {
      case TOC_ENTRY_TYPE.PAGE_REFERENCE:
        return PageTreeNodeItem.createFromTreePageDto(
          dto,
          parentTreeNodeItem,
          windowBottomCoord,
          tocEntry
        );

      case TOC_ENTRY_TYPE.STATIC_TEXT:
        return StaticTextTreeNodeItem.createFromTreePageDto(
          dto,
          parentTreeNodeItem,
          windowBottomCoord,
          tocEntry
        );
      
      default:
        console.log("dto =", dto);
        debugger;
    }
  }
}
