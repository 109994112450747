export const CONTENT_PAGE_MODE = {
  Content: 'Content',
  Search: 'Search',
  UserOptions: 'UserOptions',
  PageOptions: 'PageOptions',
};

export const PANE = {
  Left: 'left',
  Right: 'right',
};

export const PANE_PAGE_MODE = {
  View: 'view',
  Append: 'append',
  EditContent: 'edit',
  EditPageOptions: 'editPageOptions',
  ViewPageOptions: 'viewPageOptions'
};

export const SNIPPET_TYPE = {
  Text: 1,
  File: 2,
  Drawing: 5,
  Container: 6,
  FileRef: 7
};

export const PAGE_TYPE = {
  Topic: 1,
  Mapped: 2,
  Virtual: 3,
};

export const CONNECTION_TYPE = {
  GENERIC_GIT_HOST: 1,
  AZURE_DEV_OPS: 2,
  GITHUB: 3,
  BITBUCKET_CLOUD: 4,
  GITLAB: 5
};

export const GIT_ACCESS_TYPE = {
  PUBLIC: 1,
  PRIVATE: 2,
  REUSE_EXISTING: 3
};

export const GitRepoStatus = {
  NotCloned: 1,
  CloningInProcess: 2,
  PullInProcess: 3,
  Ready: 4,
  Error: 5
};

export const GIT_REPO_STATUS = {
  1: 'Not Cloned',
  2: 'Cloning In Process',
  3: 'Pull In process',
  4: 'Ready',
  5: 'Error',
  6: 'Delete in Process',
  7: 'Queued for Cloning',
  8: 'Cloned, Index Pending',
  9: 'Cloned, Indexing Started'
};

export const DEFAULT_GIT_REPO_PATTERN_LIST = [
  {
    gitRepoType: CONNECTION_TYPE.AZURE_DEV_OPS,
    pattern: 'https://dev.azure.com/$REPONAME/_git/$PROJECTNAME?path=$URLENCODED_RELATIVEPATH',
    publicPattern: 'https://$ORGNAME@dev.azure.com/$ORGNAME/$REPONAME/_git/$REPONAME.git',
    gitHostName: 'Azure DevOps'
  },
  {
    gitRepoType: CONNECTION_TYPE.GITHUB,
    pattern: 'https://github.com/$OWNERNAME/$REPONAME/blob/master/$URLENCODED_RELATIVEPATH',
    publicPattern: 'https://github.com/$OWNERNAME/$REPONAME.git',
    gitHostName: 'GitHub'
  },
  {
    gitRepoType: CONNECTION_TYPE.BITBUCKET_CLOUD,
    pattern: 'https://bitbucket.org/$WORKSPACEID/$REPONAME/src/master/$URLENCODED_RELATIVEPATH',
    publicPattern: 'https://bitbucket.org/$WORKSPACEID/$REPONAME.git',
    gitHostName: 'Bitbucket'
  },
  {
    gitRepoType: CONNECTION_TYPE.GITLAB,
    pattern: 'https://gitlab.com/$OWNERNAME/$PROJECTSLUG/-/tree/main/$URLENCODED_RELATIVEPATH',
    publicPattern: 'https://gitlab.com/$OWNERNAME/$PROJECTSLUG.git',
    gitHostName: 'GitLab'
  }
];

export const HOST_NAME = {
  GenericGitHost: 'Generic Git Host',
  AzureDevOps: 'Azure DevOps',
  GitHub: 'GitHub',
  Bitbucket: 'Bitbucket',
  GitLab: 'GitLab',
};

export const CONNECTION_TYPE_LIST = [
  { value: CONNECTION_TYPE.GENERIC_GIT_HOST, label: 'Generic Git Host' },
  { value: CONNECTION_TYPE.AZURE_DEV_OPS, label: 'Azure DevOps' },
  { value: CONNECTION_TYPE.BITBUCKET_CLOUD, label: 'Bitbucket Cloud' },
  { value: CONNECTION_TYPE.GITHUB, label: 'GitHub' },
  { value: CONNECTION_TYPE.GITLAB, label: 'GitLab' },
];

export const GIT_CREDENTIALS_TYPE = {
  USER_CREDENTIALS: 1,
  PERSONAL_ACCESS_TOKEN: 2,
  OAUTH: 3,
};

export const REFRESH_TYPE = {
  POLLING: 1,
  TRIGGERED: 2,
};

export const TREE_FORMAT_TYPE = {
  RAW: 1,
  TOC_LESS: 2,
};

export const SEARCH_PARAM = 'queryTerm';
export const REGEXP_PARAM = 'useRegexp';
export const PAGE_SUID_PARAM = 'suid';
export const USER_OPTION_PARAM = 'userOption';

export const URL_PARAM = {
  SEARCH_PARAM: 'queryTerm',
  REGEXP_PARAM: 'useRegexp',
  PAGE_SUID_PARAM: 'suid',
  USER_OPTION_PARAM: 'userOption', //true, false
  USER_OPTION_TYPE_PARAM: 'userOptionType', //default_page_options, external_connection, mount_point ...
  ORGANIZATION_ID: 'orgId',
};

export const TOC_ENTRY_TYPE = {
  PAGE_REFERENCE: 0,
  STATIC_TEXT: 1
};
