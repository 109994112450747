<script>
  import { RequestService } from "../../comm/RequestService";
  import { createEventDispatcher } from "svelte";
  import Loading from "../layout-helpers/Loading.svelte";

  export let group;
  const dispatch = createEventDispatcher();
  let groupMembers = [];
  let searchUsersList = [];
  let usersListForGroup = [];
  let searchQuery = '';
  let filteredSearchUsersList = [];
  let firstTimeSearch = false;
  let loading = false;

  const GroupMemberState = {
    List: 'list',
    Add: 'add',
  };
  let currentGroupMemberState = GroupMemberState.List;

  $: {
    if (group) {
      getGroupMembers();
    }
  }

  function filterAvailableUsers() {
    filteredSearchUsersList = searchUsersList.filter((ou) => {
      return !groupMembers.some((gm) => {
        return gm.userId.value === ou.id.value
      })
    })
  }

  async function getGroupMembers() {
    loading = true;
    const url = `/apiV2/groups/${group.id.value}/members`;
    let resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      groupMembers = resp.result || [];
    } else {
      console.error(resp.resultMessage);
    }
    loading = false;
  }

  async function onRemoveGroupMember(memberId) {
    const url = `/apiV2/groups/${group.id.value}/members`;
    let resp = await RequestService.delete(url, { groupMembershipId: memberId });

    if (resp.resultCode === 0) {
      await getGroupMembers();
    } else {
      console.error(resp.resultMessage);
    }
  }

  async function onSearchUsers() {
    if (!searchQuery) return;
    firstTimeSearch = true;
    const url = `/apiV2/users/search?email=${searchQuery}`;
    let resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      searchUsersList = resp.result || [];
      if (searchUsersList.length) {
        searchUsersList.forEach((user) => user.isAdded = false)
      }
      filterAvailableUsers();
    } else {
      console.error(resp.resultMessage);
      searchUsersList = [];
      filteredSearchUsersList = [];
    }
  }

  function onAddUserToList(user) {
    user.isAdded = true;
    filteredSearchUsersList = [...filteredSearchUsersList];
    usersListForGroup = [...usersListForGroup, user];
  }

  function onRemoveUserFromList(user) {
    user.isAdded = false;

    usersListForGroup = usersListForGroup.filter((u) => u.id.value !== user.id.value);
    filteredSearchUsersList = [...filteredSearchUsersList];
  }

  function onBackToGroupSection() {
    dispatch('backtogroupsection');
  }

  function onShowFormToAddMember() {
    currentGroupMemberState = GroupMemberState.Add
  }

  function onCloseFormToAddUser() {
    searchQuery = '';
    searchUsersList = [];
    usersListForGroup = [];
    filteredSearchUsersList = [];
    firstTimeSearch = false;
    currentGroupMemberState = GroupMemberState.List;
  }

  async function onAddUserToGroup() {
    const url = `/apiV2/groups/${group.id.value}/members`;
    const data = usersListForGroup.map((user) => {
      return { login: user.loginId, userId: user.id.value }
    });
    const resp = await RequestService.post(url, { members: data });

    if (resp.resultCode === 0) {
      await getGroupMembers();
      onCloseFormToAddUser();
    } else {
      console.error(resp.resultMessage);
    }
  }

  function onBackToList() {
    dispatch('setgroupsstate');
  }
</script>
<style lang="scss">
  label {
    position: relative;

    input[type='text'] {
      width: 400px;
      margin-bottom: 20px;
    }
  }

  .search-btn {
    position: absolute;
    right: 2px;
    top: -3px;
    width: 34px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      align-self: center;
    }
  }
</style>
<button on:click={onBackToList} class="back-btn">
  <span class="material-icons-outlined">arrow_back_ios</span>
  Back To Groups List
</button>

<div class="group-members">
  <Loading {loading}>
    {#if currentGroupMemberState === GroupMemberState.List}
      <h3 class="title">Members for {group.title.toUpperCase()} group</h3>
    {:else if currentGroupMemberState === GroupMemberState.Add}
      <h3 class="title">Adding user members for {group.title.toUpperCase()} group</h3>
    {/if}
    {#if currentGroupMemberState === GroupMemberState.List}
      {#if groupMembers.length}
        <table>
          <tbody>
          <tr>
            <th>User Name</th>
            <th>Actions</th>
          </tr>
          {#each groupMembers as member (member.id.value)}
            <tr>
              <td>{member.targetName}</td>
              <td>
                <button type="button" class="action-btn"
                        disabled={member.isAdministrator}
                        on:click={() => onRemoveGroupMember(member.id.value)}
                >
                  Delete
                </button>
              </td>
            </tr>
          {/each}
          </tbody>
        </table>
      {:else}
        <div style="margin-bottom: 20px">There are no current user members.</div>
      {/if}

      <button class="show-members-form" type="button" on:click={onShowFormToAddMember}>
        Add New User Members
      </button>
    {:else if currentGroupMemberState = GroupMemberState.Add}
      <form on:submit|preventDefault={onSearchUsers}>
        <label class="search-input">
          <input type="text" bind:value={searchQuery} required placeholder="Enter E-mail Address">
          <button class="search-btn" type="submit">
            <span class="material-icons-outlined">search</span>
          </button>
        </label>
      </form>
      {#if filteredSearchUsersList.length}
        <table>
          <tbody>
          <tr>
            <th>User</th>
            <th>Actions</th>
          </tr>
          {#each filteredSearchUsersList as user (user.id.value)}
            <tr>
              <td>{user.email + '(' + user.loginId + ')'}</td>
              <td>
                {#if !user.isAdded}
                  <button type="button" class="action-btn" on:click={onAddUserToList(user)}>
                    Add
                  </button>
                {:else}
                  <button type="button" class="action-btn" on:click={onRemoveUserFromList(user)}>
                    Remove
                  </button>
                {/if}
              </td>
            </tr>
          {/each}
          </tbody>
        </table>
      {:else}
        {#if firstTimeSearch}
          <p>No user found.</p>
        {:else}
          <p>Enter E-mail address to search and add users to the group.</p>
        {/if}
      {/if}

      <div class="action-buttons-section">
        <button type="button" name="saveBtn"
                disabled={!usersListForGroup.length}
                on:click={onAddUserToGroup}
                title={searchQuery && !usersListForGroup.length ? 'Please select users for add' : ''}
        >Save
        </button>
        <button type="button" on:click={onCloseFormToAddUser}>Close</button>
      </div>
    {/if}
  </Loading>
</div>
