<script>
  export let component = "Not Specified";
  export let message = "Not Specified";
</script>

<div>
  <h1>Internal Error</h1>
  <p>
    Oops. We apologize for the inconvenience, but it looks like
    we've had a bit of a hiccup.
    
    The program has encountered an unexpected internal error.
    Please report the problem to OpenSquiggly technical support.
  <p>
  <p>Component: {component}</p>
  <p>{message}</p>
</div>  
