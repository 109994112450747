<script>
  import { createEventDispatcher } from "svelte";

  export let overrideablePageOptions;
  export let overrideableTimeStampOptions;
  
  const dispatch = createEventDispatcher();
  let generalBindingObject;
  let timeBindingObject;

  function onSaveOptions() {
    overrideablePageOptions.copy(generalBindingObject);
    overrideableTimeStampOptions.copy(timeBindingObject);
    dispatch('updatepageoptions', { ...generalBindingObject, ...timeBindingObject });
  }
  
  function updateGeneralBindingObject(options) {
    generalBindingObject = options.getPlainObject();
  }
  
  function updateTimeBindingObject(options) {
    timeBindingObject = overrideableTimeStampOptions.getPlainObject();
  }
  
  $: updateGeneralBindingObject(overrideablePageOptions);
  $: updateTimeBindingObject(overrideableTimeStampOptions);
</script>

<style lang="scss">
  .option-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .override-element {
      padding-top: 15px;
      padding-bottom: 5px;
    }

    .override-element:first-child {
      padding-top: 0;
    }

    .rewritable-field {
      padding-left: 15px;
    }

    label {
      display: flex;
      align-items: center;
    }

    button {
      width: 80px;
    }
  }
</style>

<div class="option-section">
  <label class="override-element">
    <input type="checkbox" bind:checked={timeBindingObject.override}>Override Default:
    Date/Time
  </label>
  <label class="rewritable-field">
    <input type="checkbox" bind:checked={timeBindingObject.displayDate} disabled="{!timeBindingObject.override}">Display Date
  </label>
  <label class="rewritable-field">
    <input type="checkbox" bind:checked={timeBindingObject.displayTime} disabled="{!timeBindingObject.override}">Display Time
  </label>

  <label class="override-element">
    <input type="checkbox" bind:checked={generalBindingObject.overrideDisplayGutter}>
    Override Default: Show Left Margin Gutter</label>
  <label class="rewritable-field">
    <input type="checkbox" bind:checked={generalBindingObject.displayGutter} disabled="{!generalBindingObject.overrideDisplayGutter}">
    Display Left Margin
  </label>

  <label class="override-element">
    <input type="checkbox" bind:checked={generalBindingObject.overrideDisplaySeparators}>
    Override Default: Display Borders Between Top-Level Jots
  </label>
  <label class="rewritable-field">
    <input type="checkbox" bind:checked={generalBindingObject.displaySeparators}
           disabled="{!generalBindingObject.overrideDisplaySeparators}">Display Borders Between Top-Level Jots
  </label>
  <br>
  <br>
  <button type="button" on:click={onSaveOptions}>Save</button>
</div>
