import {
  CONNECTION_TYPE,
  GIT_ACCESS_TYPE, GitRepoStatus, REFRESH_TYPE,
  TREE_FORMAT_TYPE
} from "../constants";

export class MountPoint {
  constructor() {
    this._id = null;
    this._createDate = null;
    this._name = '';
    this._description = '';
    this._gitAccessType = GIT_ACCESS_TYPE.PUBLIC;
    this._connectionType = CONNECTION_TYPE.GITHUB;
    this._externalConnectionId = null;
    this._innerMountPointId = null;
    this._startPath = '/';
    this._treeFormatType = TREE_FORMAT_TYPE.RAW;
    // this.formatterSetType
    // this.formatterSetId
    this._refreshType = REFRESH_TYPE.POLLING;
    this._pollingInterval = 60;
    this._triggerUrl = '';
    // this.depth
    // this.includeFolders
    // this.includeFiles
    // this.excludeFolders
    // this.excludeFiles
    // this.cloneDate
    this._gitStatus = GitRepoStatus.NotCloned;
    this._lastRefreshDate = null;
    this._lastGitErrorCode = 0;
    this._lastGitError = '';
    this._overrideDomainName = false;
    this._domainName = '';
    this._overrideOrgName = false;
    this._orgName = '';
    this._projectName = '';
    this._repoName = '';
    this._branchName = '';
    this._overrideGitHostDisplayName = false;
    this._gitHostDisplayName = '';
    this._overrideGitRepoPattern = false;
    this._gitRepoPattern = '';
    this._overrideHostPath = false;
    this._hostPath = '';
    this._organizationId = '';
  }
  
  static createFromPlainObject(obj) {
    const result = new MountPoint();
    result.loadFromPlainObject(obj);
    
    return result;
  }
  
  loadFromPlainObject(obj) {
    this.id = obj.id;
    this.createDate = obj.createdOn; // Mismatched name in DTO object
    this.name = obj.name;
    this.description = obj.description;
    this.gitAccessType = obj.gitAccessType;
    this.connectionType = obj.gitRepoType; // Mismatched name in DTO object
    this.externalConnectionId = obj.extConnectionId; // Mismatched name in DTO object
    this.innerMountPointId = obj.innerMountPointId;
    this.startPath = obj.startPath;
    this.treeFormatType = obj.treeFormatType;
    // this.formatterSetType
    // this.formatterSetId
    this.refreshType = obj.refreshType;
    this.pollingInterval = obj.pollingInterval;
    this.triggerUrl = obj.triggerUrl;
    // this.depth
    // this.includeFolders
    // this.includeFiles
    // this.excludeFolders
    // this.excludeFiles
    // this.cloneDate
    this.gitStatus = obj.status; // Mismatched name in DTO object
    this.lastRefreshDate = obj.lastRefreshDate;
    this.lastGitErrorCode = obj.lastErrorCode; // Mismatched name in DTO object
    this.lastGitError = obj.lastError; // Mismatched name in DTO object
    this.overrideDomainName = obj.overrideDomainName;
    this.domainName = obj.domainName;
    this.overrideOrgName = obj.overrideOrgName;
    this.orgName = obj.orgName;
    this.projectName = obj.projectName;
    this.repoName = obj.repoName;
    this.branchName = obj.branchName;
    this.overrideGitHostDisplayName = obj.overrideGitHostDisplayName;
    this.gitHostDisplayName = obj.gitHostDisplayName;
    this.overrideGitRepoPattern = obj.overrideGitRepoPattern;
    this.gitRepoPattern = obj.repoPattern; // Mismatched name in DTO object
    this.overrideHostPath = obj.overrideHostPath;
    this.hostPath = obj.hostPath;    
    this.organizationId = obj.organizationId;
  }
  
  toPlainObject() {
    return {
      id: this.id,
      createdOn: this.createDate, // Mismatched name in DTO object
      name: this.name,
      description: this.description,
      gitAccessType: this.gitAccessType,
      gitRepoType: this.connectionType, // Mismatched name in DTO object
      extConnectionId: this.externalConnectionId, // Mismatched name in DTO object
      innerMountPointId: this.innerMountPointId,
      startPath: this.startPath,
      treeFormatType: this.treeFormatType,
      // formatterSetType:
      // formatterSetId:
      refreshType: this.refreshType,
      pollingInterval: this.pollingInterval,
      triggerUrl: this.triggerUrl,
      // depth:
      // includeFolders:
      // includeFiles:
      // excludeFolders:
      // excludeFiles:
      // cloneDate:
      status: this.gitStatus, // Mismatched name in DTO object
      lastRefreshDate: this.lastRefreshDate,
      lastErrorCode: this.lastGitErrorCode, // Mismatched name in DTO object
      lastError: this.lastGitError, // Mismatched name in DTO object
      overrideDomainName: this.overrideDomainName,
      domainName: this.domainName,
      overrideOrgName: this.overrideOrgName,
      orgName: this.orgName,
      projectName: this.projectName,
      repoName: this.repoName,
      branchName: this.branchName,
      overrideGitHostDisplayName: this.overrideGitHostDisplayName,
      gitHostDisplayName: this.gitHostDisplayName,
      overrideGitRepoPattern: this.overrideGitRepoPattern,
      repoPattern: this.gitRepoPattern, // Mismatched name in DTO object
      overrideHostPath: this.overrideHostPath,
      hostPath: this.hostPath,
      organizationId: this.organizationId
    }
  }
  
  dup() {
    return MountPoint.createFromPlainObject({ ...this.toPlainObject() });
  }
  
  get id() {
    return this._id;
  }
  
  set id(val) {
    this._id = val;
  }
  
  get createDate() {
    return this._createDate;
  }
  
  set createDate(val) {
    this._createDate = val;
  }
  
  get name() {
    return this._name;
  }
  
  set name(val) {
    this._name = val;
  }
  
  get description() {
    return this._description;
  }
  
  set description(val) {
    this._description = val;
  }
  
  get gitAccessType() {
    return this._gitAccessType;
  }
  
  set gitAccessType(val) {
    this._gitAccessType = val;
  }

  get connectionType() {
    return this._connectionType;
  }

  set connectionType(val) {
    this._connectionType = val;
  }  

  get externalConnectionId() {
    // console.log("Inside getter externalConnectionId");
    // console.log("exteranlConnectionId =", this._externalConnectionId);
    return this._externalConnectionId;
  }

  set externalConnectionId(val) {
    this._externalConnectionId = val;
  }

  get innerMountPointId() {
    return this._innerMountPointId;
  }

  set innerMountPointId(val) {
    this._innerMountPointId = val;
  }
  
  get startPath() {
    return this._startPath;
  }
  
  set startPath(val) {
    this._startPath = val;
  }
  
  get treeFormatType() {
    return this._treeFormatType;
  }
  
  set treeFormatType(val) {
    this._treeFormatType = val;
  }
  
  get refreshType() {
    return this._refreshType;
  }
  
  set refreshType(val) {
    this._refreshType = val;
  }
  
  get pollingInterval() {
    return this._pollingInterval;
  }
  
  set pollingInterval(val) {
    this._pollingInterval = val;
  }
  
  get triggerUrl() {
    return this._triggerUrl;
  }
  
  set triggerUrl(val) {
    this._triggerUrl = val;
  }
  
  get gitStatus() {
    return this._gitStatus;
  }
  
  set gitStatus(val) {
    this._gitStatus = val;
  }
  
  get lastRefreshDate() {
    return this._lastRefreshDate;
  }
  
  set lastRefreshDate(val) {
    this._lastRefreshDate = val;
  }
  
  get lastGitErrorCode() {
    return this._lastGitErrorCode;
  }
  
  set lastGitErrorCode(val) {
    this._lastGitErrorCode = val;
  }
  
  get lastGitError() {
    return this._lastGitError;
  }
  
  set lastGitError(val) {
    this._lastGitError = val;
  }
  
  get overrideDomainName() {
    return this._overrideDomainName;
  }
  
  set overrideDomainName(val) {
    this._overrideDomainName = val;
  }
  
  get domainName() {
    return this._domainName;
  }
  
  set domainName(val) {
    this._domainName = val;
  }
  
  get overrideOrgName() {
    return this._overrideOrgName;
  }
  
  set overrideOrgName(val) {
    this._overrideOrgName = val;
  }
  
  get orgName() {
    return this._orgName;
  }
  
  set orgName(val) {
    this._orgName = val;
  }
  
  get projectName() {
    return this._projectName;
  }
  
  set projectName(val) {
    this._projectName = val;
  }
  
  get repoName() {
    return this._repoName;
  }
  
  set repoName(val) {
    this._repoName = val;
  }
  
  get branchName() {
    return this._branchName;
  }
  
  set branchName(val) {
    this._branchName = val;
  }
  
  get overrideGitHostDisplayName() {
    return this._overrideGitHostDisplayName;
  }
  
  set overrideGitHostDisplayName(val) {
    this._overrideGitHostDisplayName = val;
  }
  
  get gitHostDisplayName() {
    return this._gitHostDisplayName;
  }
  
  set gitHostDisplayName(val) {
    this._gitHostDisplayName = val;
  }
  
  get overrideGitRepoPattern() {
    return this._overrideGitRepoPattern;
  }
  
  set overrideGitRepoPattern(val) {
    this._overrideGitRepoPattern = val;
  }
  
  get gitRepoPattern() {
    return this._gitRepoPattern;
  }
  
  set gitRepoPattern(val) {
    this._gitRepoPattern = val;
  }
  
  get overrideHostPath() {
    return this._overrideHostPath;
  }
  
  set overrideHostPath(val) {
    this._overrideHostPath = val;
  }
  
  get hostPath() {
    return this._hostPath;
  }
  
  set hostPath(val) {
    this._hostPath = val;
  }
  
  get organizationId() {
    return this._organizationId;
  }
  
  set organizationId(val) {
    this._organizationId = val;
  }
}
