<script>
  import CircularProgress from '@smui/circular-progress';
  import '@smui/circular-progress//bare.css';
</script>

<style lang="scss">
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: rgba(231, 229, 228, 0.6);

    :global(.mdc-circular-progress__indeterminate-circle-graphic) {
      stroke: #44403c;
      /* @alternate */
      stroke: var(--mdc-theme-primary, #44403c);
    }
  }
</style>

<div class="spinner-container">
  <CircularProgress style="height: 50px; width: 50px;" indeterminate />
</div>
