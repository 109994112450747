<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { CONNECTION_TYPE, TREE_FORMAT_TYPE } from "../../constants";
  import { getDefaultGitHostName, getDefaultRepoUrl, getDefaultViewUrl } from "./DefaultViewParams";
  import { RequestService } from "../../comm/RequestService";
  import {mappedPageCreation$, navSelectedItem$} from '../../stores';
  import {TreeNavigatorService} from "../navigator/TreeNavigatorService";
  import TopicPage from "../../pages/TopicPage";

  const dispatch = createEventDispatcher();
  let isError = false;
  let pathInput = '';
  let isAutoCreatePage = true;
  let isNavigatorActivePageTopic = false;
  let unsubscribe;

  let defaultData;

  onMount(() => {
    resetData();
    unsubscribe = navSelectedItem$.subscribe((item) => {
      if (item.page) {
        if (item.page.suid.includes('tp')) {
          isNavigatorActivePageTopic = true;
        } else {
          isNavigatorActivePageTopic = false;
          isAutoCreatePage = false;
        }
      }
    })
  });

  onDestroy(() => {
    unsubscribe();
  });

  const hosts = [
    { hostPath: 'dev.azure.com', hostType: CONNECTION_TYPE.AZURE_DEV_OPS },
    { hostPath: 'github.com', hostType: CONNECTION_TYPE.GITHUB },
    { hostPath: 'bitbucket.org', hostType: CONNECTION_TYPE.BITBUCKET_CLOUD },
    { hostPath: 'gitlab.com', hostType: CONNECTION_TYPE.GITLAB },
  ];

  function resetData() {
    defaultData = {
      gitAccessType: 1, //public
      name: '',
      description: '',
      orgName: '',
      repoName: '',
      repoPattern: '',
      hostPath: '',
      gitHostDisplayName: '',
      gitRepoType: null,
      treeFormatType: +TREE_FORMAT_TYPE.RAW,
    };
    pathInput = '';
  }

  function getModelProps() {
    let currentHost = hosts.find((h) => pathInput.toLowerCase().includes(h.hostPath));

    if (currentHost) {
      defaultData.gitRepoType = currentHost.hostType;
      defaultData.currentConnectionType = currentHost.hostType;
      defaultData.hostPath = getDefaultViewUrl(defaultData);
      defaultData.gitHostDisplayName = getDefaultGitHostName(defaultData);
      defaultData.repoPattern = getDefaultRepoUrl(defaultData);

      //https://github.com/Sanchelloz/Code-example.git
      const pathStringsArr = pathInput.split('/');
      defaultData.orgName = pathStringsArr[3];
      defaultData.repoName = pathStringsArr[4];
      if (defaultData.repoName.endsWith('.git')) {
        defaultData.repoName = defaultData.repoName.slice(0, defaultData.repoName.length - 4);
      }
      defaultData.name = pathStringsArr[3] + '/' + defaultData.repoName;
      isError = false;
    } else {
      isError = true;
    }

    // console.log('defaultData ', defaultData);
  }

  async function saveData(event) {
    getModelProps();
    const requestUrl = `/apiV2/mountPoint`;
    let resp = await RequestService.post(requestUrl, defaultData);

    if (resp.resultCode === 0) {
      await addMappedPageIntoNavigator(defaultData.name, resp.result);
      dispatch('quicksavesuccess', { isAutoCreatePage, mountPointId: resp.result, title: defaultData.name });
      resetData();
    } else {
      console.error('saveData ', resp.resultMessage);
    }
    if (event.submitter.name === 'saveCloseBtn') {
      onCancel();
    }
  }

  async function addMappedPageIntoNavigator(pageTitle, mountPointId) {
    const createMappedPageData = {
      title: pageTitle,
      mountPointId: mountPointId,
      relativePath: ''
    };

    const resp = await TreeNavigatorService.createMappedPage(createMappedPageData);

    if (resp.resultCode === 0) {
      const topicPage = new TopicPage(resp.result);
      mappedPageCreation$.set({
        detail: {
          pageSuid: topicPage.suid,
          title: pageTitle
        }
      });
    } else {
      console.error(resp.resultMessage);
    }
  }

  function onCancel() {
    dispatch('quicksavecancel');
  }
</script>
<style lang="scss">
  section {
    border: 1px solid gray;
    width: 620px;

    .headline {
      border-bottom: 1px solid gray;
      padding: 5px 10px;
    }

    form {
      .form-body {
        padding: 10px;

        input[type="url"] {
          width: 100%;
        }

        label {
          position: relative;
        }

        label:last-child {
          display: flex;
          align-items: flex-end;
          width: fit-content;
          width: -moz-fit-content;
        }

        .error {
          position: absolute;
          color: red;
          top: 40px;
          left: 0;
        }

        p {
          padding-top: 5px;
        }
      }

      .action-buttons {
        border-top: 1px solid gray;
        padding: 5px 10px;
      }
    }
  }
</style>
<section>
  <div class="headline">
    <h4>Quick Add Mount Point</h4>
  </div>

  <form id="quick-add-mount-point-form" on:submit|preventDefault={(e) => saveData(e)}>
    <div class="form-body">
      <label for="full-path">Public Cloning URL from Git Host: <br>
        <input id="full-path" type="url" bind:value={pathInput} required
               placeholder="https://test@bitbucket.org/testCompany/testProject.git">

        {#if isError}
          <span class="error">
            Please, enter valid path.
          </span>
        {/if}
      </label>
      <p>
        Note: This form allows you to quickly add public repositories from GitHub, GitLab, Bitbucket, or Azure DevOps.
        Enter the URL of the desired repository from your Git hosting system.
      </p>
      <label for="auto-create-page">
        <input id="auto-create-page" type="checkbox"
               bind:checked={isAutoCreatePage}
               disabled={!isNavigatorActivePageTopic}
        >
        Automatically create mapped page to repository in the current page
      </label>
    </div>
    <div class="action-buttons">
      <button type="submit" name="saveAddBtn">Save & Add Another</button>
      <button type="submit" name="saveCloseBtn">Save & Close</button>
      <button type="button" on:click={onCancel}>Cancel</button>
    </div>
  </form>
</section>
