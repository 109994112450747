<script>
  import {onMount} from "svelte";

  export let page;
  
  onMount(() => {
    // console.log("Inside Virtual Page Properties");
    // console.log("page =", page);
  });
</script>

<style lang="scss">
  //.options-section {
  //  width: 100%;
  //  height: 100%;
  //  transition: height 0.1s linear;
  //  overflow-y: auto;
  //}

  //.accordion-title {
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-between;
  //  width: 100%;
  //
  //  .material-icons-outlined {
  //    font-size: 30px;
  //  }
  // }
</style>

<h2 class="mb-4">View Page Properties</h2>
<div class="general-section w-full">
  <form>
    <label class="override-element">
      <span class="font-bold">Relative Path:</span>
      <input class="w-full font-sans" type="text" bind:value={page.relativePath} disabled={true} />
    </label>
  </form>
</div>
