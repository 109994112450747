<script>
  export let value;
  export let disabled = false;
  export let required = false;
</script>

<style>
  select.field-input {
    width: 100%;
  }
</style>

<select class="field-input" bind:value={value} disabled={disabled} required={required}>
  <slot />
</select>  
