<script>
  import FormItemWithOverride from "../../form-helpers/FormItemWithOverride.svelte";
  import FormItemWithLabel from "../../form-helpers/FormItemWithLabel.svelte";
  import FormTextInput from "../../form-helpers/FormTextInput.svelte";
  import FormSectionHeading from "../../form-helpers/FormSectionHeading.svelte";

  export let isCreateMode = false;
  export let allowOverrideOrgName = false;
  export let overrideOrgName = false;
  export let defaultOrgName = "";
  export let orgName = "";
  export let repoName = "";
  export let allFieldsValid = false;

  function orgNameValidator(orgName, overrideOrgName, defaultOrgName) {
    const effectiveOrgName = (!allowOverrideOrgName || (allowOverrideOrgName && overrideOrgName)) ? orgName : defaultOrgName;
    return effectiveOrgName !== null && effectiveOrgName !== undefined && effectiveOrgName.trim() !== '';
  }
  
  function repoNameValidator(repoName) {
    return repoName !== null && repoName !== undefined && repoName.trim() !== '';
  }

  function updateAllFieldsValid(orgName, repoName, overrideOrgName, defaultOrgName) {
    const orgNameValid = orgNameValidator(orgName, overrideOrgName, defaultOrgName);
    const repoNameValid = repoNameValidator(repoName);

    allFieldsValid =
      orgNameValid &&
      repoNameValid
    ;
  }

  updateAllFieldsValid(orgName, repoName, overrideOrgName, defaultOrgName);

  $: updateAllFieldsValid(orgName, repoName, overrideOrgName, defaultOrgName);
</script>

<FormSectionHeading label="GitLab-Specific Fields" />

{#if allowOverrideOrgName}
  <FormItemWithOverride
    label="Override Username / Group Name"
    defaultValue={defaultOrgName}
    disabled={!isCreateMode}
    bind:overridden={overrideOrgName}
    bind:value={orgName}
  />
{:else}
  <FormItemWithLabel label="Username / Group Name" required={true} validator={() => orgNameValidator(orgName, false, '')}>
    <FormTextInput bind:value={orgName} required={true} disabled={!isCreateMode} />
  </FormItemWithLabel>
{/if}

<FormItemWithLabel label="Project Slug" required={true} validator={() => repoNameValidator(repoName)}>
  <FormTextInput bind:value={repoName} required={true} disabled={!isCreateMode} />
</FormItemWithLabel>
