import "regenerator-runtime/runtime";
import { PAGE_LOCATOR_TYPE } from "../misc/PageLocator";
import OverrideablePageOptions from "../misc/OverrideablePageOptions";
import OverrideableTimeStampOptions from "../misc/OverrideableTimeStampOptions";
import { RequestService } from "../comm/RequestService";

const emptyTimeStampOptions = new OverrideableTimeStampOptions({
  override: true,
  displayDate: false,
  displayTime: false
});

const emptyPageOptions = new OverrideablePageOptions({
  overrideDisplayGutter: true,
  displayGutter: false,
  overrideDisplaySeparators: true,
  displaySeparators: false
});

class Page {
  /**
   * Construct a page from jsonData
   *
   * @constructor
   */
  constructor(jsonData) {
    this.jsonData = jsonData;
    this._jsonData = jsonData;
    this.itemType = jsonData.itemType;

    this._options = new OverrideablePageOptions(jsonData.options || emptyPageOptions);
    this._timeStampOptions = new OverrideableTimeStampOptions(jsonData.timeStampOptions || emptyTimeStampOptions);
  }
  
  dup() {
    return new Page({ ...this._jsonData });
  }

  get suid() {
    return this._jsonData.suid;
  }
  
  set suid(val) {
    // Not recommended. Will be deprecated.
    this._jsonData.suid = val;
  }
  
  get tocEntryId() {
    return this._jsonData.tocEntryId;
  }

  /**
   * Get the database id of the page. It is only valid for regular topic pages or
   * mapped topic pages. It is not valid for virtual pages. This method is deprecated
   * and is in the process of being replaced with the suid accessor.
   * 
   * @returns {string}
   */
  getId() {
    return this.jsonData.id.value;
  }

  /**
   * Returns true if this page type allows reordering of child topic pages.
   * @returns {boolean|*|boolean}
   */
  canReorderChildPages() {
    // User can only reorder child pages on regular topic pages. Mapped pages and virtual
    // pages are controlled by the external content.
    return  this.itemType === 1 && this.pageLocator && this.pageLocator.pageLocatorType === PAGE_LOCATOR_TYPE.TopicPage;
  }

  /**
   * Returns true if this is a journal page. Should be overridden by child classes.
   * @returns {boolean}
   */
  get isJournalPage() {
    return false;
  }

  get isTopicPage() {
    return false;
  }

  getRenderLeftMargin() {
    return true;
  }

  getRenderWithSeparators() {
    return true;
  }

  getAddPostData() {
    return {};
  }

  getReorderPostData() {
    return {};
  }

  getRemovePostData() {
    return {};
  }
  
  get options() {
    return this._options;
  }
  
  set options(val) {
    this._options = val;
  }

  get timeStampOptions() {
    return this._timeStampOptions;
  }
  
  get isPhysicalPage() {
    const prefix = this.suid.substring(0, 3);
    return prefix === 'tp:' || prefix === 'mp:';
  }
  
  get isMappedPage() {
    return this.suid.substring(0, 3) === 'mp:';
  }
  
  get isVirtualPage() {
    return this.suid.substring(0, 3) === 'vp:';
  }
  
  get hasEditableContent() {
    // return this.isTopicPage;
    return this.suid.substring(0, 3) === 'tp:'
  }
  
  get hasEditableProperties() {
    return this.isPhysicalPage;
  }
  
  get hasViewableProperties() {
    // All pages have at least on property that is viewable.
    // Virtual pages should at least be able to view their
    // mount point and relative path.
    // In the future we may have pages that don't have any
    // viewable properties at all (unlikely, but we build this
    // property is just in case).
    return true;
  }
  
  get isExternalContent() {
    return this.isMappedPage || this.isVirtualPage;
  }
  
  get isExternalToc() {
    return this.isMappedPage || this.isVirtualPage;
  }
  
  get isRefreshable() {
    return this.isExternalContent;
  }
  
  get isDeletable() {
    return this.isPhysicalPage;
  }
  
  get isRenameable() {
    return this.isPhysicalPage;
  }
  
  get isOpenableInGitHost() {
    return this.isExternalContent;
  }
  
  async readSnippets() {
    const url = `api/ReadSnippetsForPage2?suid=${this.suid}`;
    const resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      return resp.result
    }
  }
}

export default Page;
