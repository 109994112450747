import LoginLayout from './components/login/LoginLayout.svelte'
import "../static/css/core-css-reset.css";
import "../static/css/Splitter.css";
import "../static/styles/index.scss";
import "./assets/images/favicon_dark.ico";

function renderPage() {
  let body = document.getElementsByTagName('body')[0];
  let wrap = document.createElement("div");
  wrap.className = 'login-layout-wrap';
  body.appendChild(wrap);

  new LoginLayout({
    target: document.querySelector('.login-layout-wrap'),
  });
}

renderPage();

if (module['hot']) {
  module['hot'].accept('./index.js', function () {
    console.log('Accepting updated message module');
    renderPage();
  });
}
