<script>
  import { RequestService } from "../../comm/RequestService";
  import { createEventDispatcher, onMount } from 'svelte';

  export let snippet;
  export let pageSuid;
  export let editSnippetSelected = false;
  export let snippetTabActive = '';

  const dispatch = createEventDispatcher();
  let files;
  let fileInputRef;
  let uploadImageContainerRef;
  let isImage = false;
  let imageRegexp = new RegExp('^image\\/');

  onMount(() => {
    isImage = imageRegexp.test(snippet.mimeType);
  });

  function onAddFile(e) {
    uploadImageContainerRef.innerHTML = '';
    fileInputRef.files = e.clipboardData.files;

    files = e.clipboardData.files;
    snippet.fileName = files && files[0] && files[0].name;

    const item = Array.from(e.clipboardData.items).find(x => imageRegexp.test(x.type));

    if (!!item) {
      isImage = true;
      const blob = item.getAsFile();
      const uploadedImage = new Image(100);
      uploadedImage.onload = function () {
        uploadImageContainerRef.appendChild(this);
      };
      uploadedImage.src = URL.createObjectURL(blob);
    } else {
      isImage = false;
    }
  }

  function onFileUpdated() {
    snippet.fileName = files && files[0].name || snippet.fileName;
    isImage = imageRegexp.test(files && files[0].type);
    uploadImageContainerRef.innerHTML = '';

    if (isImage) {
      let image = document.createElement('img');
      uploadImageContainerRef.appendChild(image);
      image.src = window.URL.createObjectURL(fileInputRef.files[0]);
      image.width = 100;
    }
  }

  async function onSaveFileSnippet(event) {
    const formData = new FormData();

    if (!!snippet.title) {
      formData.append('title', snippet.title);
    }
    if (!!snippet.description) {
      formData.append('description', snippet.description);
    }
    formData.append('embedInDocument', snippet.embedInDocument);
    formData.append('linkToView', snippet.linkToView);
    formData.append('linkToDownload', snippet.linkToDownload);
    if (!!snippet.width) {
      formData.append('width', snippet.width);
    }
    if (files && !!files[0]) {
      formData.append('File', files && files[0]);
    }

    let req;
    let url;
    if (snippet.suid) {
      url = `/apiV2/topicPages/snippets/file/${snippet.suid}`;
      req = RequestService.put(url, formData, true)
    } else {
      url = `/apiV2/topicPages/${pageSuid}/snippets/file`;
      req = RequestService.post(url, formData, true)
    }

    const resp = await req;
    if (resp.resultCode === 0) {
      if (event.submitter.value === 'close') {
        onCancel();
      }

      dispatch('snippetadded');
    }
  }

  function onCancel() {
    dispatch('cancelsnippetupdate', { snippet });
    dispatch('clearcustomstyle');
  }

  function onClear() {
    if (files && files[0]) {
      fileInputRef.value = null;
    }
    uploadImageContainerRef.innerHTML = '';

    snippet = {
      title: '',
      description: '',
      embedInDocument: false,
      linkToView: false,
      linkToDownload: false,
      width: '',
      suid: snippet.suid || '',
    }
  }
</script>

<style lang="scss">
  .file-snippet-form {
    border: solid 1px #bed6eb;
    display: flex;
    flex-direction: column;
    text-transform: capitalize;

    .body {
      display: flex;
      flex-direction: column;
      padding: 10px;

      .form-field-label {
        max-width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 5px;

        input[type='text'], textarea {
          width: 80%;
        }

        input[type='checkbox'] {
          margin-right: 10px;
          line-height: 0;
        }

        .file-name-field {
          padding-right: 20px;
        }
      }

      .field-with-note > span {
        display: flex;
        flex-direction: column;
        width: 80%;

        input[type='text'] {
          width: 100%;
        }

        span {
          text-transform: none;
          font-size: 14px;
        }
      }

      .hide {
        display: none;
      }

      .checkbox-field {
        justify-content: flex-start;
        cursor: pointer;
      }

      .button-wrap {
        margin-top: 10px;
      }
    }
  }
</style>

<form class="file-snippet-form" on:submit|preventDefault={onSaveFileSnippet} on:paste={onAddFile}>
  <div class="body">
    <label class="form-field-label">Title (Optional)
      <input type="text" bind:value={snippet.title}>
    </label>
    <label class="form-field-label">Description (Optional)
      <textarea bind:value={snippet.description} id="{snippetTabActive}" rows="5"></textarea>
    </label>

    <label class="form-field-label checkbox-field">
      <input type="checkbox" bind:checked={snippet.embedInDocument}>
      Embed file content in page
    </label>
    <label class="form-field-label checkbox-field">
      <input type="checkbox" bind:checked={snippet.linkToView}>
      Display link to view file in a new tab
    </label>
    <label class="form-field-label checkbox-field">
      <input type="checkbox" bind:checked={snippet.linkToDownload}>
      Display link to download file
    </label>

    <label class="form-field-label field-with-note" for="{'width-control_' + snippetTabActive}">Width (Optional)
      <span>
      <input id="{'width-control_' + snippetTabActive}" type="text" bind:value={snippet.width}>
        <span>Note: Width can be specified in pixels ("px") or percentage ("%")</span>
      </span>
    </label>
    <label class="form-field-label field-with-note" for="{'files_' + snippetTabActive}">File to upload

      <span style="width: 80%">
        <span style="display: flex; flex-direction: row;">
      {#if snippet.fileName}
        <span class="file-name-field">File name: {snippet.fileName}</span>
      {/if}
          <input id="{'files_' + snippetTabActive}" bind:this={fileInputRef} bind:files
                 on:change={onFileUpdated}
                 required={!snippet.fileName}
                 type="file">
        </span>
        <span>Note: Use Ctrl-V(Cmd-V) combination or input button to add file</span>
      </span>
    </label>
    <label for="insert-file" class="form-field-label {isImage ? 'form-field-label' : 'form-field-label hide'}">
      <span></span>
      <span bind:this={uploadImageContainerRef} style="width: 80%">
          {#if snippet && snippet.suid && snippet.snippetType === 2}
            <img alt={snippet.fileName} width='100' src={'/api/ReadFile2/' + snippet.suid}/>
          {/if}
        </span>
    </label>
    <div class="button-wrap">
      {#if editSnippetSelected}
        <button value="update" type="submit">Update</button>
        <button value="close" type="submit">Update & Close</button>
      {:else}
        <button value="update" type="submit">Upload</button>
        <button value="close" type="submit">Upload & Close</button>
      {/if}
      <button on:click={onClear} type="button">Clear</button>
      <button on:click={onCancel} type="button">Close</button>
    </div>
  </div>
</form>
