<script>
  import FormItemWithOverride from "../../form-helpers/FormItemWithOverride.svelte";
  import FormItemWithLabel from "../../form-helpers/FormItemWithLabel.svelte";
  import FormTextInput from "../../form-helpers/FormTextInput.svelte";
  import FormSectionHeading from "../../form-helpers/FormSectionHeading.svelte";
  
  export let isCreateMode;

  export let allowOverrideGitHostDisplayName;
  export let overrideGitHostDisplayName;
  export let gitHostDisplayName;
  export let defaultGitHostDisplayName;
  
  export let allowOverrideGitRepoPattern
  export let overrideGitRepoPattern;
  export let gitRepoPattern;
  export let defaultGitRepoPattern;
  
  export let allowOverrideHostPath;
  export let overrideHostPath;
  export let hostPath;
  export let defaultHostPath;
</script>

<FormSectionHeading label="Git Host Information (Advanced)" />

{#if allowOverrideGitHostDisplayName}
  <FormItemWithOverride
    label="Override Git Host Display Name"
    defaultValue={defaultGitHostDisplayName}
    disabled={false}
    bind:overridden={overrideGitHostDisplayName}
    bind:value={gitHostDisplayName}
  />
{:else}
  <FormItemWithLabel label="Git Host Display Name" required={true}>
    <FormTextInput bind:value={gitHostDisplayName} required={true} disabled={false} />
  </FormItemWithLabel>
{/if}

{#if allowOverrideGitRepoPattern}
  <FormItemWithOverride
    label="Override Git Clone URL"
    defaultValue={defaultGitRepoPattern}
    disabled={!isCreateMode}
    bind:overridden={overrideGitRepoPattern}
    bind:value={gitRepoPattern}
  />
{:else}
  <FormItemWithLabel label="Git Clone URL" required={true}>
    <FormTextInput bind:value={gitRepoPattern} required={true} disabled={!isCreateMode} />
  </FormItemWithLabel>
{/if}

{#if allowOverrideHostPath}
  <FormItemWithOverride
    label="Override Git View URL"
    defaultValue={defaultHostPath}
    disabled={false}
    bind:overridden={overrideHostPath}
    bind:value={hostPath}
  />
{:else}
  <FormItemWithLabel label="Git View URL" required={true}>
    <FormTextInput bind:value={hostPath} required={true} disabled={false} />
  </FormItemWithLabel>
{/if}
