<script>
  export let label = "";
  export let required = false;
  export let validator = () => true;
</script>

<style lang="scss">
  td.field-label {
    width: 200px;
  }
  
  td.field-input {
  }
  
  td.invalid {
    color: red;
  }
</style>

<tr>
  {#if required && validator()}
    <td class="field-label">{label} *:</td>
  {:else if required && !validator()}
    <td class="field-label invalid">{label} * (required):</td>
  {:else}
    <td class="field-input">{label}:</td>
  {/if}
  <td><slot /></td>
  <td></td>
</tr>  
