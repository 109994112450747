<script>
  import TopicPagePropertiesViewer from "./TopicPagePropertiesViewer.svelte";
  import MappedPagePropertiesViewer from "./MappedPagePropertiesViewer.svelte";
  import VirtualPagePropertiesViewer from "./VirtualPagePropertiesViewer.svelte";

  export let page = null;
</script>

{#if page && page.isTopicPage}
  <TopicPagePropertiesViewer page={page} />
{:else if page && page.isMappedPage}
  <MappedPagePropertiesViewer page={page} />
{:else if page && page.isVirtualPage}
  <VirtualPagePropertiesViewer page={page} />
{/if}

