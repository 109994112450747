<script>
  import { createEventDispatcher } from 'svelte';
  import { LoginService } from "./LoginService";
  import { onDestroy } from 'svelte';

  export let loginState;

  let isValidData = true;
  let isSuccessRequest = false;
  let registerForm;
  let errMessage = '';
  const dispatch = createEventDispatcher();

  resetForm();

  onDestroy(() => {
    resetForm();
    isValidData = true;
    isSuccessRequest = false;
  });

  function resetForm() {
    registerForm = {
      // invitationCode: '',
      userName: '',
      email: '',
      fullName: '',
      password: '',
      confirmPassword: '',
    }
  }

  async function onRegister() {
    if (registerForm.password !== registerForm.confirmPassword) {
      isValidData = false;
      errMessage = 'The passwords you entered do not match.';
      return;
    }

    const resp = await LoginService.register(registerForm);

    if (resp.resultCode === 0) {
      isSuccessRequest = true;
    } else {
      isSuccessRequest = false;
      isValidData = false;
      errMessage = resp.resultMessage;
    }
  }

  function onSignIn() {
    dispatch('changeLoginState', loginState.SignIn);
  }

  function onRequestInvite() {
    dispatch('changeLoginState', loginState.Invite);
  }
</script>

<style lang="scss">
  .login-form {

    h1 {
      margin-bottom: 20px;
    }

    form {
      .login-form-field {
        .err-message {
          color: #85096a;
          font-size: 12px;
        }
      }
    }

    p {
      margin-bottom: 50px;
    }
  }
</style>

<div class="login-form">
  <h1>Create an account</h1>
  {#if !isSuccessRequest}
    <form on:submit|preventDefault={onRegister}>
      <label>
        <input class="input-field"
               type="text"
               placeholder="Login Id"
               bind:value={registerForm.userName}
               required
               minlength="4"
               maxlength="32"
               pattern="^[A-Za-z\w]+$"
               title="Login Id should only contain uppercase & lowercase letters, numbers, and the underscore character">
      </label>
      <label>
        <input class="input-field"
               type="email"
               placeholder="E-mail Address"
               bind:value={registerForm.email}
               required
               pattern="\S+@\S+\.\S+"
               minlength="7"
               title="The E-Mail Address should be in the format anystring@anystring.anystring">
      </label>
      <label>
        <input class="input-field"
               type="text"
               placeholder="Full Name"
               minlength="5"
               bind:value={registerForm.fullName}
               required>
      </label>
      <label>
        <input class="input-field"
               type="password"
               placeholder="Password"
               minlength="5"
               bind:value={registerForm.password}
               required>
      </label>
      <div class="login-form-field">
        <label>
          <input class="input-field"
                 type="password"
                 minlength="5"
                 placeholder="Confirm Password"
                 bind:value={registerForm.confirmPassword}
                 required>
        </label>
        {#if !isValidData}
          <div class="err-message">{errMessage}</div>
        {/if}
      </div>
      <button type="submit" class="submit-btn">Create an account</button>
    </form>
  {:else}
    <p>
      Welcome to OpenSquiggly. A new user account has been created and a confirmation e-mail has been sent to your
      e-mail address. Please login with your login id and password.
    </p>
  {/if}
  {#if !isSuccessRequest}
    <span class="or-text">or</span>
  {/if}
  <button type="button" class="go-to-btn" on:click="{onSignIn}">Sign in</button>
</div>
