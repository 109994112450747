<script>
  import { createEventDispatcher } from "svelte";
  import TimeStamp from '../page-options/TimeStamp.svelte';
  import Accordion from "../layout-helpers/Accordion.svelte";
  import Accordions from "../layout-helpers/Accordions.svelte";
  import UserOptionsService from "../user-options/UserOptionsService";
  import PageOptionsService from "./PageOptionsService";
  import ReferencedPages from "./ReferencedPages.svelte";
  import GeneralPageOptions from "./GeneralPageOptions.svelte";
  import OverrideablePageOptions from "../../misc/OverrideablePageOptions";
  import OverrideableTimeStampOptions from "../../misc/OverrideableTimeStampOptions";
  
  export let selectedPage;
  export let overrideablePageOptions;
  export let overrideableTimeStampOptions;

  const dispatch = createEventDispatcher();

  let ownedPageCollections;
  let pageCollections = [];
  let lastSavedTabId = '';
  let openedAccordion = 1;
  let currentOverrideablePageOptions;
  let currentOverrideableTimeStampOptions;
  let tabStates = [
    { id: 0, isOpened: false },
    { id: 1, isOpened: false },
    { id: 2, isOpened: false },
    { id: 3, isOpened: false },
  ];

  $: currentOverrideablePageOptions = new OverrideablePageOptions(overrideablePageOptions);
  $: currentOverrideableTimeStampOptions = new OverrideableTimeStampOptions(overrideableTimeStampOptions);
  
  $: {
    const selectedPageId = selectedPage.pageLocator.pageId || selectedPage.jsonData.pageLocator && selectedPage.jsonData.pageLocator.pageId;
    fetchPageCollections(selectedPageId);
    fetchOwnedPageCollections();
  }
  
  const toggleAccordion = (e) => {
    openedAccordion = e.detail === openedAccordion ? 0 : e.detail;

    if (!lastSavedTabId) {
      tabStates[e.detail].isOpened = !tabStates[e.detail].isOpened;
      lastSavedTabId = e.detail;
    } else if (lastSavedTabId === e.detail) {
      tabStates[e.detail].isOpened = !tabStates[e.detail].isOpened;
      lastSavedTabId = '';
    } else {
      tabStates[e.detail].isOpened = !tabStates[e.detail].isOpened;
      tabStates[lastSavedTabId].isOpened = !tabStates[lastSavedTabId].isOpened;
      lastSavedTabId = e.detail;
    }
  };

  async function fetchOwnedPageCollections() {
    const response = await UserOptionsService.getOwnedPageCollections();

    if (response.resultCode === 0) {
      ownedPageCollections = response.result;
    } else {
      console.error('Error: ', response.resultMessage);
    }
  }

  async function fetchPageCollections(pageId) {
    const response = await PageOptionsService.getPageCollections(pageId);

    if (response.resultCode === 0) {
      pageCollections = response.result;
    } else {
      console.error('Error: ', response.resultMessage);
    }
  }

  async function onUpdatePageOptions(data) {
    const selectedPageId = selectedPage.pageLocator.pageId || selectedPage.jsonData.pageLocator && selectedPage.jsonData.pageLocator.pageId;
    const updateData = {
      ...currentOverrideablePageOptions.getPlainObject(),
      ...currentOverrideableTimeStampOptions.getPlainObject(),
      ...data.detail
    };
    
    const resp = await PageOptionsService.updatePageOptions(selectedPageId, updateData);

    if (resp.resultCode === 0) {
      dispatch("pageoptionschanged", data.detail);
    } else {
      console.error('Error: ', resp.resultMessage);
    }
  }
</script>

<style lang="scss">
  .options-section {
    width: 100%;
    height: 100%;
    transition: height 0.1s linear;
    overflow-y: auto;
  }

  .accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .material-icons-outlined {
      font-size: 30px;
    }
  }
</style>

<div class="options-section">
  <h2 class="mb-4">Edit Page Properties</h2>
  <Accordions>
    <Accordion id="{tabStates[1].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>General</h3>
        {#if tabStates[ 1 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <GeneralPageOptions
        overrideablePageOptions={currentOverrideablePageOptions}
        on:updatepageoptions={onUpdatePageOptions}
      />
    </Accordion>

    <Accordion id="{tabStates[2].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Time Stamp Display</h3>
        {#if tabStates[ 2 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <TimeStamp
        overrideablePageOptions={currentOverrideablePageOptions}
        overrideableTimeStampOptions={currentOverrideableTimeStampOptions}
        on:updatepageoptions={onUpdatePageOptions}
      />
    </Accordion>

    <Accordion id="{tabStates[3].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Referenced By Pages</h3>
        {#if tabStates[ 3 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <ReferencedPages
        selectedPageId={selectedPage.pageLocator.pageId || selectedPage.jsonData.pageLocator && selectedPage.jsonData.pageLocator.pageId}
        overrideablePageOptions={currentOverrideablePageOptions}
        on:updatepageoptions={onUpdatePageOptions}
      />
    </Accordion>
    <!-- This is currently broken and also not currently used or needed
    <Accordion id="{tabStates[1].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <span>Page Collections</span>
        {#if tabStates[ 1 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <PageCollections
        pageCollections="{pageCollections}"
        ownedPageCollections="{ownedPageCollections}"
        selectedPageId="{selectedPage.pageLocator.pageId}"
        on:updatePageCollection={getPageCollectionsList}
      />
    </Accordion>
    -->
  </Accordions>
</div>

