<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import ScrollablePane from "../panes/ScrollablePane.svelte";
  import FullHeight from "../layout-helpers/FullHeight.svelte";
  import {LoginService} from "./LoginService";

  const dispatch = createEventDispatcher();
  
  let licenseAgreement = [];
  
  onMount(async () => {
    let result = await LoginService.getLicenseAgreement();
    if (result.resultCode === 0) {
        licenseAgreement = result.result;
    } else {
        licenseAgreement = [];
    }
  });

  function onAcceptAndCloseLicenseAgreement() {
      dispatch("acceptandclose");
  }
  
  function onCloseLicenseAgreement() {
    dispatch("close");
  }
</script>

<div class="m-5 font-sans text-[18px] box-border">
  <h3>Note: Scroll to bottom to accept or close</h3>
  {#each licenseAgreement as section}
    <p>
      {@html section}
    </p>
  {/each}  
  <button type="button" class="go-to-btn font-sans text-[14px]" on:click="{onAcceptAndCloseLicenseAgreement}">
    Accept & Close
  </button>
  <button type="button" class="go-to-btn font-sans text-[14px]" on:click="{onCloseLicenseAgreement}">
    Close
  </button>
</div>  
