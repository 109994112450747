import { MountPoint } from "./MountPoint";
import {
  CONNECTION_TYPE, DEFAULT_GIT_REPO_PATTERN_LIST,
  GIT_ACCESS_TYPE,
  TREE_FORMAT_TYPE
} from "../constants";

export class ExtendedMountPoint extends MountPoint {
  constructor() {
    super();
    
    this._externalConnections = [];
    this._externalConnection = null;
  }

  static createFromPlainObject(obj) {
    const result = new ExtendedMountPoint();
    result.loadFromPlainObject(obj);
    
    return result;
  }

  loadFromPlainObject(obj) {
    super.loadFromPlainObject(obj);
  }

  toPlainObject() {
    // Note extended properties are not placed in the plain object,
    // therefore the plain object returned from this method can be
    // sent as a DTO to the REST API
    return super.toPlainObject();
  }

  dup() {
    const result = ExtendedMountPoint.createFromPlainObject({ ...this.toPlainObject() });
    result._externalConnections = this._externalConnections;
    result._externalConnection = this._externalConnection;
    
    return result;
  }

  get externalConnections() {
    return this._externalConnections;
  }
  
  set externalConnections(val) {
    this._externalConnections = val;
    this._externalConnection = null;
  }

  get externalConnectionId() {
    return this._externalConnectionId;
  }

  set externalConnectionId(val) {
    this._externalConnectionId = val;
    this._externalConnection = null;
  }
  
  get externalConnection() {
    if (!this._externalConnection) {
      // Lazy-loaded lookup from externalConnections list
      this._externalConnection = this.externalConnections.find(ec => ec.id === this._externalConnectionId);
    }
    
    return this._externalConnection;
  }

  get isPublic() {
    return this.gitAccessType === GIT_ACCESS_TYPE.PUBLIC;
  }

  get isPrivate() {
    return this.gitAccessType === GIT_ACCESS_TYPE.PRIVATE;
  }

  getEffectiveConnectionType() {
    if (this.isPublic) {
      return this.connectionType;
    }
    
    if (this.isPrivate && !!this.externalConnection) {
      return this.externalConnection.connectionType;
    } 
    
    return 0;
  }

  getDefaultGitHostName() {
    const connectionType = this.getEffectiveConnectionType();
    
    if (connectionType <= CONNECTION_TYPE.GENERIC_GIT_HOST) {
      return '';
    } 
    
    if (this.isPublic) {
      return DEFAULT_GIT_REPO_PATTERN_LIST[ connectionType - 2 ].gitHostName;
    }
    
    return this.externalConnection ? this.externalConnection.gitHostDisplayName : '';
  }

  getDefaultGitRepoPattern() {
    const connectionType = this.getEffectiveConnectionType();

    if (connectionType <= CONNECTION_TYPE.GENERIC_GIT_HOST) {
      return '';
    }

    if (this.isPublic) {
      return DEFAULT_GIT_REPO_PATTERN_LIST[connectionType - 2].publicPattern;
    }

    return this.externalConnection ? this.externalConnection.repoUrl : '';
  }

  getDefaultHostPath() {
    const connectionType = this.getEffectiveConnectionType();

    if (connectionType <= CONNECTION_TYPE.GENERIC_GIT_HOST) {
      return '';
    }

    if (this.isPublic) {
      return DEFAULT_GIT_REPO_PATTERN_LIST[connectionType - 2].pattern;
    }

    return DEFAULT_GIT_REPO_PATTERN_LIST[connectionType - 2].pattern;;    
  }

  getEffectiveOrgName(externalConnection) {
    if (this.overrideOrgName) {
      return this.orgName;
    }
    
    if (this.isPublic) {
      return this.orgName;
    }

    return externalConnection ? externalConnection.orgName : '';
  }
}  
