import { RequestService } from "../../comm/RequestService";

export default class PageOptionsService {

  static getPageCollections(pageId) {
    const url = `/apiV2/pages/${pageId}/page-collections`;

    return RequestService.get(url);
  }

  static addPageToPageCollection(collectionId, pageId) {
    const url = `/apiV2/owned/page/collections/${collectionId}/${pageId}`;

    return RequestService.post(url, {});
  }

  static updatePageOptions(pageId, data) {
    const url = `/apiV2/pages/${pageId}/options`;

    return RequestService.put(url, data);
  }
}
