<script>
  import { FORMAT_TYPE } from "../../snippets/PlainTextSnippet";
  import HtmlUtil from '../../utils/HtmlUtil.js';
  import MarkdownViewer from "./MarkdownViewer.svelte";
  import SourceCodeViewer from "./SourceCodeViewer.svelte";
  import DOMPurify from "dompurify";
  import {onMount, tick} from "svelte";
  import mermaid from 'mermaid';

  export let snippet;
  export let searchData = '';
  export let switchSearchFile$ = {};
  export let switchSourceCodeSearchResults$ = null;

  let htmlElement;
  let snippetClasses = "";
  let styles = "";
  let markdown = "";
  let sanitizedHtml = "";
  
  onMount(async () => {
    const drawDiagram = async function (containerId, element) {
      const { svg } = await mermaid.render(containerId, element.textContent);
      element.innerHTML = svg;
    };

    const appendDiagramTo = (el) => (code) => el.outerHTML = code;

    function renderDiagram(id, el) {
      mermaid.render(
        id,
        el.textContent,
        appendDiagramTo(el)
      )
    }
    
    if (htmlElement) {
      const diagrams = htmlElement.querySelectorAll('.mermaid');
      await mermaid.run({
        nodes: diagrams,
        postRenderCallback: (id) => {
          // console.log("postRenderCallback: id =", id);
        },
        suppressErrors: true
      });

      // for (let i=0; i < diagrams.length; i++) {
      //   let diagram = diagrams[i];
      //   const containerId = `mermaid-${i}`;
      //   // const containerId = Math.random().toString();
      //   await drawDiagram(containerId, diagram);
      // }
      
      // for (let i=0; i < diagrams.length; i++) {
      //   let diagram = diagrams[i];
      //   await new Promise((resolve) => {
      //     // mermaid.run(diagram);
      //     mermaid.init(undefined, diagram);
      //     // const containerId = `mermaid-${i}`;
      //     // mermaid.render(
      //     //   containerId,
      //     //   diagram.textContent
      //     // )
      //     resolve();
      //   });
      // }
    }
  });

  $: if (snippet.formatType === FORMAT_TYPE.Html) {
    let baseHref = document.location.href;

    const term = '<img.+?src=".+?"';
    const re = new RegExp(term, "gmi");

    const index = snippet.suid.lastIndexOf("/");
    const suidUrl = snippet.suid.substr(0, index);

    //search in html content img tags & replace src path
    snippet.content = snippet.content.replace(re, (full) => {
      const indexUrl = full.indexOf('src="') + 5; // 5 - it's length of string ' src=" '
      const firstPart = full.substring(0, indexUrl);
      const lastPart = full.substring(indexUrl, full.length);

      const stringHTML = firstPart + baseHref + 'api/ReadFile2/' + suidUrl + lastPart;

      return `${stringHTML}`
    });
  }

  function getFormatClass(snippet) {
    switch (snippet.formatType) {
      case FORMAT_TYPE.Wrapped:
        return 'osq-format-normal';

      case FORMAT_TYPE.PreFormatted:
        return 'osq-format-preformatted';

      case FORMAT_TYPE.SourceCodeViewer:
        // return 'osq-format-sourcecodeviewer';
        // TODO: This is temporary until CodeMirror is implemented
        return 'osq-format-preformatted';

      case FORMAT_TYPE.Html:
        return 'osq-format-html';

      case FORMAT_TYPE.Markdown:
        return 'osq-format-markdown';

      default:
        return '';
    }
  }

  function getFontClass(snippet) {
    // TODO: Special case handling for SourceCodeViewer until
    // CodeMirror support is implemented
    if (snippet.formatType === FORMAT_TYPE.SourceCodeViewer) {
      return 'osq-font-monospaced';
    }

    switch (snippet.font) {
      case 2:
        return 'osq-font-sanserif';
      case 3:
        return 'osq-font-handwriting';
      case 4:
        return 'osq-font-monospaced';
      case 5:
        return 'osq-font-serif';
      default:
        return 'osq-font-default';
    }
  }

  function getFontSizeClass(snippet) {
    // TODO: Special case handling for SourceCodeViewer until
    // CodeMirror support is implemented
    if (snippet.formatType === FORMAT_TYPE.SourceCodeViewer) {
      return 'osq-fontsize-normal';
    }

    switch (snippet.fontSize) {
      case 2:
        return 'osq-fontsize-header';
      case 3:
        return 'osq-fontsize-subheader';
      case 4:
        return 'osq-fontsize-section';
      default:
        return 'osq-fontsize-normal';
    }
  }

  function getClasses(snippet) {
    return [
      "osq-snippet",
      "osq-snippet-plaintext",
      getFormatClass(snippet),
      getFontClass(snippet),
      getFontSizeClass(snippet)
    ];
  }

  function getStyles(snippet) {
    let style = '';

    if (snippet.bold) {
      style += 'font-weight: bold; ';
    }

    if (snippet.italics) {
      style += 'font-style: italic; ';
    }

    if (snippet.underline) {
      style += 'text-decoration: underline;';
    }

    return style;
  }

  $: if (snippet) {
    snippetClasses = getClasses(snippet);
    styles = getStyles(snippet);
  }

  $: if (snippet && snippet.formatType === FORMAT_TYPE.Html) {
    DOMPurify.addHook("uponSanitizeElement", (node, data) => {
      if (data.tagName === "iframe") {
        const src = node.getAttribute("src") || "";

        const listOfAvailableServiceLinks = ['https://www.youtube.com/embed/', 'https://www.loom.com/embed/'];
        const isSourceIncludeSrc = listOfAvailableServiceLinks.some((link) => src.startsWith(link));

        if (!isSourceIncludeSrc) {
          return node.parentNode.removeChild(node);
        }
      }
    });
    sanitizedHtml = DOMPurify.sanitize(snippet.content, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling", "target"],
    });
  }
</script>

<div bind:this={htmlElement} class="snippet-content">
  <div class={snippetClasses.join(" ")} style={styles}>
    {#if snippet.formatType === FORMAT_TYPE.Wrapped}
      {@html HtmlUtil.htmlEscape(snippet.content)}
    {:else if snippet.formatType === FORMAT_TYPE.PreFormatted}
      {@html HtmlUtil.htmlEscape(snippet.content)}
    {:else if snippet.formatType === FORMAT_TYPE.SourceCodeViewer}
      <SourceCodeViewer 
        {searchData}
        {switchSourceCodeSearchResults$}
        {switchSearchFile$}
        content="{snippet.content}"
        extensionType="{snippet.extensionType}"
        displayLineNumbers="{snippet.displayLineNumbers}"
      />
    {:else if snippet.formatType === FORMAT_TYPE.Markdown}
      <MarkdownViewer content="{snippet.content}" suid="{snippet.suid}"/>
    {:else if snippet.formatType === FORMAT_TYPE.Html}
      {@html sanitizedHtml}
    {:else}
      <span>Error: Unrecognized format type in PlainTextSnippetViewer</span>
    {/if}
  </div>
</div>  
