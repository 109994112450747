<script>
  import MaterialIcon from "../layout-helpers/MaterialIcon.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();
  let files;

  function onMousedown(evt) {
    dispatch('begindragsnippet', evt);
  }

  function onRemove() {
    dispatch('removesnippet');
  }

  function onEdit() {
    dispatch('editsnippet');
  }  
</script>

<style lang="scss">
  .snippet-row-control {
    border: #bed6eb 1px solid;
    width: 70px;

    .action-button-wrap {
      display: flex;
      align-items: center;

      .action-button {
        display: flex;
        align-items: center;
      }
    }
  }
</style>

<td class="snippet-row-control">
  <div class="action-button-wrap">
    <div class="action-button">
      <MaterialIcon iconName="mode_edit" on:click={onEdit} title={'Edit Snippet'}/>
    </div>
    <div class="action-button">
      <MaterialIcon iconName="clear" on:click={onRemove} title={'Remove Snippet'}/>
    </div>
    <div class="action-button" on:mousedown={onMousedown}>
      <MaterialIcon iconName="drag_handle" cursorType="move" title={'Drag Snippet'}/>
    </div>
  </div>
</td>
