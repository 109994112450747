export class TocEntry {
  constructor(entryId, type, pageSuid, title) {
    this._entryId = entryId;
    this._type = type;
    this._pageSuid = pageSuid;
    this._title = title;
  }
  
  static createFromTreePageDto(dto) {
    // dto is the client-side JavaScript version of the server's
    // TreePageDTOV4 object, returned by calling /apiv2/tocentry/read.
    return new TocEntry(
      dto.tocEntryId,
      dto.tocEntryType,
      dto.suid,
      dto.title // We should really differentiate between the page title and the toc title, which could be different
    );
  }
  
  dup() {
    return new TocEntry(
      this.entryId,
      this.type,
      this.pageSuid,
      this.title
    );
  }
  
  get entryId() {
    return this._entryId;
  }
  
  get type() {
    return this._type;
  }
  
  get pageSuid() {
    return this._pageSuid;
  }
  
  get title() {
    return this._title;
  }
  
  set entryId(val) {
    this._entryId = val;
  }
  
  set type(val) {
    this._type = val;
  }
  
  set pageSuid(val) {
    this._pageSuid = val;
  }
  
  set title(val) {
    this._title = val;
  }
}
