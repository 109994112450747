<script>
  import ContainerSnippetEditor from "./ContainerSnippetEditor.svelte";
  import DrawingSnippetEditor from "./DrawingSnippetEditor.svelte";
  import FileSnippetEditor from "./FileSnippetEditor.svelte";
  import PlainTextSnippetEditor from "./PlainTextSnippetEditor.svelte";
  import FileReferenceSnippetEditor from "./FileReferenceSnippetEditor.svelte";

  export let snippet;
  export let isTopicPage;
  export let snippetExpanded;
</script>

{#if snippet && snippet.snippetType === 1}
  <PlainTextSnippetEditor 
    {isTopicPage}
    {snippet}
    {snippetExpanded}
    on:removesnippet
    on:editsnippet
  />
{:else if snippet && snippet.snippetType === 2}
  <FileSnippetEditor 
    snippet={snippet}
    on:removesnippet
    on:editsnippet
    {snippetExpanded}
  />
{:else if snippet && snippet.snippetType === 5}
  <DrawingSnippetEditor snippet={snippet} />
{:else if snippet && snippet.snippetType === 6}
  <ContainerSnippetEditor snippet={snippet} />
{:else if snippet && snippet.snippetType === 7}  
  <FileReferenceSnippetEditor 
    snippet={snippet}
    snippetExpanded={snippetExpanded}
    on:removesnippet
    on:editsnippet
  />
{/if}  
