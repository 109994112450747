export const PAGE_LOCATOR_TYPE = {
  TopicPage: 1,
  VirtualPage: 2
};

class PageLocator {
  constructor(data) {
    this.pageLocatorType = data.pageLocatorType;
    this.pageId = data.pageId ? data.pageId.value : "";
    this.mountPointId = data.mountPointId ? data.mountPointId.value : "";
    this.relativePath = data.relativePath;
  }
}

export default PageLocator;
