<script>
  import { createEventDispatcher } from 'svelte';
  import { LoginService } from "./LoginService";
  import { onDestroy } from 'svelte';

  export let loginState;
  export let resetKey;

  let isValidData = true;
  let isSuccessRequest = false;
  let resetPasswordForm;
  let errMessage = '';

  const dispatch = createEventDispatcher();
  
  resetForm();

  onDestroy(() => {
    resetForm();
    isValidData = true;
    isSuccessRequest = false;
  });

  function resetForm() {
    resetPasswordForm = {
      password: '',
      confirmPassword: '',
    }
  }

  async function onResetPassword() {
    if (resetPasswordForm.password !== resetPasswordForm.confirmPassword) {
      isValidData = false;
      errMessage = 'The passwords you entered do not match.';
      return;
    }
    let reqData = {
      resetKey,
      ...resetPasswordForm
    };
    const resp = await LoginService.resetPassword(reqData);
    if (resp.resultCode === 0) {
      isSuccessRequest = true;

      const newUrl = document.location.protocol + "//" + document.location.host + '/Account/Login';
      history.pushState({ urlString: 'Account/Login' }, '', newUrl);
    } else {
      isSuccessRequest = false;
      isValidData = false;
      errMessage = resp.resultMessage;
    }
  }

  function onSignIn() {
    dispatch('changeLoginState', loginState.SignIn);
  }
</script>

<style lang="scss">
  .login-form {
    p {
      margin-bottom: 50px;
    }
  }
</style>

<div class="login-form px-[20%]">
  <h1>Reset password</h1>
  {#if !isSuccessRequest}
    <form on:submit|preventDefault={onResetPassword}>
      <label>
        <input
          type="password"
          placeholder="New Password"
          minlength="5"
          bind:value={resetPasswordForm.password}
          required>
      </label>
      <div class="login-form-field">
        <label>
          <input
            type="password"
            minlength="5"
            placeholder="Confirm New Password"
            bind:value={resetPasswordForm.confirmPassword}
            required>
        </label>
        {#if !isValidData}
          <div class="err-message">{errMessage}</div>
        {/if}
      </div>
      <button type="submit" class="submit-btn">Reset password</button>
    </form>
  {:else}
    <p>
      Success. Your password was updated. Please login with your login id and new password.
    </p>
  {/if}
  {#if !isSuccessRequest}
    <span class="or-text">or</span>
  {/if}
  <button type="button" class="go-to-btn" on:click="{onSignIn}">Sign in</button>
</div>
