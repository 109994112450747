<script>
  import { afterUpdate } from 'svelte';
  import hljs from 'highlight.js';
  import 'highlight.js/styles/xcode.css';
  import js from 'highlight.js/lib/languages/javascript.js';
  import csharp from 'highlight.js/lib/languages/csharp.js';
  import php from 'highlight.js/lib/languages/php.js';
  import c from 'highlight.js/lib/languages/c.js';
  import cpp from 'highlight.js/lib/languages/cpp.js';
  import java from 'highlight.js/lib/languages/java.js';
  import python from 'highlight.js/lib/languages/python.js';
  import perl from 'highlight.js/lib/languages/perl.js';
  import ruby from 'highlight.js/lib/languages/ruby.js';
  import lisp from 'highlight.js/lib/languages/lisp.js';
  import swift from 'highlight.js/lib/languages/swift.js';
  import typescript from 'highlight.js/lib/languages/typescript.js';
  import bash from 'highlight.js/lib/languages/bash.js';

  hljs.registerLanguage('javascript', js);
  hljs.registerLanguage('csharp', csharp);
  hljs.registerLanguage('php', php);
  hljs.registerLanguage('c', c);
  hljs.registerLanguage('cpp', cpp);
  hljs.registerLanguage('java', java);
  hljs.registerLanguage('python', python);
  hljs.registerLanguage('perl', perl);
  hljs.registerLanguage('ruby', ruby);
  hljs.registerLanguage('lisp', lisp);
  hljs.registerLanguage('swift', swift);
  hljs.registerLanguage('typescript', typescript);
  hljs.registerLanguage('bash', bash);

  import marked from 'marked';

  export let content;
  export let suid;
  const onRenderMarkdown = () => {
    markdown = getRenderedMarkdown(content, suid);
  };

  let markdown = '';
  let renderContentClass;

  afterUpdate(() => {
    onRenderMarkdown();
  });

  function getRenderedMarkdown(content, suid) {
    const tokenizer = {
      link(src) {
        const match = src.match(/^!?\[(?<label>(?:\[(?:\\.|[^\[\]\\])*]|\\.|`[^`]*`|[^\[\]\\`])*?)]\(\s*(?<href><(?:\\.|[^\n<>\\])+>|[^\s\x00-\x1f]*)\s+(?:=(?<width>\d+)x(?<height>\d+)?)\s*\)/);
        if (match) {
          return {
            type: 'image',
            raw: match[ 0 ],
            href: match.groups.href,
            text: match.groups.label,
            title: { width: match.groups.width, height: match.groups.height }
          };
        }

        return false;
      }
    };

    const renderer = new marked.Renderer();
    renderer.image = (href, title, text) => {
      const index = suid.lastIndexOf("/");
      let pathSrc = '';
      if (href.startsWith('http')) {
        pathSrc = href;
      } else {
        pathSrc = '/api/ReadFile2/' + suid.substr(0, index) + href.substr(1);
      }

      let extra = '';
      if (title) {
        extra = ` title="${title}"`;
        if (title.width) {
          extra = ` width="${title.width}"`;
        }
        if (title.height) {
          extra += ` height="${title.height}"`
        }
      }

      return `<img src="${pathSrc}" alt="${text}"${extra}>`;
    };

    const connectedLangList = ['javascript', 'csharp', 'php', 'c', 'cpp', 'java', 'python', 'perl', 'ruby', 'lisp', 'swift', 'typescript', 'bash'];

    function getSupportedLanguage(lang) {
      if (connectedLangList.some((l) => l === lang)) {
        return lang;
      } else {
        null;
      }
    }

    hljs.configure({
      languages: connectedLangList
    });
    marked.setOptions({
      highlight: (code, lang) => {
        let codeLang = getSupportedLanguage(lang);
        return codeLang ? hljs.highlight(code, { language: getSupportedLanguage(lang) }).value : null;
      },
    });
    marked.use({ tokenizer, renderer });

    return marked(content);
  }

  function renderContentAsMarkdown(content, target, suid) {
    target.innerHTML = getRenderedMarkdown(content, suid);

    const elements = target.getElementsByTagName('code');
    Array.prototype.forEach.call(elements, (block) => {
      hljs.highlightBlock(block);
    });
  }
</script>

<div class="markdown-viewer">
  {@html markdown}
</div>
