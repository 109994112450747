<script>
  import UserOptionsService from "../user-options/UserOptionsService";

  export let userOptionsEmail;

  async function onSendTestEmailMessage() {
    const response = await UserOptionsService.sendTestEmail();

    if (response.resultCode === 0) {
      // console.log(response.result);
    } else {
      console.error('Error: ', response.resultMessage);
    }
  }
</script>

<div>Your registered E-Mail address is:
  <span class="ui-dialog-content"><b>{userOptionsEmail}</b></span>
</div>
<br>
<p>It's important that OpenSquiggly has a current e-mail address for your account. Your e-mail address is
  needed to reset your password if you forget it, and to notify you of important events. For example, if
  you are added as a member of another user's group, we will notify you via e-mail.</p>
<p>To verify that you will receive e-mail messages from us, click the button below to have the system send a
  test e-mail message.</p>
<button type='button' on:click={onSendTestEmailMessage}>Send Test E-Mail Message</button>
<p>After sending the test e-mail, verify that you receive it in your e-mail system's inbox. You'll want to
  verify that the e-mail message is not being blocked by any spam e-mail filters, etc.</p>
