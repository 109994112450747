<script>
  import { createEventDispatcher } from 'svelte';
  import { RequestService } from "../../comm/RequestService";
  import CodeMirrorWrapper from "../snippet-viewers/CodeMirrorWrapper.svelte";
  import { FORMAT_TYPE } from "../../snippets/PlainTextSnippet";
  import { containerClasses } from "./containerClasses";

  export let snippet;
  export let pageSuid;
  export let editSnippetSelected = false;
  let editor;
  let content = '';
  let options;
  let tempOptions;
  let currentSnippet;
  let snippetOptions;
  let textEditorContainerRef;

  $: {
    if (snippet && snippet.formatType >= 0) {
      updateContent();
    }
  }

  function updateContent() {
    if (!currentSnippet) {
      currentSnippet = snippet;
      content = snippet.content;
    }

    if (currentSnippet.content !== snippet.content) {
      currentSnippet = snippet;
      content = snippet.content;
    }
  }

  $: options = {
    mode: "text",
    lineNumbers: true,
    lineWrapping: false,
    theme: 'default',
    value: content || '',
    scrollbarStyle: 'native',
    extraKeys: {
      'Shift-Enter': (cm) => {
        onSaveTextSnippet(null, cm.getValue())
      },
      'Cmd-Enter': (cm) => {
        onSaveTextSnippet(null, cm.getValue())
      },
    },
  };
  $: options.lineWrapping = snippet.formatType === 0;

  const breakpoints = {
    400: ["container-width-sm"],
    800: ["container-width-md"],
    1065: ["container-width-lg"]
  };

  const dispatch = createEventDispatcher();
  const formatTypes = [
    { name: 'Markdown', value: 4 },
    { name: 'HTML', value: 3 },
    { name: 'Preformatted', value: 1 },
    { name: 'Source Code Viewer', value: 2 },
    { name: 'Wrapped', value: 0 },
  ];
  const fontTypes = [
    { name: 'Default', value: 1 },
    { name: 'Default Handwriting', value: 3 },
    { name: 'Default Monospaced', value: 4 },
    { name: 'Default San Serif', value: 2 },
    { name: 'Default Serif', value: 5 },
  ];
  const fontSizes = [
    { name: 'Normal', value: 1 },
    { name: 'Header', value: 2 },
    { name: 'Subheader', value: 3 },
    { name: 'Section', value: 4 },
  ];
  const extensionTypes = [
    { name: "None", value: "none" },
    { name: "C", value: "c" },
    { name: "C#", value: "cs" },
    { name: "C++", value: "cpp" },
    { name: "CSS", value: "css" },
    { name: "HTML", value: "html" },
    { name: "Java", value: "java" },
    { name: "JavaScript", value: "js" },
    { name: "Markdown", value: "md" },
    { name: "PHP", value: "php" },
    { name: "Python", value: "py" },
    { name: "Ruby", value: "rb" },
    { name: "XML", value: "xml" },
    { name: "SCSS", value: "scss" },
    { name: "Swift", value: "swift" },
  ];

  async function onSaveTextSnippet(event, currentContent) {
    let req;
    let url;
    const data = {
      content: currentContent,
      extensionType: currentSnippet.extensionType,
      formatType: currentSnippet.formatType,
      font: currentSnippet.font,
      fontSize: currentSnippet.fontSize,
      bold: currentSnippet.bold,
      italics: currentSnippet.italics,
      underline: currentSnippet.underline,
      displayLineNumbers: currentSnippet.displayLineNumbers,
      beginSection: true,
      viewportMargin: 5,
    };

    if (snippet.suid) {
      url = `/apiV2/topicPages/${pageSuid}/snippets/plaintext/${snippet.suid}`;
      req = RequestService.put(url, data)
    } else {
      url = `/apiV2/topicPages/${pageSuid}/snippets/plaintext`;
      req = RequestService.post(url, data)
    }

    const resp = await req;

    if (resp.resultCode === 0) {
      if (textEditorContainerRef && textEditorContainerRef.submitter === 'close') {
        onClose();
      }

      if (!editSnippetSelected) {
        content = '';
        editor.setValue('');
      }
      
      dispatch('snippetadded');
    }
  }

  function onClose() {
    dispatch('cancelsnippetupdate', { snippet });
    dispatch('clearcustomstyle');
  }

  function onClear() {
    editor.setValue('');
    editor.focus();
    currentSnippet = {
      content: '',
      formatType: FORMAT_TYPE.Markdown,//markdown
      font: fontTypes[0].value,//Default
      fontSize: fontSizes[0].value,//normal
      bold: false,
      italics: false,
      underline: false,
      displayLineNumbers: false,
    }
  }
</script>

<style lang="scss">
  .add-snippet-wrapper {
    border: solid 1px #bed6eb;
    height: 100%;
  }

  .snippet-options {
    margin: 10px 10px 0;
    display: flex;

    label {
      margin-right: 5px;
    }
  }

  .snippet-content-area {
    flex: 1 1 auto;
    height: calc(100% - 95px);
    position: relative;
    border: 1px solid black;
    margin: 10px;

    :global(.CodeMirror) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
    }
  }

  .save-snippet-buttons-section {
    margin: 0 10px;

    button {
      min-width: 80px;
    }
  }

  :global(.container-width-md) {
    &.snippet-options {
      flex-direction: column;

      div:first-child {
        margin-bottom: 10px;
      }
    }

    &.snippet-content-area {
      height: calc(100% - 125px);
    }
  }

  :global(.container-width-sm) {
    &.snippet-options {
      flex-direction: row;

      .selects-section, .checkboxes-section {
        display: flex;
        flex-direction: column;
      }
    }

    &.snippet-content-area {
      height: calc(100% - 165px);
    }
  }
</style>
{#if currentSnippet}
  <form class="add-snippet-wrapper"
      on:submit|preventDefault={(event) => onSaveTextSnippet(event, editor.getValue())}
      bind:this={textEditorContainerRef}>
    <div use:containerClasses={breakpoints} class="snippet-options">
      <div class="selects-section">
      <label>Format Type:
        <select bind:value={currentSnippet.formatType}>
          {#each formatTypes as format}
            <option value="{format.value}">{format.name}</option>
          {/each}
        </select>
      </label>
      {#if currentSnippet.formatType !== 2 && currentSnippet.formatType !== 3}
        <label>Font Style:
          <select bind:value={currentSnippet.font}>
            {#each fontTypes as font}
              <option value="{font.value}">{font.name}</option>
            {/each}
          </select>
        </label>
      {/if}
      {#if currentSnippet.formatType === 0 || currentSnippet.formatType === 1}
        <label>Font Size:
          <select bind:value={currentSnippet.fontSize}>
            {#each fontSizes as size}
              <option value="{size.value}">{size.name}</option>
            {/each}
          </select>
        </label>
      {/if}
    </div>
    <div class="checkboxes-section">
      {#if currentSnippet.formatType === 0 || currentSnippet.formatType === 1}
        <label>
          <input type="checkbox" bind:checked={currentSnippet.bold}>Bold
        </label>
        <label>
          <input type="checkbox" bind:checked={currentSnippet.underline}>Underline
        </label>
        <label>
          <input type="checkbox" bind:checked={currentSnippet.italics}>Italics
        </label>
      {/if}
      {#if currentSnippet.formatType === 2}
        <label>
          <input type="checkbox" bind:checked={currentSnippet.displayLineNumbers}>Display Line Numbers
        </label>
        <label>Language:
          <select bind:value={currentSnippet.extensionType}>
            {#each extensionTypes as extType}
              <option value="{extType.value}">{extType.name}</option>
            {/each}
          </select>
        </label>        
      {/if}
    </div>
    </div>
    <div class="snippet-content-area" use:containerClasses={breakpoints}>
      <CodeMirrorWrapper bind:editor {options} />
    </div>
    <div class="save-snippet-buttons-section">
      {#if editSnippetSelected}
        <button on:click={textEditorContainerRef.submitter = 'add'} type="submit">Update</button>
        <button on:click={textEditorContainerRef.submitter = 'close'} type="submit">Update & Close</button>
      {:else}
        <button on:click={textEditorContainerRef.submitter = 'add'} type="submit">Add</button>
        <button on:click={textEditorContainerRef.submitter = 'close'} type="submit">Add & Close</button>
      {/if}
      <button on:click={onClear} type="button">Clear</button>
      <button on:click={onClose} type="button">Close</button>
    </div>
  </form>
{/if}
