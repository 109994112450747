<script>
  import { createEventDispatcher } from "svelte";
  import FormDropdownInput from "../../form-helpers/FormDropdownInput.svelte";
  import FormItemWithLabel from "../../form-helpers/FormItemWithLabel.svelte";
  import FormTextInput from "../../form-helpers/FormTextInput.svelte";
  import {RequestService} from "../../../comm/RequestService";
  
  export let mountPoints;
  let manifestMountPointId = null;
  let pathToManifest = '';

  const dispatch = createEventDispatcher();
  
  async function saveData(event) {
    const data = {
      manifestMountPointId,
      pathToManifest
    };
    
    const requestUrl = `/apiV2/mountpoint/import`;
    const response = await RequestService.post(requestUrl, data);

    if (response.resultCode === 0) {
      // console.log("Successfully saved");
      // dispatch("mountpointadded", mountPointToSave);
    } else {
      console.error('Error Starting Import:', response.resultMessage);
    }
  }
  
  function onCancel() {
    dispatch('cancel');
  }
</script>

<style lang="scss">
  section {
    border: 1px solid gray;
    width: 620px;

    .headline {
      border-bottom: 1px solid gray;
      padding: 5px 10px;
    }

    form {
      .form-body {
        padding: 10px;

        //input[type="url"] {
        //  width: 100%;
        //}

        //label {
        //  position: relative;
        //}

        //label:last-child {
        //  display: flex;
        //  align-items: flex-end;
        //  width: fit-content;
        //  // width: -moz-fit-content;
        //}

        //.error {
        //  position: absolute;
        //  color: red;
        //  top: 40px;
        //  left: 0;
        //}

        //p {
        //  padding-top: 5px;
        //}
      }

      .action-buttons {
        border-top: 1px solid gray;
        padding: 5px 10px;
      }
    }
  }
</style>

<section>
  <div class="headline">
    <h4>Import Mount Points from Manifest</h4>
  </div>

  <form id="import-manifest-form" on:submit|preventDefault={(e) => saveData(e)}>
    <div class="form-body">
<!--      <label for="full-path">Public Cloning URL from Git Host: <br>-->
<!--        <input id="full-path" type="url" bind:value={pathInput} required-->
<!--               placeholder="https://test@bitbucket.org/testCompany/testProject.git">-->

<!--        {#if isError}-->
<!--          <span class="error">-->
<!--            Please, enter valid path.-->
<!--          </span>-->
<!--        {/if}-->
<!--      </label>-->
<!--      <p>-->
<!--        Note: This form allows you to quickly add public repositories from GitHub, GitLab, Bitbucket, or Azure DevOps.-->
<!--        Enter the URL of the desired repository from your Git hosting system.-->
<!--      </p>-->
<!--      <label for="auto-create-page">-->
<!--        <input id="auto-create-page" type="checkbox"-->
<!--               bind:checked={isAutoCreatePage}-->
<!--               disabled={!isNavigatorActivePageTopic}-->
<!--        >-->
<!--        Automatically create mapped page to repository in the current page-->
<!--      </label>-->
<!--    </div>-->
        <FormItemWithLabel label="Manifest Mount Point" required={true}>
          <FormDropdownInput bind:value={manifestMountPointId} required={true}>
            {#each mountPoints as mPoint}
              <option value="{mPoint.id}">{mPoint.name}</option>
            {/each}
          </FormDropdownInput>
        </FormItemWithLabel>
        <FormItemWithLabel label="Path to Manifest" required={true} validator={() => pathToManifest.trim() !== ''}>
          <FormTextInput bind:value={pathToManifest} required={true} />
        </FormItemWithLabel>
        <br>
        <div class="action-buttons">
          <button type="submit" name="saveAddBtn">Start Import</button>
          <button type="button" on:click={onCancel}>Cancel</button>
        </div>
    </div>
  </form>
</section>
