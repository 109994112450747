<style lang="scss">
  .flex-item {
    display: flex;
    align-items: center;
    height: 100%;
  }
</style>

<div class="flex-item">
  <slot />
</div>                      
