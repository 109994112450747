<script>
  export let value = "";
  export let rows;
  export let cols;
</script>

<style>
  textarea.field-input {
    width: 100%;
    resize: none;
  }
</style>

<textarea class="field-input" bind:value={value} rows="{rows}" cols="{cols}"></textarea>
