<script>
  export let margin = true;
  export let border = true;
  export let padding = true;
  export let outerBackgroundColor = null;
  export let innerBackgroundColor = null;
  
  function getOuterStyle() {
    return outerBackgroundColor ? "background-color: " + outerBackgroundColor : "";
  }
  
  function getInnerStyle() {
    return innerBackgroundColor ? "background-color: " + innerBackgroundColor : "";
  }
</script>

<style lang="scss">
  .outer-pane {
    overflow: auto;
    box-sizing: border-box;
  }
  
  .without-margin {
    height: 100%;
    max-height: 100%;
    width: 100%;
    margin: 0;
  }

  .with-margin {
    height: calc(100% - 20px);
    max-height: calc(100% - 20px);
    width: calc(100% - 20px);
    margin: 10px;
  }

  .border {
    border: 1px solid black;
  }

  .padding {
    padding: 10px;
  }

  .scrolling-pane {
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
  }
</style>

<!--<div class="outer-pane" class:margin class:border-->
<div class="outer-pane {margin ? 'with-margin' : 'without-margin'} {border ? 'border' : ''}" style={getOuterStyle()}>
  <div class="scrolling-pane" class:padding style={getInnerStyle()}>
    <slot/>
  </div>
</div>
