class TimeStampDisplayOptions {
  constructor(sourceObjectOrJsonData) {
    if (!sourceObjectOrJsonData) {
      this._jsonData = {
        displayDate: false,
        displayTime: false
      };
    } else if (sourceObjectOrJsonData instanceof TimeStampDisplayOptions) {
      this._jsonData = sourceObjectOrJsonData.getPlainObject();
    } else {
      this._jsonData = sourceObjectOrJsonData;
    }
  }
  
  get displayDate() {
    return this._jsonData.displayDate;
  }
  
  set displayDate(val) {
    this._jsonData.displayDate = val;
  }
  
  get displayTime() {
    return this._jsonData.displayTime;
  }
  
  set displayTime(val) {
    this._jsonData.displayTime = val;
  }
  
  getEffectiveDisplayDate() {
    return this.displayDate;
  }
  
  getEffectiveDisplayTime() {
    return this.displayTime;
  }

  getPlainObject() {
    return { ...this._jsonData };
  }
  
  copy(timeStampOptions) {
    this.displayDate = timeStampOptions.displayDate;
    this.displayTime = timeStampOptions.displayTime;
  }
  
  merge(timeStampOptions) {
    // Note: I don't use the spread operator here because I want to ensure
    // that I only bring in known properties associated with this object
    if (timeStampOptions.displayDate !== undefined) {
      this.displayDate = timeStampOptions.displayDate;
    }
    
    if (timeStampOptions.displayTime !== undefined) {
      this.displayTime = timeStampOptions.displayTime;
    }
  }
}

export default TimeStampDisplayOptions;
