<script>
  import { createEventDispatcher } from 'svelte';
  import MaterialIcon from "../layout-helpers/MaterialIcon.svelte";
  import FileSnippetViewer from "../snippet-viewers/FileSnippetViewer.svelte";
  import FileSnippetForm from "./FileSnippetForm.svelte";

  export let snippet;
  export let snippetExpanded;

  const dispatch = createEventDispatcher();
  let files;
  let dragElRef;

  function onMousedown(evt) {
    evt.target.closest('.edit-snippet-table-row').draggable = true;
  }

  function onRemove() {
    dispatch('removesnippet', { snippet });
  }

  function onEdit() {
    dispatch('editsnippet', { snippet });
  }

  function isImageFile() {
    return snippet.mimeType === 'image/gif' ||
      snippet.mimeType === 'image/jpg' ||
      snippet.mimeType === 'image/jpeg' ||
      snippet.mimeType === 'image/png' ||
      snippet.mimeType === 'image/svg+xml';
  }
</script>

<style lang="scss">
  .snippet-row-control {
    border: #bed6eb 1px solid;
    width: 70px;

    .action-button-wrap {
      display: flex;
      align-items: center;

      .action-button {
        display: flex;
        align-items: center;
      }
    }
  }

  td:last-child {
    background-color: #f3f7fb;
    border: #bed6eb 1px solid;
  }

  .snippet-summary {
    display: flex;

    img {
      margin-right: 10px;
    }

    .description-params {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title-cell, .description-cell {
        max-width: 800px;
        display: flex;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

</style>

<td class="snippet-row-control">
  <div class="action-button-wrap">
    <div class="action-button">
      <MaterialIcon iconName="mode_edit" on:click={onEdit} title={'Edit Snippet'}/>
    </div>
    <div class="action-button">
      <MaterialIcon iconName="clear" on:click={onRemove} title={'Remove Snippet'}/>
    </div>
    <div class="action-button" on:mousedown={onMousedown}>
      <MaterialIcon iconName="drag_handle" cursorType="move" title={'Drag Snippet'}/>
    </div>
  </div>
</td>
<td>
  {#if snippetExpanded}
    <FileSnippetViewer {snippet}/>
  {:else}
    <div class="snippet-summary">
      {#if isImageFile()}
        <img src="{'/api/ReadFile2/' + (snippet && snippet.suid)}" alt='{snippet.fileName}' width='50px'/>
      {/if}
      <div class="description-params">
        <div class="title-cell">
          {#if snippet.title}
            <div>Tile: {snippet.title}</div>
          {:else}
            <div>File Name: {snippet.fileName}</div>
          {/if}
        </div>
        <div class="description-cell">
          {#if snippet.description}
            <div>Description: {snippet.description}</div>
          {/if}
        </div>
      </div>
    </div>
  {/if}
</td>
