<script>
  import InternalError from './InternalError.svelte';
  
  export let component = "Not Specified";
  export let propertyName = "Not Specified";
</script>

<InternalError 
  component={component}
  message="Missing property: {propertyName}"
/>  
