<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { userOptions$ } from '../../stores';
  import DefaultPageOptions from "./DefaultPageOptions.svelte";
  import SecurityCommunication from "./SecurityCommunication.svelte";
  import ExternalConnections from './ExternalConnections.svelte';
  import UserMountPointsForm from './mount-points/UserMountPointsForm.svelte';
  import GroupManagement from './GroupManagement.svelte';

  import { URL_PARAM } from "../../constants";
  import Accordion from "../layout-helpers/Accordion.svelte";
  import Accordions from "../layout-helpers/Accordions.svelte";
  import UserOptionsService from "./UserOptionsService";
  import SearchOptions from "./SearchOptions.svelte";
  import { UrlService } from "../../comm/UrlService";
  import ManageOrganizations from "./ManageOrganizations.svelte";
  import UserOptions from "../../misc/UserOptions";

  let dispatch = createEventDispatcher();
  let userOptions;
  let optionType;
  let unsubscribeContentPageMode;
  let unsubscribePageOptions;
  let selectedPage;
  let pageCollections = [];
  let pageOptions = {};
  let lastSavedTabId = '';
  let tabStates = [
    { id: 0, isOpened: false },
    { id: 1, isOpened: false },
    { id: 2, isOpened: false },
    { id: 3, isOpened: false },
    { id: 4, isOpened: false },
    { id: 5, isOpened: false },
    { id: 6, isOpened: false },
    { id: 7, isOpened: false },
  ];
  let openedAccordion = 0;

  onMount(() => {
    fetchUserOptions();
    checkUrlParams();
  });

  onDestroy(() => {
    if (unsubscribePageOptions) {
      unsubscribePageOptions();
    }
  });

  function checkUrlParams() {
    const isUserOptionParam = UrlService.isParamInURL(URL_PARAM.USER_OPTION_TYPE_PARAM);
    if (isUserOptionParam && UrlService.getPathValue(URL_PARAM.USER_OPTION_TYPE_PARAM) === 'external_connection') {
      openedAccordion = 2; //External Connections
      tabStates[2].isOpened = true; //External Connections
    }
    if (isUserOptionParam && UrlService.getPathValue(URL_PARAM.USER_OPTION_TYPE_PARAM) === 'org_external_connection') {
      openedAccordion = 7; //Manage Organizations
      tabStates[7].isOpened = true; //Manage Organizations
    }
  }

  function onCloseOptions() {
    dispatch("close");
  }

  const toggleAccordion = (e) => {
    openedAccordion = e.detail === openedAccordion ? 0 : e.detail;

    if (!lastSavedTabId) {
      tabStates[e.detail].isOpened = !tabStates[e.detail].isOpened;
      lastSavedTabId = e.detail;
    } else if (lastSavedTabId === e.detail) {
      tabStates[e.detail].isOpened = !tabStates[e.detail].isOpened;
      lastSavedTabId = '';
    } else {
      tabStates[e.detail].isOpened = !tabStates[e.detail].isOpened;
      tabStates[lastSavedTabId].isOpened = !tabStates[lastSavedTabId].isOpened;
      lastSavedTabId = e.detail;
    }
  };

  async function fetchUserOptions() {
    const resp = await UserOptionsService.getUserOptions();

    if (resp.resultCode === 0) {
      userOptions = new UserOptions(resp.result);
      userOptions$.set(userOptions);
    } else {
      console.error('Error: ', resp.resultMessage);
    }
  }
</script>

<style lang="scss">
  @import '../../../static/styles/core-palette';

  .options-section {
    width: 100%;
    height: 100%;
    transition: height 0.1s linear;
    overflow-y: auto;

    .options-header {
      display: flex;
      align-items: center;
      padding: 0 21px 0 15px;
      height: 50px;
      justify-content: space-between;

      .close {
        cursor: pointer;
        width: 20px;
        border-radius: 3px;
        font-size: 18px;
        display: flex;
        justify-content: center;
      }

      .close:hover {
        border-color: #76737f;
        background: rgba(0, 0, 0, .04);
      }
    }
  }

  .accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .material-icons-outlined {
      font-size: 30px;
    }
  }
</style>

<div class="options-section">
  <div class="options-header">
    <h1>User Options</h1>
    <div class="close" on:click={onCloseOptions} title="Close">
      <span class="material-icons-outlined">close</span>
    </div>
  </div>
  <Accordions>
    <Accordion id="{tabStates[1].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Default Page Options</h3>
        {#if tabStates[ 1 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <DefaultPageOptions {userOptions} on:useroptionsupddated={fetchUserOptions}/>
    </Accordion>
    <Accordion id="{tabStates[2].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Manage External Connections</h3>
        {#if tabStates[ 2 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <ExternalConnections/>
    </Accordion>
    <Accordion id="{tabStates[3].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Mount Points</h3>
        {#if tabStates[ 3 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <UserMountPointsForm />
    </Accordion>
    <Accordion id="{tabStates[4].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Security & Communication</h3>
        {#if tabStates[ 4 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <SecurityCommunication userOptionsEmail="{userOptions.eMail}"/>
    </Accordion>
    <Accordion id="{tabStates[5].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Group Management</h3>
        {#if tabStates[ 5 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <GroupManagement/>
    </Accordion>
    <Accordion id="{tabStates[6].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Search Options</h3>
        {#if tabStates[ 6 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <SearchOptions isAdmin={userOptions.isAdmin}/>
    </Accordion>
    <Accordion id="{tabStates[7].id}" {openedAccordion} on:accordionSelected={toggleAccordion}>
      <div slot="title" class="accordion-title">
        <h3>Manage Organizations</h3>
        {#if tabStates[ 7 ].isOpened}
          <span class="material-icons-outlined">expand_less</span>
        {:else}
          <span class="material-icons-outlined">expand_more</span>
        {/if}
      </div>
      <ManageOrganizations/>
    </Accordion>
  </Accordions>
</div>
