<script>

  import { RequestService } from "../../comm/RequestService";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import GroupMembersSection from "./GroupMembersSection.svelte";
  import Loading from "../layout-helpers/Loading.svelte";

  export let organization;

  const dispatch = createEventDispatcher();
  let groups = [];
  let isCreateGroupButtonsEnabled = true;
  let isGroupFormDisplayed = false;
  let groupTitle = '';
  let groupId = '';
  let organizationUsers = [];
  let selectedGroup;
  let showUsers = false;
  let loading = false;

  onMount(async () => {
    await getGroupsByOrgId();
    groupTitle = '';

    if (groups.length) {
      filterAllMembersGroup();
      await getAllMembers();
    }
  });

  onDestroy(() => {
    groupTitle = '';
  });

  function filterAllMembersGroup() {
    groups = groups.filter((group) => group.type !== 2);
  }

  async function getAllMembers() {
    const url = `/apiV2/organization/${organization.id.value}/members`;
    let resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      organizationUsers = resp.result || [];
    } else {
      console.error(resp.resultMessage);
    }
  }

  async function saveGroup() {
    const url = groupId ? `/apiV2/groups/${groupId}` : `/apiV2/groups`;
    let resp;

    if (groupId) {
      resp = await RequestService.put(url, { title: groupTitle, organizationId: organization.id.value });
    } else {
      resp = await RequestService.post(url, { title: groupTitle, organizationId: organization.id.value });
    }

    if (resp.resultCode === 0) {
      groupTitle = '';
      groupId = '';
      isGroupFormDisplayed = false;
      isCreateGroupButtonsEnabled = true;
      await getGroupsByOrgId();
      filterAllMembersGroup();
    } else {
      console.error(resp.resultMessage);
    }
  }

  async function getGroupsByOrgId() {
    loading = true;
    const url = `/apiV2/groups/all?orgId=${organization.id.value}`;
    let resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      groups = resp.result || [];
    } else {
      console.error(resp.resultMessage);
    }
    loading = false;
  }

  async function onRemoveGroup(groupId) {
    const url = `/apiV2/groups/${groupId}`;
    let resp = await RequestService.delete(url);

    if (resp.resultCode === 0) {
      await getGroupsByOrgId();
      filterAllMembersGroup();
      showUsers = false;
    } else {
      console.error(resp.resultMessage);
    }
  }

  function onShowFormToCreateGroup() {
    isCreateGroupButtonsEnabled = false;
    isGroupFormDisplayed = true;
  }

  function onEditGroupName(group) {
    groupTitle = group.title;
    groupId = group.id.value;
    isCreateGroupButtonsEnabled = false;
    isGroupFormDisplayed = true;
  }

  function onShowUsersSection(group) {
    selectedGroup = group;
    showUsers = true;
  }

  function onCloseUsers() {
    showUsers = !showUsers;
    selectedGroup = null;
  }

  function onShowGroupPermissionsSection(group) {
    dispatch('changeorgstate', { state: 'group-permissions', group, organization });
  }

  function onCloseGroupForm() {
    groupTitle = '';
    isCreateGroupButtonsEnabled = true;
    isGroupFormDisplayed = false;
  }

  function formatDate(val) {
    const date = new Date(val);
    const options = {
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      timeZoneName: 'short'
    };
    return date.toLocaleString(options);
  }

  function onBackToList() {
    dispatch('changeorgstate', { state: 'organization' });
  }
</script>
<style lang="scss">
  .organization-groups {
    position: relative;

    .groups-users-wrapper {
      display: flex;

      .group {
        margin-right: 20px;

        .active-group {
          background-color: rgba(0, 0, 0, .05);

          td:first-child {
            font-weight: 600;
          }
        }

        .show-group-form {
          margin-top: 10px;
        }

        .action-buttons-section {
          margin-top: 30px;

          button {
            min-width: 80px;
          }
        }
      }
    }
  }
</style>
<div class="organization-groups">
  <button on:click={onBackToList} class="back-btn">
    <span class="material-icons-outlined">arrow_back_ios</span>
    Back To Organizations List
  </button>
  <Loading {loading}>
    <div class="groups-users-wrapper">
      <div class="group">
        <h3>Current Groups
          for {organization.fullName ? organization.fullName.toUpperCase(): organization.name.toUpperCase()}</h3>
        {#if groups.length}
          <table>
            <tbody>
            <tr>
              <th>Group Name</th>
              <th>Actions</th>
            </tr>
            {#each groups as group (group.id.value)}
              <tr class="{selectedGroup && selectedGroup.id.value === group.id.value ? 'active-group' : ''}">
                <td>{group.title}</td>
                <td>
                  <button type="button" class="action-btn"
                          disabled={!group.isAdministrator}
                          on:click={() => onShowUsersSection(group)}
                  >Manage User Members
                  </button>
                  <button type="button" class="action-btn"
                          disabled={!group.isAdministrator}
                          on:click={() => onShowGroupPermissionsSection(group)}
                  >Manage Group Permissions
                  </button>
                  <button type="button" class="action-btn"
                          disabled={!group.isAdministrator}
                          on:click={() => onEditGroupName(group)}>Edit
                  </button>
                  <button type="button" class="action-btn" disabled="{!group.canBeDeleted}"
                          on:click={() => onRemoveGroup(group.id.value)}>Delete
                  </button>
                </td>
              </tr>
            {/each}
            </tbody>
          </table>
        {:else}
          <div>There are no current groups.</div>
        {/if}

        {#if isCreateGroupButtonsEnabled}
          <button class="show-group-form" type="button" on:click={onShowFormToCreateGroup}>Add New Group</button>
        {/if}
        {#if isGroupFormDisplayed}
          <form on:submit|preventDefault={(event) => saveGroup(event)}>
            <label class="form-field">Title *:
              <input class="input-field"
                     type="text"
                     placeholder="Title"
                     bind:value={groupTitle}
                     required
                     minlength="3"
                     maxlength="24"
              >
            </label>

            <div class="action-buttons-section">
              <button type="submit" name="saveBtn">Save</button>
              <button type="button" on:click={onCloseGroupForm}>Close</button>
              <button type="button" on:click={groupTitle = ''}>Clear</button>
            </div>
          </form>
        {/if}
      </div>
      {#if showUsers}
        <GroupMembersSection
          group={selectedGroup}
          {organization}
          {organizationUsers}
          on:hidemembersection={onCloseUsers}
        />
      {/if}
    </div>
  </Loading>
</div>

