<script>
  import UserOptionsService from "../user-options/UserOptionsService";
  import { onMount } from "svelte";
  import PersonalGroupMembersSection from "./PersonalGroupMembersSection.svelte";
  import GroupPermissionsSection from "./GroupPermissionsSection.svelte";
  import Loading from "../layout-helpers/Loading.svelte";

  const GroupManagementStates = {
    Group: 'group',
    Permissions: 'permissions',
    Members: 'members',
  };

  let currentState = GroupManagementStates.Group;
  let ownedGroups = [];
  let isCreateGroupButtonsEnabled = true;
  let isGroupFormDisplayed = false;
  let groupTitle = '';
  let selectedGroup = null;
  let loading = false;

  onMount(() => {
    fetchOwnedGroups();
  });

  async function fetchOwnedGroups() {
    loading = true;
    const response = await UserOptionsService.getOwnedGroups();

    if (response.resultCode === 0) {
      ownedGroups = response.result || [];
    } else {
      ownedGroups = [];
      console.error('Error: ', response.resultMessage);
    }
    loading = false;
  }

  async function saveGroup() {
    let resp;
    if (selectedGroup) {
      resp = await UserOptionsService.updateGroup(groupTitle, selectedGroup.id.value);
    } else {
      resp = await UserOptionsService.addGroup(groupTitle);
    }

    if (resp.resultCode === 0) {
      onCloseFormToCreateGroup();
      await fetchOwnedGroups();
    } else {
      console.error(resp.resultMessage);
    }
  }

  function onSetGroupState() {
    currentState = GroupManagementStates.Group;
    selectedGroup = null;
  }

  function onChangeGroupState(state, group) {
    selectedGroup = group;
    currentState = state;
  }

  function onEditGroupName(group) {
    selectedGroup = group;
    groupTitle = group.title;
    isCreateGroupButtonsEnabled = false;
    isGroupFormDisplayed = true;
  }

  async function onRemoveGroup(groupId) {
    const response = await UserOptionsService.removeEmptyGroup(groupId);

    if (response.resultCode === 0) {
      onCloseFormToCreateGroup();
      await fetchOwnedGroups();
    } else {
      console.error('onRemoveGroup ', response.resultMessage)
    }
  }

  function onShowFormToCreateGroup() {
    isCreateGroupButtonsEnabled = false;
    isGroupFormDisplayed = true;
  }

  function onClearForm() {
    groupTitle = '';
  }

  function onCloseFormToCreateGroup() {
    isCreateGroupButtonsEnabled = true;
    isGroupFormDisplayed = false;
    groupTitle = '';
    selectedGroup = null;
  }
</script>

<style lang="scss">
  .personal-groups {
    :global(th) {
      border: 1px solid grey;
      min-width: 180px;
    }

    :global(td) {
      border: 1px solid grey;
      min-width: 180px;
    }

    :global(.back-btn) {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }
</style>

<div class="personal-groups">
  <Loading {loading}>
    {#if currentState === GroupManagementStates.Group}
      <div class="group">
        <h3>Your Current Groups</h3>
        {#if ownedGroups.length}
          <table>
            <tbody>
            <tr>
              <th>Group Name</th>
              <th>Actions</th>
            </tr>
            {#each ownedGroups as group (group.id.value)}
              <tr>
                <td>{group.title}</td>
                <td>
                  <button type="button" class="action-btn"
                          disabled={!group.isAdministrator}
                          on:click={() => onChangeGroupState(GroupManagementStates.Members, group)}
                  >
                    Manage Members
                  </button>
                  <button type="button" class="action-btn"
                          disabled={!group.isAdministrator}
                          on:click={() => onChangeGroupState(GroupManagementStates.Permissions, group)}
                  >
                    Manage Permissions
                  </button>
                  <button type="button" class="action-btn"
                          disabled={!group.isAdministrator}
                          on:click={() => onEditGroupName(group)}
                  >
                    Edit
                  </button>
                  <button type="button" class="action-btn"
                          disabled={!group.isAdministrator}
                          on:click={() => onRemoveGroup(group.id.value)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            {/each}
            </tbody>
          </table>
        {:else}
          <div>There are no current groups.</div>
        {/if}

        {#if isCreateGroupButtonsEnabled}
          <button class="show-group-form" type="button" on:click={onShowFormToCreateGroup}>Add New Group</button>
        {/if}
        {#if isGroupFormDisplayed}
          <form on:submit|preventDefault={(event) => saveGroup(event)}>
            <label class="form-field">Title *:
              <input class="input-field"
                     type="text"
                     placeholder="Title"
                     bind:value={groupTitle}
                     required
                     minlength="3"
                     maxlength="24"
              >
            </label>

            <div class="action-buttons-section">
              <button type="submit" name="saveBtn">Save</button>
              <button type="button" on:click={onCloseFormToCreateGroup}>Close</button>
              <button type="button" on:click={onClearForm}>Clear</button>
            </div>
          </form>
        {/if}
      </div>
    {:else if currentState === GroupManagementStates.Members}
      <PersonalGroupMembersSection group={selectedGroup}
                                   on:setgroupsstate={onSetGroupState}/>
    {:else if currentState === GroupManagementStates.Permissions}
      <GroupPermissionsSection
        on:setgroupsstate={onSetGroupState}
        group={selectedGroup}
      />
    {/if}
  </Loading>
</div>
