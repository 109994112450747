<style lang="scss">
  .flex-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
</style>

<div class="flex-wrapper">
  <slot />
</div>  
