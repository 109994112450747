<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { RequestService } from "../../../comm/RequestService";
  import CommonMountPointsForm from "./CommonMountPointsForm.svelte";
  import { signalRConnection$ } from "../../../stores";
  import {ExtendedMountPoint} from "../../../domain-objects/ExtendedMountPoint";
  
  export let organization;

  const dispatch = createEventDispatcher();
  let loading = true;
  let mountPoints = [];
  let externalConnections = [];
  let unsubscribeSignalRConnection;

  onMount(() => {
    async function getData() {
      await getDataForTable();
    }

    unsubscribeSignalRConnection = signalRConnection$.subscribe(async (eventData) => {
      if (eventData) {
        await listenNotificationEvents(eventData)
      }
    });
    
    getData();
  });

  onDestroy(() => {
    unsubscribeSignalRConnection();
  });

  async function listenNotificationEvents(args) {
    if (args.status === 'Done' || args.status === 'Error') {
      await getDataForTable();
    }
  }

  async function getExternalConnections() {
    const requestUrl = `apiV2/externalConnection?orgId=${organization.id.value}`;

    const resp = await RequestService.get(requestUrl);

    if (resp.resultCode === 0) {
      return resp.result;
    } else {
      console.error('getExternalConnectionsList ', resp.resultMessage);
      return [];
    }
  }

  async function getMountPointsData() {
    const response = await RequestService.get(`/apiV2/mountPoint/all?orgId=${organization.id.value}`);

    if (response.resultCode === 0) {
      let result = [];
      response.result.forEach(dto => {
        const mp = ExtendedMountPoint.createFromPlainObject(dto);
        mp.externalConnections = externalConnections;
        result.push(mp);
      });
      return result;
    } else {
      console.error('getMountPointsData ', response.resultMessage);
      return [];
    }    
  }

  async function getDataForTable() {
    loading = true;
    externalConnections = await getExternalConnections();
    mountPoints = await getMountPointsData();
    loading = false;
  }

  function onRefreshAll() {
    getDataForTable();
  }
  
  function onMountPointRefreshed(event) {
  }
  
  function onMountPointUpdated(event) {
  }
  
  function onMountPointDeleted(event) {
  }

  function onBackToList() {
    dispatch('changeorgstate', { state: 'organization' });
  }
  
  function addOrgIdToMountPoint(mountPoint) {
    // The CommonMountPointsForm doesn't have built-in knowledge
    // of organizational mount points. This function gives us a
    // chance to fill in the organization id before we create the
    // mount point.
    // console.log("Inside addOrgIdToMountPoint");
    mountPoint.organizationId = organization.id.value;
    // console.log("mountPoint =", mountPoint);
  }
</script>

<style lang="scss">
</style>

<button on:click={onBackToList} class="back-btn">
  <span class="material-icons-outlined">arrow_back_ios</span>
  Back To Organizations List
</button>

<CommonMountPointsForm
  mountPoints={mountPoints}
  externalConnections={externalConnections}
  loading={loading}
  beforeSaveNewFunc={addOrgIdToMountPoint}
  on:mountpointrefreshed={onMountPointRefreshed}
  on:mountpointupdated={onMountPointUpdated}
  on:mountpointdeleted={onMountPointDeleted}
/>
