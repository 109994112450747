<script>
  import { createEventDispatcher} from "svelte";
  import { CONNECTION_TYPE, DEFAULT_GIT_REPO_PATTERN_LIST, GIT_ACCESS_TYPE } from "../../../constants";
  import SingleColumnForm from "../../form-helpers/SingleColumnForm.svelte";
  import FormRow from "../../form-helpers/FormRow.svelte";
  import FormSectionHeading from "../../form-helpers/FormSectionHeading.svelte";
  import EditGeneralFields from "./EditGeneralFields.svelte";
  import EditAzureDevOpsFields from "./EditAzureDevOpsFields.svelte";
  import EditCommonGitFields from "./EditCommonGitFields.svelte";
  import EditBitbucketFields from "./EditBitbucketFields.svelte";
  import EditGitHubFields from "./EditGitHubFields.svelte";
  import EditGitLabFields from "./EditGitLabFields.svelte";

  export let mountPoint;
  export let mountPoints;
  export let externalConnections;
  export let isCreateMode = true;
  
  const dispatch = createEventDispatcher();
  let showGitCommonFields = false;
  let allowGitFieldOverrides = false;
  let effectiveConnectionType = 0;
  let defaultGitHostDisplayName = '';
  let defaultGitRepoPattern = '';
  let defaultHostPath = '';
  let defaultOrgName = '';
  let allFieldsValid = true;
  let azureDevOpsFieldsValid = false;
  let bitbucketFieldsValid = false;
  let gitHubFieldsValid = false;
  let gitLabFieldsValid = false;

  function isPublic(gitAccessType) {
    return gitAccessType === GIT_ACCESS_TYPE.PUBLIC;
  }
  
  function isPrivate(gitAccessType) {
    return gitAccessType === GIT_ACCESS_TYPE.PRIVATE;
  }
  
  function updateEffectiveConnectionType(mountPoint, gitAccessType, connectionType, externalConnectionId, externalConnection) {
    effectiveConnectionType = mountPoint.getEffectiveConnectionType();

    if (isPublic(gitAccessType)) {
      defaultOrgName = '';
    } else if (!!externalConnection) {
      defaultOrgName = externalConnection.orgName;
    }    
  }
  
  function updateShowGitCommonFields(gitAccessType, connectionType, externalConnectionId, externalConnection) {
    showGitCommonFields = (
      isPublic(gitAccessType) ||
      isPrivate(gitAccessType) && !!externalConnection
    );    
  }

  function updateDefaultGitFields(mountPoint /*, gitAccessType, connectionType, externalConnectionId, externalConnection */) {
    defaultGitHostDisplayName = mountPoint.getDefaultGitHostName();
    defaultGitRepoPattern = mountPoint.getDefaultGitRepoPattern();
    defaultHostPath = mountPoint.getDefaultHostPath();
  }

  function updateAllowGitFieldOverrides(gitAccessType, connectionType, externalConnectionId, externalConnection) {
    allowGitFieldOverrides = (
      isPublic(gitAccessType) && connectionType !== CONNECTION_TYPE.GENERIC_GIT_HOST ||
      isPrivate(gitAccessType) && !!externalConnection && externalConnection.connectionType !== CONNECTION_TYPE.GENERIC_GIT_HOST
    );
  }
  
  function updateValidation(
    name,
    gitAccessType,
    connectionType,
    innerMountPointId,
    orgName,
    projectName,
    repoName,
    azureDevOpsFieldsValid,
    bitbucketFieldsValid,
    gitHubFieldsValid,
    gitLabFieldsValid
  ) {
    if (name === null || name === undefined || name.trim() === '') {
      return false;
    }
    
    if (gitAccessType === GIT_ACCESS_TYPE.REUSE_EXISTING) {
      return innerMountPointId !== undefined && innerMountPointId !== null;
    }
    
    switch (connectionType) {
      case CONNECTION_TYPE.GENERIC_GIT_HOST:
        return true;
        
      case CONNECTION_TYPE.AZURE_DEV_OPS:
        return azureDevOpsFieldsValid;
        
      case CONNECTION_TYPE.BITBUCKET_CLOUD:
        return bitbucketFieldsValid;
        
      case CONNECTION_TYPE.GITHUB:
        return gitHubFieldsValid;
        
      case CONNECTION_TYPE.GITLAB:
        return gitLabFieldsValid;
        
      default:
        return false;
    }
  }
  
  function onClose() {
    dispatch("close");
  }
  
  function onClear() {
    dispatch("clear");
  }
  
  function onSave() {
    dispatch("save");
  }

  $: updateShowGitCommonFields(
    mountPoint.gitAccessType,
    mountPoint.connectionType,
    mountPoint.externalConnectionId,
    mountPoint.externalConnection
  );

  $: updateEffectiveConnectionType(
    mountPoint,
    mountPoint.gitAccessType,
    mountPoint.connectionType,
    mountPoint.externalConnectionId,
    mountPoint.externalConnection
  );

  $: updateDefaultGitFields(
    mountPoint //,
    // mountPoint.gitAccessType,
    // mountPoint.connectionType,
    // mountPoint.externalConnectionId,
    // mountPoint.externalConnection
  );

  $: updateAllowGitFieldOverrides(
    mountPoint.gitAccessType,
    mountPoint.connectionType,
    mountPoint.externalConnectionId,
    mountPoint.externalConnection
  );
  
  $: allFieldsValid = updateValidation(
    mountPoint.name,
    mountPoint.gitAccessType,
    effectiveConnectionType,
    mountPoint.innerMountPointId,
    mountPoint.getEffectiveOrgName(mountPoint.externalConnection),
    mountPoint.projectName,
    mountPoint.repoName,
    azureDevOpsFieldsValid,
    bitbucketFieldsValid,
    gitHubFieldsValid,
    gitLabFieldsValid
  );
</script>

<SingleColumnForm on:submit={onSave}>
  {#if isCreateMode}
    <FormSectionHeading label="Add Mount Point" />
  {:else}
    <FormSectionHeading label="Edit Mount Point" />
  {/if}

  <EditGeneralFields 
    mountPoints={mountPoints}
    externalConnections={externalConnections}
    id={mountPoint.id}
    isCreateMode={isCreateMode}
    
    bind:name={mountPoint.name}
    bind:description={mountPoint.description}
    bind:startPath={mountPoint.startPath}
    bind:gitAccessType={mountPoint.gitAccessType}
    bind:connectionType={mountPoint.connectionType}
    bind:innerMountPointId={mountPoint.innerMountPointId}
    bind:externalConnectionId={mountPoint.externalConnectionId}
    bind:treeFormatType={mountPoint.treeFormatType}
    bind:refreshType={mountPoint.refreshType}
    bind:pollingInterval={mountPoint.pollingInterval}
  />
  
  {#if effectiveConnectionType === CONNECTION_TYPE.AZURE_DEV_OPS}
    <EditAzureDevOpsFields 
      isCreateMode={isCreateMode}
      allowOverrideOrgName={mountPoint.gitAccessType === GIT_ACCESS_TYPE.PRIVATE}
      defaultOrgName={defaultOrgName}
      bind:allFieldsValid={azureDevOpsFieldsValid}
      bind:overrideOrgName={mountPoint.overrideOrgName}
      bind:orgName={mountPoint.orgName}
      bind:projectName={mountPoint.projectName}
      bind:repoName={mountPoint.repoName}
    />
  {:else if effectiveConnectionType === CONNECTION_TYPE.BITBUCKET_CLOUD}
    <EditBitbucketFields
      isCreateMode={isCreateMode}
      allowOverrideOrgName={mountPoint.gitAccessType === GIT_ACCESS_TYPE.PRIVATE}
      defaultOrgName={defaultOrgName}
      bind:allFieldsValid={bitbucketFieldsValid}
      bind:overrideOrgName={mountPoint.overrideOrgName}      
      bind:orgName={mountPoint.orgName}
      bind:repoName={mountPoint.repoName}
    />
  {:else if effectiveConnectionType === CONNECTION_TYPE.GITHUB}
    <EditGitHubFields
      isCreateMode={isCreateMode}
      allowOverrideOrgName={mountPoint.gitAccessType === GIT_ACCESS_TYPE.PRIVATE}
      defaultOrgName={defaultOrgName}
      bind:allFieldsValid={gitHubFieldsValid}
      bind:overrideOrgName={mountPoint.overrideOrgName}
      bind:orgName={mountPoint.orgName}
      bind:repoName={mountPoint.repoName}
    />
  {:else if effectiveConnectionType === CONNECTION_TYPE.GITLAB}
    <EditGitLabFields
      isCreateMode={isCreateMode}      
      allowOverrideOrgName={mountPoint.gitAccessType === GIT_ACCESS_TYPE.PRIVATE}
      defaultOrgName={defaultOrgName}
      bind:allFieldsValid={gitLabFieldsValid}
      bind:overrideOrgName={mountPoint.overrideOrgName}      
      bind:orgName={mountPoint.orgName}
      bind:repoName={mountPoint.repoName}
    />
  {/if}
  
  {#if showGitCommonFields}
    <EditCommonGitFields
      isCreateMode={isCreateMode}
      
      allowOverrideGitHostDisplayName={allowGitFieldOverrides}
      defaultGitHostDisplayName={defaultGitHostDisplayName}
      bind:overrideGitHostDisplayName={mountPoint.overrideGitHostDisplayName}
      bind:gitHostDisplayName={mountPoint.gitHostDisplayName}
  
      allowOverrideGitRepoPattern={allowGitFieldOverrides}
      defaultGitRepoPattern={defaultGitRepoPattern}
      bind:overrideGitRepoPattern={mountPoint.overrideGitRepoPattern}
      bind:gitRepoPattern={mountPoint.gitRepoPattern}
  
      allowOverrideHostPath= {allowGitFieldOverrides}
      defaultHostPath={defaultHostPath}
      bind:overrideHostPath={mountPoint.overrideHostPath}
      bind:hostPath={mountPoint.hostPath}
    />
  {/if}

  <FormRow>
    {#if isCreateMode}
      <div class="action-buttons-section">
        <button type="submit" name="saveBtn" disabled={!allFieldsValid}>Save</button>
        <button type="button" on:click={onClose}>Close</button>
        <button type="button" on:click={onClear}>Clear</button>
      </div>
    {:else}
      <div class="action-buttons-section">
        <button type="submit" name="saveBtn" disabled={!allFieldsValid}>Save</button>
        <button type="button" on:click={onClear}>Revert</button>
        <button type="button" on:click={onClose}>Cancel</button>
      </div>    
    {/if}
  </FormRow>
</SingleColumnForm>
