import Snippet from './Snippet.js';
import DateUtil from '../utils/DateUtil.js';
import { FILE_EXTENSION, SNIPPET_TYPE } from "./Snippet";

export const FORMAT_TYPE = {
  Wrapped: 0,
  PreFormatted: 1,
  SourceCodeViewer: 2,
  Html: 3,
  Markdown: 4,
};

class PlainTextSnippet extends Snippet {
  constructor(jsonData) {
    super(jsonData);
    if (jsonData != null) {
      this.jsonData = jsonData;
      if (this.jsonData.formatType === undefined) {
        this.jsonData.formatType = this.jsonData.html ? FORMAT_TYPE.Html : FORMAT_TYPE.Wrapped;
      }
      this.createDate = DateUtil.getDateFromJson(jsonData.createDate);
      this.lastModifiedDate = DateUtil.getDateFromJson(jsonData.lastModifiedDate);
      this.isEmpty = false;
    } else {
      this.jsonData = {};
    }
  }

  static createEmpty() {
    const snippet = new PlainTextSnippet({
      snippetType: SNIPPET_TYPE.PlainText,
      content: '',
      font: 1,
      fontSize: 1,
      bold: false,
      italics: false,
      underline: false,
      html: false,
      formatType: FORMAT_TYPE.Markdown,
      displayLineNumbers: true,
      ext: ''
    });

    snippet.isEmpty = true;

    return snippet;
  }

  get suid() {
    return (this.jsonData && this.jsonData.suid) ? this.jsonData.suid : '';
  }

  get content() {
    return (this.jsonData && this.jsonData.content !== undefined) ? this.jsonData.content : 'No content';
  }

  set content(val) {
    this.jsonData.content = val;
  }

  get font() {
    return (this.jsonData && this.jsonData.font !== undefined) ? this.jsonData.font : 1;
  }

  set font(val) {
    this.jsonData.font = val;
  }

  get fontSize() {
    return (this.jsonData && this.jsonData.fontSize !== undefined) ? this.jsonData.fontSize : 1;
  }

  set fontSize(val) {
    this.jsonData.fontSize = val;
  }

  get bold() {
    return (this.jsonData && this.jsonData.bold !== undefined) ? this.jsonData.bold : false;
  }

  set bold(val) {
    this.jsonData.bold = val;
  }

  get italics() {
    return (this.jsonData && this.jsonData.italics !== undefined) ? this.jsonData.italics : false;
  }

  set italics(val) {
    this.jsonData.italics = val;
  }

  get underline() {
    return (this.jsonData && this.jsonData.underline !== undefined) ? this.jsonData.underline : false;
  }

  set underline(val) {
    this.jsonData.underline = val;
  }

  get html() {
    // This property is in the process of being replaced by the formatType property, but some
    // code still uses this old property
    return (this.jsonData && this.jsonData.html !== undefined) ? this.jsonData.html : false;
  }

  set html(val) {
    if (val) {
      this.jsonData.html = true;
      this.jsonData.formatType = FORMAT_TYPE.Html;
    } else {
      this.jsonData.html = false;
      this.jsonData.formatType = FORMAT_TYPE.Wrapped;
    }
  }

  get formatType() {
    return (this.jsonData && this.jsonData.formatType !== undefined) ? this.jsonData.formatType : FORMAT_TYPE.Wrapped;
  }

  set formatType(val) {
    this.jsonData.formatType = val;
  }

  get displayLineNumbers() {
    return (this.jsonData && this.jsonData.displayLineNumbers !== undefined) ? this.jsonData.displayLineNumbers : false;
  }

  set displayLineNumbers(val) {
    this.jsonData.displayLineNumbers = val;
  }

  get displayHighlighting() {
    return (this.jsonData && this.jsonData.displayHighlighting !== undefined) ? this.jsonData.displayHighlighting : false;
  }

  set displayHighlighting(val) {
    this.jsonData.displayHighlighting = val;
  }

  get useSourceCodeViewer() {
    return (this.jsonData && this.jsonData.useSourceCodeViewer !== undefined) ? this.jsonData.useSourceCodeViewer : false;
  }

  set useSourceCodeViewer(val) {
    this.jsonData.useSourceCodeViewer = val;
  }

  get extensionType() {
    return (this.jsonData && this.jsonData.extensionType !== undefined) ? this.jsonData.extensionType : FILE_EXTENSION.OTHER;
  }

  get isHtmlFile() {
    return this.extensionType === FILE_EXTENSION.HTML;
  }

  get isMarkdownFile() {
    return this.extensionType === FILE_EXTENSION.MD;
  }

  getStyle() {
    let style = '';

    if (this.bold) {
      style += 'font-weight: bold; ';
    }

    if (this.italics) {
      style += 'font-style: italic; ';
    }

    if (this.underline) {
      style += 'text-decoration: underline;';
    }

    return style;
  }
}

export default PlainTextSnippet;
