<script>
  import { createEventDispatcher } from 'svelte';

  export let iconName;
  export let text = null;
  export let title = null;
  export let emitValue = null;

  const dispatch = createEventDispatcher();

  function onClick() {
    dispatch("click", {emitValue});
  }
</script>

<style lang="scss">
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .icon {
      font-size: 20px;
      text-align: center;
    }

    .text {
      padding-left: 5px;
    }
  }
</style>

{#if title}
  <button on:click={onClick} title="{title}">
    <span class="material-icons-outlined icon">{iconName}</span>
    {#if text}
      <span class="text">{text}</span>
    {/if}
  </button>
{:else}
  <button on:click={onClick}>
    <span class="material-icons-outlined icon">{iconName}</span>
    {#if text}
      <span class="text">{text}</span>
    {/if}
  </button>
{/if}
