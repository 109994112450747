<script>
  export let snippet;
  let linkPath = '';
  let fileWidth = '';
  let baseHref = document.location.origin + '/';
  $: linkPath = baseHref + 'api/ReadFile2/' + (snippet && snippet.suid) + '?' + new Date().getTime();
  $: fileWidth = snippet && snippet.width || '';

  function isImageFile() {
    return snippet.mimeType === 'image/gif' ||
      snippet.mimeType === 'image/jpg' ||
      snippet.mimeType === 'image/jpeg' ||
      snippet.mimeType === 'image/png' ||
      snippet.mimeType === 'image/svg+xml';
  }
</script>

<style lang="scss">
  .snippet-content {
    padding: 10px;

    img {
      display: inline-block;
    }

    .file-options-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: fit-content;
    }

    .file-options-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: fit-content;
    }

    .link-container {
      display: flex;
      justify-content: flex-start;
      min-width: 110px;
    }
  }
</style>

<div class="snippet-content">
  {#if snippet.embedInDocument && isImageFile()}
    <img src='{linkPath}' alt='{snippet.fileName}' width={fileWidth ? fileWidth : undefined}/>
    <br>
    {#if snippet.linkToView}
      <a href='{linkPath}' target='_blank'>Open File in a new Tab</a>
    {/if}
  {:else if snippet.linkToView}
    <a href='{linkPath}' target='_blank'>View File: {snippet.fileName}</a>
  {/if}

  {#if !(snippet.embedInDocument && isImageFile()) && !snippet.linkToView}
    <div>File Name: <b>{snippet.fileName}</b></div>
  {/if}

  {#if snippet.linkToDownload}
    <div class="{isImageFile() && snippet.embedInDocument ? 'link-container' : ''}"
         style="width: {isImageFile() ? fileWidth : '100%'}">
      <a href='{linkPath}' download>Download File</a>
    </div>
  {/if}

  <div class="{isImageFile() && snippet.embedInDocument ? 'file-options-center' : 'file-options-left'}"
       style="min-width: {isImageFile() ? fileWidth : '100%'}">
    {#if snippet.title}
      <h2>{snippet.title}</h2>
    {/if}

    {#if snippet.description}
      <div style="text-align: center">{snippet.description}</div>
    {/if}
  </div>
</div>
