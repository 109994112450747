import api from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../node_modules/css-loader/dist/cjs.js??ref--9-1!../../node_modules/postcss-loader/dist/cjs.js??ref--9-2!../../node_modules/sass-loader/dist/cjs.js??ref--9-3!../../node_modules/mini-css-extract-plugin/dist/loader.js!../../node_modules/css-loader/dist/cjs.js??ref--13-1!../../node_modules/postcss-loader/dist/cjs.js??ref--13-2!../../node_modules/sass-loader/dist/cjs.js??ref--13-3!./index.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};