<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import { Guid } from "js-guid";
  import TopicPage from "../../pages/TopicPage";
  import {
    openPageLinkEvent$,
    mappedPageCreation$,
    headerRefactoredHomeEvent$,
    topicPagesList$,
    navSelectedItem$,
    navRootTopicPage$
  } from "../../stores";
  import AddTopicPage from '../page-forms/AddTopicPage.svelte';
  import AddMappedPage from '../page-forms/AddMappedPage.svelte';
  import PageTree from './PageTree3';
  import { TOC_ENTRY_TYPE } from "../../constants";
  import { UrlService } from "../../comm/UrlService";
  import { TreeNavigatorService } from "./TreeNavigatorService";
  import ScrollablePane from "../panes/ScrollablePane.svelte";
  import AppStylesPane from "../panes/AppStylesPane.svelte";
  import PaneWithTopHeader from "../panes/PaneWithTopHeader.svelte";
  import TwoPartHeaderPane from "../panes/TwoPartHeaderPane.svelte";
  import MaterialIcon from "../layout-helpers/MaterialIcon.svelte";
  import VerticalCenter from "../layout-helpers/VerticalCenter.svelte";
  import VerticalCenterItem from "../layout-helpers/VerticalCenterItem.svelte";
  import { TreeNodeItemFactory } from "./TreeNodeItemFactory";
  import { TocEntry } from "../../pages/TocEntry";
  import { PageTreeNodeItem } from "./PageTreeNodeItem";
  import { StaticTextTreeNodeItem } from "./StaticTextTreeNodeItem";
  
  export let route = null;

  // export let useTreeNavigator = true;

  // These two variables control whether to show the old-style
  // list-based navigator, the new tree-based navigator, or both
  // const showPageListNavigator = !useTreeNavigator;
  // const showPageTreeNavigator = useTreeNavigator;

  let dispatch = createEventDispatcher();
  let childTopicPages = null;
  let loadingAllTopicPages = true;
  let allTopicPages = [];
  let rootTopicPage;
  let currentNavigationTopicPage;
  let currentContentTopicPage;
  let renameIndex = -1;
  let renameTitle = "";
  let removeIndex = -1;
  let loading = true;
  let showAddPageControls = false;
  let isAddPageButtonDisplayed = true;
  let isLoading = false;
  let pagesTreeContainer;
  let draggedData = {
    draggableElementId: '',
    endElementId: '',
    isPutIntoPage: false,
    isLastPage: false,
    isCopyAction: false,
    isCopyFromSearch: false,
  };
  let parentWithExtraChild;
  let searchTree;
  let isPageAddingInProcess = false;
  let draggableElEvt;
  let hoveredEl;
  let bottomElId;
  let nodesByLocalId = {};
  let backReferences = {};
  let currentRoute = route;
  let enableDragAndDrop = false;

  let tree = {
    expanded: false,
    removing: false,
    isEditMode: false,
    pageCreating: false,
    childrenLoaded: false,
    parentPageSuid: '',
    innerPageCount: 0,
    children: [],
    tocEntryId: '',
    previousTreeState: null,
    previousDisplayedPage: null,
    mountPointId: '',
    relativePath: '',
    windowBottomCoord: null
  };
  
  let unsubscribeHomeEvent;
  let unsubscribeCreateMappedPage;
  let unsubscribeTopicPagesListEvent;
  let unsubscribeOpenPageLinkEvent$;
  let windowBottomCoord;

  onMount(async () => {
    isLoading = true;
    unsubscribeHomeEvent = headerRefactoredHomeEvent$.subscribe(async (val) => {
      if (val) {
        await home();
        headerRefactoredHomeEvent$.set(false);
      }
    });

    unsubscribeCreateMappedPage = mappedPageCreation$.subscribe((val) => {
      if (val) {
        onPageAdded(val);
      }
      mappedPageCreation$.set(null);
    });

    unsubscribeTopicPagesListEvent = topicPagesList$.subscribe(async (value) => {
      if (value === 'update') {
        await refreshAllTopicPagesList();
        topicPagesList$.set('');
      }
    });

    unsubscribeOpenPageLinkEvent$ = openPageLinkEvent$.subscribe((value) => {
      if (value.linkPath) {
        navigateToHyperLink(value);
      }
    });
    
    // openSearchPageEvent$.subscribe((data) => {
    //   if (data.isOpenedFromSearchPanel) {
    //     onRenderPage(data.pageSuid);
    //   }
    // });
    
    pagesTreeContainer = document.querySelectorAll(`.page-tree`)[0];
    windowBottomCoord = window.pageYOffset + document.documentElement.clientHeight;

    await loadRootTopicPage();
    
    window.addEventListener("popstate", () => {
      route = UrlService.getRouteFromLocation();
    });
  });

  setRoute(route);
  
  $: if (currentRoute !== route) {
    setRoute(route);
  }

  onDestroy(() => {
    unsubscribeHomeEvent();
    unsubscribeCreateMappedPage();
    unsubscribeTopicPagesListEvent();
    unsubscribeOpenPageLinkEvent$();
  });
  
  async function setRoute(route) {
    currentRoute = route;

    if (UrlService.routeContainsPageSuid(currentRoute)) {
      const suid = UrlService.getPageSuidFromRoute(currentRoute);
      await loadPageFromSuid(suid);
    } else {
      await loadPageFromRoot();
    }
  }
  
  async function loadPageFromSuid(suid) {
    const res = await TreeNavigatorService.getPageData(suid);
    const page = new TopicPage(res.result);
    navSelectedItem$.set({ page: page, node: tree });
  }
  
  async function loadPageFromRoot() {
    navSelectedItem$.set({ page: tree.page, node: tree });
  }

  async function findChildSegment(currentNode, urlSegment, isVirtual) {
    let childSegment;

    if (isVirtual) {
      childSegment = currentNode.children.find((child) => {
        const relativePathSegments = child.relativePath.split('/');

        return relativePathSegments[relativePathSegments.length - 1] === urlSegment;
      });
    } else {
      if (currentNode && !currentNode.children.length) {
        currentNode.childrenLoaded = false;
        await expandNode(currentNode);
      }

      childSegment = currentNode.children.find((child) => child.page.urlSlug === urlSegment);
    }

    if (childSegment && !childSegment.children.length) {
      childSegment.childrenLoaded = false;
      await expandNode(childSegment);
    }

    return childSegment;
  }

  async function navigateToHyperLink(evt) {
    const { linkPath, startPage, startNode } = evt;
    const urlSegments = linkPath.split('/');

    // The first approach is to traverse to the new node starting
    // at the startNode passed into us by TopicPageRenderer
    let currentNode = startNode;
    let nextNode = null;

    let isVirtual = false;

    if (startNode) {
      for (let index = 0; index < urlSegments.length; index++) {
        if (urlSegments[index] === "." || urlSegments[index] === "..") {
          nextNode = currentNode.parent;
        } else {
          if (currentNode.page.suid.startsWith('vp') || currentNode.page.suid.startsWith('mp')) {
            isVirtual = true;
          }

          nextNode = await findChildSegment(currentNode, urlSegments[index], isVirtual);
        }

        currentNode = nextNode;

        if (!nextNode) {
          break;
        }
      }
    }

    if (currentNode) {
      onClickTreeNode(currentNode);
    } else {
      // Search using backend method
      // To be implemented in the future (Task 387)
      console.error('currentNode = null')
      // TODO: Display Error Message Somewhere
    }
  }

  function createTreeNodeItem(page, parent) {
    if (page.tocEntryType === undefined) {
    }
    
    return {
      localId: Guid.newGuid().toString(),
      parentLocalId: parent ? parent.localId : "root",
      label: page ? page.title : "",
      parent: parent,
      page: (page === null || page.tocEntryType !== TOC_ENTRY_TYPE.STATIC_TEXT) ? new TopicPage(page) : null,
      childrenLoaded: false,
      innerPageCount: page ? page.innerPageCount : 0,
      children: [],
      expanded: false,
      removing: false,
      pageCreating: false,
      title: page ? page.title : "",
      tocEntryId: page.tocEntryId,
      tocEntryType: page.tocEntryType,
      mountPointId: page ? page.mountPointId : null, 
      relativePath: page ? page.relativePath : null,
      previousTreeState: null,
      dragCounter: 0,
      dragAboveCounter: 0,
      dragBelowCounter: 0,
      windowBottomCoord
    };
    
  }

  function forceRefresh(obj) {
    // This is a bit of a hack to trick Svelte into refreshing the DOM state
    // based on inner changes to objects or arrays. Svelte is not able to
    // detect changes to an object or an array's contents, so we have to
    // manually trigger the refresh with as assignment statement.
    if (obj === tree) {
      tree = tree;
    } else if (obj === nodesByLocalId) {
      nodesByLocalId = nodesByLocalId;
    } else if (obj === backReferences) {
      backReferences = backReferences;
    }
  }

  function forceFullRefresh() {
    forceRefresh(tree);
    forceRefresh(nodesByLocalId);
    forceRefresh(backReferences);
  }

  function updateUrlParam(suid) {
    UrlService.updateURLWithSuid(suid);
    route = UrlService.getRouteFromLocation();
    setRoute(route);
  }

  function addBackReferenceForNode(node) {
    let backRefs = backReferences[node.backReferenceId];

    if (backRefs === null || backRefs === undefined) {
      // Create first backreference in the table
      backReferences[node.backReferenceId] = [node];
    } else {
      // Add the new node to the list of backreferences
      backRefs.push(node);
    }
    
    forceRefresh(backReferences);
  }

  async function loadRootTopicPage() {
    nodesByLocalId = {};

    const res = await TreeNavigatorService.getRootTopicPage();

    if (res.resultCode === 0) {
      tree = TreeNodeItemFactory.createFromTreePageDto(res.result, null, null);
      rootTopicPage = tree.page;
      currentNavigationTopicPage = tree.page;
      currentContentTopicPage = tree.page;
      nodesByLocalId[tree.localId] = tree;
      addBackReferenceForNode(tree);
      forceRefresh(nodesByLocalId);
      
      if (!UrlService.routeContainsPageSuid(route)) {
        currentRoute = route;
        navSelectedItem$.set({ page: tree.page, node: tree });
      }

      await getChildTopicPagesV3(rootTopicPage, tree);
    }

    isLoading = false;
  }

  async function getChildTopicPagesV3(page, parent = null, currentTreeState = null, currentPageState = null) {
    const res = await TreeNavigatorService.getTocEntryPages(page.suid);

    if (res.resultCode === 0) {
      const pages = [];
      const treeNodes = [];
      (res.result || []).forEach((dto) => {
        const treeNode = TreeNodeItemFactory.createFromTreePageDto(dto, parent, windowBottomCoord);
        treeNodes.push(treeNode);
        nodesByLocalId[treeNode.localId] = treeNode;
        addBackReferenceForNode(treeNode);
      });
      
      // console.log("treeNodes =", treeNodes);
      tree.children = treeNodes;
      tree.childrenLoaded = true;
      // tree.innerPageCount = page.jsonData.innerPageCount;
      tree.expanded = true;
      if (currentTreeState) {
        tree.previousTreeState = currentTreeState;
        tree.previousDisplayedPage = currentPageState;
      }
      childTopicPages = pages;
      loading = false;

      forceRefresh(nodesByLocalId);
      forceRefresh(backReferences);

      return res;
    }
  }

  async function expandNode(treeNode) {
    if (!treeNode.childrenLoaded) {
      const res = await TreeNavigatorService.getTocEntryPages(treeNode.page.suid);

      if (res.resultCode === 0) {
        const treeNodes = [];
        let parentTocEntryId = '';
        if (treeNode.page.isMappedPage) {
          parentTocEntryId = treeNode.tocEntry.entryId;
        }
        (res.result || []).forEach((dto, index) => {
          const newTreeNode = TreeNodeItemFactory.createFromTreePageDto(dto, treeNode, windowBottomCoord);
          treeNodes.push(newTreeNode);
          nodesByLocalId[newTreeNode.localId] = newTreeNode;
          addBackReferenceForNode(newTreeNode);

          // const treeNodePage = createTreeNodeItem(navPage, treeNode);
          // const tempParentId = parentTocEntryId || treeNode.parentTocEntryId;
          // // treeNodePage.parentTocEntryId = tempParentId;
          // treeNodePage.parentTocEntryId = treeNode.tocEntryId;
          // treeNodePage.parentPageSuid = treeNode.page.suid;
          // treeNodePage.parentInnerPageCount = treeNode.innerPageCount;
          // // treeNodePage.tocEntryId = tempParentId ? tempParentId + '_' + treeNodePage.page.suid : treeNodePage.page.jsonData.tocEntryId;
          // if (!treeNodePage.tocEntryId) {
          //   treeNodePage.tocEntryId = treeNode.tocEntryId + "-" + index;
          // }
          // treeNodes.push(treeNodePage);
          // nodesByLocalId[treeNodePage.localId] = treeNodePage;
          // addBackReferenceForNode(navPage, treeNodePage);
        });
        
        treeNode.children = treeNodes;
        treeNode.childrenLoaded = true;
        treeNode.expanded = true;
        tree = tree;
        nodesByLocalId = nodesByLocalId;
      }
    }
  }

  async function onExpandNode(event) {
    const treeNode = event.detail.node;
    await expandNode(treeNode);
  }
  
  function closeUserOptions() {
    dispatch("changeuseroptions", false);
  }

  async function home() {
    await loadRootTopicPage();
    closeUserOptions();
    UrlService.updateURLToHome();
    route = UrlService.getRouteFromLocation();
  }

  function onReloadBasePage() {
    closeUserOptions();
    
    if (currentNavigationTopicPage.suid === rootTopicPage.suid) {
      UrlService.updateURLToHome();
    } else {
      UrlService.updateURLWithSuid(currentNavigationTopicPage.suid);
    }

    route = UrlService.getRouteFromLocation();
    navRootTopicPage$.set(currentNavigationTopicPage);
  }

  function onClickTreeNode(treeNode) {
    currentContentTopicPage = treeNode.page;
    navSelectedItem$.set({ page: currentContentTopicPage, node: treeNode });
    closeUserOptions();
    updateUrlParam(treeNode.page.suid);
  }

  function onSelectNode(event) {
    const treeNode = event.detail.node;
    onClickTreeNode(treeNode);
  }

  async function onDrillIntoTreeNode(treeNode, isClickEvent = true) {
    if (isClickEvent) {
      updateUrlParam(treeNode.page.suid);
    }

    const page = treeNode.page;
    let currentTreeState = null;
    let currentPageState = null;
    let oldTree = tree;

    if (page.suid !== rootTopicPage.suid) {
      tree = tree.dup();
      currentTreeState = { ...tree };
      currentPageState = { ...currentNavigationTopicPage };
    }

    currentNavigationTopicPage = page;
    isAddPageButtonDisplayed = currentNavigationTopicPage.itemType === 1;

    if (!isAddPageButtonDisplayed && showAddPageControls) {
      showAddPageControls = false;
    }

    nodesByLocalId = {};
    await getChildTopicPagesV3(page, treeNode, currentTreeState, currentPageState);
    tree.previousTreeState = oldTree;

    // tree.page = page;  What is the purpose of this. There's no getter on this object.
    forceFullRefresh();

    navRootTopicPage$.set(currentNavigationTopicPage);
  }

  async function onPageBack() {
    const isPreviousStateAvailable = !!tree.previousTreeState;
    if (isPreviousStateAvailable) {
      updateUrlParam(tree.previousDisplayedPage.jsonData.suid || rootTopicPage.suid);
      currentNavigationTopicPage = new TopicPage(tree.previousDisplayedPage.jsonData);
      isAddPageButtonDisplayed = currentNavigationTopicPage.itemType === 1;

      if (tree.page.suid === rootTopicPage.suid) {
        tree = tree.previousTreeState;
      } else {
        await loadRootTopicPage();
      }
    }
  }

  function removeNode(parentNode, originalNode) {
    const backRefs = backReferences[parentNode.backReferenceId];

    if (backRefs === null || backRefs === undefined) {
      return;
    }

    backRefs.forEach(node => {
      if (node.childrenLoaded) {
        const index = node.children.findIndex(el => el.tocEntry.entryId === originalNode.tocEntry.entryId);

        if (index >= 0) {
          if (currentContentTopicPage.suid === node.children[index].pageSuid) {
            currentContentTopicPage = node.page;
            navSelectedItem$.set({ page: currentContentTopicPage, node: tree });
          }

          node.children.splice(index, 1);
        } else {
        }
      }

      if (node.innerPageCount > 0) {
        node.innerPageCount = node.innerPageCount - 1;
      }
    });
  }

  function createAndInsertNode(destNode, sourceNode, index, tocEntryId, tocEntryType, innerPageCount) {
    const backRefs = [ ...backReferences[destNode.backReferenceId] ];

    if (backRefs === null || backRefs === undefined) {
      return;
    }
    
    backRefs.forEach(node => {
      if (node.childrenLoaded) {
        const innerNode = sourceNode.dup();
        innerNode.regenerateLocalId();
        
        if (innerPageCount) {
          innerNode.innerPageCount = innerPageCount;
          innerNode.childrenLoaded = false;
        }
        
        innerNode.tocEntry.entryId = tocEntryId;
        innerNode.tocEntry.type = tocEntryType;
        
        node.children.splice(index, 0, innerNode);
        addBackReferenceForNode(innerNode);
        nodesByLocalId[innerNode.localId] = innerNode;
      }
      node.innerPageCount = node.innerPageCount + 1;
    });
    
    forceRefresh(backReferences);
    forceRefresh(nodesByLocalId);
    forceRefresh(tree);
  }

  function changePageTitle(node, newTitle) {
    const backRefs = [ ...backReferences[node.backReferenceId] ];

    if (!backRefs) {
      return;
    }

    backRefs.forEach(node => {
      node.label = newTitle;
    });
  }

  function refreshPageChildren(node) {
    const backRefs = [ ...backReferences[node.backReferenceId] ];

    if (!backRefs) {
      return;
    }

    backRefs.forEach(node => {
      if (node.childrenLoaded) {
        node.childrenLoaded = false;
        node.expanded = false;
        node.children = [];
      }
    });
  }

  async function moveNodeAbove(sourceParentNode, destParentNode, sourceNode, destNode) {
    const reqBodyDataToInsert = {
      parentPageId: destParentNode.pageSuid,
      childPageId: sourceNode.pageSuid,
      title: sourceNode.label,
      relativeTocEntryId: destNode.tocEntry.entryId,
      tocEntryType: sourceNode.tocEntry.type
    };

    const response1 = await TreeNavigatorService.insertTocEntryPageAbove(reqBodyDataToInsert);
    if (response1.resultCode === 0) {
      const response2 = await TreeNavigatorService.removeTocEntryPage(sourceParentNode.pageSuid, sourceNode.tocEntry.entryId);
      if (response2.resultCode === 0) {
        createAndInsertNode(
          destParentNode,
          sourceNode,
          response1.result.newTocEntryIndex,
          response1.result.newTocEntryId,
          sourceNode.tocEntryType,
          sourceNode.innerPageCount
        );
        removeNode(sourceParentNode, sourceNode);
        forceFullRefresh();
      }
    }
  }

  async function moveNodeBelow(sourceParentNode, destParentNode, sourceNode, destNode) {
    const reqBodyDataToInsert = {
      parentPageId: destParentNode.pageSuid,
      childPageId: sourceNode.pageSuid,
      title: sourceNode.label,
      relativeTocEntryId: destNode.tocEntry.entryId,
      tocEntryType: sourceNode.tocEntry.type
    };
    
    const response1 = await TreeNavigatorService.insertTocEntryPageBelow(reqBodyDataToInsert);
    if (response1.resultCode === 0) {
      const response2 = await TreeNavigatorService.removeTocEntryPage(sourceParentNode.pageSuid, sourceNode.tocEntry.entryId);
      if (response2.resultCode === 0) {
        createAndInsertNode(
          destParentNode,
          sourceNode,
          response1.result.newTocEntryIndex,
          response1.result.newTocEntryId,
          sourceNode.tocEntryType,
          sourceNode.innerPageCount
        );
        removeNode(sourceParentNode, sourceNode);
        forceFullRefresh();
      }
    }
  }

  async function moveNodeToLastChild(sourceParentNode, destParentNode, sourceNode, destNode) {
    const reqBodyDataToInsert = {
      parentPageId: destNode.pageSuid,
      childPageId: sourceNode.pageSuid,
      title: sourceNode.label,
      relativeTocEntryId: null
    };

    // Note: Calling moveTocEntryPageBelow with a null relativeTocEntryId will
    // move the node to the last child of the destination page
    const response1 = await TreeNavigatorService.insertTocEntryPageBelow(reqBodyDataToInsert);
    if (response1.resultCode === 0) {
      const response2 = await TreeNavigatorService.removeTocEntryPage(sourceParentNode.pageSuid, sourceNode.tocEntry.entryId);
      if (response2.resultCode === 0) {
        createAndInsertNode(
          destNode,
          sourceNode,
          response1.result.newTocEntryIndex,
          response1.result.newTocEntryId,
          sourceNode.tocEntryType,
          sourceNode.innerPageCount
        );
        removeNode(sourceParentNode, sourceNode);
        forceFullRefresh();
      }
    }
  }

  async function onMoveNodeBetween(evt) {
    const sourceParentNode = evt.detail.sourceParentNode;
    const sourceNode = evt.detail.sourceNode;
    const destParentNode = evt.detail.destParentNode;
    const destNode1 = evt.detail.destNode1;
    const destNode2 = evt.detail.destNode2;

    if (destNode1 === null && destNode2 !== null) {
      // console.log("Move", sourceNode.label, "in", sourceParentNode.label, "to first child node in", destParentNode.label);
      await moveNodeAbove(sourceParentNode, destParentNode, sourceNode, destNode2);
    } else if (destNode1 !== null && destNode2 !== null) {
      // console.log("Move", sourceNode.label, "in", sourceParentNode.label, "between nodes", destNode1.label, "and", destNode2.label, "in", destParentNode.label);
      await moveNodeBelow(sourceParentNode, destParentNode, sourceNode, destNode1);
    } else if (destNode1 !== null) {
      // console.log("Move", sourceNode.label, "in", sourceParentNode.label, "to last child node in", destParentNode.label);
      await moveNodeBelow(sourceParentNode, destParentNode, sourceNode, destNode1);
    }
  }

  async function onMoveNode(evt) {
    const sourceParentNode = evt.detail.sourceParentNode;
    const sourceNode = evt.detail.sourceNode;
    const destParentNode = evt.detail.destParentNode;
    const destNode = evt.detail.destNode;

    // console.log("Move", sourceNode.label, "in", sourceParentNode.label, "to", destNode.label, "in", destParentNode.label);
    await moveNodeToLastChild(sourceParentNode, destParentNode, sourceNode, destNode);
  }

  async function copyNodeAbove(sourceParentNode, destParentNode, sourceNode, destNode) {
    const reqBodyDataToInsert = {
      parentPageId: destParentNode.pageSuid,
      childPageId: sourceNode.pageSuid,
      title: sourceNode.label,
      relativeTocEntryId: destNode.tocEntry.entryId,
      tocEntryType: sourceNode.tocEntry.type
    }

    // console.log("Inside copyNodeAbove()");
    // console.log("sourceParentNode =", sourceParentNode);
    // console.log("destParentNode =", destParentNode);
    // console.log("sourceNode =", sourceNode);
    // console.log("destNode =", destNode);
    // console.log("reqBodyData =", reqBodyDataToInsert);

    const response = await TreeNavigatorService.insertTocEntryPageAbove(reqBodyDataToInsert);
    if (response.resultCode === 0) {
      createAndInsertNode(
        destParentNode,
        sourceNode,
        response.result.newTocEntryIndex,
        response.result.newTocEntryId,
        sourceNode.tocEntryType,
        sourceNode.innerPageCount
      );
      forceFullRefresh();
    }
  }

  async function copyNodeBelow(sourceParentNode, destParentNode, sourceNode, destNode) {
    const reqBodyDataToInsert = {
      parentPageId: destParentNode.pageSuid,
      childPageId: sourceNode.pageSuid,
      title: sourceNode.label,
      relativeTocEntryId: destNode.tocEntry.entryId,
      tocEntryType: sourceNode.tocEntry.type
    };
    
    // console.log("Inside copyNodeBelow()");
    // console.log("sourceParentNode =", sourceParentNode);
    // console.log("destParentNode =", destParentNode);
    // console.log("sourceNode =", sourceNode);
    // console.log("destNode =", destNode);
    // console.log("reqBodyData =", reqBodyDataToInsert);

    const response = await TreeNavigatorService.insertTocEntryPageBelow(reqBodyDataToInsert);
    // console.log("response =", response);
    if (response.resultCode === 0) {
      // console.log("Copied");
      // console.log("result =", response.result);
      createAndInsertNode(
        destParentNode,
        sourceNode,
        response.result.newTocEntryIndex,
        response.result.newTocEntryId,
        sourceNode.tocEntryType,
        sourceNode.innerPageCount
      );
      forceFullRefresh();
    }
  }

  async function copyNodeToLastChild(sourceParentNode, destParentNode, sourceNode, destNode) {
    const reqBodyData = {
      parentPageId: destNode.pageSuid,
      childPageId: sourceNode.pageSuid,
      title: sourceNode.label,
      relativeTocEntryId: null,
      tocEntryType: sourceNode.tocEntry.type
    };

    // Note: Calling copyTocEntryPageBelow with a null relativeTocEntryId will
    // copy the node to the last child of the destination page
    const response1 = await TreeNavigatorService.insertTocEntryPageBelow(reqBodyData);
    if (response1.resultCode === 0) {
      // console.log("Copied");
      // console.log("result =", response1.result);
      createAndInsertNode(
        destNode,
        sourceNode,
        response1.result.newTocEntryIndex,
        response1.result.newTocEntryId,
        sourceNode.tocEntryType,
        sourceNode.innerPageCount
      );
      forceFullRefresh();
    }
  }

  async function onCopyNodeBetween(evt) {
    const sourceParentNode = evt.detail.sourceParentNode;
    const sourceNode = evt.detail.sourceNode;
    const destParentNode = evt.detail.destParentNode;
    const destNode1 = evt.detail.destNode1;
    const destNode2 = evt.detail.destNode2;

    if (destNode1 === null && destNode2 !== null) {
      // console.log("Copy", sourceNode.label, "in", sourceParentNode.label, "to first child node in", destParentNode.label);
      await copyNodeAbove(sourceParentNode, destParentNode, sourceNode, destNode2);
    } else if (destNode1 !== null && destNode2 !== null) {
      // console.log("Copy", sourceNode.label, "in", sourceParentNode.label, "between nodes", destNode1.label, "and", destNode2.label, "in", destParentNode.label);
      await copyNodeBelow(sourceParentNode, destParentNode, sourceNode, destNode1);
    } else if (destNode1 !== null) {
      // console.log("Copy", sourceNode.label, "in", sourceParentNode.label, "to last child node in", destParentNode.label);
      await copyNodeBelow(sourceParentNode, destParentNode, sourceNode, destNode1);
    }
  }

  async function onCopyNode(evt) {
    const sourceParentNode = evt.detail.sourceParentNode;
    const sourceNode = evt.detail.sourceNode;
    const destParentNode = evt.detail.destParentNode;
    const destNode = evt.detail.destNode;

    // console.log("Copy", sourceNode.label, "in", sourceParentNode.label, "to", destNode.label, "in", destParentNode.label);
    await copyNodeToLastChild(sourceParentNode, destParentNode, sourceNode, destNode);
  }

  async function newNodeAbove(destParentNode, suid, title, destNode, tocEntryType = TOC_ENTRY_TYPE.PAGE_REFERENCE) {
    const reqBodyDataInsert = {
      parentPageId: destParentNode.pageSuid,
      childPageId: suid,
      title: title,
      relativeTocEntryId: destNode.tocEntry.entryId,
      tocEntryType: tocEntryType
    };
    
    const response1 = await TreeNavigatorService.insertTocEntryPageAbove(reqBodyDataInsert);
    if (response1.resultCode === 0) {
      let newNode;
      let innerPageCount = 0;
      
      if (tocEntryType === TOC_ENTRY_TYPE.PAGE_REFERENCE) {
        const response2 = await TreeNavigatorService.getPageById(suid);
        const response3 = await TreeNavigatorService.getTocEntryCount(suid);
        if (response2.resultCode === 0) {
          const newPage = new TopicPage(response2.result);
          const newTocEntry = new TocEntry(response1.result.newTocEntryId, tocEntryType, newPage.suid, newPage.title);
          newNode = new PageTreeNodeItem(destNode, windowBottomCoord, newTocEntry, newPage);
          innerPageCount = response3.result;
        }
      } else {
        const newTocEntry = new TocEntry(response1.result.newTocEntryId, tocEntryType, null, title);
        newNode = new StaticTextTreeNodeItem(destNode, windowBottomCoord, newTocEntry);
      }
        
      createAndInsertNode(
        destParentNode,
        newNode,
        response1.result.newTocEntryIndex,
        response1.result.newTocEntryId,
        tocEntryType,
        innerPageCount
      );
      forceFullRefresh();
    }
  }

  async function newNodeBelow(destParentNode, suid, title, destNode, tocEntryType = TOC_ENTRY_TYPE.PAGE_REFERENCE) {
    const reqBodyDataInsert = {
      parentPageId: destParentNode.pageSuid,
      childPageId: suid,
      title: title,
      relativeTocEntryId: destNode.tocEntry.entryId,
      tocEntryType: tocEntryType
    };

    const response1 = await TreeNavigatorService.insertTocEntryPageBelow(reqBodyDataInsert);
    if (response1.resultCode === 0) {
      let newNode;
      let innerPageCount = 0;

      if (tocEntryType === TOC_ENTRY_TYPE.PAGE_REFERENCE) {
        const response2 = await TreeNavigatorService.getPageById(suid);
        const response3 = await TreeNavigatorService.getTocEntryCount(suid);
        if (response2.resultCode === 0) {
          const newPage = new TopicPage(response2.result);
          const newTocEntry = new TocEntry(response1.result.newTocEntryId, tocEntryType, newPage.suid, newPage.title);
          newNode = new PageTreeNodeItem(destNode, windowBottomCoord, newTocEntry, newPage);
          innerPageCount = response3.result;
        }
      } else {
        const newTocEntry = new TocEntry(response1.result.newTocEntryId, tocEntryType, null, title);
        newNode = new StaticTextTreeNodeItem(destNode, windowBottomCoord, newTocEntry);
      }

      createAndInsertNode(
              destParentNode,
              newNode,
              response1.result.newTocEntryIndex,
              response1.result.newTocEntryId,
              tocEntryType,
              innerPageCount
      );
      forceFullRefresh();
    }
  }

  async function newNodeToFirstChild(destNode, suid, title) {
    const reqBodyData = {
      parentPageId: destNode.pageSuid,
      childPageId: suid,
      title: title,
    };

    const response1 = await TreeNavigatorService.insertTocEntryPageAbove(reqBodyData);

    if (response1.resultCode === 0) {
      const response2 = await TreeNavigatorService.getPageById(suid);
      const response3 = await TreeNavigatorService.getTocEntryCount(suid);
      if (response2.resultCode === 0) {
        const newPage = new TopicPage(response2.result);
        const newTocEntry = new TocEntry(response1.result.newTocEntryId, TOC_ENTRY_TYPE.PAGE_REFERENCE, newPage.suid, newPage.title);
        const newNode = new PageTreeNodeItem(destNode, windowBottomCoord, newTocEntry, newPage);
        
        createAndInsertNode(
          destNode,
          newNode,
          response1.result.newTocEntryIndex,
          response1.result.newTocEntryId,
          TOC_ENTRY_TYPE.PAGE_REFERENCE,
          response3.result
        );
        forceFullRefresh();
      }
    }
  }

  async function newNodeToLastChild(destNode, suid, title, tocEntryType = TOC_ENTRY_TYPE.PAGE_REFERENCE) {
    const reqBodyData = {
      parentPageId: destNode.pageSuid,
      childPageId: suid,
      title: title,
      tocEntryType: tocEntryType
    };

    const response1 = await TreeNavigatorService.insertTocEntryPageBelow(reqBodyData);

    if (response1.resultCode === 0) {
      const response2 = await TreeNavigatorService.getPageById(suid);
      const response3 = await TreeNavigatorService.getTocEntryCount(suid);
      if (response2.resultCode === 0) {
        const newPage = new TopicPage(response2.result);
        const newTocEntry = new TocEntry(response1.result.newTocEntryId, TOC_ENTRY_TYPE.PAGE_REFERENCE, newPage.suid, newPage.title);
        const newNode = new PageTreeNodeItem(destNode, windowBottomCoord, newTocEntry, newPage);

        createAndInsertNode(
          destNode,
          newNode,
          response1.result.newTocEntryIndex,
          response1.result.newTocEntryId,
          TOC_ENTRY_TYPE.PAGE_REFERENCE,
          response3.result
        );
        
        forceFullRefresh();
      }
    }
  }

  async function onNewNodeBetween(evt) {
    const suid = evt.detail.suid;
    const title = evt.detail.title;
    const destParentNode = evt.detail.destParentNode;
    const destNode1 = evt.detail.destNode1;
    const destNode2 = evt.detail.destNode2;

    if (destNode1 === null && destNode2 !== null) {
      // console.log("Create new node", title, "as first child node in", destParentNode.label);
      await newNodeAbove(destParentNode, suid, title, destNode2);
    } else if (destNode1 !== null && destNode2 !== null) {
      // console.log("Create new node", title, "between nodes", destNode1.label, "and", destNode2.label, "in", destParentNode.label);
      await newNodeBelow(destParentNode, suid, title, destNode1);
    } else {
      // console.log("Create new node", title, "as last child node in", destParentNode.label);
      await newNodeBelow(destParentNode, suid, title, destNode1);
    }
  }

  async function onNewNode(evt) {
    const suid = evt.detail.suid;
    const title = evt.detail.title;
    const destNode = evt.detail.destNode;

    // console.log("New node", title);
    await newNodeToLastChild(destNode, suid, title);
  }

  function onPageAddToggle() {
    showAddPageControls = !showAddPageControls;
  }

  async function onPageAdded(evt) {
    // One of the add page components (AddTopicPage, AddMappedPage, or
    // AddExistingPage) has added a new page to the database, but it is 
    // still our job to add it to the table of contents wher we want it 
    // to go

    await newNodeToLastChild(tree, evt.detail.pageSuid, evt.detail.title, TOC_ENTRY_TYPE.PAGE_REFERENCE);
    await refreshAllTopicPagesList();
  }

  async function onTextEntryAdded(evt) {
    await newNodeToLastChild(tree, null, evt.detail.title, TOC_ENTRY_TYPE.STATIC_TEXT);
    await refreshAllTopicPagesList();
  }

  async function refreshAllTopicPagesList() {
    loadingAllTopicPages = true;

    const resp = await TreeNavigatorService.getTopicPages();

    if (resp.resultCode === 0) {
      const pages = [];

      resp.result.forEach((page) => {
        let topicPage = new TopicPage(page);
        pages.push(topicPage);
      });

      allTopicPages = pages;
      loadingAllTopicPages = false;
    }
  }

  async function onRemovePageFromTree(event, node) {
    const resp = await TreeNavigatorService.removeTocEntryPage(node.parent.page.suid, node.tocEntry.entryId);
    if (resp.resultCode === 0) {
      removeNode(node.parent, node);
      forceFullRefresh();
    }
  }

  function removeTemporaryNode(tempNode) {
    const siblingPageIndex = tempNode.parent.children.findIndex(el => el.localId === tempNode.localId);
    tempNode.parent.children.splice(siblingPageIndex, 1);
  }

  async function onBeginAddChild(evt) {
    const parentNode = evt.detail.parentNode;
    const newPageLocation = evt.detail.newPageLocation;

    // Create a temporary node to add to the tree
    // const tempNode = createTreeNodeItem(parentNode.page, parentNode);
    const tempNode = evt.detail.parentNode.dup();
    tempNode.regenerateLocalId();
    tempNode.parent = parentNode;
    tempNode.label = '';
    tempNode.relationshipType = 'child';
    tempNode.pageCreating = true;
    tempNode.newPageLocation = newPageLocation;

    // Force parent node to expand and load children if it isn't already
    parentNode.expanded = true;
    if (!parentNode.children.length) {
      await expandNode(parentNode);
    }

    // Add the temporary node to the top or bottom of the children
    if (newPageLocation === 'top') {
      parentNode.children.unshift(tempNode);
    } else if (newPageLocation === 'bottom') {
      parentNode.children.push(tempNode);
    } else {
      throw new Error("Unknown page location");
    }

    forceRefresh(tree);
  }

  async function onCompleteAddChild(evt) {
    const tempNode = evt.detail.tempNode;
    const newPageTitle = evt.detail.newPageTitle;
    
    removeTemporaryNode(tempNode);

    const postData = {
      title: newPageTitle
    };

    // Add the new node to the page and add it to the table of contents
    // of the parent page
    const response = await TreeNavigatorService.createTopicPage(postData);
    if (response.resultCode === 0) {
      const topicPage = new TopicPage(response.result);
      if (tempNode.newPageLocation === 'top') {
        await newNodeToFirstChild(tempNode.parent, topicPage.suid, topicPage.title);
      } else if (tempNode.newPageLocation === 'bottom') {
        await newNodeToLastChild(tempNode.parent, topicPage.suid, topicPage.title);
      }
    }

    forceRefresh(tree);
  }

  async function onCancelAddChild(evt) {
    const tempNode = evt.detail.tempNode;

    removeTemporaryNode(tempNode);
    forceRefresh(tree);
  }

  async function onBeginAddSibling(evt) {
    const relativeNode = evt.detail.relativeNode;
    const newPageLocation = evt.detail.newPageLocation;
    
    // Create a temporary node to add to the tree
    // const tempNode = createTreeNodeItem(relativeNode.page, relativeNode.parent);
    const tempNode = relativeNode.dup();
    tempNode.regenerateLocalId();
    tempNode.parent = relativeNode.parent;
    tempNode.label = '';
    tempNode.relationshipType = 'sibling';
    tempNode.pageCreating = true;
    tempNode.newPageLocation = newPageLocation;
    tempNode.relativeNode = relativeNode;

    // Add the temporary node to the tree either above or below the selected sibling
    const siblingPageIndex = relativeNode.parent.children.findIndex(el => el.localId === relativeNode.localId);
    if (newPageLocation === 'above') {
      relativeNode.parent.children.splice(siblingPageIndex, 0, tempNode);
    } else if (newPageLocation === 'below') {
      relativeNode.parent.children.splice(siblingPageIndex + 1, 0, tempNode);
    } else {
      throw new Error("Unknown page location");
    }

    forceRefresh(tree);
  }

  async function onCompleteAddSibling(evt) {
    const tempNode = evt.detail.tempNode;
    const newPageTitle = evt.detail.newPageTitle;

    removeTemporaryNode(tempNode);

    const postData = {
      title: newPageTitle
    };

    // Add the new node to the page and add it to the table of contents
    // of the sibling's parent
    const response = await TreeNavigatorService.createTopicPage(postData);
    if (response.resultCode === 0) {
      const topicPage = new TopicPage(response.result);
      if (tempNode.newPageLocation === 'above') {
        await newNodeAbove(tempNode.parent, topicPage.suid, topicPage.title, tempNode.relativeNode);
      } else if (tempNode.newPageLocation === 'below') {
        await newNodeBelow(tempNode.parent, topicPage.suid, topicPage.title, tempNode.relativeNode);
      }
    }
  }

  async function onCancelAddSibling(evt) {
    const tempNode = evt.detail.tempNode;

    removeTemporaryNode(tempNode);
    forceRefresh(tree);
  }

  async function onBeginAddTextHeading(evt) {
    const relativeNode = evt.detail.relativeNode;
    const newPageLocation = evt.detail.newPageLocation;

    // Create a temporary node to add to the tree
    // const tempNode = createTreeNodeItem(relativeNode.page, relativeNode.parent);
    const tempNode = relativeNode.dup();
    tempNode.regenerateLocalId();
    tempNode.parent = relativeNode.parent;
    tempNode.label = '';
    tempNode.relationshipType = 'sibling';
    tempNode.textHeadingCreating = true;
    tempNode.newPageLocation = newPageLocation;
    tempNode.relativeNode = relativeNode;

    // Add the temporary node to the tree either above or below the selected sibling
    const siblingPageIndex = relativeNode.parent.children.findIndex(el => el.localId === relativeNode.localId);
    if (newPageLocation === 'above') {
      relativeNode.parent.children.splice(siblingPageIndex, 0, tempNode);
    } else if (newPageLocation === 'below') {
      relativeNode.parent.children.splice(siblingPageIndex + 1, 0, tempNode);
    } else {
      throw new Error("Unknown page location");
    }

    forceRefresh(tree);
  }

  async function onCompleteAddTextHeading(evt) {
    const tempNode = evt.detail.tempNode;
    const title = evt.detail.newPageTitle;
    
    removeTemporaryNode(tempNode);

    const postData = {
      title: title
    };

    // Add the new node to the page and add it to the table of contents
    // of the sibling's parent
    if (tempNode.newPageLocation === 'above') {
      await newNodeAbove(tempNode.parent, null, title, tempNode.relativeNode, TOC_ENTRY_TYPE.STATIC_TEXT);
    } else if (tempNode.newPageLocation === 'below') {
      await newNodeBelow(tempNode.parent, null, title, tempNode.relativeNode, TOC_ENTRY_TYPE.STATIC_TEXT);
    }
  }

  async function onCancelAddTextHeading(evt) {
    const tempNode = evt.detail.tempNode;

    removeTemporaryNode(tempNode);
    forceRefresh(tree);
  }

  function pageOptionsUpdated(node, changeDetails) {
    if (changeDetails.titleChanged) {
      changePageTitle(node, changeDetails.title);
      forceRefresh(tree);

      if (currentContentTopicPage.suid === node.pageSuid) {
        currentContentTopicPage = node.page;
        navSelectedItem$.set({ page: currentContentTopicPage, node: tree });
      }
    }

    if (node.tocEntryType === TOC_ENTRY_TYPE.PAGE_REFERENCE && !changeDetails.isTopic && (changeDetails.mountPointIdChanged || changeDetails.relativePathChanged)) {
      refreshPageChildren(node);
      forceRefresh(tree);
    }
  }
  
  function toggleDragAndDrop() {
    enableDragAndDrop = !enableDragAndDrop;
  }
</script>

<style lang="scss">
  @import '../../../static/styles/theme-standard';
  @import '../../../static/styles/core-fonts';

  .topic-pages {
    //padding-top: 10px;
  }

  /*.tree-navigator-container {
    position: relative;

    .loader {
      background-color: rgba(231, 229, 228, 0.7);
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }
  */

  .page-tree {
    //border: 1px solid black;
    width: 100%;
    vertical-align: middle;
    border: none;
    margin: 0;
    background-color: $navigator-pagelist-background;
    color: $navigator-pagelist-text;
    font-size: 11pt;
    font-family: $font-family-2;

    :global(.selected) {
      opacity: 0.6;
    }
  }

  .navigator-header-title2 {
    display: flex;
    flex-direction: row;
    
    span {
      font-size: 24px;
      
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      &.back-button {
        margin-right: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover, &:hover span {
          text-decoration: none;
          font-weight: bold;
        }
      }      
    }

    //&--title-row {
    //  display: flex;
    //  justify-content: space-between;
    //  align-items: center;
    //
    //  &--title-wrap {
    //    display: flex;
    //    max-width: 90%;
    //    
    //    .show-page {
    //      width: 100%;
    //      word-break: break-word;
    //      align-items: center;
    //
    //      &:hover {
    //        text-decoration: underline;
    //        cursor: pointer;
    //      }
    //    }
    //  }
    //}
  }

  /*.page-link-cell {
    width: 100%;
    max-width: 100%;

    .remove {
      font-weight: bold;
    }
  }*/
</style>

<AppStylesPane>
  <PaneWithTopHeader bodyBackgroundColor="rgba(231, 229, 228, 0.7)" border="none">
    <svelte:fragment slot="header">
      <TwoPartHeaderPane backgroundColor="rgba(231, 229, 228, 0.7)">
        <svelte:fragment slot="left">
          {#if currentNavigationTopicPage}
            <VerticalCenter>
              <VerticalCenterItem>
                <div class="navigator-header-title2">
                  {#if tree.previousTreeState}
                    <span class="back-button" on:click={onPageBack}>
                      <span class="material-icons-outlined icon">arrow_back</span>
                    </span>
                  {/if}
                  {#if currentNavigationTopicPage}
                    <span class="show-page ml-1" on:click={onReloadBasePage}>{currentNavigationTopicPage.title}</span>
                  {:else}
                    No Page Loaded
                  {/if}
                </div>
              </VerticalCenterItem>
            </VerticalCenter>
          {/if}
        </svelte:fragment>
        <svelte:fragment slot="right">
          <VerticalCenterItem>
            <div class="navigator-header-title2">
              {#if isAddPageButtonDisplayed && !showAddPageControls}
                <MaterialIcon iconName="arrow_drop_down" size="large" on:click={onPageAddToggle} />
              {:else if isAddPageButtonDisplayed && showAddPageControls}
                <MaterialIcon iconName="arrow_drop_up" size="large" on:click={onPageAddToggle} />
              {/if}
            </div>
          </VerticalCenterItem>
        </svelte:fragment>
      </TwoPartHeaderPane>
    </svelte:fragment>
    <svelte:fragment slot="body">
      <ScrollablePane padding={false} margin={true}>
        {#if showAddPageControls}
          <div style="font-size: 9pt; padding: 10px">
            {#if enableDragAndDrop}
              <button on:click={toggleDragAndDrop}>Disable Drag & Drop</button>
            {:else}
              <button on:click={toggleDragAndDrop}>Enable Drag & Drop</button>
            {/if}
            <hr>
            <AddTopicPage on:pageadded={onPageAdded} />
            <hr>
            <AddMappedPage on:pageadded={onPageAdded} />
            <hr>              
          </div>
        {/if}
        {#if isLoading}
          <table class="page-tree">
            <tr>
              <td>
                Loading ...
              </td>
            </tr>
          </table>
        {:else if !tree || !tree.children || tree.children.length === 0}
          <table class="page-tree">
            <tr>
              <td>
                This page does not contain any child pages. Press the +
                icon above to add child pages.
              </td>
            </tr>
          </table>
        {:else}
          <table class="page-tree">
            <PageTree
              tree={tree}
              nodesByLocalId={nodesByLocalId}
              onClick={onClickTreeNode}
              enableDragAndDrop={enableDragAndDrop}
              on:nodeexpanded={onExpandNode}
              on:nodeselected={onSelectNode}
              on:movenode={onMoveNode}
              on:movenodebetween={onMoveNodeBetween}
              on:copynode={onCopyNode}
              on:copynodebetween={onCopyNodeBetween}
              on:newnode={onNewNode}
              on:newnodebetween={onNewNodeBetween}
              on:beginaddchild={onBeginAddChild}
              on:completeaddchild={onCompleteAddChild}
              on:canceladdchild={onCancelAddChild}
              on:beginaddsibling={onBeginAddSibling}
              on:completeaddsibling={onCompleteAddSibling}
              on:canceladdsibling={onCancelAddSibling}
              on:beginaddtextheading={onBeginAddTextHeading}
              on:completeaddtextheading={onCompleteAddTextHeading}
              on:canceladdtextheading={onCancelAddTextHeading}              
              onDrillInto={onDrillIntoTreeNode}
              onRemovePage={onRemovePageFromTree}
              onPageOptUpdated={pageOptionsUpdated}
            />
          </table>
        {/if}              
      </ScrollablePane>
    </svelte:fragment>
  </PaneWithTopHeader>
</AppStylesPane>  
