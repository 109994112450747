<script>
  import { onMount, onDestroy, createEventDispatcher } from 'svelte';
  import { RequestService } from "../../comm/RequestService";
  import { mountPointTable$, mountPointsList$, externalConnectionTable$ } from '../../stores';
  import {
    CONNECTION_TYPE,
    CONNECTION_TYPE_LIST,
    GIT_CREDENTIALS_TYPE,
  } from "../../constants";
  import { getDefaultViewUrl, getDefaultGitHostName } from './DefaultViewParams';
  import { UrlService } from "../../comm/UrlService";
  import Loading from "../layout-helpers/Loading.svelte";

  export let organization = null;
  const dispatch = createEventDispatcher();

  let metadata = getDefaultMetadata();
  let externalConnections = [];
  let isFormDisplayed = false;
  let isCreateMode = false;
  let formData;
  let loading = false;
  let showRestoreDefault;
  const OAUTH_PARAM = {
    Mode: 'mode',//create & edit
    Type: 'type',//2
    Name: 'name',
    Description: 'description',
    OrgName: 'orgName',
    Authorization: 'authorization',//1, 2 & 3(GIT_CREDENTIALS_TYPE)
    GitHostName: 'gitHostName',//Azure DevOps
    GitRepoUrl: 'gitRepoUrl',
    GitViewUrl: 'gitViewUrl',
    Code: 'code',
    ConnectionId: 'connectionId',
  };

  $: showRestoreDefault = formData && hasDefaultRepoUrl();
  $: {
    if (formData && formData.connectionType) {
      if (formData.currentConnectionType !== formData.connectionType) {
        onConnectionTypeChanged();
      }
    }
  }

  const unsubscribeUpdateTable = externalConnectionTable$.subscribe(value => {
    if (value === 'update') {
      getExternalConnectionsList();
      externalConnectionTable$.set('');
    }
  });

  onMount(async () => {
    await getExternalConnectionsList();
    resetForm();
    checkUrlParams();
  });

  onDestroy(unsubscribeUpdateTable);

  function resetForm() {
    formData = {
      id: '',
      connectionType: CONNECTION_TYPE.AZURE_DEV_OPS,
      name: '',
      description: '',
      orgName: '',
      gitAccessType: GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN,
      loginId: '',
      password: '',
      token: '',
      oauthToken: '',
      repoUrl: '',
      hostPath: '',
      gitHostDisplayName: '',
      currentConnectionType: CONNECTION_TYPE.AZURE_DEV_OPS,
      currentGitAccessType: GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN,
      isDataFromServer: false,
      organizationId: ''
    };

    metadata = getDefaultMetadata();
    const metadataKey = getMetadataKey(formData.connectionType, formData.gitAccessType);
    formData.repoUrl = metadata[metadataKey].currentRepoUrl;
  }

  function checkUrlParams() {
    const isUserOptionParam = UrlService.isParamInURL(OAUTH_PARAM.Mode);
    if (isUserOptionParam) {
      if (UrlService.getPathValue(OAUTH_PARAM.Mode) === 'create') {
        onShowConnectionForm();
        formData = {
          connectionType: +UrlService.getPathValue(OAUTH_PARAM.Type),
          name: decodeURIComponent(UrlService.getPathValue(OAUTH_PARAM.Name)),
          description: decodeURIComponent(UrlService.getPathValue(OAUTH_PARAM.Description)),
          orgName: UrlService.getPathValue(OAUTH_PARAM.OrgName),
          gitAccessType: +UrlService.getPathValue(OAUTH_PARAM.Authorization),
          token: '',
          oauthToken: '',
          repoUrl: UrlService.getPathValue(OAUTH_PARAM.GitRepoUrl),
          hostPath: UrlService.getPathValue(OAUTH_PARAM.GitViewUrl),
          gitHostDisplayName: UrlService.getPathValue(OAUTH_PARAM.GitHostName),
          currentConnectionType: +UrlService.getPathValue(OAUTH_PARAM.Type),
          currentGitAccessType: +UrlService.getPathValue(OAUTH_PARAM.Authorization),
          id: UrlService.getPathValue(OAUTH_PARAM.ConnectionId),
          isDataFromServer: true,
        };
        onRestoreDefault();
        formData.hostPath = getDefaultViewUrl(formData);
        if (!!formData.id) {
          isCreateMode = false;
        }
        if (UrlService.isParamInURL(OAUTH_PARAM.Code)) {
          getOauthPAT();
        } else {
          resetUrl();
        }
      }
    }
  }

  function getMetadataKey(connectionType, gitAccessType) {
    return connectionType + "-" + gitAccessType;
  }

  function hasDefaultRepoUrl() {
    if (!formData || !formData.connectionType || !formData.gitAccessType) {
      return false;
    }
    const metadataKey = getMetadataKey(formData.connectionType, formData.gitAccessType);

    return metadata[metadataKey].defaultRepoUrl !== '';
  }

  function getDefaultMetadata() {
    return {
      [getMetadataKey(CONNECTION_TYPE.GENERIC_GIT_HOST, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS)]: {
        defaultRepoUrl: '',
        userOverrodeDefault: false,
        currentRepoUrl: '',
        allowed: false,
        loginIdLabel: "Username",
        passwordLabel: "Password"
      },
      [getMetadataKey(CONNECTION_TYPE.GENERIC_GIT_HOST, GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN)]: {
        defaultRepoUrl: '',
        userOverrodeDefault: false,
        currentRepoUrl: '',
        allowed: true
      },
      [getMetadataKey(CONNECTION_TYPE.GENERIC_GIT_HOST, GIT_CREDENTIALS_TYPE.OAUTH)]: {
        defaultRepoUrl: '',
        userOverrodeDefault: false,
        currentRepoUrl: '',
        allowed: true
      },
      [getMetadataKey(CONNECTION_TYPE.AZURE_DEV_OPS, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS)]: {
        defaultRepoUrl: 'https://$ORGNAME@dev.azure.com/$ORGNAME/$PROJECTNAME/_git/$REPONAME',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://$ORGNAME@dev.azure.com/$ORGNAME/$PROJECTNAME/_git/$REPONAME',
        allowed: false,
        loginIdLabel: "Username",
        passwordLabel: "Password"
      },
      [getMetadataKey(CONNECTION_TYPE.AZURE_DEV_OPS, GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN)]: {
        defaultRepoUrl: 'https://$PAT@dev.azure.com/$ORGNAME/$PROJECTNAME/_git/$REPONAME',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://$PAT@dev.azure.com/$ORGNAME/$PROJECTNAME/_git/$REPONAME',
        allowed: true
      },
      [getMetadataKey(CONNECTION_TYPE.AZURE_DEV_OPS, GIT_CREDENTIALS_TYPE.OAUTH)]: {
        defaultRepoUrl: 'https://$ORGNAME:$PAT@dev.azure.com/$ORGNAME/$PROJECTNAME/_git/$REPONAME',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://$ORGNAME:$PAT@dev.azure.com/$ORGNAME/$PROJECTNAME/_git/$REPONAME',
        allowed: true
      },
      [getMetadataKey(CONNECTION_TYPE.GITHUB, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS)]: {
        defaultRepoUrl: 'https://github.com/$USERNAME/$REPONAME.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://github.com/$USERNAME/$REPONAME.git',
        allowed: false,
        loginIdLabel: "Username",
        passwordLabel: "Password"
      },
      [getMetadataKey(CONNECTION_TYPE.GITHUB, GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN)]: {
        defaultRepoUrl: 'https://$PAT@github.com/$OWNERNAME/$REPONAME.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://$PAT@github.com/$OWNERNAME/$REPONAME.git',
        allowed: true
      },
      [getMetadataKey(CONNECTION_TYPE.GITHUB, GIT_CREDENTIALS_TYPE.OAUTH)]: {
        defaultRepoUrl: 'https://$PAT@github.com/$OWNERNAME/$REPONAME.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://$PAT@github.com/$OWNERNAME/$REPONAME.git',
        allowed: true
      },
      [getMetadataKey(CONNECTION_TYPE.BITBUCKET_CLOUD, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS)]: {
        defaultRepoUrl: 'https://$AUTH_USERNAME:$AUTH_PASSWORD@bitbucket.org/$WORKSPACEID/$REPONAME.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://$AUTH_USERNAME:$AUTH_PASSWORD@bitbucket.org/$WORKSPACEID/$REPONAME.git',
        allowed: true,
        loginIdLabel: "Username",
        passwordLabel: "App Password"
      },
      [getMetadataKey(CONNECTION_TYPE.BITBUCKET_CLOUD, GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN)]: {
        defaultRepoUrl: 'https://x-oauth-basic:$PWD@bitbucket.org/$WORKSPACEID/$REPONAME.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://x-oauth-basic:$PWD@bitbucket.org/$WORKSPACEID/$REPONAME.git',
        allowed: false
      },
      [getMetadataKey(CONNECTION_TYPE.BITBUCKET_CLOUD, GIT_CREDENTIALS_TYPE.OAUTH)]: {
        defaultRepoUrl: 'https://x-token-auth:$PAT@bitbucket.org/$WORKSPACEID/$REPONAME.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://x-token-auth:$PAT@bitbucket.org/$WORKSPACEID/$REPONAME.git',
        allowed: false
      },
      [getMetadataKey(CONNECTION_TYPE.GITLAB, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS)]: {
        defaultRepoUrl: 'https://gitlab.com/$OWNERNAME/$PROJECTSLUG.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://gitlab.com/$OWNERNAME/$PROJECTSLUG.git',
        allowed: false,
        loginIdLabel: "Username",
        passwordLabel: "Password"
      },
      [getMetadataKey(CONNECTION_TYPE.GITLAB, GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN)]: {
        defaultRepoUrl: 'https://oauth2:$PAT@gitlab.com/$OWNERNAME/$PROJECTSLUG.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://oauth2:$PAT@gitlab.com/$OWNERNAME/$PROJECTSLUG.git',
        allowed: true
      },
      [getMetadataKey(CONNECTION_TYPE.GITLAB, GIT_CREDENTIALS_TYPE.OAUTH)]: {
        defaultRepoUrl: 'https://oauth2:$PAT@gitlab.com/$OWNERNAME/$PROJECTSLUG.git',
        userOverrodeDefault: false,
        currentRepoUrl: 'https://oauth2:$PAT@gitlab.com/$OWNERNAME/$PROJECTSLUG.git',
        allowed: true
      }
    }
  }

  function onShowConnectionForm() {
    isFormDisplayed = !isFormDisplayed;
    isCreateMode = true;
    formData.hostPath = getDefaultViewUrl(formData);
    formData.gitHostDisplayName = getDefaultGitHostName(formData);
  }

  async function getExternalConnectionsList() {
    loading = true;
    let requestUrl = '';
    if (!!organization) {
      requestUrl = `apiV2/externalConnection?orgId=${organization.id.value}`;
    } else {
      requestUrl = 'apiV2/externalConnection';
    }

    const resp = await RequestService.get(requestUrl);

    if (resp.resultCode === 0) {
      externalConnections = resp.result;
    } else {
      console.error('externalConnections ', response.resultMessage);
      externalConnections = [];
    }
    loading = false;
  }

  function onEditConnection(connection) {
    connection.currentConnectionType = connection.connectionType;
    connection.currentGitAccessType = connection.gitAccessType;
    connection.isDataFromServer = true;
    formData = connection;
    isFormDisplayed = true;
    isCreateMode = false;
  }

  async function onDeleteConnection(connection) {
    let canDelete;
    connection = externalConnections.find((ec) => ec.id === connection.id);
    if (connection.mountPointIds.length) {
      canDelete = confirm(`
      Are you sure you want to delete connection ${connection.name}?
      Warning!!! All mount points associated with this connection will be removed.`);
    } else {
      canDelete = confirm(`Are you sure you want to delete connection ${connection.name}?`);
    }

    if (canDelete) {
      const requestUrl = `apiV2/externalConnection/${connection.id}`;
      const delResp = await RequestService.delete(requestUrl);

      if (delResp.resultCode === 0) {
        updateMountPointTable();
        mountPointsList$.set('update');
        await getExternalConnectionsList();
      } else {
        console.error('Error', delResp.resultMessage)
      }
    }
  }

  function updateMountPointTable() {
    mountPointTable$.set('update');
  }

  async function saveData() {
    formData.id = isCreateMode ? '' : formData.id;
    if (!!organization) {
      formData.organizationId = organization.id.value;
    }

    const requestUrl = `apiV2/externalConnection`;
    let response;

    if (!isCreateMode) {
      response = await RequestService.put(requestUrl, formData);
    } else {
      response = await RequestService.post(requestUrl, formData);
    }

    if (response.resultCode === 0) {
      await getExternalConnectionsList();
      updateMountPointTable();
      resetForm();
      isFormDisplayed = false;
    } else {
      console.error('Error', response.resultMessage || '')
    }
  }

  function onRestoreDefault() {
    let metadataKey = getMetadataKey(formData.currentConnectionType, formData.currentGitAccessType);
    formData.repoUrl = metadata[metadataKey].defaultRepoUrl;
  }

  function onCloseConnectionForm() {
    resetForm();
    isFormDisplayed = false;
  }

  function onConnectionTypeChanged() {
    let oldConnectionType = formData.currentConnectionType;
    let oldGitAccessType = formData.currentGitAccessType;
    let oldMetadataKey = getMetadataKey(oldConnectionType, oldGitAccessType);
    metadata[oldMetadataKey].currentRepoUrl = formData.repoUrl;

    let metadataKey = getMetadataKey(formData.connectionType, formData.gitAccessType);
    if (!metadata[metadataKey].allowed) {
      if (metadata[getMetadataKey(formData.connectionType, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS)].allowed) {
        formData.gitAccessType = GIT_CREDENTIALS_TYPE.USER_CREDENTIALS;
      } else if (metadata[getMetadataKey(formData.connectionType, GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN)].allowed) {
        formData.gitAccessType = GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN;
      }
      metadataKey = getMetadataKey(formData.connectionType, formData.gitAccessType);
    }

    formData.repoUrl = metadata[metadataKey].currentRepoUrl;
    formData.currentConnectionType = formData.connectionType;

    if (!formData.isDataFromServer) {
      formData.hostPath = getDefaultViewUrl(formData);
      formData.gitHostDisplayName = getDefaultGitHostName(formData);
    }
  }

  function onGitTypeChanged() {
    let oldConnectionType = formData.currentConnectionType;
    let oldGitAccessType = formData.currentGitAccessType;
    let oldMetadataKey = getMetadataKey(oldConnectionType, oldGitAccessType);
    metadata[oldMetadataKey].currentRepoUrl = formData.repoUrl;

    let metadataKey = getMetadataKey(formData.connectionType, formData.gitAccessType);

    formData.repoUrl = metadata[metadataKey].currentRepoUrl;
    formData.currentGitAccessType = formData.gitAccessType;
  }

  async function onAuthorizeUser() {
    let userOptionType = '';
    if (!!organization) {
      userOptionType = 'org_external_connection';
    } else {
      userOptionType = 'external_connection';
    }

    let stateUrl = `&userOption=true&userOptionType=${userOptionType}&mode=create&type=${formData.connectionType}&authorization=${GIT_CREDENTIALS_TYPE.OAUTH}&name=${encodeURIComponent(formData.name)}&description=${encodeURIComponent(formData.description)}&orgName=${formData.orgName}&gitHostName=${formData.gitHostDisplayName}&empty=`;
    if (!!organization) {
      stateUrl = `&orgId=${organization.id.value}` + stateUrl;
    }
    if (!!formData.id) {
      stateUrl = `&connectionId=${formData.id}` + stateUrl;
    }
    if (formData.connectionType === CONNECTION_TYPE.AZURE_DEV_OPS) {
      stateUrl = encodeURIComponent(stateUrl);
    }

    const reqUrl = '/apiV2/oauth/authorize';
    const reqData = {
      state: stateUrl,
      tokenProviderType: +formData.connectionType
    };
    const resp = await RequestService.post(reqUrl, reqData);

    if (resp.resultCode === 0) {
      window.location.href = resp.result;
    } else {
      console.error(resp.errorMessage)
    }
  }

  async function getOauthPAT() {
    const params = window.location.search;

    const url = `/apiV2/oauth/token${params}`;
    const resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      formData.oauthAccessToken = resp.result.accessToken;
      formData.oauthTokenType = resp.result.tokenType;
      formData.oauthRefreshToken = resp.result.refreshToken;
      formData.oauthExpiresIn = resp.result.expiresIn;
      formData.oauthRefreshTokenExpiresIn = resp.result.refreshTokenExpiresIn;
      formData.oauthScope = resp.result.scope;
      formData.createdAt = resp.result.createdAt;

      resetUrl();
    } else {
      console.error(resp.errorMessage);
    }
  }

  function resetUrl() {
    const newUrl = document.location.protocol + "//" + document.location.host;
    history.pushState({ urlString: '' }, '', newUrl);
  }

  function onBackToList() {
    dispatch('changeorgstate', { state: 'organization' });
  }
</script>

<style lang="scss">
  .external-connection-section {
    h3 {
      width: 400px;
    }

    td, th {
      border: 1px solid grey;
      min-width: 180px;
    }

    td:first-child, th:first-child {
      min-width: 220px;
    }

    .action-btn:last-child {
      margin-right: 0;
    }

    .external-connection-form-wrap {
      border: 1px solid gray;
      padding: 10px;
      width: max-content;
      height: max-content;
    }

    .external-connection-repo-url input {
      width: 400px;
    }

    .external-connection-host-name {
      padding-top: 5px;

      input {
        width: 264px;
        margin-left: 5px;
      }
    }

    .form-field {
      input[type='text'] {
        min-width: 200px;
      }
    }

    .external-connection-repo-url, .external-connection-host-name {
      padding-bottom: 5px;
    }
  }
</style>

<div class="external-connection-section">
  {#if organization}
    <button on:click={onBackToList} class="back-btn">
      <span class="material-icons-outlined">arrow_back_ios</span>
      Back To Organizations List
    </button>
  {/if}
  <Loading {loading}>
    {#if organization}
      <h3>Current External Connections
        for {organization.fullName ? organization.fullName.toUpperCase(): organization.name.toUpperCase()}</h3>
    {:else}
      <h3 class="form-subsection">Current External Connections</h3>
    {/if}

    {#if externalConnections.length}
      <table id="external-connection-table">
        <tbody>
        <tr>
          <th>Name</th>
          <th>Actions</th>
        </tr>
        {#each externalConnections as connection (connection.id)}
          <tr>
            <td>{connection.name}</td>
            <td>
              <button type="button" class="action-btn"
                      on:click={() => onEditConnection(connection)}>Edit
              </button>
              <button type="button" class="action-btn"
                      on:click={() => onDeleteConnection(connection)}>Delete
              </button>
            </td>
          </tr>
        {/each}
        </tbody>
      </table>
    {:else}
      <div id="no-connections-text">There are no current external connections.</div>
    {/if}

    {#if !isFormDisplayed}
      <button type="button" on:click={onShowConnectionForm}>Add New</button>
    {:else}
      <form id="external-connection-form" class="external-connection-form-wrap"
            on:submit|preventDefault={(event) => saveData(event)}>
        <label class="form-field">Connection Type:
          <select bind:value={formData.connectionType}>
            {#each CONNECTION_TYPE_LIST as cType}
              <option value="{cType.value}">{cType.label}</option>
            {/each}
          </select>
        </label>
        <div class="inner-select-content">
          <div id="external-connection-common-form">
            <label class="form-field">Name *:
              <input type="text" bind:value={formData.name} required>
            </label>
            <label class="form-field">Description:
              <input type="text" bind:value={formData.description}>
            </label>
            {#if formData.connectionType === CONNECTION_TYPE.AZURE_DEV_OPS}
              <label class="form-field" id="org-name">Organization Name *:
                <input type="text" required autocomplete="new-password" bind:value={formData.orgName}>
              </label>
            {/if}
            {#if formData.connectionType === CONNECTION_TYPE.BITBUCKET_CLOUD}
              <label class="form-field" id="org-name">Workspace ID or<br>Username *:
                <input type="text" required bind:value={formData.orgName}>
              </label>
            {/if}
            {#if formData.connectionType === CONNECTION_TYPE.GITHUB}
              <label class="form-field" id="org-name">Owner Name (User or Organization) *:
                <input type="text" required bind:value={formData.orgName}>
              </label>
            {/if}
            {#if formData.connectionType === CONNECTION_TYPE.GITLAB}
              <label class="form-field" id="org-name">Owner Name (User or Group) *:
                <input type="text" required bind:value={formData.orgName}>
              </label>
            {/if}
          </div>
          <div id="external-connection-auth-form">
            <fieldset>
              <legend>Authentication</legend>
              {#if metadata[ getMetadataKey(formData.connectionType, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS) ].allowed}
                <label class="form-field field-wrap-radio">
                  <input type=radio bind:group={formData.gitAccessType}
                         value={GIT_CREDENTIALS_TYPE.USER_CREDENTIALS} on:change={onGitTypeChanged}>
                  User Credentials
                </label>
                <div class="sub-fields">
                  <label class="form-field">
                    {metadata[ getMetadataKey(formData.connectionType, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS) ].loginIdLabel}
                    *:
                    <input type="text" bind:value={formData.loginId}
                           required autocomplete="new-password"
                           disabled="{formData.gitAccessType !== GIT_CREDENTIALS_TYPE.USER_CREDENTIALS}">
                  </label>
                  <label class="form-field">
                    {metadata[ getMetadataKey(formData.connectionType, GIT_CREDENTIALS_TYPE.USER_CREDENTIALS) ].passwordLabel}
                    *:
                    <input type="password" bind:value={formData.password}
                           required minlength="6"
                           autocomplete="new-password"
                           disabled="{formData.gitAccessType !== GIT_CREDENTIALS_TYPE.USER_CREDENTIALS}">
                  </label>
                </div>
              {/if}
              {#if metadata[ getMetadataKey(formData.connectionType, GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN) ].allowed}
                <label class="form-field field-wrap-radio">
                  <input type=radio bind:group={formData.gitAccessType}
                         value={GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN} on:change={onGitTypeChanged}>
                  Personal Access Token
                </label>
                <div class="sub-fields">
                  <label class="form-field">Token *:
                    <input type="password" autocomplete="new-password"
                           bind:value={formData.personalAccessToken}
                           disabled="{formData.gitAccessType !== GIT_CREDENTIALS_TYPE.PERSONAL_ACCESS_TOKEN}"
                           required>
                  </label>
                </div>
              {/if}
              {#if formData.connectionType !== CONNECTION_TYPE.GENERIC_GIT_HOST}
                <label class="form-field field-wrap-radio">
                  <input type=radio bind:group={formData.gitAccessType}
                         value={GIT_CREDENTIALS_TYPE.OAUTH} on:change={onGitTypeChanged}>
                  Authorizing OAuth
                </label>
                <div class="sub-fields">
                  <label class="form-field">Token *:
                    <input type="password" autocomplete="new-password"
                           bind:value={formData.oauthAccessToken}
                           disabled required
                    >
                  </label>
                  {#if !formData.id}
                    <button type="button" on:click="{onAuthorizeUser}"
                            disabled="{formData.gitAccessType !== GIT_CREDENTIALS_TYPE.OAUTH}">
                      Authorize
                    </button>
                  {:else }
                    <button type="button" on:click="{onAuthorizeUser}"
                            disabled="{formData.gitAccessType !== GIT_CREDENTIALS_TYPE.OAUTH}">
                      Refresh Token
                    </button>
                  {/if}
                </div>
              {/if}
            </fieldset>
            <div class="external-connection-host-name">
              <label for="repo-host-name">Git Host Display Name *</label>
              <input type="text" id="repo-host-name" bind:value={formData.gitHostDisplayName} required>
              {#if showRestoreDefault}
                <button type="button" on:click={() => formData.gitHostDisplayName = getDefaultGitHostName(formData)}>
                  Restore Default
                </button>
              {/if}
            </div>
            <div class="external-connection-repo-url">
              <label for="repo-url-clone">Git Repo URL Pattern (For cloning repos) *</label><br>
              <input type="text" id="repo-url-clone" bind:value={formData.repoUrl} required>
              {#if showRestoreDefault}
                <button type="button" on:click={onRestoreDefault}>Restore Default</button>
              {/if}
            </div>
            <div class="external-connection-repo-url">
              <label for="repo-url-view">Git View URL Pattern (For viewing files/folders) *</label><br>
              <input type="text" id="repo-url-view" bind:value={formData.hostPath} required>
              {#if showRestoreDefault}
                <button type="button" on:click={() => formData.hostPath = getDefaultViewUrl(formData)}>
                  Restore Default
                </button>
              {/if}
            </div>
          </div>
          <!--			  <span>* - required fields</span>-->
          <div class="action-buttons-section">
            {#if isCreateMode}
              <button type="submit" name="saveBtn"
                      disabled="{formData.gitAccessType === GIT_CREDENTIALS_TYPE.OAUTH && !formData.oauthAccessToken}">Save
              </button>
            {:else}
              <button type="submit" name="updateBtn">Update</button>
            {/if}
            <button type="button" on:click={resetForm}>Clear</button>
            <button type="button" on:click={onCloseConnectionForm}>Close</button>
          </div>
        </div>
      </form>
    {/if}
  </Loading>
</div>
