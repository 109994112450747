<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { draggableElementEvent$, openSearchPageEvent$ } from '../../stores';
  import MaterialIcon from "../layout-helpers/MaterialIcon.svelte";

  export let item;
  let dragItemRef;

  const dispatch = createEventDispatcher();

  onMount(() => {
    addEvents();
  });

  function addEvents() {
    dragItemRef.draggable = true;
    dragItemRef.addEventListener(`dragstart`, (evt) => {
      draggableElementEvent$.set({ dragStartEvent: evt, isDragEndEvent: false });
      evt.dataTransfer.setData('suid', evt.target.id);
      evt.dataTransfer.setData('title', evt.target.title);
      evt.dataTransfer.dropEffect = 'copy';

      const dti = {
        dragAction: 'copy',
        dragFromSearch: true,
        suid: evt.target.id,
        title: evt.target.title
      };

      evt.dataTransfer.setData("text", JSON.stringify(dti));
    });
    dragItemRef.addEventListener(`dragend`, () => {
      draggableElementEvent$.set({ dragStartEvent: null, isDragEndEvent: true });
    })
  }

  function onPreviewPage(item) {
    dispatch('itemselected', {
      item: item
    });
  }

  function onOpenPageInNavigator(page) {
    openSearchPageEvent$.set({
      isOpenedFromSearchPanel: true,
      pageSuid: page.suid,
    });
  }
</script>

<style lang="scss">
  td {
    word-break: break-all;
    padding: 0;
    overflow: hidden;
  }

  .icon-cell {
    width: 1%;
    min-width: 25px;
    line-height: 13px;

    :global(span) {
      cursor: pointer;
    }
  }

  .title-cell {
    // TODO: I'm trying to get the column to display with text-overflow ellipsis but it doesn't work.
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }

    .text-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 95%;
    }
  }
</style>

<td bind:this={dragItemRef} id="{item.suid}" title="{item.title}" class="icon-cell">
  <MaterialIcon iconName="description" on:click={onOpenPageInNavigator(item)} title="Open In Navigator"/>
</td>
<td class="title-cell">
  <div on:click="{() => onPreviewPage(item)}" title="Preview page" class="text-ellipsis">
    {item.relativePath || item.title}
  </div>
</td>

