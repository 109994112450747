<script>
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { RequestService } from '../../comm/RequestService';
  import { TreeNavigatorService } from "../navigator/TreeNavigatorService";
  import AutoComplete from "simple-svelte-autocomplete";
  import TopicPage from "../../pages/TopicPage";

  const dispatch = createEventDispatcher();
  let formData;
  let selectedMountPoint;
  let mountPoints = [];
  let organizations = [];
  let personalAccountOrg = {
    isPersonal: true,
    id: { value: '', rawValue: ''},
    name: "Personal",
    fullName: "Personal"
  };

  let selectedOrg;
  let currentOrg;

  resetForm();

  onMount(() => {
    getOrganizationsData();
  });

  $: if (currentOrg !== selectedOrg) {
    // console.log("Changing org to", selectedOrg);
    currentOrg = selectedOrg;
    getMountPointsData();
  }

  async function onSaveMappedPage() {
    // This component adds a new mapped page to the database, but it does not 
    // add a table of contents entry anywhere. It is the reponsibility of the
    // invoking component to catch the 'pageadded' event and add in any
    // appropriate toc entries.
    
    if (!selectedMountPoint.id) {
      return;
    }

    const postData = {
      title: formData.title,
      mountPointId: selectedMountPoint.id,
      relativePath: formData.relativePath
    };

    // console.log("postData =", postData);
    const response = await TreeNavigatorService.createMappedPage(postData);
    if (response.resultCode === 0) {
      const topicPage = new TopicPage(response.result);
      dispatch('pageadded', {
        title: formData.title,
        pageSuid: topicPage.suid
      });
      resetForm();
    }
  }

  function resetForm() {
    formData = {
      title: '',
      relativePath: '/'
    };
    selectedMountPoint = null;
  }
  
  async function getOrganizationsData() {
    const requestUrl = `/apiv2/organization/memberof`;
    const resp = await RequestService.get(requestUrl);

    if (resp.resultCode === 0) {
      organizations = [ personalAccountOrg, ...resp.result ] || [ personalAccountOrg ];
    }   
  }

  async function getMountPointsData() {
    const requestUrl = 
      currentOrg && !currentOrg.isPersonal
        ? `/apiv2/mountpoint/all?orgId=${currentOrg.id.value}`
        : `/apiv2/mountpoint`;
    
    const resp = await RequestService.get(requestUrl);

    if (resp.resultCode === 0) {
      mountPoints = resp.result || [];
    }
  }
</script>

<style lang="scss">
  .organization-field,
  .mount-point-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;

    :global(.autocomplete) {
      min-width: 0;
      width: 100%;
      font-size: 9pt;
      padding: 0;
    }

    :global(.autocomplete-input) {
      padding-left: 2px;
    }

    :global(.autocomplete-list-item) {
      padding-left: 2px;
    }
  }
</style>

<form on:submit|preventDefault={onSaveMappedPage} id="add-mapped-page-form">
  <div class="form-section" style="width: 100%;">Add Mapped Page</div>
  <div style="width: 100%;">
    <label class="form-field" style="width: 100%; display: block;">
      <b>Title:</b>
      <input type="text" style="width: 100%;" bind:value={formData.title} required>
    </label>
  </div>
  <div style="width: 100%;">
    <label class="organization-field" for="organization-field" style="display: block; width: 100%;">
      <b>Personal/Organization:</b>
      <AutoComplete
        items={organizations}
        bind:selectedItem={selectedOrg}
        valueFieldName="id"
        labelFieldName="name"
        delay="{200}"
      />
    </label>
  </div>
  <label class="mount-point-field" for="mount-point-field" style="display: block; width: 100%;">
    <b>Mount Point:</b>
    <AutoComplete
      items={mountPoints}
      bind:selectedItem={selectedMountPoint}
      valueFieldName="id"
      labelFieldName="name"
      delay="{200}"
    />
  </label>
  <div style="width: 100%;">
    <label class="form-field" style="display: block; width: 100%;">
      <b>Relative Path:</b>
      <input type="text" style="width: 100%;" bind:value={formData.relativePath} placeholder="/...">
    </label>
  </div>
  <button type="submit" class="add-page-btn">Add New</button>
</form>
