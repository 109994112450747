import { writable } from 'svelte/store';
import { CONTENT_PAGE_MODE } from "./constants";

// Stores used to control state (old names)
export const externalConnectionTable$ = writable('');
export const mountPointTable$ = writable('');
export const mountPointsList$ = writable('');
export const rootTopicPage$ = writable(null);
export const userName$ = writable('');
export const userOptions$ = writable(null);
export const mappedPageCreation$ = writable(null);

// Stores used as events (old names)
export const menuEventSearch$ = writable({ searchTerm: '', useRegexp: false });
export const menuEventHome$ = writable(false);
export const openSearchPageEvent$ = writable({ isOpenedFromSearchPanel: false, pageSuid: '', searchData: {} });
export const draggableElementEvent$ = writable({ dragStartEvent: null, isDragEndEvent: false });

// Stores used as events (refactored new names)
export const headerRefactoredHomeEvent$ = writable(false);
export const headerRefactoredNavVisibilityEvent$ = writable({ firing: false });
export const headerRefactoredSplitterVisibilityEvent$ = writable({ firing: false });
export const headerSwapEvent$ = writable({ firing: false });
export const headerRefactoredSearchEvent$ = writable({ firing: false, searchTerm: '', useRegexp: false });
export const mainRefactoredSplitterOpen$ = writable(false);
export const signalRConnection$ = writable(null);
export const loadingProgress$ = writable(false);
export const topicPagesList$ = writable('');
export const openPageLinkEvent$ = writable({ linkPath: '', startPage: '', startNode: '' });
export const navRootTopicPage$ = writable(null);
export const navSelectedItem$ = writable({ page: null, node: null });
export const pageOptionsEvent$ = writable({ options: null });
