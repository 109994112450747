<script>
  import { onMount, afterUpdate } from 'svelte';
  import { createEventDispatcher } from 'svelte';
  import { PANE } from "../../constants";

  const dispatch = createEventDispatcher();
  export let splitterOpen = false;
  export let resizable = false;
  export let primaryPane;
  
  export let displaySeparator = true;

  export let initLeftSizePercent = null;
  export let initLeftSizePixels = null;
  export let initRightSizePercent = null;
  export let initRightSizePixels = null;
  export let minLeftSizePixels = '';
  export let minRightSizePixels = '';

  let sideInitialized = PANE.Left;
  let leftSideRef;
  let rightSideRef;
  let sideSeparatorRef;
  let widthLeftPane;
  let widthRightPane;
  let defaultWidthLeft;
  let defaultWidthRight;
  let leftItemClasses = '';
  let rightItemClasses = '';

  onMount(() => {
    if (resizable) {
      sideSeparatorRef.addEventListener('mousedown', initResize, false);
      sideSeparatorRef.addEventListener('dblclick', returnDefaultHeight, false);
    }

    checkErrorAndInitOptions();
  });

  afterUpdate(() => {
    if (primaryPane === PANE.Left && !splitterOpen) {
      leftSideRef.style.width = null;
    }

    if (primaryPane === PANE.Right && !splitterOpen) {
      rightSideRef.style.width = null;
    }

    if (splitterOpen && sideInitialized === PANE.Left) {
      initLeftSideWidth();
    }

    if (splitterOpen && sideInitialized === PANE.Right) {
      initRightSideWidth();
    }

    getItemClasses();
  });

  function checkErrorAndInitOptions() {
    const sizeOptions = [];
    if (initLeftSizePercent) {
      widthLeftPane = initLeftSizePercent + '%';
      defaultWidthLeft = widthLeftPane;
      sideInitialized = PANE.Left;
      sizeOptions.push(widthLeftPane);
    }
    if (initLeftSizePixels) {
      widthLeftPane = initLeftSizePixels + 'px';
      defaultWidthLeft = widthLeftPane;
      sideInitialized = PANE.Left;
      sizeOptions.push(widthLeftPane)
    }
    if (initRightSizePercent) {
      widthRightPane = initRightSizePercent + '%';
      defaultWidthRight = widthRightPane;
      sideInitialized = PANE.Right;
      sizeOptions.push(widthRightPane)
    }
    if (initRightSizePixels) {
      widthRightPane = initRightSizePixels + 'px';
      defaultWidthRight = widthRightPane;
      sideInitialized = PANE.Right;
      sizeOptions.push(widthRightPane)
    }

    if (sizeOptions.length > 1) {
      alert('Error!!! Please, set only one, of the initial size values!\n' +
        ' initLeftSizePercent or\n' +
        ' initLeftSizePixels or\n' +
        ' initRightSizePercent or\n' +
        ' initRightSizePixels')
    }
  }

  function returnDefaultHeight() {
    if (sideInitialized === PANE.Left) {
      widthLeftPane = defaultWidthLeft;
    } else {
      widthRightPane = defaultWidthRight;
    }
    leftSideRef.style.width = defaultWidthLeft ? defaultWidthLeft : null;
    rightSideRef.style.width = defaultWidthRight ? defaultWidthRight : null;

    emitSidesWidth();
  }

  function initResize(e) {
    e.preventDefault();
    window.addEventListener('mousemove', resize, false);
    window.addEventListener('mouseup', stopResize, false);
  }

  function initLeftSideWidth() {
    leftSideRef.style.width = widthLeftPane;
    rightSideRef.style.width = `calc(100% - ${leftSideRef.style.width})`;
  }

  function initRightSideWidth() {
    rightSideRef.style.width = widthRightPane;
    leftSideRef.style.width = `calc(100% - ${rightSideRef.style.width})`;
  }

  function resize(e) {
    e.preventDefault();
    //leftSideRef.style.transition = 'none';
    if (minLeftSizePixels && (e.clientX - leftSideRef.offsetLeft < minLeftSizePixels)) {
      return;
    }

    if (minRightSizePixels && (window.innerWidth - e.clientX < minRightSizePixels)) {
      return;
    }

    if (sideInitialized === PANE.Left) {
      leftSideRef.style.width = (e.clientX - leftSideRef.offsetLeft) + 'px';
      rightSideRef.style.width = `calc(100% - ${leftSideRef.style.width})`;
      widthLeftPane = leftSideRef.style.width;
    } else {
      leftSideRef.style.width = (e.clientX - leftSideRef.offsetLeft) + 'px';
      rightSideRef.style.width = `calc(100% - ${leftSideRef.style.width})`;
      widthRightPane = rightSideRef.style.width;
    }

    emitSidesWidth();
  }

  function stopResize() {
    window.removeEventListener('mousemove', resize, false);
    window.removeEventListener('mouseup', stopResize, false);

    //leftSideRef.style.transition = null;
  }

  function emitSidesWidth() {
    let leftSideWidthPx = getComputedStyle(leftSideRef);
    let rightSideWidthPx = getComputedStyle(rightSideRef);

    dispatch('paneResized', {
      leftSideSize: leftSideRef.style.width || leftSideWidthPx.width,
      rightSideSize: rightSideRef.style.width || rightSideWidthPx.width,
    });
  }

  function getItemClasses() {
    if (primaryPane === PANE.Left && !splitterOpen) {
      leftItemClasses = 'full-width';
      rightItemClasses = 'closed';
    }

    if (primaryPane === PANE.Left && splitterOpen) {
      leftItemClasses = 'full-width';
      rightItemClasses = 'full-width open';
    }

    if (primaryPane === PANE.Right && !splitterOpen) {
      leftItemClasses = 'closed';
      rightItemClasses = 'full-width';
    }

    if (primaryPane === PANE.Right && splitterOpen) {
      leftItemClasses = 'full-width open';
      rightItemClasses = 'full-width';
    }
  }
</script>

<style lang="scss">
  .side-outer-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch;
    box-sizing: border-box;

    .left-item, .right-item {
      display: flex;
      width: 50%;
      overflow: hidden;
      min-height: 0;
      /*transition: width 0.1s linear;*/

      .full-height {
        height: 100%;
      }
    }

    .full-width {
      width: 100%;
    }

    .left-item {
      box-sizing: border-box;
    }

    .right-item {
      .side-pane-separator {
        border-left: 5px double lightgray;
        cursor: col-resize;
      }

      .resize-deprecated {
        cursor: initial;
      }
    }

    .open {
      display: flex;
    }

    .closed {
      display: none;
    }
  }
</style>

<div class="side-outer-div">
  <div class="left-item {leftItemClasses}"
       bind:this={leftSideRef}>
    <div class="full-height full-width">
      <slot name="left" />
    </div>
  </div>
  <div class="right-item {rightItemClasses}"
       bind:this={rightSideRef}>
    {#if displaySeparator}
      <div class="side-pane-separator"
           class:resize-deprecated={!resizable}
           class:closed={primaryPane === PANE.Right && !splitterOpen}
           bind:this={sideSeparatorRef}>
      </div>
    {/if}
    <div class="full-height full-width">
      <slot name="right" />
    </div>
  </div>
</div>  
