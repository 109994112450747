<script>
  import SearchResultsRow from "./SearchResultsRow.svelte";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { RequestService } from "../../comm/RequestService";

  export let searchResults;
  export let selectedItem;
  export let switchSearchFile$;

  let displayItems = [];
  let selectedItemIndex = 1;
  const dispatch = createEventDispatcher();
  let unsubscribe;

  onMount(() => {
      document.addEventListener('keydown', checkKey);
      unsubscribe = switchSearchFile$.subscribe((res) => {
        checkFileToDisplay(res.searchFileOrder);
      })
    }
  );

  $: if (searchResults) {
    getSearchDisplayItems();
    getSelectedItemIndex();
  }

  onDestroy(() => {
    document.removeEventListener('keydown', checkKey);
    unsubscribe();
  });

  function checkKey(e) {
    if (e.code === 'ArrowUp') {
      changeSelectedItem('ArrowUp');
    }

    if (e.code === 'ArrowDown') {
      changeSelectedItem('ArrowDown');
    }
  }

  function getSearchDisplayItems() {
    let items = [];
    let index = 0;

    if (!searchResults) {
      return items;
    }

    while (index < searchResults.length) {
      let currentItemType = searchResults[index].itemType;
      let currentMountPoint = searchResults[index].mountPointName;

      if (currentItemType === 1) {
        items.push({
          itemType: "topicPageGroup",
          title: "Topic Pages",
        });
      } else {
        items.push({
          itemType: "mountPointGroup",
          title: currentMountPoint,
        });
      }

      while (index < searchResults.length &&
      currentItemType === searchResults[index].itemType && currentMountPoint === searchResults[index].mountPointName) {
        items.push({
          itemType: "page",
          isReferenceDisplayed: false,
          title: searchResults[index].relativePath,
          result: searchResults[index],
        });

        index++;
      }
    }

    displayItems = items;
  }

  function getSelectedItemIndex() {
    selectedItemIndex = displayItems.findIndex((di) => {
      return di.result && di.result.suid === selectedItem.suid
    });
  }

  function onItemSelected(item) {
    dispatch('itemselected', { item });
  }

  function checkFileToDisplay(file) {
    if (file === 'next') {
      changeSelectedItem('ArrowDown');
    }

    if (file === 'prev') {
      changeSelectedItem('ArrowUp');
    }
  }

  function changeSelectedItem(code) {
    if (code === 'ArrowUp') {
      selectedItemIndex--;
      if (selectedItemIndex === 0) {
        selectedItemIndex++;

        return;
      }

      if (!displayItems[selectedItemIndex].result) {
        selectedItemIndex--;
      }
    }

    if (code === 'ArrowDown') {
      selectedItemIndex++;
      if (selectedItemIndex === displayItems.length) {
        selectedItemIndex--;

        return;
      }

      if (selectedItemIndex < displayItems.length && !displayItems[selectedItemIndex].result) {
        selectedItemIndex++;
      }
    }

    selectedItem = displayItems[selectedItemIndex].result;
    onItemSelected(selectedItem);
  }

  async function getReferencedPagesList(page) {
    const url = `apiV2/topicPages/${page.result.id.value}/referenced`;
    const resp = await RequestService.get(url);

    if (resp.resultCode === 0) {
      if (resp.result.length) {
        page.referencedPageTitles = resp.result.map(((page, i) => (i > 0 ? ' ' : '') + page.title));
      } else {
        page.referencedPageTitles = 'Pages not found.';
      }
      page.isReferenceDisplayed = true;
      const displayedItemReferenceIndex = displayItems.findIndex((el) => el.result && el.result.id.value === page.result.id.value);
      displayItems[displayedItemReferenceIndex] = page;
    } else {
      console.error('Error: ', resp.resultMessage);
    }
  }

  function onShowReferences(page) {
    getReferencedPagesList(page);
  }
</script>

<style lang="scss">
  table, tr {
    border: none;
  }

  table, tbody {
    padding: 0;
    width: 100%;
    max-width: 100%;

    tr {
      padding: 0;
      height: 35px;

      &.selected {
        background-color: lightblue;
      }

      .ref-names {
        width: 30%;

        button {
          height: 25px;
          line-height: 1;
        }
      }

      .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
</style>

<table>
  <tbody>
  {#each displayItems as displayItem}
    {#if displayItem.itemType === "mountPointGroup"}
      <tr>
        <td colspan="3">
          <strong>Mount Point: {displayItem.title}</strong>
        </td>
      </tr>
    {:else if displayItem.itemType === "topicPageGroup"}
      <tr>
        <td colspan="3">
          <strong>{displayItem.title}</strong>
        </td>
      </tr>
    {:else}
      <tr class:selected={displayItem.result.suid === (selectedItem && selectedItem.suid)}>
        <SearchResultsRow
          item={displayItem.result}
          on:itemselected
        />
        {#if displayItem.result.itemType === 1 && displayItem.result.isUnreferenced}
          <td class="ref-names">
            <div class="ref-names text-ellipsis">Referenced By: Pages not found.</div>
          </td>
        {:else if displayItem.result.itemType === 1 && !displayItem.result.isUnreferenced && !displayItem.isReferenceDisplayed}
          <td class="ref-names">
            <button on:click="{()=> onShowReferences(displayItem)}">Show References</button>
          </td>
        {:else if displayItem.result.itemType === 1 && !displayItem.result.isUnreferenced && displayItem.isReferenceDisplayed}
          <td class="ref-names">
            <div class="ref-names text-ellipsis">Referenced By: {displayItem.referencedPageTitles}</div>
          </td>
        {/if}
      </tr>
    {/if}
  {/each}
  </tbody>
</table>
