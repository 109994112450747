<script>
  export let propertyName = null;
  export let val = null;
  export let comment = null;
  export let render = false;
</script>  

{#if propertyName && val && comment && render}
  <div style='display: none;'>{val}</div>
{/if}  
