<script>
  import OrganizationGroupsSection from "./OrganizationGroupsSection.svelte";
  import OrganizationsSection from "./OrganizationsSection.svelte";
  import OrganizationUsersSection from "./OrganizationUsersSection.svelte";
  import GroupPermissionsSection from "./GroupPermissionsSection.svelte";
  import ExternalConnections from "./ExternalConnections.svelte";
  import OrganizationMountPointsForm from "./mount-points/OrganizationMountPointsForm.svelte";

  const organizationStates = {
    Organization: 'organization',
    Group: 'group',
    GroupPermissions: 'group-permissions',
    User: 'user',
    ExtConnection: 'ext-connection',
    MountPoint: 'mount-point',
  };
  let currentState = organizationStates.Organization;
  let selectedOrganization;
  let selectedGroup;

  function onChangeOrgState(data) {
    selectedOrganization = data.detail.organization;
    selectedGroup = data.detail.group;
    currentState = data.detail.state;
  }
</script>

<style lang="scss">
  :global(div.manage-organizations) {
    min-height: 300px;

    :global(div.mount-points-table table tbody tr th),
    :global(div.organizations-table table tbody tr th) {
      border: 1px solid grey;
      min-width: 180px;
    }

    :global(div.mount-points-table table tbody tr td),
    :global(div.organizations-table table tbody tr td) {
      border: 1px solid grey;
      min-width: 180px;
    }

    :global(.err-message) {
      color: #85096a;
      font-size: 12px;
      position: absolute;
      line-height: 1;
      bottom: 40px;
      left: 200px;
    }

    :global(.back-btn) {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }
</style>

<div class="manage-organizations">
  {#if currentState === organizationStates.Organization}
    <OrganizationsSection on:changeorgstate={onChangeOrgState}/>
  {:else if currentState === organizationStates.Group}
    <OrganizationGroupsSection
      on:changeorgstate={onChangeOrgState}
      organization={selectedOrganization}
    />
  {:else if currentState === organizationStates.GroupPermissions}
    <GroupPermissionsSection
      on:changeorgstate={onChangeOrgState}
      group={selectedGroup}
      organization={selectedOrganization}
    />
  {:else if currentState === organizationStates.User}
    <OrganizationUsersSection
      on:changeorgstate={onChangeOrgState}
      organization={selectedOrganization}
    />
  {:else if currentState === organizationStates.ExtConnection}
    <ExternalConnections
      on:changeorgstate={onChangeOrgState}
      organization={selectedOrganization}
    />
  {:else if currentState === organizationStates.MountPoint}
    <OrganizationMountPointsForm
      on:changeorgstate={onChangeOrgState}
      organization={selectedOrganization}
    />
  {/if}
</div>
