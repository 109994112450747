<script>
  import { createEventDispatcher } from "svelte";
  import SnippetEditorControls from "./SnippetEditorControls.svelte";
  import FileReferenceSnippetViewer from "../snippet-viewers/FileReferenceSnippetViewer.svelte";
  
  export let snippet;
  export let snippetExpanded;
  
  const dispatch = createEventDispatcher();
  let files;
  
  function onMousedown(evt) {
    evt.detail.target.closest('.edit-snippet-table-row').draggable = true;
  }
  
  function onRemove() {
    dispatch('removesnippet', { snippet });
  }
  
  function onEdit() {
    dispatch('editsnippet', { snippet });
  }
</script>


<SnippetEditorControls
  on:begindragsnippet={onMousedown}
  on:removesnippet={onRemove}
  on:editsnippet={onEdit}
/>
<td>
  {#if snippetExpanded}
    <FileReferenceSnippetViewer {snippet} />
  {:else}
    FileReference Thumbnail
  {/if}
</td>
