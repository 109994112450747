<script>
  import { RequestService } from '../../../comm/RequestService';
  import { onMount } from "svelte";
  import { mountPointsList$, signalRConnection$ } from "../../../stores";
  import { createEventDispatcher} from "svelte";
  import Loading from "../../layout-helpers/Loading.svelte";
  import {
    GIT_ACCESS_TYPE,
    GIT_REPO_STATUS,
    GitRepoStatus,
  } from "../../../constants";
  import EditMountPoint from "./EditMountPoint.svelte";

  export let loading;
  export let mountPoints = [];
  export let externalConnections = [];
  
  const dispatch = createEventDispatcher();

  const locale = window.navigator.language.slice(0, 2);
  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: locale === 'en',
    hour: 'numeric',
    minute: '2-digit',
  });
  
  let selectedMountPoint = null;
  let copyOfSelectedMountPoint = null;
  
  async function getCloneError(mountPoint) {
    const url = `/apiV2/notification?mountPointId=${mountPoint.id}`;
    const res = await RequestService.get(url);

    if (res.resultCode === 0) {
      return res.result.length && res.result[0].message || '';
    } else {
      return '';
    }
  }

  async function onRefreshMountPoint(mPoint) {
    const requestUrl = `/apiV2/mountPoint/pull/${mPoint.id}`;
    const response = await RequestService.put(requestUrl);

    if (response.resultCode === 0) {
      dispatch("mountpointrefreshed", mPoint);
    } else {
      console.error('Error refreshing mount point: ', response.resultMessage);
    }
  }

  function onEditMountPoint(mPoint) {
    selectedMountPoint = mPoint;
    copyOfSelectedMountPoint = selectedMountPoint.dup();
    // isFormDisplayed = true;
    // isCreateButtonsEnabled = false;
    // isQuickFormDisplayed = false;
    // isCreateMode = false;
    // formData = mPoint;
    // if (formData.gitAccessType === GIT_ACCESS_TYPE.REUSE_EXISTING) {
    //   selectedMountPointId = formData.innerMountPointId;
    // }
    // if (formData.gitAccessType === GIT_ACCESS_TYPE.PRIVATE) {
    //   selectedExtConnectionId = formData.extConnectionId;
    // }

    // onRefreshTypeChanged(+formData.refreshType);
  }

  async function onDeleteMountPoint(mPoint) {
    const confirmDelete = confirm(`Are you sure you want to delete mount point ${mPoint.name}?`);
    
    if (!confirmDelete) {
      return;
    }
    
    const requestUrl = `/apiV2/mountPoint/${mPoint.id}`;
    const response = await RequestService.delete(requestUrl);

    if (response.resultCode === 0) {
      dispatch("mountpointdeleted", mPoint);
    } else {
      console.error('DeleteMountPoint ', response.resultMessage);
    }
  }
  
  function onClose() {
    selectedMountPoint = null;
    copyOfSelectedMountPoint = null;
  }
  
  function onClear() {
    copyOfSelectedMountPoint = selectedMountPoint.dup();
  }
  
  function onSave() {
    // console.log("Inside onSave");
    // console.log("original =", selectedMountPoint);
    // console.log("updated =", copyOfSelectedMountPoint);
    
    dispatch("mountpointupdated", { 
      original: selectedMountPoint,
      updated: copyOfSelectedMountPoint
    });

    selectedMountPoint = null;
    copyOfSelectedMountPoint = null;
  }
</script>

<style lang="scss">
  //#mount-points-form {
  //  border: 1px solid gray;
  //  padding: 10px;
  //  width: fit-content;
  //  width: -moz-fit-content;
  //}

  .action-btn {
    margin-top: 0 !important;
  }

  table.mount-points-table tbody {
    tr {
      border: 1px solid grey;
    }
  }

  td, th {
    border: 1px solid grey;
    min-width: 120px;
  }

  .status-cell {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
    }
  }

  .action-btn:last-child {
    margin-right: 0;
  }

  //.field-title {
  //  max-width: 200px;
  //}
</style>

<Loading loading={loading}>
  {#if mountPoints && mountPoints.length === 0}
    <br>
    <div>No Mount Points Created.</div>
  {:else}    
    <div>
      <br>
      <table class="mount-points-table">
        <tbody>
        <tr>
          <th>Name</th>
          <th>External Connection</th>
          <th>Last Refresh Date</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
        {#each mountPoints as mPoint (mPoint.id)}
          <tr>
            <td>{mPoint.name}</td>
            {#if mPoint.gitAccessType === GIT_ACCESS_TYPE.PUBLIC}
              <td>None (Public)</td>
            {:else if mPoint.gitAccessType === GIT_ACCESS_TYPE.PRIVATE}
              <td>{!!mPoint.externalConnection ? mPoint.externalConnection.name : 'Not Found'}</td>
            {:else}
              <td>Reuse</td>
            {/if}
            <td>{formatter.format(new Date(mPoint.lastRefreshDate))}</td>
            <td>
              <div class="status-cell">
                {GIT_REPO_STATUS[ mPoint.gitStatus ]}
                {#if mPoint.status === GitRepoStatus.Error }
                  {#await getCloneError(mPoint)}
                    <p>Please, wait...</p>
                  {:then errorMessage}
                    <span class="material-icons-outlined" title="{errorMessage}">info</span>
                  {/await}
                {/if}
              </div>
            </td>
            <td>
              <button type="button" class="action-btn"
                      on:click={() => onRefreshMountPoint(mPoint)}
                      disabled={mPoint.gitStatus === 2 || mPoint.gitAccessType === GIT_ACCESS_TYPE.REUSE_EXISTING}>Refresh
              </button>
              <button type="button" class="action-btn" on:click={() => onEditMountPoint(mPoint)}>Edit</button>
              <button type="button" class="action-btn"
                      on:click={() => onDeleteMountPoint(mPoint)}
                      disabled={mPoint.inReuse}
                      title="{mPoint.inReuse ? `Unable to delete Mount Point, already used by other Mount Points: ${mPoint.reuseNames}` : ''}">
                Delete
              </button>
            </td>
          </tr>
          {#if selectedMountPoint === mPoint}
            <tr>
              <td colspan="5">
                <EditMountPoint
                  isCreateMode={false}
                  mountPoint={copyOfSelectedMountPoint}
                  mountPoints={mountPoints}
                  externalConnections={externalConnections}
                  on:close={onClose}
                  on:clear={onClear}
                  on:save={onSave}
                />              
              </td>
            </tr>
          {/if}
        {/each}
        </tbody>
      </table>
    </div>
  {/if}
</Loading>
