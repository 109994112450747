<script>
  import { onMount, onDestroy } from "svelte";
  import AppStylesPane from './AppStylesPane.svelte';
  import FlipperPane from './FlipperPane.svelte';
  import SideBySidePane from './SideBySidePane.svelte';
  import { PANE } from "../../constants";
  import { CONTENT_PAGE_MODE } from "../../constants";
  import {
    navSelectedItem$,
    headerRefactoredSearchEvent$,
    mainRefactoredSplitterOpen$,
    headerSwapEvent$,
    loadingProgress$
  } from "../../stores";
  import Spinner from "../layout-helpers/Spinner.svelte";

  let unsubscribe1;
  let unsubscribe2;
  let unsubscribe3;
  let unsubscribe4;
  let loading = true;

  let leftPaneOptions = {
    pinned: false,
    mode: CONTENT_PAGE_MODE.Content,
    searchTerm: "",
    useRegexp: false,
    searchResultsAvailable: false,
    topicPage: null,
    navNode: null
  };

  let rightPaneOptions = {
    pinned: false,
    mode: CONTENT_PAGE_MODE.Content,
    searchTerm: "",
    useRegexp: false,
    searchResultsAvailable: false,
    topicPage: null,
    navNode: null
  };

  onMount(() => {
    unsubscribe1 = navSelectedItem$.subscribe((navItem) => {
      if (navItem.page) {
        showPage(navItem);
      }
    });

    unsubscribe2 = headerRefactoredSearchEvent$.subscribe(val => {
      if (val.firing) {
        showSearchResults(val.searchTerm, val.useRegexp);
        headerRefactoredSearchEvent$.set({ firing: false, searchTerm: '', useRegexp: false });
      }
    });

    unsubscribe3 = headerSwapEvent$.subscribe(val => {
      if (val.firing) {
        swapPanes();
        headerSwapEvent$.set({ firing: false, swap: false });
      }
    });

    unsubscribe4 = loadingProgress$.subscribe(val => {
      loading = val;
    });
  });

  onDestroy(() => {
    unsubscribe1();
    unsubscribe2();
    unsubscribe3();
    unsubscribe4();
  });

  function showPage(navItem) {
    if (!$mainRefactoredSplitterOpen$) {
      showPageInLeftPane(navItem);
    } else if (leftPaneOptions.pinned) {
      showPageInRightPane(navItem);
    } else if (rightPaneOptions.pinned) {
      showPageInLeftPane(navItem);
    } else if (leftPaneOptions.mode === CONTENT_PAGE_MODE.Content && rightPaneOptions.mode === CONTENT_PAGE_MODE.Search) {
      showPageInLeftPane(navItem);
    } else if (leftPaneOptions.mode === CONTENT_PAGE_MODE.Search && rightPaneOptions.mode === CONTENT_PAGE_MODE.Content) {
      showPageInRightPane(navItem);
    } else if (leftPaneOptions.mode === CONTENT_PAGE_MODE.Search && rightPaneOptions.mode === CONTENT_PAGE_MODE.Search) {
      showPageInLeftPane(navItem);
    } else if (leftPaneOptions.mode === CONTENT_PAGE_MODE.Content && rightPaneOptions.mode === CONTENT_PAGE_MODE.Content) {
      showPageInLeftPane(navItem);
      showPageInRightPane(navItem);
    }

    if (!rightPaneOptions.topicPage) {
      rightPaneOptions = {
        ...rightPaneOptions,
        topicPage: leftPaneOptions.topicPage
      }
    }
  }

  function showSearchResults(searchTerm, useRegexp) {
    if (!$mainRefactoredSplitterOpen$) {
      showSearchInLeftPane(searchTerm, useRegexp);
    } else if (leftPaneOptions.pinned) {
      showSearchInRightPane(searchTerm, useRegexp);
    } else if (rightPaneOptions.pinned) {
      showSearchInLeftPane(searchTerm, useRegexp);
    } else if (leftPaneOptions.mode === CONTENT_PAGE_MODE.Search && rightPaneOptions.mode === CONTENT_PAGE_MODE.Content) {
      showSearchInLeftPane(searchTerm, useRegexp);
    } else if (leftPaneOptions.mode === CONTENT_PAGE_MODE.Content && rightPaneOptions.mode === CONTENT_PAGE_MODE.Search) {
      showSearchInRightPane(searchTerm, useRegexp);
    } else if (leftPaneOptions.mode === CONTENT_PAGE_MODE.Content && rightPaneOptions.mode === CONTENT_PAGE_MODE.Content) {
      showSearchInRightPane(searchTerm, useRegexp);
    } else if (leftPaneOptions.mode === CONTENT_PAGE_MODE.Search && rightPaneOptions.mode === CONTENT_PAGE_MODE.Search) {
      showSearchInLeftPane(searchTerm, useRegexp);
      showSearchInRightPane(searchTerm, useRegexp);
    }
  }

  function showPageInLeftPane(navItem) {
    leftPaneOptions = {
      ...leftPaneOptions,
      mode: CONTENT_PAGE_MODE.Content,
      topicPage: navItem.page,
      navNode: navItem.node,
    }
  }

  function showPageInRightPane(navItem) {
    rightPaneOptions = {
      ...rightPaneOptions,
      mode: CONTENT_PAGE_MODE.Content,
      topicPage: navItem.page,
      navNode: navItem.node,
    }
  }

  function showSearchInLeftPane(searchTerm, useRegexp) {
    leftPaneOptions = {
      ...leftPaneOptions,
      searchTerm: searchTerm,
      useRegexp: useRegexp,
      mode: CONTENT_PAGE_MODE.Search,
      searchResultsAvailable: true
    };
  }

  function showSearchInRightPane(searchTerm, useRegexp) {
    rightPaneOptions = {
      ...rightPaneOptions,
      searchTerm: searchTerm,
      useRegexp: useRegexp,
      mode: CONTENT_PAGE_MODE.Search,
      searchResultsAvailable: true
    };
  }

  function onToggleLeftPin() {
    leftPaneOptions = {
      ...leftPaneOptions,
      pinned: !leftPaneOptions.pinned
    };

    rightPaneOptions = {
      ...rightPaneOptions,
      pinned: false
    };
  }

  function onToggleRightPin() {
    leftPaneOptions = {
      ...leftPaneOptions,
      pinned: false
    };

    rightPaneOptions = {
      ...rightPaneOptions,
      pinned: !rightPaneOptions.pinned
    };
  }

  function onLeftClearSearch() {
    leftPaneOptions = {
      ...leftPaneOptions,
      searchTerm: '',
      useRegexp: false,
      searchResultsAvailable: false,
      mode: CONTENT_PAGE_MODE.Content
    };
  }

  function onRightClearSearch() {
    rightPaneOptions = {
      ...rightPaneOptions,
      searchTerm: '',
      useRegexp: false,
      searchResultsAvailable: false,
      mode: CONTENT_PAGE_MODE.Content
    }
  }

  function onLeftModeChanged(event) {
    leftPaneOptions = {
      ...leftPaneOptions,
      mode: event.detail.mode
    };
  }

  function onRightModeChanged(event) {
    rightPaneOptions = {
      ...rightPaneOptions,
      mode: event.detail.mode
    };
  }

  function swapPanes() {
    const tempPane = leftPaneOptions;

    leftPaneOptions = rightPaneOptions;
    rightPaneOptions = tempPane;
  }
</script>
<style lang="scss">
  .pane-manager {
    position: relative;
    height: 100%;

    .spinner-section {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5
    }

    .pane-manager-right, .pane-manager-left {
      height: 100%;
    }
  }
</style>

<div class="pane-manager">
  <AppStylesPane>
    <SideBySidePane
      splitterOpen={$mainRefactoredSplitterOpen$}
      resizable={true}
      primaryPane={PANE.Left}
      initRightSizePercent={50}
      minLeftSizePixels={200}
      minRightSizePixels={200}
    >
      <svelte:fragment slot="left">
        {#if leftPaneOptions.topicPage}
          <div class="pane-manager-left">
            <FlipperPane
              pinnable={$mainRefactoredSplitterOpen$}
              pinned={leftPaneOptions.pinned}
              searchResultsAvailable={leftPaneOptions.searchResultsAvailable}
              topicPage={leftPaneOptions.topicPage}
              navNode={leftPaneOptions.navNode}
              mode={leftPaneOptions.mode}
              searchTerm={leftPaneOptions.searchTerm}
              useRegexp={leftPaneOptions.useRegexp}
              on:togglepin={onToggleLeftPin}
              on:clearsearch={onLeftClearSearch}
              on:modechanged={onLeftModeChanged}
            />
          </div>
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="right">
        {#if rightPaneOptions.topicPage}
          <div class="pane-manager-right">
            <FlipperPane
              pinnable={true}
              pinned={rightPaneOptions.pinned}
              searchResultsAvailable={rightPaneOptions.searchResultsAvailable}
              topicPage={rightPaneOptions.topicPage}
              navNode={leftPaneOptions.navNode}
              mode={rightPaneOptions.mode}
              searchTerm={rightPaneOptions.searchTerm}
              useRegexp={rightPaneOptions.useRegexp}
              on:togglepin={onToggleRightPin}
              on:clearsearch={onRightClearSearch}
              on:modechanged={onRightModeChanged}
            />
          </div>
        {/if}
      </svelte:fragment>
    </SideBySidePane>
    {#if loading}
      <div class="spinner-section">
        <Spinner/>
      </div>
    {/if}
  </AppStylesPane>
</div>
