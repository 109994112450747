<script>
  import TopicPage from "../../pages/TopicPage.js";
  import SnippetViewer from "../snippet-viewers/SnippetViewer.svelte";
  import MissingPropertyError from '../errors/MissingPropertyError.svelte';
  import DateUtil from '../../utils/DateUtil';
  import { tick, createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { RequestService } from "../../comm/RequestService";
  import { FORMAT_TYPE } from "../../snippets/PlainTextSnippet";
  import { writable } from 'svelte/store';
  import { openPageLinkEvent$ } from '../../stores.js';
  import OverrideableTimeStampOptions from "../../misc/OverrideableTimeStampOptions";

  export let topicPage = null;
  export let navNode = null;
  export let userOptions;
  export let searchData = '';
  export let switchSearchFile$ = null;
  export let switchSearchResults$ = null;

  const dispatch = createEventDispatcher();
  let me = "TopicPageRenderer";
  let topLevelSnippets = [];
  let renderLeftMargin;
  let renderWithSeparators;
  let timeStampOptions;
  let counter = 1;
  let unsubscribe;
  let matchingItems;
  let currentActiveItemIndex = 0;
  let numberOfResults = 0;
  let tableSnippetRef;
  let isSourceCodeFormatType = false;
  let isPageOptionsUpdated = false;
  const switchSourceCodeSearchResults$ = writable({ searchResultOrder: '', isSourceCodeFormatType: false });

  onMount(() => {
    if (switchSearchResults$) {
      unsubscribe = switchSearchResults$.subscribe((res) => {
        if (isSourceCodeFormatType) {
          switchSourceCodeSearchResults$.set({ searchResultOrder: res.searchResultOrder, isSourceCodeFormatType: true })
        } else if (res.searchResultOrder) {
          checkSnippetsToDisplay(res.searchResultOrder);

          switchSearchResults$.set({ searchResultOrder: '' })
        }
      });
    }
  });
  
  function updateSettings(topicPage, userOptions) {
    timeStampOptions = getTimeStampOptions(topicPage, userOptions);
    renderLeftMargin = getRenderLeftMargin(topicPage, userOptions);
    renderWithSeparators = getRenderWithSeparators(topicPage, userOptions);
  }
  
  $: {
    updateSettings(topicPage, userOptions);
  }

  $: if (topicPage && !isPageOptionsUpdated) {
    updateSnippets(topicPage);
  }

  onDestroy(() => {
    if (unsubscribe) {
      unsubscribe();
    }
  });

  function checkSnippetsToDisplay(res) {
    if (res === 'next') {
      moveNext();
    } else {
      movePrevious();
    }
  }

  function getSearchContainer() {
    return document.getElementsByClassName('search-results-area')[0];
  }

  function moveNext(isFirstHighlight = false) {
    const searchContainer = getSearchContainer();

    if (isFirstHighlight) {
      const nextItem = searchContainer.querySelectorAll(`[searchindex="${currentActiveItemIndex}"]`)[0];
      if (nextItem) {
        nextItem.classList.add('highlight-active');
      }
    } else {
      const prevItem = searchContainer.querySelectorAll(`[searchindex="${currentActiveItemIndex}"]`);
      if (prevItem[0]) {
        prevItem[0].classList.remove('highlight-active');
      }
      currentActiveItemIndex++;

      if (currentActiveItemIndex >= numberOfResults) {
        switchSearchFile$.set({ searchFileOrder: 'next' });
        return;
      }
    }

    const nextItem = searchContainer.querySelectorAll(`[searchindex="${currentActiveItemIndex}"]`);

    if (nextItem[0]) {
      nextItem[0].classList.add('highlight-active');

      const parentScrollEl = searchContainer.getElementsByClassName('scrolling-pane')[1];
      scrollToElm(parentScrollEl, nextItem[0]);
    }
  }

  function movePrevious() {
    if (currentActiveItemIndex === 0) {
      switchSearchFile$.set({ searchFileOrder: 'prev' });
      return;
    }

    const searchContainer = getSearchContainer();

    const prevItem = searchContainer.querySelectorAll(`[searchindex="${currentActiveItemIndex}"]`)[0];
    prevItem.classList.remove('highlight-active');
    currentActiveItemIndex--;

    if (currentActiveItemIndex >= 0) {
      const nextItem = searchContainer.querySelectorAll(`[searchindex="${currentActiveItemIndex}"]`)[0];
      nextItem.classList.add('highlight-active');

      const parentScrollEl = searchContainer.getElementsByClassName('scrolling-pane')[1];
      scrollToElm(parentScrollEl, nextItem, 300);
    }
  }

  function highlightSearchResults() {
    const term = searchData && searchData.searchTerm;
    counter = 0;
    currentActiveItemIndex = 0;

    if (!term) {
      return;
    }
    const re = new RegExp(term, "gmi");

    const searchContainer = getSearchContainer();
    matchingItems = searchContainer.getElementsByClassName('osq-snippet');

    Array.prototype.forEach.call(matchingItems, (el) => {
      let html = el.innerHTML;

      el.innerHTML = html.replace(re, (full) => {
        return `<span class="highlight" searchIndex="${counter++}">${full}</span>`
      });
    });
    numberOfResults = counter;

    if (!isSourceCodeFormatType) {
      moveNext(true);
    }
  }

  function scrollToElm(container, currentEl) {
    const pos = getRelativePos(container, currentEl);
    scrollTo(container, pos.top);
  }

  function getRelativePos(parent, elm) {
    const pPos = parent.getBoundingClientRect();
    const cPos = elm.getBoundingClientRect();
    const pos = {};

    pos.top = cPos.top - pPos.top + parent.scrollTop;
    pos.bottom = cPos.bottom - pPos.bottom;

    return pos;
  }

  function scrollTo(container, to) {
    const start = container.scrollTop;
    const change = to - start;
    const shiftFromContainerTop = 40;

    container.scrollTop = start + change - shiftFromContainerTop;
  }

  async function updateSnippets(page) {
    if (!page) {
      return;
    }

    topicPage = new TopicPage(page.jsonData);
    const resp = await topicPage.readSnippets();

    topLevelSnippets = [];
    if (resp && resp.topLevelSnippets.length) {
      topLevelSnippets = resp.topLevelSnippets;
      dispatch('snippetsuid', {
        suid: topLevelSnippets[0].suid,
      });
    }

    isSourceCodeFormatType = topLevelSnippets.length === 1 && topLevelSnippets[0].formatType === FORMAT_TYPE.SourceCodeViewer;

    if (topLevelSnippets.length && !isSourceCodeFormatType) {
      await tick();
      highlightSearchResults();
      findLinks();
    }

    timeStampOptions = getTimeStampOptions(topicPage, userOptions);
    renderLeftMargin = getRenderLeftMargin(topicPage, userOptions);
    renderWithSeparators = getRenderWithSeparators(topicPage, userOptions);
  }

  function getRenderLeftMargin(page, userOptions) {
    if (!page) {
      return false;
    }

    if (!page.jsonData || !page.jsonData.options) {
      return false;
    }
    
    if (!hasDateTimeInfo()) {
      // Only display the left margin if we have some content
      // to put in it
      return false;
    }
    
    return page.options.getEffectiveDisplayGutter(userOptions.globalTopicPageOptions);
  }

  function getRenderWithSeparators(page, userOptions) {
    if (!page) {
      return false;
    }
    if (!page.jsonData || !page.jsonData.options) {
      return false;
    }
    
    return page.options.getEffectiveDisplaySeparators(userOptions.globalTopicPageOptions);
  }

  function getTimeStampOptions(page, userOptions) {
    if (!page) {
      return new OverrideableTimeStampOptions();
    }
    
    if (page.timeStampOptions && page.timeStampOptions.override) {
      return page.timeStampOptions;
    } else if (!userOptions) {
      return new OverrideableTimeStampOptions();
    } else {
      return userOptions.topicPageTimeStampOptions;
    }
  }

  async function onAddSnippet(data) {
    const url = `/apiv2/topicpages/${topicPage.suid}/snippets`;

    const res = await RequestService.post(url, data.detail.snippet);

    if (res.resultCode === 0) {
      dispatch('viewpage');
    }
  }

  function snippetAdded() {
    dispatch('viewpage')
  }

  function cancelSnippetUpdate() {
    dispatch('viewpage')
  }
  
  function hasDateTimeInfo() {
    return timeStampOptions.displayDate || timeStampOptions.displayTime;
  }

  function getCreateDateTimeInfo(snippet) {
    let dt = DateUtil.getDateFromJson(snippet.createDate);

    if (timeStampOptions.displayDate && !timeStampOptions.displayTime) {
      return DateUtil.formatDate(dt);
    } else if (!timeStampOptions.displayDate && timeStampOptions.displayTime) {
      return DateUtil.formatTime(dt);
    } else if (timeStampOptions.displayDate && timeStampOptions.displayTime) {
      return DateUtil.formatDate(dt) + "<br>" + DateUtil.formatTime(dt);
    } else {
      return "";
    }
  }

  function getLastModifiedDateTimeInfo(snippet) {
    let dt = DateUtil.getDateFromJson(snippet.lastModifiedDate);

    if (timeStampOptions.displayDate && !timeStampOptions.displayTime) {
      return DateUtil.formatDate(dt);
    } else if (!timeStampOptions.displayDate && timeStampOptions.displayTime) {
      return DateUtil.formatTime(dt);
    } else if (timeStampOptions.displayDate && timeStampOptions.displayTime) {
      return DateUtil.formatDate(dt) + "&nbsp;" + DateUtil.formatTime(dt);
    } else {
      return "";
    }
  }  

  function findLinks() {
    const hyperLinks = tableSnippetRef.getElementsByTagName('a');

    if (hyperLinks) {
      for (let i = 0; i < hyperLinks.length; i++) {
        hyperLinks[i].addEventListener('click', addCustomClickEvent, false);
      }
    }
  }

  function addCustomClickEvent(evt) {//https:// http:/
    if (evt.target.attributes[0].nodeValue.startsWith('http')) {
      return;
    }
    evt.preventDefault();
    openPageLinkEvent$.set({
      linkPath: evt.target.attributes[0].nodeValue,
      startPage: topicPage, // The starting page of traversal is this page
      startNode: navNode    // The starting node of traversal is the associated node for this page
    });
  }
</script>

<style lang="scss">
  .empty-page {
    color: #833;
  }
</style>

{#if topicPage && topicPage.jsonData && topLevelSnippets && !userOptions}
  <MissingPropertyError
    component={me}
    propertyName="userOptions"
  />
{:else if topLevelSnippets.length}
  <div class="loj-page-content topic-page-renderer">
    <table class="table-snippets" bind:this={tableSnippetRef}>
      {#each topLevelSnippets as snippet (snippet.suid)}
        <tr>
          {#if renderLeftMargin}
            <td class="snippet-row-control snippet-timestamp text-[12px] align-middle"
                class:snippet-row-control-with-separator="{renderLeftMargin && renderWithSeparators}"
            >
              Last updated<br>
              {@html getLastModifiedDateTimeInfo(snippet)}
            </td>
          {/if}
          <td class="snippet-content"
              class:with-borders="{renderWithSeparators}"
          >
            <SnippetViewer 
              {searchData}
              {switchSourceCodeSearchResults$}
              {switchSearchFile$}
              {snippet}
            />
          </td>
        </tr>
      {/each}
    </table>
  </div>
{:else}
  <div class="empty-page">This page contains no content.</div>
{/if}
