<script>
  import { createEventDispatcher } from 'svelte';
  import { LoginService } from "./LoginService";

  export let loginState;

  const dispatch = createEventDispatcher();
  let login = '';
  let errMessage = '';

  async function onResetPassword() {
    const resetPasswordResp = await LoginService.requestResetPassword(login);

    if (resetPasswordResp.resultCode === 0) {
      login = '';
    } else {
      errMessage = resetPasswordResp.resultMessage;
    }
  }

  function onSignIn() {
    dispatch('changeLoginState', loginState.SignIn);
  }
</script>

<style lang="scss">
  form {
    .login-form-field {
      .err-message {
        line-height: 1.2;
      }
    }

    button[type='submit'] {
      margin-top: 15px;
    }
  }
</style>

<div class="login-form px-[20%]">
  <h1>Reset password</h1>

  <span class="login-form--hint">Enter loginId or e-mail address associated with your account and we'll send you a reset code that can be used to reset your password.</span>
  <form on:submit|preventDefault={onResetPassword}>

    <div class="login-form-field">
      <label>
        <input type="text"
               required
               placeholder="Login Id or E-mail Address"
               bind:value={login}>
      </label>
      {#if errMessage}
        <div class="err-message">{errMessage}</div>
      {/if}
    </div>
    <p>
      If the e-mail address you entered is associated with an OpenSquiggly account,
      then you should receive an e-mail within a few minutes with a password reset key and instructions for how to reset
      your password.
    </p>
    <button type="submit">Submit reset</button>
  </form>

  <span class="or-text">or</span>
  <button type="button" class="go-to-btn" on:click={onSignIn}>Sign in</button>
</div>
