<script>
  export let value;
  export let suffix;
</script>

<style>
  div.field-input-container {
    width: 100%;
    display: inline-block;
  }
  
  input.field-input {
    width: 100px;
  }
</style>

{#if suffix}
  <div class="field-input-container"><input class="field-input" type="number" bind:value={value}>&nbsp;{suffix}</div>
{:else}  
  <input class="field-input" type="number" bind:value={value}>
{/if}
