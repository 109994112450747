<style lang="scss">
  .wrapper {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
</style>

<div class="wrapper">
  <slot />
</div>
