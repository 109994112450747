<script>
  import { createEventDispatcher } from 'svelte';
  import VerticalCenter from '../layout-helpers/VerticalCenter.svelte';
  import VerticalCenterItem from '../layout-helpers/VerticalCenterItem.svelte';

  export let pinnable = false;
  export let pinned = false;
  export let backgroundColor = null;

  const dispatch = createEventDispatcher();

  function onTogglePin() {
    dispatch('togglepin');
  }

  function getWrapperStyle() {
    return backgroundColor ? "background-color: " + backgroundColor : "";
  }
</script>

<style lang="scss">
  .flex-wrapper {
    display: flex;
    padding: 0 10px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    .left-side-items {
      height: 100%;
      padding: 0;
    }

    .right-side-items {
      height: 100%;
      padding: 0;

      button {
        height: 32px;
        width: 32px;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
      }

      .pinned {
        border: 4px inset darkgray;
        background-color: lightgray;
        color: darkgray;
        outline: none;
      }

      .icon {
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
</style>

<div class="flex-wrapper two-part-header-pane" style={getWrapperStyle()}>
  <div class="left-side-items header-title">
    <slot name="left" />
  </div>
  <div class="right-side-items">
    <VerticalCenter>
      <slot name="right" />
      {#if pinnable}
        <VerticalCenterItem>
          <button on:click={onTogglePin} class:pinned={pinned}>
            <span class="material-icons-outlined icon">push_pin</span>
          </button>
        </VerticalCenterItem>
      {/if}
    </VerticalCenter>
  </div>
</div>  
