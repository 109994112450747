<script>
  import { slide } from "svelte/transition";
  import { createEventDispatcher } from "svelte";

  export let id = 0;
  export let openedAccordion = 0;

  const dispatch = createEventDispatcher();
</script>

<style lang="scss">
  @import '../../../static/styles/core-fonts';
  @import '../../../static/styles/core-palette';

  .collapsible-body {
    border-bottom: 1px solid $warmgray-300;
    box-sizing: border-box;
    padding: 15px;
  }

  .collapsible-header {
    display: flex;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 1.5;
    padding: 10px 15px;
    border-bottom: 1px solid $warmgray-300;
    font-weight: bold;
    font-size: $font-size-2;
  }
</style>

<div class="collapsible-header" on:click={() => dispatch('accordionSelected', id)}>
  <slot name="title" />
</div>

{#if id === openedAccordion }
  <div class="collapsible-body"><!--transition:slide|local-->
    <slot />
  </div>
{/if}
