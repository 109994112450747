import { DEFAULT_GIT_REPO_PATTERN_LIST, CONNECTION_TYPE, HOST_NAME } from "../../constants";

export const getDefaultViewUrl = (form) => {
  const gitRepo = DEFAULT_GIT_REPO_PATTERN_LIST.find((git) => git.gitRepoType === form.currentConnectionType);

  return gitRepo ? gitRepo.pattern : '';
};

export const getDefaultRepoUrl = (form) => {
  const gitRepo = DEFAULT_GIT_REPO_PATTERN_LIST.find((git) => git.gitRepoType === form.currentConnectionType);

  return gitRepo ? gitRepo.publicPattern : '';
};

export const getDefaultGitHostName = (form) => {
  switch (form.currentConnectionType) {
    case CONNECTION_TYPE.GENERIC_GIT_HOST:
      return HOST_NAME.GenericGitHost;
    case CONNECTION_TYPE.AZURE_DEV_OPS:
      return HOST_NAME.AzureDevOps;
    case CONNECTION_TYPE.BITBUCKET_CLOUD:
      return HOST_NAME.Bitbucket;
    case CONNECTION_TYPE.GITHUB:
      return HOST_NAME.GitHub;
    case CONNECTION_TYPE.GITLAB:
      return HOST_NAME.GitLab;
    default:
      return  'Native Host';
  }
};

/*
AZURE_DEV_OPS
'https://$PAT@dev.azure.com/$ORGNAME/$PROJECTNAME/_git/$REPONAME'

GITHUB
'https://$PAT@github.com/$OWNERNAME/$REPONAME.git'

BITBUCKET_CLOUD
'https://$AUTH_USERNAME:$AUTH_PASSWORD@bitbucket.org/$WORKSPACEID/$REPONAME.git',
*/
